import styled, { css } from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const ContentLabel = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  align-items: ${(props) => (props.selfEnd ? props.selfEnd : "self-end")};
  cursor: pointer;

  @media (min-width: 1024px) {
    width: ${(props) =>
    props.widthTablet
      ? props.widthTablet
      : props.widthDesk
        ? props.widthDesk
        : "100%"};
    font-size: 1rem;
    display: flex;
    align-items: center;
    height: fit-content;
  }

  @media (min-width: 1800px) {
    width: ${(props) => (props.widthDesk ? props.widthDesk : "100%")};
  }
`;

export const Label = styled.label`
  margin-right: ${(props) => props.marginright && props.marginright};
  width: 100%;
  font-size: 0.875rem;
  line-height: ${(props) => props.lineHeight && props.lineHeight};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
  font-family: "Open Sans";
  color: ${(props) => (props.isError ? colors.error : colors.gray02)};
  align-items: self-end;
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  cursor: pointer;
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};

  @media (min-width: 1024px) {
    font-size: 1rem;
    padding-top: 0.5rem;
  }
`;
export const Input = styled.input`
  background: #bebebe;
  border: 1px solid #707070;
  border-radius: 5px;
  min-width: 1.25rem;
  min-height: 1.25rem;
  margin-right: 0.625rem;
  align-items: self-end;

  ${(props) =>
    props.isError &&
    css`
      :before {
        content: "";
        color: transparent !important;
        display: block;
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 3px;
        border: 1px solid ${colors.error};
      }
    `}
`;
