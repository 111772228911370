import React from "react";

import Info from "../../Info";
import * as S from "./styles";

const InputNumber = ({
  paddingBottom,
  paddingRight,
  paddingTop,
  paddingLeft,
  paddingLeftMedia,
  widthNumber,
  width,
  height,
  border,
  weight,
  maxLength,
  order,
  compSelect,
  isSelect,
  column,
  label,
  place,
  value,
  name,
  onChange,
  info,
  widthDesk,
  noRequired,
  isError,
}) => {
  return (
    <S.Container
      compSelect={compSelect}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingLeftMedia={paddingLeftMedia}
      column={column}
    >
      <S.ContentLabel widthDesk={widthDesk} width={width}>
        <S.Label
          weight={weight}
          isSelect={isSelect}
          paddingRight={paddingRight}
          width={width}
          order={order}
        >
          {label}
        </S.Label>
        {info && (
          <S.ButtonInfo>
            <Info>Colaboradores remunerados</Info>
          </S.ButtonInfo>
        )}
      </S.ContentLabel>

      <S.Input
        type="number"
        widthNumber={widthNumber}
        placeholder={place || "0"}
        value={value}
        name={name}
        onChange={onChange}
        maxLength={maxLength}
        height={height}
        border={border}
        min="0"
        defaultValue="0"
        required={noRequired ? false : true}
        compSelect={compSelect}
        isError={isError}
      />
    </S.Container>
  );
};
export default InputNumber;
