import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// Icons
import CloseModal from "../../../../../../assets/icons/close.svg";

// Components
import Title from "../../../../../../components/Title/TitleWithLine";
import { colors } from "../../../../../../styles/Theme";

import {
  skinColorsList,
  birthGenderList,
  genderList,
  scholarityList,
  workList,

} from "../../../../../../utils/lists";

// provider
import { useApi } from "../../../../../../providers/Api";

// styles
import * as S from "./styles";
import { cpfMask } from "../../../../../../utils/Masks/cpf";
import { toast, ToastContainer } from "react-toastify";

function InputHeading(props) {
  return (
    <S.ContainerInputHeading>
      <S.TitleInput>{props.title} *</S.TitleInput>
      <S.Input
        onChange={props.onChange}
        value={props.value}
        placeholder={props.label}
        disabled={props.isEdit}
        name={props.name}
        mask={props.mask}
        {...props}
      />

      {props.isOptional === true && (
        <div>
          <input type="radio" />
          <S.Label>Nao possui</S.Label>
        </div>
      )}
    </S.ContainerInputHeading>
  );
}

function InputMarked(props) {
  return (
    <S.BoxInputMarkup>
      <S.TitleInput {...props}>{props.title} *</S.TitleInput>
      <S.ContentInput>
        <input
          type={props.type}
          checked={props.marked}
          disabled={props.isEdit}
          {...props}
          readOnly
        />
        <S.Label>{props.label} *</S.Label>
      </S.ContentInput>
    </S.BoxInputMarkup>
  );
}

function ModalGrey({ data, setIsModalContainer }) {
  const { listAssisteds, getMyUser, deleteAssisted, updateAssisted } = useApi();
  const [isEdit, setIsEdit] = useState(false);
  const [listAssisted, setListAssisted] = useState([]);
  const [user, setUser] = useState();
  const [isModal, setIsModal] = useState(false);
  const [form, setForm] = useState({
    fullname: data?.fullname,
    cpf: data?.cpf,
    birth_date: data?.birth_date.split('-').reverse().join('/'),
    contact_num: data?.contact_num,
    responsible_name: data?.responsible_name,
  });
  const { register, handleSubmit } = useForm();

  const handleChange = (ev) => {
    const { name, value } = ev.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleEdit = (id) => {

    if (!isEdit) {
      return setIsEdit(true)
    }

    form.birth_date = form.birth_date.split('/').reverse().join('-')

    const { id: _, ong, family_group, ...assistedData } = { ...data, ...form }
    updateAssisted(assistedData, id)

    setIsEdit(false);

  };

  const onSubmit = async () => {
    await listAssisteds().then((res) => {
      setListAssisted(res.response)

    });
  };

  const handleDelete = async (id) => {
    await deleteAssisted(id).then((res) => {
      onSubmit();

      toast.success('Assistido deletado com sucesso')
    });
    setIsModalContainer(false)
  };

  useEffect(() => {
    (async () => await getMyUser().then((res) => setUser(res)))();
    onSubmit();
  }, []);

  return (
    <S.Container>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Title line>Dados pessoais</Title>
        <S.ContainerInputPersonalData>
          <InputHeading
            title="CPF"
            value={form.cpf}
            isEdit={!isEdit}
            onChange={handleChange}
            name="cpf"
            mask="999.999.999-99"
          // {...register("cpf")}
          />
          <InputHeading
            title="Nome"
            isEdit={!isEdit}
            value={form.fullname}
            onChange={handleChange}
            name="fullname"
          // {...register("fullname")}
          />
          <InputHeading
            title="Data de nascimento"
            isEdit={!isEdit}
            value={form.birth_date}
            onChange={handleChange}
            mask="99/99/9999"
            name="birth_date"
          // {...register("birth_date")}
          />
          <InputHeading
            title="Celular para contato"
            isEdit={!isEdit}
            value={form.contact_num}
            onChange={handleChange}
            mask="(99) 99999-9999"
            name="contact_num"
          // {...register("contact_num")}
          />
          <InputHeading
            title="Nome do Responsável"
            isEdit={!isEdit}
            value={form.responsible_name}
            name="responsible_name"
            onChange={handleChange}
          // {...register("responsible_name")}
          />
        </S.ContainerInputPersonalData>

        <Title line>Dados socioeconômicos</Title>
        <div>
          <S.ContainerInputMarkup>
            <InputMarked
              type="radio"
              title="Cor"
              label={
                skinColorsList.find((item) => item.value === data?.skin_color)
                  ?.name
              }
              marked
              {...register("Cor")}
            />

            <InputMarked
              type="radio"
              title="Sexo de nascimento"
              label={
                birthGenderList.find((item) => item.value === data.birth_gender)
                  ?.name
              }
              marked
              {...register("sexo-de-nascimento")}
            />

            <InputMarked
              type="radio"
              title="Gênero"
              label={
                genderList.find((item) => item.value === data.gender)?.name
              }
              marked
              {...register("Gênero")}
            />
          </S.ContainerInputMarkup>

          <S.ContainerFormation>
            <S.ContentFormation>
              <InputMarked
                type="radio"
                title="Escolaridade"
                label={
                  scholarityList.find((item) => item.value === data.scholarity)
                    ?.name
                }
                marked
                {...register("Escolaridade")}
              />
              {data.scholarity === "completed_college" && (
                <S.BoxFormationInput>
                  <S.TitleInput maxW="100%" mediaMaxW="250px">
                    Qual curso superior o assistido é graduado?
                  </S.TitleInput>
                  <S.InputDiference
                    type="text"
                    maxW="200px"
                    value={data?.graduation_name}
                    disabled={isEdit}
                  />
                </S.BoxFormationInput>
              )}
            </S.ContentFormation>
            <InputMarked
              type="radio"
              title="O assistido possui trabalho formal?"
              label={workList.find((item) => item.value === data.work)?.name}
              checked={data?.work}
              {...register("trabalho-formal")}
              readOnly
            />
          </S.ContainerFormation>

          <S.ContentJob>
            <S.TitleInput>
              Quais atividades desempenhada como trabalho, mesmo que não seja
              trabalho formal?
            </S.TitleInput>
            {data?.work_activities.map((item) => (
              <S.InputDiference
                key={item.id}
                type="text"
                maxW="400px"
                value={item.name}
                disabled={isEdit}
                {...register("trabalho_formal")}
              />
            ))}
          </S.ContentJob>
        </div>

        <S.ContainerInfoDeficiencies>
          <S.ContentInfoDeficiencies>
            <InputMarked
              type="radio"
              title="Recebe algum auxílio do governo?"
              label={data?.government_aid === true ? "Sim" : "Não"}
              marked
              maxW="170px"
            />

            <div>
              {data?.government_aids.length !== 0 ? (
                <S.TitleInput>Quais? *</S.TitleInput>
              ) : (
                ""
              )}

              <S.InputMarkedIfoDeficiences>
                {data?.government_aids.map((item) => (
                  <S.InputMarkedIfoDeficiences>
                    <input type="checkbox" checked readOnly/>
                    <S.Label>{item.name}</S.Label>
                  </S.InputMarkedIfoDeficiences>
                ))}
              </S.InputMarkedIfoDeficiences>
            </div>
          </S.ContentInfoDeficiencies>

          <S.ContentInfoDeficiencies>
            <InputMarked
              type="radio"
              title="Possui alguma doença crônica?"
              label={data?.chronic_disease === true ? "Sim" : "Não"}
              marked
              maxW="170px"
              {...register("doenca")}
            />

            <div>
              {data?.diseases.length !== 0 ? (
                <S.TitleInput>Quais? *</S.TitleInput>
              ) : (
                ""
              )}
              <S.InputMarkedIfoDeficiences display="grid">
                {data?.diseases.map((item) => (
                  <S.InputMarkedIfoDeficiences>
                    <input type="checkbox" checked />
                    <S.Label>{item.name}</S.Label>
                  </S.InputMarkedIfoDeficiences>
                ))}
              </S.InputMarkedIfoDeficiences>
            </div>
          </S.ContentInfoDeficiencies>
          <InputMarked
            type="radio"
            title="Possui alguma deficiência física?"
            label={data?.physical_disability === true ? "Sim" : "Não"}
            marked
            {...register("deficiencia_fisica")}
          />
        </S.ContainerInfoDeficiencies>

        <Title line>Informações sobre a Família</Title>
        <div>
          <S.ContentFamilyInternet>
            <S.TitleInput>
              A familia tem acesso à internet banda larga? *
            </S.TitleInput>

            <S.BoxInputsFamilyInternet>
              <S.InputFamilyInternet>
                <input
                  type="radio"
                  checked={data?.family_group.internet_access}
                  {...register("internet_banda_larga")}
                  readOnly
                />
                <S.Label>Sim</S.Label>
              </S.InputFamilyInternet>

              <S.InputFamilyInternet>
                <input
                  type="radio"
                  checked={!data?.family_group.internet_access}
                  readOnly
                />
                <S.Label>Não</S.Label>
              </S.InputFamilyInternet>
            </S.BoxInputsFamilyInternet>
          </S.ContentFamilyInternet>
          {data?.family_group?.internet_access === true && (
            <S.ContainerInputsNumber>
              <S.BoxInputsNumbers>
                <S.TitleInput>
                  Quantas pessoas da família possuem celular com acesso à
                  internet? *
                </S.TitleInput>
                <input
                  type="number"
                  value={data?.family_group.cell_qty}
                  {...register("total_celulares_a_internet")}
                />
              </S.BoxInputsNumbers>
              <S.BoxInputsNumbers>
                <S.TitleInput>
                  Quantos computadores ou notebooks a família possui? *
                </S.TitleInput>
                <input
                  type="number"
                  value={data?.family_group.computer_qty}
                  {...register("total_computadores")}
                />
              </S.BoxInputsNumbers>
            </S.ContainerInputsNumber>
          )}
          <S.ContainerTotalIncome>
            <S.TitleInput>
              Qual a renda total da família? *
              <span>
                (esta renda deve ser desconsiderado o valor que recebe de
                auxílio)
              </span>
            </S.TitleInput>
            <S.InputTotalIncome>
              <input type="radio" checked {...register("renda_total")} readOnly/>
              <span>{data?.family_group.family_income}</span>
            </S.InputTotalIncome>
          </S.ContainerTotalIncome>

          <S.ContainerFooter>
            {isModal && (
              <S.Modal>
                <img
                  src={CloseModal}
                  alt="icon de fechar modal"
                  onClick={() => setIsModal(false)}
                />
                <h3>Esta ação irá deletar os dados do assistido.</h3>
                <S.Label>Tem certeza que deseja deletar esses dados ?</S.Label>

                <S.BoXButtons>
                  <S.ButtonsModal
                    backgroundColor={colors.white}
                    color={colors.orange}
                    onClick={() => setIsModal(false)}
                  >
                    Cancelar
                  </S.ButtonsModal>
                  <S.ButtonsModal
                    onClick={() => handleDelete(data?.id)}
                    backgroundColor={colors.darkBlue}
                    color={colors.white}
                  >
                    Sim, deletar dados
                  </S.ButtonsModal>
                </S.BoXButtons>
              </S.Modal>
            )}
            <S.ContentButtons>
              <S.Button onClick={() => setIsModal(true)}>
                Excluir assistido
              </S.Button>
              <S.Button onClick={() => handleEdit(data.id)}>
                {!isEdit ? 'Editar dados do assistido' : 'Salvar mudança'}
              </S.Button>
            </S.ContentButtons>
          </S.ContainerFooter>
        </div>
      </S.Form>
      <ToastContainer />
    </S.Container>
  );
}

export default ModalGrey;
