import React from "react";

//Styles
import * as S from "./styles";

const ContentModal = ({
  width,
  top,
  name,
  list,
  value,
  line,
  checked,
  handleChange,
}) => {
  return (
    <S.Container width={width} top={top}>
      {list?.map((i, idx) => (
        <S.Label>
          <S.Fill checked={i === checked} />
          <S.Input
            name={name || "profile"}
            value={value}
            id={i}
            type="radio"
            checked={i === checked}
            onChange={handleChange}
          />
          {i}
          <S.Line last={idx === list.length - 1}>{line}</S.Line>
        </S.Label>
      ))}
    </S.Container>
  );
};

export default ContentModal;
