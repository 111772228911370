import styled from 'styled-components';
import { colors } from '../../styles/Theme';


export const Container = styled.div`
    display:flex;
    width: ${props => props.width || '8rem'};
`

export const img = styled.img`
    width:3rem;
`

export const TextNumber = styled.p`
    margin-top:4rem;
    margin-right:1rem;
    font-weight: 800;
    font-size: 3.75rem;
    color: ${colors.blue01};
`

export const Text = styled.p`
    font-family: 'Open sans';
    font-weight: 700;
    text-align: left;
    font-size: 1rem;
    max-width: 12rem;
`

export const TextImage = styled.div`
    text-align: left;
`