import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../../modules/api";
import { IconLeft, IconRight, Vi2 } from "../../../assets";
import { changePermission } from "../../../utils/permissions";

// Components
import Header from "../../../components/Header";
import FooterGreen from "../../../components/Footer/FooterGreen";
import Btn from "../../../components/Buttons/button";

// Styles
import * as S from "./styles";

const Welcome = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [currentText, setCurrentText] = useState(0);
  const isDescktop = window.innerWidth > 768;

  const listSlider = [
    "Saiba que todas as informações que você disponibilizar aqui servem para te conectar a potenciais parceiros de acordo com o seu perfil. ",
    "Confira a seguir, a ordem em que os tópicos do formulário aparecem. Assim, você saberá em que ponto estará.",
  ];

  const handleBackSlider = () => {
    const backSlider = currentText - 1;
    setCurrentText(backSlider);
  };

  const handleNextSlider = () => {
    if (listSlider.length === currentText) return handleNextScreen();
    const nextSlider = currentText + 1;
    setCurrentText(nextSlider);
  };

  const handleNextScreen = () => {
    const localStorageFormFinished = JSON.parse(
      localStorage.getItem("FormOngfinished")
    );

    if (localStorageFormFinished) {
      return navigate("/home");
    }
    navigate("/transicao");
  };

  const handleRoute = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      },
    };
    await api
      .get("/user/me/", headers)
      .then((response) => {
        if (response?.data[0]?.ong_id?.current_form === "1") {
          changePermission(1);
          navigate("/cadastro/atuacao-e-assistidos");
        } else if (response?.data[0]?.ong_id?.current_form === "2") {
          changePermission(2);
          navigate("/cadastro/estrutura-fisica-e-funcional");
        } else if (response?.data[0]?.ong_id?.current_form === "3") {
          const typeDocument = response?.data[0]?.ong_id?.document_type;
          localStorage.setItem("@Vision/document", typeDocument);
          changePermission(3);
          navigate("/cadastro/envio-de-documentos");
        } else if (response?.data[0]?.ong_id?.current_form === "4") {
          localStorage.setItem("@Vision/ongRegistrationCompleted", true);
          if (isDescktop) {
            navigate("/home");
          } else {
            navigate("/tutorial");
          }
        } else {
          navigate("/cadastro/contatos-e-dados-oficiais");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(true);
  };

  const skipWelcome = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("idToken")}`,
      },
    };
    await api.get("/user/me/", headers).then((response) => {
      if (response?.data[0]?.ong_id?.current_form === "4") {
        if (isDescktop) {
          navigate("/home");
        } else {
          navigate("/tutorial");
        }
      }
      setLoading(true);
    });
  };

  useEffect(() => {
    skipWelcome();
  }, []);

  const handleContactScreen = () => {
    handleRoute();
  };

  const renderIconBackSlider = () => (
    <S.BtnLeft>
      <S.ImgIconLeft src={IconLeft} alt="Voltar" onClick={handleBackSlider} />
    </S.BtnLeft>
  );

  const renderSubtitle = () => (
    <S.ContentVi>
      <S.Title width="11rem">
        Olá! Eu sou a <S.ViText>Vi</S.ViText>,
        <div>
          sua assistente virtual. Estou aqui para guiar a sua jornada no Vision.
        </div>
      </S.Title>
    </S.ContentVi>
  );

  const RenderTitle = () => (
    <S.Content bckg={listSlider[currentText]}>
      {currentText === 0 ? (
        renderSubtitle()
      ) : (
        <S.Title>{listSlider[currentText - 1]}</S.Title>
      )}
      <S.BoxBtn>
        {0 !== currentText ? (
          renderIconBackSlider(null, handleBackSlider, "Voltar")
        ) : (
          <div></div>
        )}
        {listSlider.length === currentText && isDescktop ? (
          <Btn text="Iniciar cadastro" onClick={handleContactScreen} />
        ) : (
          <S.BtnRight>
            <S.IconImgRight
              onClick={() => {
                handleNextSlider();
              }}
              src={IconRight}
              alt="right"
            />
          </S.BtnRight>
        )}
      </S.BoxBtn>
    </S.Content>
  );

  return (
    <>
      {loading && (
        <S.Container>
          <Header />
          <S.Main>
            <S.ContentData>
              <RenderTitle />
            </S.ContentData>
            <S.ContentBCKGGreen>
              <FooterGreen />
              <S.ImgVi src={Vi2} alt="Vi" />
            </S.ContentBCKGGreen>
          </S.Main>
        </S.Container>
      )}
    </>
  );
};

export default Welcome;
