import React from 'react';

//Styles
import * as S from './styles';

const Subtitle = ({ subtitle, homeScreen, bold, color, widthDesk, weight, font, paddingBottom, paddingTop, paddingTopMob, paddingBottomMob, widthMob}) => {
  return (
    <S.Subtitle widthMob={widthMob} paddingBottomMob={paddingBottomMob} paddingTopMob={paddingTopMob} paddingBottom={paddingBottom} paddingTop={paddingTop} font={font} weight={weight} widthDesk={widthDesk} color={color} bold={bold} homeScreen={homeScreen}>
      {subtitle}
    </S.Subtitle>
  )
}

export default Subtitle;
