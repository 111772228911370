import styled from "styled-components/macro";
import { colors } from "../../styles/Theme";

export const Container = styled.div`
  position: relative;
  border-radius:10px;
  border:none;
  width: 19rem;
  height:18rem;
  color: #FFFFFF;
  background-color:rgba(84, 132, 237, 0.2);
  display:none;

  @media (min-width: 1024px) {
    width: 18.6rem;
    height: 15.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media (min-width: 1600px) {
    width: 23.25rem;
    height: 19rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const img = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left:1rem;
  height:12.8rem;
  width:9.6rem;

  @media (min-width: 1600px) {
    height:16rem;
    width:12rem;
  }
`

export const Text = styled.h3`
  color: ${colors.black};
  width: 10rem;
  margin-right: 4rem;
  font-size: 1rem;
  font-weight: 800;

  @media (max-width: 1600px) {
    margin-right: 1rem;
  }
`
