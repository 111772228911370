import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};

  .labelElement {
    font-weight: 600;
    position: absolute;
    top: 5px;
    left: 12px;
    transition: top 0.2s;
  }

  .inputElement {
    padding: 0.5rem 0.75rem;
    margin: 0;
    background-color: #f6f6f6;
    border: 1px solid
      ${({ isBorderColorRed }) => (isBorderColorRed ? "#e54747" : "#bebebe")};
    border-radius: 5px;
    color: ${({ isBorderColorRed }) =>
      isBorderColorRed ? "#B71C1C" : "#333333"};
  }

  .inputElement:focus + .labelElement {
    top: -24px;
    left: 0;
    margin-left: 0px;
  }

  .non-empty {
    top: -24px;
    left: 0;
  }

  .error {
    color: #b71c1c;
  }

  .description {
    font-size: 14px;
  }

  .bold {
    font-weight: 700;
  }

  @media only screen and (max-width: 1400px) {
    width: 100%;
  }
`;
