import styled from "styled-components";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  padding-top: ${(props) => props.paddingTop && "1rem"};
  display: flex;
  align-items: center;
  width: ${(props) => props.width || props.width};

  @media (max-width: 633px) {
    position: relative;
    width: 100%;
  }

  @media (min-width: 1024px) {
    padding-bottom: ${(props) => props.paddingBottom && props.paddingBottom};
  }
`;

export const Title = styled.h1`
  padding-right: 0.5rem;
  font-size: 19px;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 65px;
  color: ${colors.black};
  white-space: nowrap;

  @media (min-width: 500px) {
    width: initial;
    white-space: nowrap;
    font-size: 30px;
  }

  @media (min-width: 1000px) {
    font-size: 30px;
  }
`;

export const LineSubtitle = styled.div`
  display: none;
  display: ${(props) => (props.line ? "flex" : "none")};
  height: 1px;
  width: 100%;
  background: ${colors.black02};

  @media (min-width: 1024px) {
    display: flex;
    margin-left: 1.625rem;
  }
`;
