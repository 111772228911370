import { StyledTitle } from "./styles";

const TitleQuestion = ({ children, hasError, isSubtitle = false }) => {
  return (
    <StyledTitle hasError={hasError} isSubtitle={isSubtitle}>
      {children}
    </StyledTitle>
  );
};

export default TitleQuestion;
