import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Layout from "../../../components/Slider/LayoutSlider";
import Title from "../../../components/ProvisionalTitle";
import Subtitle from "../../../components/Subtitle";
import Btn from "../../../components/Buttons/button";
import Input from "../../../components/Inputs/index";
import InputP from "../../../components/Inputs/passwordInput/index";

//Api
import { api } from "../../../modules/api";

//Styles
import * as S from "./styles";
import BtnBack from "../../../components/Buttons/back";

const RenderTitle = () => (
  <S.ContentTitle>
    <Title paddingBottom="3.125rem">Conecte-se</Title>
    <Subtitle
      subtitle="Por favor, insira suas informações
        nos campos abaixo para entrar 
        na plataforma."
    />
  </S.ContentTitle>
);

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: undefined,
    password: undefined,
  });
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    localStorage.clear();
  }, []);
  const handleChange = (ev) => {
    ev.preventDefault();
    const { value, name } = ev.target;

    const updateForm = {
      ...form,
      [name]: value,
    };

    setHasError(false);
    setForm(updateForm);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const { email, password } = form;
    localStorage.clear();
    const data = {
      email: email,
      password: password,
    };

    try {
      const response = await api.post("/login/", data);
      const idToken = response.data.access;
      const FormOngfinished = JSON.parse(localStorage.getItem("FormOngfinished"));
      localStorage.setItem("idToken", idToken);

      if (FormOngfinished === true) {
        localStorage.setItem(
          "formStep",
          JSON.stringify({
            pathname: "/bem-vindo",
            step: 0,
          })
        );
      }
      localStorage.setItem("FormOngfinished", JSON.parse(false));

      const headers = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const responseUser = await api.get("/user/me/", headers);
      localStorage.setItem("@Vision/user", responseUser?.data[0]?.first_name);

      if (responseUser?.data[0]?.ong_id === null) {
        localStorage.setItem("FormOngfinished", JSON.parse(false));
      } else {
        localStorage.setItem("FormOngfinished", JSON.parse(true));
      }

      if (
        responseUser?.data[0]?.role === "Auxiliar" ||
        responseUser?.data[0]?.role === "Analista" ||
        responseUser?.data[0]?.role === "Supervisão" ||
        responseUser?.data[0]?.role === "Gerência"
      ) {
        localStorage.setItem("FormOngfinished", JSON.parse(true))
        return navigate("/tutorial");
      }

      if (!responseUser?.data[0]?.ong_id?.current_form === "4") {
        localStorage.setItem("@Vision/ongRegistrationCompleted", true);
        localStorage.setItem("FormOngfinished", JSON.parse(true));
        navigate("/cadastro-novos-assistidos");
      } else {
        navigate("/bem-vindo");
      }
    } catch (err) {
      console.log(err);
      if (
        err?.response?.data?.detail ===
        "No active account found with the given credentials"
      ) {
        setHasError("Usuario ou senha invalidos");
        return;
      }
      console.log("err", err);
      navigate("/login");

      setHasError(true);
    }
  };

  const isDesktop = window.innerWidth > 768;

  const handleClick = () => {
    navigate("/criar-conta");
  };

  const Footer = ({ text, link, href, underline, onClick }) => (
    <>
      <S.ContentLink>
        <S.Paragraph>{text}</S.Paragraph>
        <S.ParagraphLink onClick={onClick} underline={underline} href={href}>
          {link}
        </S.ParagraphLink>
      </S.ContentLink>
      {isDesktop ? null : <BtnBack />}
    </>
  );

  return (
    <Layout>
      <S.Content onSubmit={handleSubmit}>
        <RenderTitle />
        <Input
          label="E-mail"
          type="email"
          place="nossolar@home.org"
          name="email"
          value={form.email}
          onChange={handleChange}
          error={hasError}
          widthInput="100%"
        />
        <Input
          label="Senha"
          type="password"
          place="*****************"
          name="password"
          value={form.password}
          onChange={handleChange}
          error={hasError}
          marginTop="40px"
          widthInput="100%"
        />
        <InputP />
        <S.BoxBtn>
          <Btn widthDesk="236px" text="Entrar" marginLeft="0" />
        </S.BoxBtn>
        <Footer
          text="Não tem uma conta ainda ?"
          link="Criar uma conta agora"
          onClick={handleClick}
          underline
        />
      </S.Content>
    </Layout>
  );
};

export default Login;
