import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HiUserRemove, HiUserAdd } from "react-icons/hi";
import * as S from "./styles";
import { createFamilyGroupSchema } from "../../utils/schemas";
import Input from "../Input";
import { useEffect, useState } from "react";
import InputMasked from "../InputMasked";
import { reverseDate } from "../../utils/reverseDate";
import { useApi } from "../../providers/Api";
import Btn from "../Buttons/button";
import formMessages from "../../utils/formMessages";
import isEmpty from "lodash/isEmpty";
import { Text } from "../Text";
import { getListOfErrorsMessage } from "../../utils/messageUtils";
import { useMemo } from "react";

//Components
import ModalAlert from "../Modals/modalAlert";

// assets
import iconAlert from "../../assets/icons/iconAlert.png";

const FamilyGroupDataForm = ({ data, adderFn }) => {
  const { getMyUser } = useApi();
  const [user, setUser] = useState({});
  const [returnedError, setReturnedError] = useState(false);

  const [inputList, setInputList] = useState([]);

  const removeInput = (n) => {
    setInputList((v) => v.filter((input) => input !== n));
    unregister(`familyGroupResidents[${n - 1}].name`);
    unregister(`familyGroupResidents[${n - 1}].birthdate`);
    unregister(`familyGroupResidents[${n - 1}].cpf`);
  };

  const addInput = () => setInputList((v) => [...v, v.length + 1]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createFamilyGroupSchema),
    defaultValues: data,
  });

  const getFamilyGroupData = (data) => {
    if (!data.familyGroupResidents) {
      data.familyGroupResidents = [];
    }

    adderFn({ data, formStage: "secondPart" });
  };

  const comunityNameValue = watch("comunityName");

  useEffect(() => {
    getMyUser()
      .then(setUser, setReturnedError(false))
      .catch((error) => {
        return setReturnedError(true);
      });
  }, [returnedError]);

  useEffect(() => {
    if (!comunityNameValue) {
      setValue(
        "comunityName",
        data.comunityName || user?.ong_id?.ong_address?.community || "",
      );
    }
    if (!inputList.length) {
      unregister("familyGroupResidents");
      clearErrors("familyGroupResidents");
    }
  }, [
    data,
    setValue,
    unregister,
    clearErrors,
    comunityNameValue,
    inputList,
    user,
  ]);

  return (
    <S.Wrapper onSubmit={handleSubmit(getFamilyGroupData)}>
      {returnedError && (
        <ModalAlert
          icon={iconAlert}
          title={"Desculpe, estamos passando por uma instabilidade"}
          subtitle={`Por favor, tente novamente`}
          buttonText={"Tentar novamente"}
          handleCloseModal={() => {
            getMyUser();
            setReturnedError(false);
          }}
        />
      )}
      <h3 className="section-title">Endereço*</h3>
      <div className="community-wrapper">
        <h4 className="community-title">Comunidade</h4>
        <input
          {...register("comunityName", {
            disabled: true,
          })}
          className="community-name"
        />
      </div>
      <Input
        label="Área de comunidade (ou microrregião)*"
        name="comunityArea"
        register={register}
        error={errors?.comunityArea?.message}
        description={formMessages.comunityArea.description}
        hasSomeValue={watch("comunityArea") !== ""}
      />
      <div>
        <Input
          label="Rua*"
          description="Av. Niemeyer"
          name="street"
          register={register}
          error={errors?.street?.message}
          hasSomeValue={watch("street") !== ""}
        />
      </div>
      <div>
        <Input
          label="N*"
          description="Inclua o número referente a sua rua principal. Ex.: Av. Niemeyer, <span className='bold'>567</span>"
          name="number"
          type="number"
          register={register}
          error={errors?.number?.message}
          hasSomeValue={watch("number") !== ""}
        />
      </div>
      <Input
        label="Complemento*"
        description="Exemplo: Casa 34"
        name="adjunct"
        register={register}
        error={errors?.adjunct?.message}
        hasSomeValue={watch("adjunct") !== ""}
      />
      <Input
        label="Ponto de referência*"
        description="Exemplo: Em frente ao Bar do Zé"
        name="landmark"
        register={register}
        hasSomeValue={watch("landmark") !== ""}
        error={errors?.landmark?.message}
      />
      <S.StyledWrapper>
        <h3 className="section-title">Quais pessoas moram na sua casa?</h3>
        {errors?.familyGroupResidents && (
          <span>{errors.familyGroupResidents.message}</span>
        )}
        {inputList.map((n, idx) => (
          <S.InputListContainer
            key={idx}
            completed={
              (
                errors?.familyGroupResidents &&
                !!errors.familyGroupResidents[idx]
              )?.name || !!watch(`familyGroupResidents[${idx}].name`)
            }
          >
            <Input
              label="Nome completo do morador*"
              name={`familyGroupResidents[${idx}].name`}
              register={register}
              description="Não abrevie o nome"
              fullWidth={true}
              hasSomeValue={watch(`familyGroupResidents[${idx}].name`) !== ""}
              error={
                errors?.familyGroupResidents &&
                errors?.familyGroupResidents[idx]?.name?.message
              }
            />
            <Controller
              control={control}
              name={`familyGroupResidents[${idx}].birthdate`}
              render={({ field: { onChange, value, name } }) => (
                <InputMasked
                  label="Data de nascimento"
                  mask="99/99/9999"
                  onChange={onChange}
                  description={formMessages.birthDate.description}
                  fullWidth={true}
                  value={value}
                  currentValue={value}
                  name={name}
                  defaultValue={
                    data.familyGroupResidents &&
                    data.familyGroupResidents[idx] &&
                    reverseDate(data.familyGroupResidents[idx].birthdate)
                  }
                  error={
                    errors?.familyGroupResidents &&
                    errors?.familyGroupResidents[idx]?.birthdate?.message
                  }
                />
              )}
            />
            <Controller
              control={control}
              name={`familyGroupResidents[${idx}].cpf`}
              render={({ field: { onChange, value, name } }) => (
                <InputMasked
                  label="CPF"
                  mask="999.999.999-99"
                  onChange={onChange}
                  description={formMessages.cpf.description}
                  fullWidth={true}
                  value={value}
                  currentValue={value}
                  name={name}
                  defaultValue={
                    data.familyGroupResidents &&
                    data.familyGroupResidents[idx] &&
                    data.familyGroupResidents[idx].cpf
                  }
                  error={
                    errors?.familyGroupResidents &&
                    errors?.familyGroupResidents[idx]?.cpf?.message
                  }
                />
              )}
            />
            <S.ButtonRemove type="button" onClick={() => removeInput(n)}>
              <HiUserRemove />
              <span>Remover</span>
            </S.ButtonRemove>
          </S.InputListContainer>
        ))}
        <S.ButtonAdd type="button" onClick={() => addInput()}>
          <HiUserAdd />
          <span>Adicionar</span>
        </S.ButtonAdd>
        <p className="family-total">
          Total de pessoas que moram na casa: {inputList.length + 1}
        </p>
      </S.StyledWrapper>
      <S.StyledButtonWrapper>
        <Btn
          text={"Próximo"}
          color={"#5484ED"}
          borderRadius={"5px"}
          marginBottom={"0"}
        />
      </S.StyledButtonWrapper>
      {!isEmpty(errors) && (
        <Text.Root>
          <Text.ErrorWithIcon>
            {getListOfErrorsMessage(errors)}
          </Text.ErrorWithIcon>
        </Text.Root>
      )}
    </S.Wrapper>
  );
};

export default FamilyGroupDataForm;
