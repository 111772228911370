import styled from 'styled-components';
import { colors } from '../../../styles/Theme';

export const Container = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  @media(min-width: 1024px) {
    display: ${props => props.none && 'none'};
    position: absolute;
    right: 0;
    height: fit-content;
    width: auto;
  }
`;

export const ImgBack = styled.img`
  padding-bottom: .15rem;
  @media(min-width: 1024px) {
    width: 20px;
  } 
`;

export const Text = styled.p`
  font-size: .75rem;
  font-weight: 400;
  color: ${colors.gray};

  @media(min-width: 1024px) {
    font-size: .875rem;
  } 
`;
