import styled from "styled-components/macro";

import { colors } from "../../../../styles/Theme";

export const Container = styled.form`
  width: 100%;
`;

export const Content = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;


  @media (min-width: 1024px) {
    display: block;
  }
`;

export const ContainerSelect = styled.div`
  margin-top: 0.938rem;
  @media (min-width: 1024px) {
    width: 100%;
    padding: 3.25rem 0;
    display: flex;
    background: #eaeffb4d;
    border: 1px solid ${colors.blue};
    justify-content: center;
    border-radius: 15px;
  }
`;

export const BoxSelect = styled.div`
  @media (min-width: 1024px) {
    width: 90%;
    display: flex;
    border: 1px dashed #9e9e9e;
    border-radius: 15px;
    padding: 1.438rem 0 1.188rem 2.104rem;
    align-items: center;
    button {
      margin-bottom: 0;
    }
  }
`;
export const BoxContentSelect = styled.div`
  padding-left: 1.313rem;
  padding-top: 1.313rem;
  @media (min-width: 1024px) {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const BoxIconUpload = styled.figure`
  display: flex;
  padding-top: 2.5rem;
  justify-content: center;
  @media (min-width: 1024px) {
    display: flex;
    padding-top: 0;
  }
`;

export const Subtitle = styled.h2`
  max-width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${colors.black};
  width: 17.5rem;

  @media (min-width: 1024px) {
    width: 40rem;
    font-size: 1rem;
    line-height: 1.375rem;
    padding-bottom: 1rem;
  }
`;

export const BoxIconPaper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BoxUpload = styled.section`
  /* width: 100vw; */
  display: flex;
  padding-left: 2rem;
`;

export const ImgUpload = styled.img`
  width: 2.625rem;
  height: 1.438rem;

  @media (min-width: 1024px) {
    width: 4.209rem;
    height: 2.375rem;
  }
`;

export const ParagraphSelect = styled.p`
  font-size: 0.875rem;
  color: #5484ed;
  display: flex;
  font-weight: 600;
  line-height: 1.188rem;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const InfoExtensions = styled.p`
  max-width: 15rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  font-family: "Open Sans";
  padding-bottom: 1.313rem;

  @media (min-width: 1024px) {
    max-width: 24.458rem;
    padding-left: 0;
    padding-bottom: 0;
    margin-right: 5rem;
  }
`;

export const BoxUploadSelect = styled.div`
  display: flex;

  @media (min-width: 1024px) {
    padding: 0;
    flex-direction: row;
  }
`;

export const Label = styled.label`
  display: flex;
  justify-content: center;
  border-radius: 20px;
  border: none;
  width: 12rem;
  height: 2.8rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  color: #ffffff;
  background-color: #0080ff;
  align-items: center;
  cursor: pointer;
`;

export const BoxBtn = styled.section`
  /* padding-top: 1rem; */

  @media (min-width: 1024px) {
    display: flex;
    border: 2px solid green;
    justify-content: center;
    align-items: center;
  }
`;

export const ParagraphSize = styled.p`
  font-size: 0.6rem;
  padding: 0.3rem 1rem;
`;

export const HrTwo = styled.hr`
  width: inherit;
  height: 0;
  color: ${colors.black02};
  margin: 2rem 0 2rem 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Description = styled.p`
  padding-top: 1.5rem;
  max-width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${colors.black};
  width: 17.5rem;
`;

export const BoxDocuments = styled.section``;

export const InfoDocuments = styled.div`
  display: flex;
  padding-top: 1rem;
  @media (min-width: 1024px) {
    :hover {
      filter: ${(props) =>
        !props.isHover &&
        "invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(100%) contrast(85%); cursor: pointer;"};
    }
  }
`;

export const ImgDoc = styled.img`
  width: 1rem;
  height: 1.25rem;
`;

export const ParagraphDoc = styled.p`
  font-size: 1rem;
  font-weight: 700;
  font-style: normal;
  font-family: "Open Sans";
  line-height: 1.375rem;
  padding-left: 1rem;
`;

export const ParagraphDocuments = styled.p`
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-family: "Open Sans";
  line-height: 1.375rem;
  padding-left: 1rem;
  /* color: #7fbe30; */

  @media (min-width: 1024px) {
    :hover {
      cursor: pointer;
      color: ${colors.orange};
    }
  }
`;

export const BoxProgressBar = styled.div`
  display: flex;
  padding-top: 1rem;
  width: 198px;
  #Progress_Status {
    width: 35%;
    background-color: #ddd;
    border-radius: 8px;
    height: 8px;

    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  #myprogressBar {
    width: 0%;
    height: 20px;
    background-color: #5483ea;
    border-radius: 8px;
    height: 8px;
    animation: progressBarAnimation 1s linear forwards;
  }

  @keyframes progressBarAnimation {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  @keyframes progressBarFade {
    from {
    }
    to {
      display: none;
    }
  }
`;

export const ButtonRemove = styled.button`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9e9e9e;
  padding-left: 1rem;
`;

export const ImgIconRemove = styled.img`
  width: 0.938rem;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  font-family: "Open Sans";
  line-height: 1.375rem;
  padding-left: 1rem;
  color: ${(props) => (props.color ? "#7FBE30" : "#707070")};
  ${(props) => props.loading && "color:  #5483EA;"}

  width: 15rem;

  @media (min-width: 1024px) {
    width: auto;
  }
`;

export const Size = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-left: 0.4rem;
  @media (min-width: 1024px) {
    padding-left: 1rem;
  }
`;

export const BoxLogo = styled.div`
  width: 40rem;
  padding-top: 3rem;
  padding-left: 9rem;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1920px) {
    /* tela 4k */
    padding-top: 0;
  }
`;

export const ImgLogo = styled.img`
  width: 17.5rem;
  height: 5.5rem;
`;

export const ContentFooter = styled.div`
  margin-top: 1rem;

  div {
    margin-top: 0.25rem;
  }
`;

export const TextError = styled.p`
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;
  text-align: center;

  @media (min-width: 1024px) {
    text-align: right;
  }
`;

export const BoxFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
