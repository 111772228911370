import { createContext, useCallback, useContext, useState } from "react";

const ExtraFieldsContext = createContext({});

export const ExtraFieldsProvider = ({ children }) => {
  const [errorOptions] = useState({
    message: "campo obrigatório",
    type: "required",
  });

  const [anothersWAct, setAnothersWAct] = useState([]);
  const [anotherWActValue, setAnotherWActValue] = useState("");
  const [anothersGAids, setAnothersGAids] = useState([]);
  const [anotherGAidValue, setAnotherGAidValue] = useState("");
  const [pDisabilities, setPDisabilities] = useState([]);
  const [pDisabilityValue, setPDisabilityValue] = useState("");
  const [anothersDiseases, setAnothersDiseases] = useState([]);
  const [anotherDiseaseValue, setAnotherDiseaseValue] = useState("");
  const [anothersHobbies, setAnothersHobbies] = useState([]);
  const [anotherHobbieValue, setAnotherHobbieValue] = useState("");
  const [graduationNameValue, setGraduationNameValue] = useState("");
  const [graduationNameList, setGraduationName] = useState([]);



  const handleWActChange = (e) => setAnotherWActValue(e.target.value);

  const handleGraduationValue= (e) => setGraduationNameValue(e.target.value);

  const handleGAidChange = (e) => setAnotherGAidValue(e.target.value);

  const handlePDisabilityChange = (e) => setPDisabilityValue(e.target.value);

  const handleDiseaseChange = (e) => setAnotherDiseaseValue(e.target.value);

  const handleHobbiesChange = (e) => setAnotherHobbieValue(e.target.value);

  const addAnotherWAct = (name) => {
    if (!anothersWAct.some((v) => v.name === name)) {
      setAnothersWAct((v) => [...v, { name }]);
      setAnotherWActValue("");
    }
  };

  const addAnotherGratuation = (name) => {
    if (!graduationNameList.some((v) => v.name === name)) {
      setGraduationName((v) => [...v, { name }]);
      setGraduationNameValue("");
    }
  };

  const addAnotherGAid = (name) => {
    if (!anothersGAids.some((v) => v.name === name)) {
      setAnothersGAids((v) => [...v, { name }]);
      setAnotherGAidValue("");
    }
  };

  const addPDisability = (name) => {
    if (!pDisabilities.some((v) => v.name === name)) {
      setPDisabilities((v) => [...v, { name }]);
      setPDisabilityValue("");
    }
  };

  const addAnotherDisease = (name) => {
    if (!anothersDiseases.some((v) => v.name === name)) {
      setAnothersDiseases((v) => [...v, { name }]);
      setAnotherDiseaseValue("");
    }
  };

  const addAnotherHobbie = (name) => {
    if (!anothersHobbies.some((v) => v.name === name)) {
      setAnothersHobbies((v) => [...v, { name }]);
      setAnotherHobbieValue("");
    }
  };

  const removeAnotherWAct = (name) => {
    setAnothersWAct((v) => v.filter((v) => v.name !== name));
  };

  const removeGraduationName = (name) => {
    setGraduationName ((v) => v.filter((v) => v.name !== name));
  };

  const removeAnotherGAid = (name) => {
    setAnothersGAids((v) => v.filter((v) => v.name !== name));
  };

  const removePDisability = (name) => {
    setPDisabilities((v) => v.filter((v) => v.name !== name));
  };

  const removeAnotherDisease = (name) => {
    setAnothersDiseases((v) => v.filter((v) => v.name !== name));
  };

  const removeAnotherHobbie = (name) => {
    setAnothersHobbies((v) => v.filter((v) => v.name !== name));
  };

  const resetAnothersWact = useCallback(() => {
    setAnothersWAct([]);
    setAnotherWActValue("");
  }, []);

  const resetAnothersGAid = useCallback(() => {
    setAnotherGAidValue([]);
    setAnotherGAidValue("");
  }, []);

  const resetPDisability = useCallback(() => {
    setPDisabilities([]);
    setPDisabilityValue("");
  }, []);

  const resetAnothersDisease = useCallback(() => {
    setAnothersDiseases([]);
    setAnotherDiseaseValue("");
  }, []);

  const resetAnothersHobbie = useCallback(() => {
    setAnothersHobbies([]);
    setAnotherDiseaseValue("");
  }, []);

  const addExtraFields = (data, setError) => {
    const updatedData = {
      ...data,
      workActivities: [...data.workActivities, ...anothersWAct],
      governmentAids: data.governmentAid
        ? data.governmentAids && anothersGAids.length
          ? [...data.governmentAids, ...anothersGAids]
          : data.governmentAids
        : anothersGAids.length
        ? anotherGAidValue
        : null,
      physicalDisabilities: data.physicalDisability ? pDisabilities : null,
      diseases: data.chronicDisease
        ? data.diseases && anothersDiseases.length
          ? [...data.diseases, ...anothersDiseases]
          : data.diseases
        : anothersDiseases.length
        ? anothersDiseases
        : null,
      hobbies: data.hasHobbies ? [...data.hobbies, ...anothersHobbies] : null,
    };

    const errors = [];

    if (!updatedData.workActivities.length) {
      errors.push("workActivities");
    }

    if (updatedData.governmentAid && !updatedData?.governmentAids?.length) {
      errors.push("governmentAid");
    }

    if (updatedData.physicalDisability === 'true' && !pDisabilities?.length) {
      errors.push("physicalDisability");
    }

    if (updatedData.chronicDisease === 'true' && !updatedData?.diseases?.length) {
      errors.push("chronicDisease");
    }

    if (updatedData.hasHobbies && !updatedData?.hobbies?.length) {
      errors.push("hasHobbies");
    }

    if (errors.length) {
      errors.forEach((err) => {
        setError(err, errorOptions);
      });
      return;
    }

    if (anothersWAct.length) {
      updatedData.hasAnothersWAct = true;
    }

    if (anothersGAids.length) {
      updatedData.hasAnothersGAids = true;
    }

    if (pDisabilities.length) {
      updatedData.hasPDisabilities = true;
    }

    if (anothersDiseases.length) {
      updatedData.hasAnothersDiseases = true;
    }

    if (anothersHobbies.length) {
      updatedData.hasAnothersHobbies = true;
    }

    return updatedData;
  };

  return (
    <ExtraFieldsContext.Provider
      value={{
        anothersWAct,
        anothersGAids,
        anotherWActValue,
        anotherGAidValue,
        pDisabilities,
        pDisabilityValue,
        anothersDiseases,
        anotherDiseaseValue,
        anothersHobbies,
        anotherHobbieValue,
        graduationNameValue,
        handleWActChange,
        handleGraduationValue,
        handleGAidChange,
        handlePDisabilityChange,
        handleDiseaseChange,
        handleHobbiesChange,
        addAnotherWAct,
        addAnotherGAid,
        addPDisability,
        addAnotherDisease,
        addAnotherHobbie,
        removeAnotherWAct,
        removeGraduationName,
        removeAnotherGAid,
        removePDisability,
        removeAnotherDisease,
        removeAnotherHobbie,
        resetAnothersWact,
        resetAnothersGAid,
        resetPDisability,
        resetAnothersDisease,
        resetAnothersHobbie,
        addExtraFields,
        addAnotherGratuation,
        graduationNameList
        
      }}
    >
      {children}
    </ExtraFieldsContext.Provider>
  );
};

export const useExtraFields = () => useContext(ExtraFieldsContext);
