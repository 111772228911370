

import styled from "styled-components/macro";

export const Container = styled.div`
  margin-top: ${props => props.marginTop && '.3rem'};
  margin-left:1rem;
  width:   44px;
  height:30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: ${props => props.addField ? '#e6f9ce' : ' #ffecd3'};
  font-size: 0.875rem;
  padding:3px;
  border-radius: 5px;
  cursor: pointer;

  @media (min-width: 1024px) {
    width: 6.813rem;
    border-radius: 50px;
    flex-direction: row;
    align-items: center;
  }
`;

export const Image = styled.img`
  height:0.875rem;

  @media (min-width: 1024px) {
    padding: 0 .625rem 0 .875rem;
  }
`;

export const Trash = styled.p`
  font-size:0.375rem;
  font-weight: 400;
  font-family: 'Open Sans';
  color: ${props => props.addField ? '#67b605' : '#FF8F00'};
  text-align: center;

  @media (min-width: 1024px) {
    padding-right:  ${props => props.addField || '.813rem'};
    font-size: .75rem;
    white-space: nowrap;
  }
`;
