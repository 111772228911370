// Libs
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ONGRegistrationRoute from "./ONGRegistrationRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";

// Onboarding
import Home from "../pages/Onboarding/homeScreen";
import Login from "../pages/Onboarding/login";
import EnterYourInformation from "../pages/Onboarding/enterYourInformation";
import ChangePassword from "../pages/Onboarding/changePassword";
import ForgotPassword from "../pages/Onboarding/forgotPassword";

//Dashboard
import Welcome from "../pages/Dashboard/welcome";
import FormSectionsList from "../pages/Dashboard/formSectionsList/layout";
import OfficialContactsAndData from "../pages/Dashboard/formSectionsList/officialContactsAndData";
import ActionAndAssisted from "../pages/Dashboard/formSectionsList/actingAssisted";
import RegistrationCreatedSucessfully from "../pages/Dashboard/registrationCreatedSuccessfully";
import PhysicalAndFunctionalStructure from "../pages/Dashboard/formSectionsList/physicalAndFunctionalStructure";
import SendingDocuments from "../pages/Dashboard/formSectionsList/sendingDocuments";
import Tutorial from "../pages/Dashboard/tutorial";
import HomeDashboard2 from "../pages/Dashboard/tutorial/layout";
import ActivityManagement from "../pages/Dashboard/Profile/activityManagement";
import SettingsPage from "../pages/Dashboard/Profile/settingsPage";
import RegisterNewActivities from "../pages/Dashboard/registerNewActivities";
import RegistrationOfNewAssisted from "../pages/Dashboard/Profile/registrationOfNewAssisted";
import AssistedList from "../pages/Dashboard/Profile/assistedList";
import ActivityAvaliation from "../pages/Dashboard/Profile/activityAvaliation";

//Feedback
import SuccessRegister from "../pages/Feedbacks/successRegister";
import Error500 from "../pages/Feedbacks/error500";

const NavigateRoutes = (props) => {
  return (
    <Router>
      <Routes>
        <Route
          path="/transicao"
          element={
            <PrivateRoute beforeForm={true}>
              <FormSectionsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/tela-de-sucesso"
          element={
            <PrivateRoute beforeForm={true}>
              <RegistrationCreatedSucessfully />
            </PrivateRoute>
          }
        />
        <Route
          path="/bem-vindo"
          element={
            <PrivateRoute beforeForm={true}>
              <Welcome />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro/contatos-e-dados-oficiais"
          element={
            <ONGRegistrationRoute step={0}>
              <OfficialContactsAndData />
            </ONGRegistrationRoute>
          }
        />
        <Route
          path="/cadastro/atuacao-e-assistidos"
          element={
            <ONGRegistrationRoute step={1}>
              <ActionAndAssisted />
            </ONGRegistrationRoute>
          }
        />
        <Route
          path="/cadastro/estrutura-fisica-e-funcional"
          element={
            <ONGRegistrationRoute step={2}>
              <PhysicalAndFunctionalStructure />
            </ONGRegistrationRoute>
          }
        />
        <Route
          path="/cadastro/envio-de-documentos"
          element={
            <ONGRegistrationRoute step={3}>
              <SendingDocuments />
            </ONGRegistrationRoute>
          }
        />
        <Route
          path="/login"
          element={
            <UnauthenticatedRoute>
              <Login />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/criar-conta"
          element={
            <UnauthenticatedRoute>
              <EnterYourInformation />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/esqueceu-a-senha"
          element={
            <UnauthenticatedRoute>
              <ForgotPassword />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/alterar-senha"
          element={
            <UnauthenticatedRoute>
              <ChangePassword />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="/"
          element={
            <UnauthenticatedRoute>
              <Home />
            </UnauthenticatedRoute>
          }
        />

        <Route
          path="/tutorial"
          element={
            <PrivateRoute>
              <Tutorial />
            </PrivateRoute>
          }
        />

        <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomeDashboard2 />
            </PrivateRoute>
          }
        />

        <Route
          path="/gestao-de-atividades"
          element={
            <PrivateRoute>
              <ActivityAvaliation />
            </PrivateRoute>
          }
        />

        <Route
          path="/configuracoes"
          element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/cadastro-de-novas-atividades"
          element={
            <PrivateRoute>
              <RegisterNewActivities />
            </PrivateRoute>
          }
        />

        <Route
          path="/cadastro-novos-assistidos"
          element={
            <PrivateRoute>
              <RegistrationOfNewAssisted />
            </PrivateRoute>
          }
        />

        <Route
          path="/listar-assistidos"
          element={
            <PrivateRoute>
              <AssistedList />
            </PrivateRoute>
          }
        />

        <Route
          path="/cadastro-com-sucesso"
          element={
            <PrivateRoute>
              <SuccessRegister />
            </PrivateRoute>
          }
        />

        <Route
          path="/erro"
          element={
            <PrivateRoute>
              <Error500 />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default NavigateRoutes;
