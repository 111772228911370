import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Header from "../../../components/Header/mobileHeader";
import Animation from "../../../components/Animation/index";

//Styles
import * as S from "./styles";

// Logo
import { BackgroundElipse, LogoMob } from "../../../assets";

// backgrounds
import {
  peopleLecturingRadius,
  peopleWithBasicFoodBasket,
  childOnWhiteboard,
  BackgroundLeft,
  Backgroundright,
} from "../../../assets";

// const RenderTitle = () => (
//   <>
//     <S.ContentTitle>
//       <Title  homeScreen width='31rem' noPaddBtn paddingLeftDesk='0'  mobWidth='15rem'>Faça parte desta revolução </Title>
//       <Title paddingMob='2rem' homeScreen paddingLeftDesk='0' padding='3.625rem' width='43rem' >no Terceiro setor!</Title>
//     </S.ContentTitle>
//     <S.BackgroundLeft src={BackgroundLeft}></S.BackgroundLeft>
//     <Subtitle
//       homeScreen
//       widthDesk="51.8rem"
//       subtitle="Gerencie os projetos sociais de sua ONG
//       e as informações de seus assistidos, de forma rápida e segura.
//       Emita relatórios e acompanhe o desenvolvimento de seus projetos."
//     />
//   </>
// );

const HomeScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(undefined);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile && loading === undefined) setLoading(true);
  });

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 3500);
  }, []);

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <>
      {loading ? (
        <Animation />
      ) : (
        <S.Container>
          <S.Content>
            <Header />
            <S.BoxLogo>
              <img src={LogoMob} alt="Logo" />
            </S.BoxLogo>
            <S.ContentInfo>
              <h1>
                Faça parte <h1>desta revolução</h1> no Terceiro Setor
              </h1>
              <p>
                Gerencie os projetos sociais de sua ONG e as informações de seus
                assistidos, de forma rápida e segura. Emita relatórios e
                acompanhe o desenvolvimento de seus projetos.
              </p>
              <S.BackgroundLeft src={BackgroundLeft} />
              <S.BoxBtn>
                <button onClick={handleClick}>Conecte-se</button>
              </S.BoxBtn>
            </S.ContentInfo>
          </S.Content>
          <S.BoxBackgrounds>
          <S.ContentImgs>
            <img src={peopleLecturingRadius} alt="peopleLecturingRadius" />
          </S.ContentImgs>
          <S.ContentBasket>
            <img src={peopleWithBasicFoodBasket} alt="peopleWithBasicFoodBasket" />
          </S.ContentBasket>
          <S.ContentChild>
            <img src={childOnWhiteboard} alt="childOnWhiteboard" />
          </S.ContentChild>
          <S.BackgroundRight src={Backgroundright} />
            <S.Elipse src={BackgroundElipse} />
          </S.BoxBackgrounds>
        </S.Container>
      )}
    </>
  );
};

export default HomeScreen;
