import styled from "styled-components";
import { colors } from "../../../../../styles/Theme";

export const Container = styled.div`
  width: 100%;
  border: 2px solid #7fbe30;

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 20rem;

  @media (min-width: 1024px) {
    height: 30%;
  }
`;

export const Content = styled.form`
  /* overflow: scroll; */

  @media (min-width: 1316px) {
    overflow: hidden;
  }
`;

export const BoxModal = styled.div`
  overflow: scroll;
  height: 10%;
`;

export const BoxLabel = styled.div`
  display: grid;
  grid-template-columns: 175px 255px 200px 150px;
  padding: 0 0.813rem 0.813rem 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 150px 250px 200px 150px;
  }
`;

export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.black};
`;

export const BoxInput = styled.div`
  width: 104vh;
  display: grid;
  grid-template-columns: 173px 257px 205px 100px;
  background: rgba(217, 217, 217, 0.5);
  padding: 1rem;

  @media (min-width: 1024px) {
    cursor: pointer;
    grid-template-columns: 150px 250px 200px 150px;
  }

  @media (min-width: 1316px) {
    /* width: 136vh; */
    grid-template-columns: 150px 250px 200px 150px;
  }
`;

export const Paragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
`;

export const BoxInputTwo = styled.div`
  display: grid;
  grid-template-columns: 173px 257px 205px 100px;
  background: white;
  padding: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 150px 250px 200px 150px;
  }
`;
