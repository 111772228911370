import styled from 'styled-components';

import { colors } from '../../styles/Theme';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-height: 768px) {
    position: absolute;
    bottom: 2.875rem;
    width: 100%;
    
  }
`

export const Content = styled.div`

`

export const Paragraph = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  font-style: normal;
  font-family: 'Open Sans';
  line-height: 1rem;
  color: ${colors.gray};
  text-align: center;

  @media(max-width: 360px) {
    line-height: 0.5;
  }

  @media (min-width: 1024px) {
    bottom: 2.875rem;
  }
`

