import styled from "styled-components";
import { colors } from "../../styles/Theme";

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;

  .question-subtitle {
    color: #000;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .answer-wrapper {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
  }

  input[type="number"] {
    border: 1px solid #bebebe;
    background-color: #f6f6f6;
    border-radius: 5px;
    width: 69px;
    height: 40px;
    margin-top: 0.5rem;
    text-align: center;
  }

  input[type="radio"] {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  input[type="checkbox"] {
    cursor: pointer;
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
`;

export const InputCheckboxListWrapper = styled.ul`
  list-style: none;
  padding: 0.5rem 0 0 0;

  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns || 2}, 1fr)`};
  row-gap: 16px;

  > li {
    display: flex;
    align-items: center;
  }
`;

export const Add = styled.div`
  margin-left: 4%;
  background: ${colors.blue};
  border-radius: 10%;
  color: ${colors.white};
  font-size: 0.8rem;
  cursor: pointer;
  padding: 7px 10px;
  margin-top: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledList = styled.ul`
  list-style: none;
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 0.5em;

  .list-item {
    display: flex;
    align-items: center;
  }
`;

export const StyledTitle = styled.h3`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 38px;
  margin-bottom: -10px;

  font-weight: 700;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #707070;
  }
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "16px" : "0")};
`;

export const StyledItems = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledInputListWrapper = styled.div`
  margin-top: 16px;
`;

export const StyledGovernmentAidWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledHobbiesWrapper = styled.ul`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledInternetAccess = styled.div`
  margin-top: 8px;

  .title {
    font-weight: 500;
  }
`;

export const StyledButtonWrapper = styled.div`
  align-self: flex-end;
  margin: 40px 8px 40px 0;
`;
