
import styled from "styled-components/macro";

export const Container = styled.div`
  margin-top: 8px;

  p {
    margin-top: 8px;
  }

  button {
    background: transparent;
    color: #0080ff;
    font-size: 0.75rem;
    text-align: end;
    cursor: pointer;
    align-items: normal;
    border-style: none;
    box-sizing: content-box;
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0;
    perspective-origin: 0 0;
    text-decoration: underline;
    transform-origin: 0 0;
    width: auto;
    -moz-appearance: none;
    -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
    -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
}
`;

export const OptionsContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 50% 1fr;
`;

export const ContentInputRadio = styled.div`
  display: flex;
  
  input[type="radio"] {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }

  label ~ input {
    margin-left: 25px;
  }

  input ~ label {
    margin-left: 0.5rem;
  }
`;
