import styled from 'styled-components';
import { colors } from '../../../styles/Theme';


export const Header = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.orange};

  @media(max-width: 1023px) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin-left: 9.125rem;
  height: 2.8rem;
  cursor: pointer;
`;

export const BoxContent = styled.div`
  display: flex;
  margin-right: 9.125rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BoxProfile = styled.div`
    margin-right: 3rem;
    display: flex;
    align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Profile = styled.p`
  font-weight: 700;
  font-style: normal;
  font-size: 1.125rem;
  font-family: 'Nunito';
`;

export const ImgLogout = styled.img`
  @media (min-width: 1024px) {
    padding-right: .4rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BtnLogout = styled.button`
    font-size: 0.875rem;
    font-family: 'Open Sans';
    font-weight: 700;
    display: flex;
    width: 5rem;
    cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BoxLogout = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    
  }
`;

