import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Header from "../../../components/Header";
import Btn from "../../../components/Buttons/button";

//assets
import ViNotebook from "../../../assets/vi/viNotebook.png";

//Styles
import * as S from "./styles";

const SuccessRegister = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("user");
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <Header />
      <S.Container>
        <S.Wrapper>
          <S.Content>
            <S.TextContainer>
              <S.Title>Assistido cadastrado com sucesso</S.Title>
              <S.Subtitle>
                Você pode gerenciar os assistidos na aba de gestão do Vision
              </S.Subtitle>
            </S.TextContainer>

            <S.ButtonContainer>
              <Btn
                text={"Concluir"}
                color={"#0080FF"}
                borderRadius={window.innerWidth <= 768 ? "100px" : "5px"}
                marginBottom={"0"}
                onClick={() => navigate("/cadastro-novos-assistidos")}
              />
            </S.ButtonContainer>
          </S.Content>
        </S.Wrapper>

        <S.Image src={ViNotebook}></S.Image>
      </S.Container>
    </>
  );
};

export default SuccessRegister;
