import styled from "styled-components/macro";

export const Main = styled.main`
  display: flex;
  height: calc(100vh - 5rem);
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0 auto;
  overflow: scroll;
  height: calc(100vh - 5rem);
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-track {
    width: 0;
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 0;
    border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  @media (min-width: 768px) {
    width: 45%;
  }

  @media (min-width: 1024px) {
    width: 34%;
  }
`;
