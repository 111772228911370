import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import { fieldToTextMapping } from "./mappings";

export const getListOfErrorsMessage = (errors, additionalErrors = []) => {
  if (errors) {
    let errorsFlattened = { ...errors };
    if (errorsFlattened.familyGroupResidents) {
      Object.assign(errorsFlattened, {
        name: errorsFlattened.familyGroupResidents[0],
      });
      delete errorsFlattened.familyGroupResidents;
    }
    if (errorsFlattened.familyGroup) {
      errorsFlattened = merge(errorsFlattened, errorsFlattened.familyGroup);
      delete errorsFlattened.familyGroup;
    }
    const errorsQuantity =
      Object.keys(errorsFlattened)?.length + additionalErrors?.length;
    const message =
      errorsQuantity === 1
        ? "campo precisa ser revisado:"
        : "campos precisam ser revisados:";
    let myarr = [];
    Object.keys(errorsFlattened).forEach((key) => {
      myarr.push(fieldToTextMapping[key]);
    });
    if (!isEmpty(additionalErrors)) {
      additionalErrors.forEach((item) => myarr.push(item));
    }
    const fieldsWithError = myarr.join(", ");
    return `${errorsQuantity} ${message} ${fieldsWithError}`;
  }
  return "";
};
