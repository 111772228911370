import styled from 'styled-components';

export const Container = styled.div`
 width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  z-index: 2;
`;

export const Content = styled.div`
  margin: 0 auto;
  padding-top: 3rem;
  width: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    position: relative;
    width: 60%;
    max-width: 558px;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1440px) {
    width: 80%;
  }`

export const ContentVi = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentData = styled.section`
  width: 100%;
  z-index: 2;
`;

export const Main = styled.main`
  height: calc(100vh - 5rem);
  width: 100vw;
  display: flex;
`;

export const BoxBtn = styled.div`
  padding-top: 3rem;
  margin-right: -6rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  
  @media (min-width: 1024px) {
    margin-top: 10rem;
    position: absolute;
    bottom: 5.75rem;
  }
`;

export const ContentBCKGGreen = styled.section`
 display: contents;

 @media (min-width: 1024px) {
  position: relative;
   width: 40vw;
   display: block;
  }
`;

export const Title = styled.h1`
  font-size: 0.875rem;
  font-weight: 700;
  font-family: "Nunito";
  font-style: normal;
  line-height: 1.188rem;
  z-index: 1;
  width: 14rem;

  @media (max-width: 320px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1024px) {
    
    font-size: 2.125rem;
    line-height: 2.25rem;
    width: 100%;
  }

  @media (min-width: 1440px) {
    font-size: 3.125rem;
    line-height: 4.25rem;
  }
`;

export const ImgVi = styled.img`
  position: absolute;
  width: 21rem;
  bottom: 0;
  right: 0.5rem;

  @media (max-height: 768px) {
    width: 17rem;
  }

  @media (max-height: 670px) {
    width: 15rem;
  }

  @media (min-width: 1024px) {
    width: 26rem;
    right: -11rem;
    bottom: 0;
  }

  @media (min-width: 1920px) {
    width: 35rem;
  }
`;