import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../providers/Api";

//Components
import Header from "../../../components/Header";
import Btn from "../../../components/Buttons/button";

//Styles
import * as S from "./styles";

//Assets
import AlertIcon from "../../../assets/icons/iconAlert.png";

const Error500 = () => {
  const navigate = useNavigate();
  const { createAssisted } = useApi();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  }, []);

  const tryCreateAssistedAgain = async () => {
    const newData = JSON.parse(localStorage.getItem("user"));
    console.log(newData);
    await createAssisted(newData)
      .then((res) => {
        return navigate("/cadastro-com-sucesso");
      })
      .catch((error) => {
        console.log("Error " + error);
      });
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Wrapper>
          <S.TextContainer>
            <S.Icon src={AlertIcon} />
            <S.Title>Desculpe, estamos passando por uma instabilidade</S.Title>
            <S.Subtitle>Por favor, tente novamente</S.Subtitle>
          </S.TextContainer>

          <S.ButtonContainer>
            <Btn
              text={"Tentar novamente"}
              color={"#0080FF"}
              borderRadius={"5px"}
              marginBottom={"0"}
              marginLeft={"0"}
              width={window.innerWidth <= 768 ? "100%" : "200px"}
              onClick={tryCreateAssistedAgain}
            />
          </S.ButtonContainer>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default Error500;
