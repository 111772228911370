import styled from "styled-components";

export const Wrapper = styled.form`
  padding: 30px 0 48px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .section-title {
    font-size: 1.13rem;
    font-weight: 700;
    margin-bottom: 20px;

    &::after {
      content: "";
      display: block;
      margin-top: 4px;
      width: 100%;
      height: 1px;
      background-color: #00000066;
    }
  }

  .community-wrapper {
    margin-top: -40px;
  }

  .community-title {
    font-weight: 600;
  }

  .community-name {
    background-color: #c5c5c5;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    padding: 2px 0;
    width: 25%;
    border-radius: 5px;
    align-self: center;
    color: #000;
    font-family: "Open Sans";

    @media (max-width: 700px) {
      width: 50%;
    }
  }

  input[type="radio"] {
    cursor: pointer;
  }

  .family-total {
    color: #000;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const InputListContainer = styled.div`
  transition: 400ms;
  padding: 26px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-right: 8px;
  border-bottom: 1px solid #00000075;
  background-color: ${({ completed }) =>
    completed ? "#FF8F004D" : "#D9D9D933"};
`;

export const ButtonRemove = styled.button`
  background-color: #ffecd3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 6px;
  padding: 5px 14px;
  align-self: flex-end;
  width: 109px;
  height: width: 21px;
  height: 27px;
  gap: 4px;

  span {
    color: #ff8f00;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  > svg {
    font-size: 1.3rem;
    color: #ff8f00;
  }
`;

export const ButtonAdd = styled.button`
  background-color: #cadbff;
  display: flex;
  align-items: center;
  color: #5484ed;
  padding: 5px 8px;
  border-radius: 5px;
  align-self: end;
  font-size: 1rem;
  gap: 8px;
  margin: 27px 34px 48px 0;
  font-family: "Open sans";

  > svg {
    font-size: 1.5rem;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  align-self: flex-end;
  margin-right: 8px;
`;
