import styled from 'styled-components';
import { colors } from '../../../styles/Theme';

export const Container = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @media(min-width: 1024px) {
    display: none;
  }
`

export const ImgLogo = styled.img`
  width: 7rem;
  cursor: pointer;

  @media(max-width: 360px) {
    width: 6rem;
  }
`

export const Step = styled.p`
  position: absolute;
  top: 1.2rem;
  right: 2rem;
  font-size: 1.125rem;
  font-weight: 800;
  color: ${colors.darkGreen};

  span {
    font-weight: 400;
    color: ${colors.black};
  }

  @media(min-width: 768px) {
    display: none;
  }
`
