import { ApiProvider } from "./Api";
import { ExtraFieldsProvider } from "./ExtraFields";

const Providers = ({ children }) => (
  <ApiProvider>
    <ExtraFieldsProvider>{children}</ExtraFieldsProvider>
  </ApiProvider>
);

export default Providers;
