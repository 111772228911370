import styled from "styled-components";
import { colors } from "../../../../../../styles/Theme";
import InputMask from "react-input-mask";

export const Container = styled.div`
  width: 100%;
  margin-block: 28px;
  border: 1px solid #ff8f00;
  border-radius: 10px;
  padding: 8px 0;

  background: rgba(255, 143, 0, 0.05);
  @media (min-width: 1024px) {
    max-height: 345px;
    overflow-y: scroll;
  }
`;

export const Form = styled.form`
  width: 100%;
  margin-bottom: 60px;

  padding: 20px 16px;

  @media (min-width: 1025px) {
    margin-bottom: 0px;
  }
`;

export const Label = styled.span`
  width: 100%;
  max-width: 300px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
`;

export const ContainerInputPersonalData = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

export const ContainerInputHeading = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 400px) {
    width: 235px;
  }
`;
export const TitleInput = styled.h3`
  max-width: ${({ maxW }) => (maxW ? maxW : "auto")};

  font-weight: 700;
  font-size: 14px;
  margin-bottom: ${({ marginB }) => (marginB ? marginB : "auto")};

  color: #000;
  span {
    display: block;
  }

  @media (max-width: 500px) {
    max-width: ${({ mediaMaxW }) => (mediaMaxW ? mediaMaxW : "auto")};
  }
`;

export const Input = styled(InputMask)`
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  padding: 8px 12px;

  background-color: ${colors.lightGray};
`;

export const ContainerInputMarkup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 480px) {
    flex-direction: row;
  }
`;

export const BoxInputMarkup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentInput = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContainerFormation = styled.div`
  max-width: 800px;
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 22px;

  margin-top: 22px;
  margin-bottom: 20px;
`;

export const ContentFormation = styled.div`
  max-width: 650px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
`;
export const BoxFormationInput = styled.div`
  width: 310px;

  display: flex;
  justify-content: start;
  flex-direction: column;
  gap: 8px;
`;

export const InputDiference = styled.input`
  max-width: ${({ maxW }) => maxW && maxW};

  border: 1px solid ${colors.grey};
  border-radius: 5px;
  padding: 8px 12px;

  background-color: ${colors.lightGray};
`;

export const ContentJob = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 40px;
  margin-bottom: 20px;
`;

export const ContainerInfoDeficiencies = styled.div`
  max-width: 500px;

  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ContentInfoDeficiencies = styled.div`
  max-width: 450px;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
`;

export const InputMarkedIfoDeficiences = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  display: ${(props) => props.display};
`;

export const ContentFamilyInternet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BoxInputsFamilyInternet = styled.div`
  display: flex;
  gap: 70px;
`;

export const InputFamilyInternet = styled.div`
  display: flex;
  gap: 8px;
`;

export const ContainerInputsNumber = styled.div`
  width: 100%;
  max-width: 700px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  margin-top: 20px;
`;

export const BoxInputsNumbers = styled.div`
  width: 100%;

  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input {
    max-width: 65px;
    padding: 8px;
    background-color: ${colors.lightGray};
    border: 1px solid ${colors.grey};
  }
`;

export const ContainerTotalIncome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;

  & > h3 > span {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const InputTotalIncome = styled.div`
  display: flex;
  gap: 8px;
`;

export const ContentButtons = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Button = styled.button`
  width: 202px;

  position: relative;
  padding: 5px 14px;
  border-radius: 5px;

  font-size: 12px;
  font-weight: 700;

  color: ${colors.white};
  background-color: ${colors.blue01};
`;

export const ContainerFooter = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 25px;

  margin-top: 20px;
`;

export const Modal = styled.div`
  width: 100%;
  max-width: 416px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 18px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background-color: ${colors.orange};

  img {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  h3 {
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 4px;
  }
`;

export const BoXButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const ButtonsModal = styled.button`
  margin-top: 25px;
  padding: 4px 20px;
  border-radius: 20px;

  font-size: 11px;
  font-weight: 700;

  color: ${({ color }) => color && color};
  background: ${({ backgroundColor }) => backgroundColor && backgroundColor};
`;
