

import React from "react";
import { IconLeft } from "../../../assets";
import { useNavigate } from 'react-router-dom';

import * as S from "./styles";

const FooterMob = () => {
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate(-1)}>
      <S.ImgBack src={IconLeft} alt={IconLeft} />
    </S.Container>
  );
};

export default FooterMob;
