import styled from "styled-components/macro";
import { colors } from "../../styles/Theme";

export const Container = styled.section`
  width: 100%;
  max-height: 100vh;
`;

export const Line = styled.div`
  opacity: 0.4;
  border: 1px solid ${colors.black};
  width: 100%;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Content = styled.div`
  height: calc(100vh - 8.438rem);
  display: flex;

  @media (min-width: 1024px) {
    justify-content: initial;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const ContentButtons2 = styled.section`
  margin: auto;
  padding-top: 3rem;
  max-height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 100vw;

  @media (max-width: 1024px) {
    display: ${(props) => (props.homeScreen ? "flex" : "none")};
  }

  @media (min-width: 1024px) {
    margin: inherit;
    max-width: 110rem !important;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
  }
`;

export const LineVertical = styled.span`
  width: 1px;
  max-height: 100vh;
  background: ${colors.black02};

  @media (max-width: 1024px) {
    display: ${(props) => (props.homeScreen ? "flex" : "none")};
  }
`;

export const Button = styled.button`
  margin-bottom: 0.5rem;
  width: 18.6rem;
  height: 5.625rem;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.688rem;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.disabled
      ? colors.gray03
      : props.currentScreen
      ? colors.gray04
      : colors.darkBlue};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};

  @media (min-width: 1600px) {
    width: 23.25rem;
    font-size: 1.25rem;
  }

  :hover {
    background: ${(props) => props.disabled || colors.gray04};
  }
`;

export const ImageButton = styled.img`
  margin-right: 1rem;
`;

export const ButtonTxt = styled.p`
  width: 55%;
  text-align: left;
`;
