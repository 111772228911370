import styled from "styled-components/macro";
import { colors } from "../../styles/Theme";

export const Content = styled.div`
  display:flex;
  margin-top:-1rem;
  cursor: pointer;
`;

export const ImgNovoCriterio = styled.img`
   width:1rem;
   height:1rem;
   margin-top:.2rem;
   margin-right:.5rem;
`;

export const Text = styled.p`
    color: ${colors.blue};
    font-size:.9rem;
`;
