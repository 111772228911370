import React, { useState } from "react";

//Components
import Layout from "../../../components/Slider/LayoutSlider";
import Title from "../../../components/Title";
import Footer from "../../../components/Footer";
import Btn from "../../../components/Buttons/button";
import Input from "../../../components/Inputs";
import SuccessScreen from "../../../components/SuccessScreen";

//Styles
import * as S from "./styles";
import { api } from "../../../modules/api";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isModal, setIsModal] = useState(undefined);
  const [isError, setIsError] = useState(undefined);
  const [isErrorCode, setIsErrorCode] = useState(undefined);

  const handleChangePassword = (ev) => {
    ev.preventDefault();
    const value = ev.target.value;
    setIsError(false);
    setPassword(value);
  };

  const handleChangeConfirmPassword = (ev) => {
    ev.preventDefault();
    const value = ev.target.value;
    setIsError(false);
    setConfirmPassword(value);
  };

  const renderTitle = () => {
    return (
      <div>
        Sua senha
        <div>
          foi alterada 
          <div>
            com sucesso!
          </div>
        </div>
      </div>
    );
  };

  const handleConfirmationCode = (ev) => {
    ev.preventDefault();
    const value = ev.target.value;
    setIsErrorCode(false);
    setConfirmationCode(value);
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    api
      .post("/user/password_reset/confirm/", {
        password,
        token: confirmationCode,
      })
      .then((res) => {
        setIsModal(true);
      })
      .catch((err) => {
        console.log(err);
        setIsErrorCode(true);
        if (err.response.status === 404) {
          setIsErrorCode('Código de confirmação inválido');
        }
        if (err.response.status === 400) {
          setIsError(err.response.data.password.map((item) => item.includes('common') ? 'Senha muito comum' : 'senha é inteiramente numérica').join(', '));
        }
        validarSenha();
      });

  };

  // valida os campos de senha
  const validarSenha = () => {
    const msgErro = password.length < 8 ? 'A senha deve ter no mínimo 8 caracteres' : '';
    const msgErroConfirm = password !== confirmPassword ? 'As senhas não conferem' : '';
   
    if (msgErro || msgErroConfirm) {
      setIsError(msgErro || msgErroConfirm);
      return false;
    } else {
      return true;
    }
  };

  return (
    
    <Layout>
      <S.Content onSubmit={handleSubmit}>
        <Title>Mudar a senha</Title>
        <Input
          label="Código de alteração"
          place="000000"
          name="code"
          type="code"
          onChange={(ev) => handleConfirmationCode(ev)}
          error={isErrorCode}
          width="300px"
          ResponseWidth="100%"
          widthInput="100%"
        />
        <Input
          label="Nova Senha"
          place="*********"
          name="password"
          type="password"
          onChange={(e) => handleChangePassword(e)}
          error={isError}
          width="300px"
          ResponseWidth="100%"
          marginTop="30px"
          widthInput="100%"
        />
        <Input
          label="Confirmar nova senha"
          place="*********"
          name="confirmAPassword"
          type="password"
          onChange={(e) => handleChangeConfirmPassword(e)}
          error={isError}
          width="300px"
          ResponseWidth="100%"
          marginTop="30px"
          widthInput="100%"
        />
        <S.BoxContent>
          <Btn
            widthDesk='374px'
            text="Confirmar alteração"
            width="15rem"
            // onClick={validarSenha}
          />
        </S.BoxContent>
        <Footer
          text="Ainda precisa de ajuda?"
          link="Consultar equipe de suporte"
          href="mailto:suporte.vision@favelainc.com?"
        />
      </S.Content>
      {isModal && <SuccessScreen margin title={renderTitle()} />}
    </Layout>
  );
};

export default ChangePassword;
