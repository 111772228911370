import React from "react";
import { useNavigate } from "react-router-dom";

//components
import Title from "../../../components/Title";
import ImgHome from "../../../components/imgHome";
import InstrucoesHome from "../../../components/InstrucoesHome";
import LayoutHome from "../../../components/SideBarSettings";
import Button from "../../../components/Buttons/button";
import FooterHome from "../../../components/Footer/FooterHome";

//imgs
import {
  ExclamacaoHome,
  ConfigHome,
  HandHome,
  ElipseHome1,
  ElipseHome2,
  TrianguloHome1,
  TrianguloHome2,
  ElipseHome3,
  TeamworkHome,
} from "../../../assets";

import {
  CircleGreenTutorial,
  RectangleBlue,
  RectangleGreen,
  RectangleOrange,
  CircleBlack,
  Teamwork,
  HumanEmployees,
  VectorTutorial,
  Processing,
  ViTutorial,
} from "../../../assets";

//Styles
import * as S from "./styles";

const Tutorial = () => {
  const navigate = useNavigate();

  const renderDesktopScreen = () => (
    <S.BoxTutorial>
      <S.ContentBox>
        <S.Img1home>
          <ImgHome img={ElipseHome1} />
        </S.Img1home>
        <S.Titlehome>
          <Title wrap>Veja como utilizar o Vision</Title>
        </S.Titlehome>
        <div>
          <ImgHome img={TrianguloHome1} />
        </div>
      </S.ContentBox>

      <S.BoxInstructions>
        <S.NumberBox>
          <div>
            <InstrucoesHome
              img={TeamworkHome}
              number="1"
              text="Primeiro você cadastra as atividades oferecidas pela sua ONG."
              width="15rem"
            />
          </div>
          <S.BoxInstruction3>
            <InstrucoesHome
              img={ExclamacaoHome}
              number="3"
              text="Aqui você acompanha o desempenho deles
                em suas atividades."
              width="13.5rem"
            />
          </S.BoxInstruction3>
        </S.NumberBox>

        <S.BoxElipse>
          <ImgHome img={ElipseHome3} width="6rem" />
        </S.BoxElipse>
        <S.NumberBox>
          <div>
            <InstrucoesHome
              img={HandHome}
              number="2"
              text="Depois você preenche as informações dos seus assistidos."
              width="14rem"
            />
          </div>

          <div>
            <InstrucoesHome
              img={ConfigHome}
              number="4"
              text="E nas configurações, você edita as suas próprias informações e dá permissão para outros colaboradores utilizarem o Vision."
              width="13.5rem"
            />
          </div>
        </S.NumberBox>
      </S.BoxInstructions>

      <S.ContentBox>
        <S.Img4home>
          <ImgHome img={TrianguloHome2} width="7rem" />
        </S.Img4home>

        <S.Img5home>
          <ImgHome img={ElipseHome2} width="10rem;" />
        </S.Img5home>
      </S.ContentBox>
    </S.BoxTutorial>
  );

  const renderMobileScreen = () => (
    <S.ContentMob>
      <S.ImageCircleGreen src={CircleGreenTutorial} alt="CircleGreen" />
      <S.TitleTutorial>
        Veja como Utilizar <br /> o Vision
      </S.TitleTutorial>
      <S.ImageRectangleGreen src={RectangleGreen} alt="RectangleGreen" />
      <S.ImageRectangleOrange src={RectangleOrange} alt="RectangleOrange" />

      <S.BoxTutorialMob>
        <S.ImageBoxTutorial src={Teamwork} alt="Teamwork" />
        <S.BoxText>
          <S.TitleBoxTutorial>Cadastro de atividades</S.TitleBoxTutorial>
          <S.Description>
            Primeiro você cadastra as atividades oferecidas pela sua ONG.
          </S.Description>
        </S.BoxText>
      </S.BoxTutorialMob>

      <S.BoxTutorialMob>
        <S.ImageBoxTutorial src={HumanEmployees} alt="HumanEmployees" />
        <S.BoxText>
          <S.TitleBoxTutorial>Cadastro de novos assistidos</S.TitleBoxTutorial>
          <S.Description>
            Depois você preenche as informações dos seus assistidos.
          </S.Description>
        </S.BoxText>
      </S.BoxTutorialMob>

      <S.ImageRectangleOrangeTwo src={RectangleOrange} alt="RectangleOrange" />
      <S.ImageRectangleBlue src={RectangleBlue} alt="RectangleBlue" />

      <S.BoxTutorialMob>
        <S.ImageBoxTutorial src={VectorTutorial} alt="Vector" />
        <S.BoxText>
          <S.TitleBoxTutorial>Gestão de atividades</S.TitleBoxTutorial>
          <S.Description>
            Aqui você acompanha o desempenho deles em suas atividades.
          </S.Description>
        </S.BoxText>
      </S.BoxTutorialMob>

      <S.BoxTutorialMob>
        <S.ImageBoxTutorial src={Processing} alt="Processing" />
        <S.BoxText>
          <S.TitleBoxTutorial>Configurações</S.TitleBoxTutorial>
          <S.Description>
            E nas configurações, você edita as suas próprias informações e dá
            permissão para outros colaboradores utilizarem o Vision.
          </S.Description>
        </S.BoxText>
      </S.BoxTutorialMob>

      <S.ImageRectangleOrangeThree
        src={RectangleOrange}
        alt="RectangleOrange"
      />
      <S.ImageRectangleBlueTwo src={RectangleBlue} alt="RectangleBlue" />
      <S.ImageRectangleOrangeFour src={RectangleOrange} alt="RectangleOrange" />
      <S.ImageCircleBlack src={CircleBlack} alt="CircleBlack" />

      <S.BoxVi>
        <S.BoxImageVi>
          <S.ImageVi src={ViTutorial} alt="ImageVi" />
        </S.BoxImageVi>

        <S.BoxContentVi>
          <S.TitleVi>
            Seja bem-vindo(a) ao Vision,
            {localStorage.getItem("@Vision/user")}
          </S.TitleVi>
          <Button right text="Avançar" onClick={() => navigate("/home")} />
        </S.BoxContentVi>
      </S.BoxVi>
      <FooterHome />
    </S.ContentMob>
  );

  return (
    <S.Container>
      <LayoutHome home seo="/ Tutorial">
        {renderDesktopScreen()}
        {renderMobileScreen()}
      </LayoutHome>
    </S.Container>
  );
};

export default Tutorial;
