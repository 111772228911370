import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Component
import InputAddItem from "../../../../components/AddItem";
import InputRadioNumber from "../../../../components/Inputs/InputRadioNumber";
import InputCheckbox from "../../../../components/Inputs/checkbox";
import Title from "../../../../components/Title";
import Layout from "../layout";

//Images
import { IconAdd, IconDelete, imgCar } from "../../../../assets";

//Styles
import * as SX from "../../../../components/Inputs/InputRadioSelect/styles";
import * as S from "./styles";

import { toast } from "react-toastify";
import BtnBack from "../../../../components/Buttons/backGrey";
import Button from "../../../../components/Buttons/button";
import InputNumber from "../../../../components/Inputs/InputNumber";
import InputRadio from "../../../../components/Inputs/radio";
import Select from "../../../../components/Select";
import { api } from "../../../../modules/api";
import { useApi } from "../../../../providers/Api";
import { changePermission } from "../../../../utils/permissions";

const SelectRadio = ({
  hasIcon,
  title,
  isError,
  padding,
  column,
  name,
  value,
  onChangeRadioInput,
  list,
  addNewItem,
  deleteItem,
  required,
}) => {
  const [selected, setSelected] = useState("");
  const [amount, setAmount] = useState(0);

  const handleSelect = (e) => {
    const { value } = e.target;

    setSelected(value);
  };

  return (
    <div>
      <SX.Title>{title}</SX.Title>
      <SX.LineDesk />
      <SX.ContainerSelectedItem padding={padding}>
        <InputRadio
          noChecked
          name={name}
          onChange={onChangeRadioInput}
          value={value}
        />
      </SX.ContainerSelectedItem>

      {value ? (
        <SX.ContainerSelect>
          <SX.BoxTextInput>
            <Select
              onChangeSelectVehicle={handleSelect}
              label="Tipo de veículo"
              isError={isError}
              required={required}
            />
            <SX.BoxInputNumber>
              <InputNumber
                isSelect
                label="Quant."
                width="4.375rem"
                height="2.5rem"
                column={column}
                compSelect
                value={amount}
                onChange={(e) => setAmount(+e.target.value)}
              />
            </SX.BoxInputNumber>
            {hasIcon ? (
              <SX.ImageAdd
                src={IconAdd}
                alt="adicionar"
                onClick={() =>
                  amount &&
                  selected &&
                  addNewItem({ vehicle: selected, amount }, name)
                }
              />
            ) : null}
          </SX.BoxTextInput>

          {isError ? (
            <SX.TextError>Preencha todos os campos vazios</SX.TextError>
          ) : null}

          {list.map((item, i) => (
            <SX.ContainerSelectedItem key={i} margin={i === 2}>
              <SX.ContentSelectedItem width="10.563rem">
                {item.vehicle}
              </SX.ContentSelectedItem>
              <SX.ContentSelectedItem width="3.75rem">
                {item.amount}
              </SX.ContentSelectedItem>
              <SX.IconAdd
                src={IconDelete}
                alt="deletar"
                onClick={() => deleteItem(item, name)}
              />
            </SX.ContainerSelectedItem>
          ))}
        </SX.ContainerSelect>
      ) : null}
    </div>
  );
};

const PhysicalAndFunctionalStructure = () => {
  const navigate = useNavigate();
  const isDescktop = window.innerWidth > 768;
  const [user, setUser] = useState("");
  const { getMyUser } = useApi();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("idToken")}`,
    },
  };
  const [addOther, setAddOther] = useState(undefined);
  const [checkOthers, setCheckOthers] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    functionalStructure: [],
    physicalStructure: [],
    others: [],
    classroom: {
      has: undefined,
      value: "",
      classroom_capacit: 0,
      classroom_qty: 0,
    },
    computers_room: {
      has: undefined,
      value: "",
      computers_room_qty: 0,
      computers_per_room: 0,
    },
    employeesONG: {
      has: true,
      value: "",
      employees_qty: 0,
      volunteers_qty: 0,
    },
    materialTransport: {
      has: undefined,
      value: "",
      values: [],
    },
    transportOfPeople: {
      has: undefined,
      value: "",
      values: [],
    },
  });
  const [isError, setIsError] = useState({
    functionalStructure: undefined,
    materialTransport: undefined,
    transportOfPeople: undefined,
    classroom: {
      type: "Sim",
      value: "",
      classroom_capacit: undefined,
      classroom_qty: undefined,
    },
    computers_room: {
      type: "Sim",
      value: "",
      computers_room_qty: undefined,
      computers_per_room: undefined,
    },
    employeesONG: {
      type: "Sim",
      value: "",
      employees_qty: undefined,
      volunteers_qty: undefined,
    },
  });

  const handleCheckedList = (ev) => {
    const { id, name, checked } = ev.target;

    if (checked && !form[name].includes(id)) {
      setForm({
        ...form,
        [name]: [...form[name], id],
      });

      setIsError({
        ...isError,
        [name]: false,
      });
    } else if (!checked && form[name].includes(id)) {
      setForm({
        ...form,
        [name]: form[name].filter((item) => item !== id),
      });
    }
  };

  const handleDeleteOther = (name, addname, item) => {
    const index = form.others.findIndex((element) => element === item);
    if (index !== -1) {
      let newOthers = form.others;

      newOthers.splice(index, 1);

      setForm({
        ...form,
        others: newOthers,
      });
    }
  };

  const InputRadioOthers = (ev) => {
    setCheckOthers(!checkOthers);
    setForm({
      ...form,
      others: [],
    });
  };

  const handleChangeADD = (ev) => {
    setAddOther(ev.target.value);
  };

  const handleAddOther = (value) => {
    const exists = form.others.find((element) => element === value);
    if (!exists && value !== "") {
      const newOthers = [...form.others, value];
      setForm({
        ...form,
        others: newOthers,
      });
    }
    setAddOther("");

    setIsError({
      ...isError,
      functionalStructure: false,
    });
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    const response = await getMyUser().then((res) => {
      const { ong_id: ong } = res;

      // console.log(res);

      if (ong.ong_structure) {
        const others = ong?.ong_structure?.others.split(";") || [];

        setForm((prev) => ({
          ...prev,
          functionalStructure: Object.keys(ong.ong_structure).filter(
            (key) => ong.ong_structure[key] === true
          ),
          classroom: {
            ...prev.classroom,
            has: ong.ong_structure.classroom,
            classroom_capacit: ong?.ong_structure?.classroom_capacit || 0,
            classroom_qty: ong?.ong_structure?.classroom_qty || 0,
          },
          computers_room: {
            ...prev.computers_room,
            has: ong.ong_structure.computers_room,
            computers_per_room: ong?.ong_structure?.computers_per_room || 0,
            computers_room_qty: ong?.ong_structure?.computers_room_qty || 0,
          },
          employeesONG: {
            ...prev.employeesONG,
            employees_qty: ong?.ong_structure?.employees_qty || 0,
            volunteers_qty: ong?.ong_structure?.volunteers_qty || 0,
          },
          materialTransport: {
            ...prev.materialTransport,
            has: ong.ong_structure.material_transports.length > 0,
            values: ong?.ong_structure?.material_transports?.map((item) => ({
              vehicle: item.type,
              amount: item.qty,
            })),
          },
          transportOfPeople: {
            ...prev.transportOfPeople,
            has: ong.ong_structure.transport_peoples.length > 0,
            values: ong?.ong_structure?.transport_peoples?.map((item) => ({
              vehicle: item.type,
              amount: item.qty,
            })),
          },
          others,
        }));
        if (others.length) {
          setCheckOthers(true);
        }
      }
      return res;
    });
    console.log(response);
    setUser(response);
  };

  const notify = () => {
    if (isError) {
      toast.error("Preencha todos os campos corretamente");
    }
    console.log(form);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const isEmptyOthers = checkOthers && !form.others.length;
    const errorFunctionalStructure = !form.functionalStructure.length;
    const isErrorFunctionalStructure = checkOthers
      ? isEmptyOthers
      : errorFunctionalStructure;

    const errorMaterialTransport =
      form.materialTransport.type === "Sim" &&
      !form?.materialTransport?.materialTransportList?.length;
    const errorTransportOfPeople =
      form.transportOfPeople.type === "Sim" &&
      !form?.transportOfPeople?.transportOfPeopleList?.length;

    if (
      isErrorFunctionalStructure ||
      errorMaterialTransport ||
      errorTransportOfPeople
    ) {
      notify();
      setIsError({
        functionalStructure: errorFunctionalStructure,
        transportOfPeople: errorTransportOfPeople,
        materialTransport: errorMaterialTransport,
      });
    } else {
      const data = {
        transport_peoples: [
          ...form.transportOfPeople.values.map((type) => {
            return { type: type.vehicle, qty: type.amount };
          }),
        ],
        material_transports: [
          ...form.materialTransport.values.map((type) => {
            return { type: type.vehicle, qty: type.amount };
          }),
        ],
        meeting_room: !!form.functionalStructure.filter(
          (i) => i === "meeting_room"
        ).length,
        meal_room: !!form.functionalStructure.filter((i) => i === "meal_room")
          .length,
        sports_field: !!form.functionalStructure.filter(
          (i) => i === "sports_field"
        ).length,
        open_air_section: !!form.functionalStructure.filter(
          (i) => i === "open_air_section"
        ).length,
        inventory_room: !!form.functionalStructure.filter(
          (i) => i === "spaceForOutdoorActivity"
        ).length,
        pool: !!form.functionalStructure.filter((i) => i === "pool").length,
        kitchen: !!form.functionalStructure.filter((i) => i === "kitchen")
          .length,
        auditorium: !!form.functionalStructure.filter((i) => i === "auditorium")
          .length,
        locker_room: !!form.functionalStructure.filter(
          (i) => i === "locker_room"
        ).length,
        projector: !!form.functionalStructure.filter((i) => i === "projector")
          .length,
        others: form?.others?.join(";"),
        classroom: form.classroom.has,
        classroom_qty: form.classroom.classroom_qty,
        classroom_capacit: form.classroom.classroom_capacit,
        computers_room: form.computers_room.has,
        computers_per_room: form.computers_room.computers_per_room,
        computers_room_qty: form.computers_room.computers_room_qty,
        employees_qty: form.employeesONG.employees_qty,
        volunteers_qty: form.employeesONG.volunteers_qty,
      };

      const updateData = {
        current_form: "3",
        ong_structure: data,
      };

      api
        .patch(`/ong/${user?.ong_id?.id}/`, updateData, config)
        .then((response) => {
          changePermission(3);
          localStorage.setItem(
            "formStep",
            JSON.stringify({
              pathname: "/cadastro/envio-de-documentos",
              step: 3,
            })
          );
          isDescktop
            ? navigate("/cadastro/envio-de-documentos")
            : navigate("/transicao");
        })
        .catch((error) => {
          console.log(error);
          setError(true);
        });
    }
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: { ...prev[name], has: value === "true" },
    }));
  };

  const onChangeNumberInput = (e) => {
    const { name, value } = e.target;

    const [prevName, actualName] = name.split(" ");

    setForm((prev) => ({
      ...prev,
      [prevName || actualName]: prevName
        ? {
            ...prev[prevName || actualName],
            [actualName]: value,
          }
        : value,
    }));
  };

  const renderContainerWithInputNumber = () => (
    <>
      <InputRadioNumber
        name="classroom"
        nameADD="quantityRooms"
        firstText="Quantidade de salas"
        secondText="Capacidade de pessoas por sala"
        title="A ONG possui sala de aula ?*"
        noMarginTop
        border
        firstPaddingRight=".5rem"
        firstNumber="classroom_qty"
        secondNumber="classroom_capacit"
        value={form.classroom.has}
        onChange={onChange}
        fNumberInputHandler={onChangeNumberInput}
        fNumberInputValue={form.classroom.classroom_qty}
        sNumberInputHandler={onChangeNumberInput}
        sNumberInputValue={form.classroom.classroom_capacit}
      />
      <S.LineMob />
      <InputRadioNumber
        noMarginTop
        border
        titleWidth="11rem"
        title="A ONG possui sala de informática ?*"
        firstText="Quantidade de salas"
        firstPaddingRight=".5rem"
        firstWidth="10.5rem"
        secondText="Quantidade de computadores por sala"
        secondWidth="11.5rem"
        name="computers_room"
        value={form.computers_room.has}
        firstNumber="computers_room_qty"
        secondNumber="computers_per_room"
        onChange={onChange}
        fNumberInputHandler={onChangeNumberInput}
        fNumberInputValue={form.computers_room.computers_room_qty}
        sNumberInputHandler={onChangeNumberInput}
        sNumberInputValue={form.computers_room.computers_per_room}
      />
      <S.LineMob />
      <InputRadioNumber
        info
        noInput
        noAlignSelf
        title="Funcionários da ONG *"
        firstText="Quantos Colaboradores a Iniciativa possui ?"
        secondText="Quantos voluntários a iniciativa possui ?"
        name="employeesONG"
        firstWidth="12.5rem"
        value={form.employeesONG.has}
        firstNumber="employees_qty"
        secondNumber="volunteers_qty"
        onChange={onChange}
        fNumberInputHandler={onChangeNumberInput}
        fNumberInputValue={form.employeesONG.employees_qty}
        sNumberInputHandler={onChangeNumberInput}
        sNumberInputValue={form.employeesONG.volunteers_qty}
      />
    </>
  );

  const onChangeSelect = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        has: value === "true",
      },
    }));
  };

  const addNewItem = (item, name) => {
    if (form[name]?.values.some(({ vehicle }) => vehicle === item.vehicle))
      return;

    setForm((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        values: [...prev[name].values, item],
      },
    }));
  };

  const deleteItem = ({ vehicle }, name) => {
    setForm((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        values: prev[name].values.filter((item) => item.vehicle !== vehicle),
      },
    }));
  };

  const onChangeRadioInput = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        has: value === "true",
      },
    }));
  };

  const rendercontainerWithSelect = () => (
    <>
      <SelectRadio
        hasIcon
        title="A ONG possui veículos para transporte de materiais? *"
        isError={isError.materialTransport}
        padding
        column
        name="materialTransport"
        value={form.materialTransport.has}
        onChangeRadioInput={onChangeRadioInput}
        list={form.materialTransport.values}
        addNewItem={addNewItem}
        deleteItem={deleteItem}
        required={
          form.materialTransport.has && !form.materialTransport.values.length
        }
      />
      <SelectRadio
        hasIcon
        title="A ONG possui veículos para transporte de pessoas? *"
        isError={isError.transportOfPeople}
        padding
        column
        name="transportOfPeople"
        value={form.transportOfPeople.has}
        onChangeRadioInput={onChangeRadioInput}
        list={form.transportOfPeople.values}
        addNewItem={addNewItem}
        deleteItem={deleteItem}
        required={
          form.transportOfPeople.has && !form.transportOfPeople.values.length
        }
      />
    </>
  );
  const validateFunctionStructure = () => {
    return error === true && form.functionalStructure;
  };

  return (
    <Layout descktop currentStep="3">
      <S.Container onSubmit={handleSubmit}>
        <Title
          form
          icon={imgCar}
          subtitle="Estrutura Física da ONG *"
          paddingMob="1.5rem"
          paddingLeftDesk="4.063rem"
        >
          Estrutura física <div>e funcional</div>
        </Title>

        <S.BoxForm>
          <S.Description>
            Marque os itens que fazem parte da estrutura física da ONG (é
            possível escolher mais de uma opção).
          </S.Description>

          {validateFunctionStructure() && (
            <S.ErrorText>
              Campo obrigatório! Selecione ao menos uma quantidade:
            </S.ErrorText>
          )}

          <S.ContentOccupationArea>
            <S.DivCheckbox>
              <InputCheckbox
                label="Sala para reuniões"
                id="meeting_room"
                name="functionalStructure"
                onChange={handleCheckedList}
                wrap
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "meeting_room"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Espaço para refeições"
                id="meal_room"
                name="functionalStructure"
                onChange={handleCheckedList}
                wrap
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "meal_room"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Quadra para esportes"
                id="sports_field"
                name="functionalStructure"
                onChange={handleCheckedList}
                wrap
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "sports_field"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Espaço para atividade ao ar livre"
                id="open_air_section"
                name="functionalStructure"
                onChange={handleCheckedList}
                wrap
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "open_air_section"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Espaço para estoque de doações"
                id="spaceForOutdoorActivity"
                name="functionalStructure"
                onChange={handleCheckedList}
                wrap
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "spaceForOutdoorActivity"
                )}
                isError={isError.functionalStructure}
              />
            </S.DivCheckbox>
            <S.DivCheckbox>
              <InputCheckbox
                label="Piscina"
                id="pool"
                name="functionalStructure"
                onChange={handleCheckedList}
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "pool"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Cozinha"
                id="kitchen"
                name="functionalStructure"
                onChange={handleCheckedList}
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "kitchen"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Auditório"
                id="auditorium"
                name="functionalStructure"
                onChange={handleCheckedList}
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "auditorium"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Vestiários"
                id="locker_room"
                name="functionalStructure"
                onChange={handleCheckedList}
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "locker_room"
                )}
                isError={isError.functionalStructure}
              />
              <InputCheckbox
                label="Projetores"
                id="projector"
                name="functionalStructure"
                onChange={handleCheckedList}
                defaultChecked={form.functionalStructure.find(
                  (item) => item === "projector"
                )}
                isError={isError.functionalStructure}
              />
            </S.DivCheckbox>
            <S.DivCheckbox>
              <InputCheckbox
                label="Outros"
                id="others"
                name="occupationArea"
                onChange={InputRadioOthers}
                defaultChecked={form?.others?.length ? "on" : null}
                isError={isError.functionalStructure}
              />
              {checkOthers && (
                <InputAddItem
                  name="others"
                  nameADD="others"
                  value={addOther}
                  handleAddTransport={handleAddOther}
                  handleChangeADD={handleChangeADD}
                  handleAddItem={handleAddOther}
                  handleDeleteItem={handleDeleteOther}
                  handleNewItemChange={handleChangeADD}
                  list={form.others}
                  hasIcon
                  noRequired
                  isError={isError.functionalStructure}
                />
              )}
            </S.DivCheckbox>
          </S.ContentOccupationArea>
        </S.BoxForm>
        <S.LineMob />

        {renderContainerWithInputNumber()}

        <S.LineMob />
        {rendercontainerWithSelect()}
        {isDescktop && (
          <S.BoxFooter>
            <Button
              text="Voltar"
              color="#CADBFF"
              fontColor="#5484ED"
              onClick={() => navigate("/cadastro/atuacao-e-assistidos")}
            />
            <Button text="Próximo" />
          </S.BoxFooter>
        )}
        {!isDescktop && (
          <>
            <Button text="Próximo" />
            <BtnBack />
          </>
        )}
      </S.Container>
    </Layout>
  );
};

export default PhysicalAndFunctionalStructure;
