import styled, { css } from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  @media (min-width: 1024px) {
    /* width: 18rem; */
    width: ${(props) => (props.titleWidth ? "18rem" : props.titleWidth)};
  }
  /* // tela 4k */
  /* @media (min-width: 2560px) {
    width: 35%;
  } */
`;

export const ContentInput = styled.div`
  padding-bottom: ${(props) => (props.padding ? "0.625rem" : "1.625rem")};
  display: flex;
  flex-direction: ${(props) => props.column && "column"};
`;

export const Title = styled.h3`
  width: ${(props) => props.width && "11rem"};
  margin-top: 1.5rem;
  margin-bottom: 0.625rem;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.231rem;
  line-height: ${(props) => (props.titleWidth ? props.titleWidth : "1.375rem")};
  width: ${(props) => (props.titleWidth ? "18rem" : "100%")};
  //width:20rem;
  color: ${colors.black};
  align-items: center;

  @media (min-width: 1024px) {
    width: initial;
    font-size: 1.125rem;
    line-height: 1.563rem;
    border-bottom: ${(props) => props.border && "1px solid #00000040"};
    width: ${(props) => props.border && "44vw"};
  }
`;

export const Label = styled.label`
  margin-right: ${(props) => (props.noMarginRight ? "0" : "1.563rem")};
  display: flex;
  padding-top: ${(props) => props.paddingTopMob && props.paddingTopMob};
  align-items: ${(props) =>
    props.positionText ? props.positionText : "center"};
  font-size: ${(props) => (props.small ? ".875rem" : "1.125rem")};
  font-weight: 600;
  color: ${(props) => (props.isError ? colors.error : colors.gray02)};
  font-family: "Open Sans";
  width: ${(props) => props.widthMob && props.widthMob};

  @media (min-width: 1195px) {
    font-size: 1.125rem;
  }

  @media (min-width: 1024px) {
    padding-top: ${(props) => props.paddingTop && props.paddingTop};
    justify-content: ${(props) => props.paddingLeft && props.paddingLeft};
  }
`;

export const Fill = styled.div`
  margin-right: 0.4rem;
  height: 1.25rem;
  width: 1.25rem;
`;

export const Input = styled.input`
  margin-right: 3px;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;

  ${(props) =>
    props.isError &&
    css`
      :before {
        content: "";
        color: transparent !important;
        display: block;
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 50%;
        border: 1px solid ${colors.error};
      }
    `}
`;
