

import React from "react";
import * as S from "./styles";

//Image
import { IconCloseInfoModal } from '../../assets';

const Info = ({ children, descImg, isOval, low, isIconCloseModal, onClick }) => {
  return (
    <S.Info descImg={descImg} isOval={isOval} low={low}>
      {descImg && <S.Arrow />}
      <>
        {isIconCloseModal
          && <S.IconClose
            onClick={onClick}
            src={IconCloseInfoModal}
            alt='Fechar'
          />}
        <S.InfoText descImg={descImg} isOval={isOval}>{children}</S.InfoText>
      </>
    </S.Info>
  );
};

export default Info;
