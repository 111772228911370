import styled from "styled-components";

export const Content = styled.form`
  position: relative;
  margin: 0 auto;
  padding-top: 2.961rem;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 510px;
  height: calc(100vh - 5rem);

  @media (min-width: 1024px) {
    position: relative;
    padding-top: 9rem;
    align-items: center;
  }
`;

export const BoxTitle = styled.div`
  @media (min-width: 1024px) {
  }
`;

export const BoxContent = styled.div`
  margin-top: 2rem;
`;
