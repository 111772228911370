import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans";
  width: 100%;
  margin: 0 24px 0;

  @media (min-width: 1024px) {
    margin-top: 2rem;
    display: block;
    width: 70%;
    padding: 50px 120px 0 160px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media (min-width: 1024px) {
    max-height: 590px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 16px;
    }
    ::-webkit-scrollbar-track {
      background: #eee;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #7fbe30;
    }
  }
`;

export const DropDownButton = styled.button`
  min-height: 50px;
  background-color: #7fbe30;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding-inline: 18px;

  .title {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
  }

  > svg {
    transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : "0deg")});
    transition: 400ms;
  }
`;
