import styled, { css } from "styled-components";
import { colors } from "../../styles/Theme";

export const Container = styled.div`
  @media (min-width: 1024px) {
    padding-bottom: 1.188rem;
    margin-top: 5%;

    ${(props) =>
      props.boxBreak &&
      css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 12rem;
        align-content: flex-start;
      `}
  }
`;

export const ContentAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  label {
    padding-bottom: 0;
    width: 80%;
    max-width: 300px;
  }

  input {
    width: 7rem;
  }

  @media (min-width: 1024px) {
    width: ${(props) => props.boxBreak && "15rem"};
    padding-top: ${(props) =>
      !props.noPadding && (props.idx === 2 || props.idx === 5) && "1.7rem"};
  }
`;

export const Add = styled.div`
  margin-left: 4%;
  background: ${colors.blue};
  border-radius: 10%;
  color: ${colors.white};
  font-size: 0.8rem;
  padding: 4%;
  cursor: pointer;
  margin-top: 10%;
`;

export const Input = styled.p`
  margin: 0.391rem 0 0 0;
  padding: 0.469rem 0.8rem;
  background: ${colors.lightGray};
  border: 1px solid ${colors.darkGray};
  border-radius: 5px;
  width: 13.125rem;
  min-height: 2.5rem;
  height: auto;
  max-width: 300px;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${colors.gray};
  font-family: "Open Sans";
`;

export const IconAdd = styled.img`
  padding-left: 0.5rem;

  width: 23px;
  height: ${(props) => props.height || "2.5rem"};
  cursor: pointer;

  @media (min-width: 1024px) {
    padding-top: ${(props) => (props.positionIcon ? props.positionIcon : "0")};
  }
`;
