import styled, { css } from "styled-components";
import { colors } from "../../styles/Theme";

export const ContentLabel = styled.div`
  /* > button {
    position: relative;
    /* bottom: 15px; */
  /* bottom: ${({ error }) => (error ? "50%" : "32%")};
    left: ${({ width }) => (width ? css`calc(${width} - 15%)` : "90%")}; */
  /* color: #c80c0c;
    font-size: 1rem;
    width: max-content;
    height: max-content; 

    @media(min-width: 1024px) {
      left: 82%;
    }
  } */

  width: ${(props) => (props.width ? props.width : "inital")};
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};

  /* padding-bottom: ${(props) =>
    props.sPaddingBottom
      ? props.sPaddingBottom
      : props.paddingBottom
      ? "1rem"
      : "1.864rem"}; */

  @media (min-width: 1024px) {
    padding-right: ${(props) => props.paddingRight && "2.5rem"};
    ${(props) => props.flex && "flex: 1;"}
    justify-content: space-between;
    width: ${(props) => props.widthLabel || props.widthLabel};
    margin-left: ${(props) => props.marginLabel || props.marginLabel};
  }

  width: ${(props) => props.contentWidth && "100%"};
  margin-right: ${(props) => props.marginLabelMob || props.marginLabelMob};

  @media (min-width: 1024px) {
    padding-right: ${(props) => props.paddingRight && "2.5rem"};
    ${(props) => props.flex && "flex: 1;"}
    justify-content: space-between;
    width: ${(props) => props.widthLabel || props.widthLabel};
    margin-left: ${(props) => props.marginLabel || props.marginLabel};
  }

  @media (max-width: 1024px) {
    width: ${(props) => (props.ResponseWidth ? props.ResponseWidth : "inital")};
  }
`;

export const ContentInput = styled.div`
  width: 100%;

  position: relative;
  > button {
    position: absolute;
    right: 15px;
    top: 20px;
    color: #c80c0c;
  }
`;

export const ContentInfo = styled.label`
  position: relative;
  width: ${(props) => props.subLabel && "13rem"};
  font-size: ${(props) => (props.subLabel ? "1rem" : "0.875rem")};
  font-weight: 600;
  color: ${(props) => (props.subLabel ? colors.gray02 : colors.black)};
  font-family: "Open Sans";
  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;

export const Input = styled.input`
  height: 2.5rem;
  width: ${(props) => (props.widthInput ? props.widthInput : "inital")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "auto")};
  margin: 0.391rem 0 0 0;
  padding-left: ${(props) => (props.icon ? "3rem" : ".688rem")};
  background: ${(props) =>
    props.isError ? colors.lightRed : colors.lightGray};
  border: ${(props) =>
    props.isError
      ? `1px solid ${colors.error}`
      : `1px solid ${colors.darkGray}`};
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Open Sans";
  background: ${colors.lightGray};
  background: ${colors.lightGray} url(${(props) => props.icon}) no-repeat scroll
    12px 5px;
  background-size: 28px;
  :-webkit-autofill {
    -webkit-box-shadow: ${(props) =>
      props.isError
        ? `0 0 0px 1000px ${colors.lightRed} inset`
        : `0 0 0px 1000px ${colors.lightGray} inset`};
  }

  ::placeholder {
    color: ${(props) => props.isError && colors.error};
  }
`;

export const TextError = styled.p`
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;
  min-height: 2rem;

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }
`;
