import * as S from "./styles";

const InputList = ({
  label,
  name,
  value,
  onChangeFn,
  adderFn,
  removerFn,
  list,
}) => (
  <S.Wrapper>
    <label>{label}</label>
    <div>
      <input
        type="text"
        placeholder="Digite"
        name={name}
        value={value}
        onChange={onChangeFn}
      />
      <S.Add type="button" onClick={() => adderFn(value)} add>
        Adicionar
      </S.Add>
    </div>
    {list.map(({ name }) => (
      <div key={name}>
        <input disabled placeholder={name} />
        <S.Button onClick={() => removerFn(name)}>-</S.Button>
      </div>
    ))}
  </S.Wrapper>
);

export default InputList;
