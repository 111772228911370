import styled from "styled-components/macro";

export const Btn = styled.button`
  margin: ${(props) => props.margin && "0 auto"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "20px"};
  border: none;
  width: ${(props) => props.width || "10rem"};
  height: 2rem;
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.fontSmall ? ".75rem" : "1rem")};
  line-height: 20px;
  color: ${(props) => props.fontColor || "#ffffff"};
  background-color: ${(props) => props.color || "#0080ff"};
  margin-bottom: ${(props) => props.marginBottom || "2.313rem"};
  display: ${(props) => props.display || "flex"};
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};

  &:hover {
    cursor: pointer;
    font-weight: 800;
  }

  &:disabled {
    opacity: 0.4;
    cursor: initial !important;
  }

  @media (min-width: 1024px) {
    font-size: ${(props) => props.homeScreen && "1.75rem"};
    width: ${(props) => props.homeScreen && "19.625rem"};
    height: ${(props) => props.homeScreen && "5.75rem"};
    border-radius: ${(props) => props.homeScreen && "56px"};
    text-transform: ${(props) => props.homeScreen && "uppercase"};
    font-weight: ${(props) => props.homeScreen && "800"};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "1rem")};
    display: flex;
    width: ${(props) => props.widthDesk || props.widthDesk};
  }
`;

export const ContentBtn = styled.div`
  text-align: ${(props) => (props.left ? "left" : "center")};
  display: flex;
  justify-content: ${(props) => (props.positionBtn ? "flex-start" : "center")};
  align-items: center;

  @media (min-width: 1024px) {
    height: ${(props) => props.height && "1.875rem"};
    text-align: ${(props) => props.right && "right"};
    justify-content: ${(props) => props.positionBtn && "flex-start"};
  }
`;
