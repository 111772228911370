import React, { useState, useEffect } from "react";

//Styles
import * as S from "./styles";

// Logo
import { VectorSelect } from "../../../assets";

const ModalSettingPage = ({
  title,
  label,
  line,
  bgTitle,
  paddingTop,
  paddingTopModal,
  selectOng,
  handleSelectOng,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    setIsOpenModal(false);
  }, [handleSelectOng]);

  const handleModalConsultPermissions = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <S.BoxModal paddingTopModal={paddingTopModal}>
      <S.LabelTitle bgTitle={bgTitle} onClick={handleModalConsultPermissions}>
        {title} <S.Image src={VectorSelect} />
      </S.LabelTitle>
      {isOpenModal && (
        <S.Form>
          {label?.map((i, index) => {
            return (
              <S.Label paddingTop={paddingTop}>
                <S.Input
                  value={i.name}
                  type="checkbox"
                  name="selectOng"
                  id={i.id}
                  onChange={handleSelectOng}
                  // checked={!!selectOng.filter((it) => it === i.id).length}
                />
                {i.name}
                <S.Line displayNone={label.length - 1 === index}>{line}</S.Line>
              </S.Label>
            );
          })}
        </S.Form>
      )}
    </S.BoxModal>
  );
};

export default ModalSettingPage;