import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import LayoutHomeDashboard from '../../../../components/SideBarSettings';
import Title from '../../../../components/Title';
import FooterHome from '../../../../components/Footer/FooterHome';

import { useApi } from '../../../../providers/Api';

import { IconExclamation, IconSave, IconEdit } from '../../../../assets';

import * as S from './styles';
// import { toast, ToastContainer } from 'react-toastify'

import { monthOptions } from '../../../../utils/lists';
import { getYearOptions } from '../../../../utils/getYearOptions';

const ActivityAvaliation = () => {
  const {
    listActivities,
    listActivityAssisteds,
    createAttendance,
    getActivityDetails,
  } = useApi();

  const [activity, setActivity] = useState();
  const [activities, setActivities] = useState([]);
  const [evaluationCriterias, setEvaluationCriterias] = useState([]);
  const [selectedCriteria, setSelectedCriteria] = useState([]);
  const [assisteds, setAssisteds] = useState([]);
  const [showTable, setShowTable] = useState(null);
  const [activityError, setActivityError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [tableTHs, setTableTHs] = useState([]);
  const [filters, setFilters] = useState({
    activity: null,
    evaluationCriteria: null,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const activityOptions = activities.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const yearOptions = getYearOptions();

  const selectStyle = {
    control: (baseStyles) => ({
      ...baseStyles,
      width: '90%',
      background: '#F4F4F4',
      borderRadius: '2px',
      fontWeight: '500',
      margin: '8px 0',
    }),
    option: (styles) => ({
      ...styles,
      fontWeight: '500',
    }),
  };

  const getActivities = useCallback(async () => {
    await listActivities().then(setActivities);
  }, [listActivities]);

  const getSelectedActivity = useCallback(
    async (activityId) => {
      await getActivityDetails(activityId).then((activity) => {
        setActivity(activity);
        let evaluationCriteriaOptions = activity.evaluation_criterias.map(
          (criteria) => {
            return {
              label: fixLabel(criteria.evaluation),
              value: criteria.id,
            };
          }
        );
        setEvaluationCriterias(evaluationCriteriaOptions);
      });
    },
    [getActivityDetails]
  );

  const fixLabel = (criteria) => {
    switch (criteria) {
      case 'frequencia':
        return 'Frequência';
      case 'nota':
        return 'Nota';
      case 'participacao':
        return 'Participação';
      default:
        return criteria;
    }
  };

  const getActivityAttendance = useCallback(
    async (activity) => {
      await listActivityAssisteds(activity).then((assisteds) => {
        setAssisteds(assisteds);
        mountTable(assisteds);
      });
    },
    [listActivityAssisteds, filters, assisteds, evaluationCriterias]
  );

  const addAttendance = useCallback(
    async (data) => {
      await createAttendance(data).then(() => {
        toast.success('Avaliação atualizada com sucesso!');
      });
    },
    [createAttendance]
  );

  const handleActivitySelect = (ev) => {
    let activityId = ev.value;
    setActivityError(false);
    setSelectedCriteria(null);
    setFilters((filters) => ({
      ...filters,
      activity: activityId,
      evaluationCriteria: null,
    }));
    getSelectedActivity(activityId);
  };

  const handleCriteriaSelect = (ev) => {
    let criteria = ev.value;
    setEvaluationError(false);
    setSelectedCriteria(ev[0]);
    setFilters((filters) => ({ ...filters, evaluationCriteria: criteria }));
  };

  const handleMonthSelect = (ev) => {
    let month = ev.value;
    setFilters((filters) => ({ ...filters, month: month }));
  };

  const handleYearSelect = (ev) => {
    let year = ev.value;
    setFilters((filters) => ({ ...filters, year: year }));
  };

  const handleFilters = () => {
    let activity = {
      id: filters.activity,
      evaluationCriteriaId: filters.evaluationCriteria,
      month:
        filters.month < 10 ? ('0' + filters.month).slice(-2) : filters.month,
      year: filters.year,
    };

    if (!activity.id || !activity.evaluationCriteriaId) {
      !activity.id ? setActivityError(true) : setEvaluationError(true);
      return;
    } else {
      getActivityAttendance(activity);
    }
  };

  const dropTable = () => {
    setTableTHs([]);
    setTableRows([]);
  };

  const mountTable = (tableAssisteds) => {
    dropTable();

    if (!tableAssisteds.length) {
      setShowTable(false);
      return;
    }

    const monthDays = new Date(filters.year, filters.month, 0).getDate();

    for (let i = 0; i < monthDays; i++) {
      let newDay = {
        index: i,
        day: `${i + 1}`,
        disabled: true,
        buttonDisabled: false,
      };
      setTableTHs((tableTHs) => [...tableTHs, newDay]);
    }

    let assistedAttendances = [];

    for (let i = 0; i < tableAssisteds.length; i++) {
      let assistedName = tableAssisteds[i].fullname;
      let assistedId = tableAssisteds[i].id;
      let attendance = tableAssisteds[i].assisted_attendance;
      let attendanceArray = [];
      let attendanceDays = [];

      if (attendance.length > 0) {
        let asssitedTotal = 0;

        for (let i = 0; i < attendance.length; i++) {
          let attendanceDate = new Date(`${attendance[i].date}T00:00:00`);
          let attendanceDay = attendanceDate.getDate();
          attendanceDays.push(attendanceDay);
        }

        for (let i = 0; i < monthDays; i++) {
          let day = i + 1;
          let indexOfDay = attendanceDays.indexOf(day);
          let status =
            indexOfDay !== -1 ? parseInt(attendance[indexOfDay].status) : 0;

          attendanceArray.push({
            day: day,
            status: status,
          });
        }

        for (let i = 0; i < attendance.length; i++) {
          asssitedTotal += parseInt(attendance[i].status);
        }
        let assistedAverage = (asssitedTotal / monthDays).toFixed(1);

        let newRow = {
          id: assistedId,
          name: assistedName,
          total: asssitedTotal,
          average: assistedAverage,
          attendance: attendanceArray,
        };

        assistedAttendances.push(newRow);
        setTableRows(assistedAttendances);
      } else {
        for (let i = 0; i < monthDays; i++) {
          attendanceArray.push({
            day: i + 1,
            status: 0,
          });
        }

        let newRow = {
          id: assistedId,
          name: assistedName,
          total: 0,
          average: 0,
          attendance: attendanceArray,
        };

        assistedAttendances.push(newRow);
        setTableRows(assistedAttendances);
      }
    }
    setShowTable(true);
  };

  const handleColumnClick = (ev, columnIndex) => {
    ev.preventDefault();
    const newTableTHs = tableTHs.map((th, index) => {
      if (index === columnIndex) {
        return {
          ...th,
          disabled: false,
        };
      }
      return {
        ...th,
        buttonDisabled: true,
      };
    });
    setTableTHs(newTableTHs);
  };

  const handleAttendanceChange = (ev, assisted, attendance, day) => {
    const assistedId = assisted.id;

    const newAttendance = attendance.map((el) => {
      if (el.day === day) {
        return {
          ...el,
          status: parseInt(ev.target.value),
        };
      }
      return el;
    });

    const newAssisteds = tableRows.map((assisted) => {
      if (assisted.id === assistedId) {
        return {
          ...assisted,
          attendance: newAttendance,
        };
      }
      return assisted;
    });

    setTableRows(newAssisteds);
  };

  const handleColumnSave = (ev, columnIndex) => {
    ev.preventDefault();
    const newRegisters = tableRows.map((assisted) => {
      let attendances = assisted.attendance;
      const newAttendance = attendances.map((attendance) => {
        if (attendance.day === columnIndex + 1) {
          return {
            ...attendance,
            date: `${filters.year}-${
              filters.month < 10
                ? ('0' + filters.month).slice(-2)
                : filters.month
            }-${
              attendance.day < 10
                ? ('0' + attendance.day).slice(-2)
                : attendance.day
            }`,
            status: attendance.status,
            assisted: assisted.id,
            activity: filters.activity,
            evaluation_criteria: filters.evaluationCriteria,
          };
        } else {
          return null;
        }
      });
      return newAttendance.filter((item) => item !== null);
    });

    if (!newRegisters.length) {
      return;
    }

    const attendancesToSave = newRegisters.flat(1);
    attendancesToSave.length && addAttendance(attendancesToSave);

    let activity = {
      id: filters.activity,
      evaluationCriteriaId: filters.evaluationCriteria,
      month:
        filters.month < 10 ? ('0' + filters.month).slice(-2) : filters.month,
      year: filters.year,
    };

    setTimeout(() => {
      getActivityAttendance(activity);
    }, 200);
  };

  useEffect(() => {
    getActivities();
  }, [
    getActivities,
    getActivityAttendance,
    listActivityAssisteds,
    assisteds,
    evaluationCriterias,
  ]);

  return (
    <>
      <LayoutHomeDashboard
        seo="/ Gestão de assistidos"
        screen="assistedManagement"
      >
        <S.Container>
          <Title
            paddingLeftDesk="1rem"
            form
            icon={IconExclamation}
            alt="Configurações"
          >
            Gestão de assistidos e atividades
          </Title>

          <S.FilterWrapper>
            <S.SelectContainer>
              <S.Label>
                Nome da atividade
                <Select
                  className="small"
                  styles={selectStyle}
                  options={activityOptions}
                  onChange={handleActivitySelect}
                />
              </S.Label>
              {activityError && <S.Error>Selecione uma atividade</S.Error>}
            </S.SelectContainer>
            <S.SelectContainer>
              <S.Label>
                Critério de avaliação
                <Select
                  className="small"
                  styles={selectStyle}
                  options={evaluationCriterias}
                  onChange={handleCriteriaSelect}
                  value={selectedCriteria}
                  id="evaluationCriteria"
                />
              </S.Label>
              {evaluationError && (
                <S.Error>Selecione um critério de avaliação</S.Error>
              )}
            </S.SelectContainer>
            <S.SelectContainer>
              <S.Label>
                Período de avaliação
                <Select
                  className="small"
                  styles={selectStyle}
                  options={monthOptions}
                  onChange={handleMonthSelect}
                  defaultValue={{
                    label: new Date().toLocaleString('default', {
                      month: 'long',
                    }),
                    value: new Date().getMonth() + 1,
                  }}
                />
              </S.Label>
            </S.SelectContainer>
            <S.SelectContainer>
              <S.Label>
                Ano
                <Select
                  className="small"
                  styles={selectStyle}
                  options={yearOptions}
                  onChange={handleYearSelect}
                  defaultValue={{
                    label: new Date().getFullYear(),
                    value: new Date().getFullYear(),
                  }}
                />
              </S.Label>
            </S.SelectContainer>
            <S.BoxBtn>
              <S.Button type="button" onClick={handleFilters}>
                Filtrar
              </S.Button>
            </S.BoxBtn>
          </S.FilterWrapper>

          {showTable && (
            <>
              <S.Subtitle>Resultado da Busca:</S.Subtitle>
              <S.ContentTable>
                <S.Table>
                  <thead>
                    <S.TR>
                      <S.TH minWidth="200px" alignLeft paddingTop>
                        Nome Assistido
                      </S.TH>
                      <S.TH paddingTop>Média</S.TH>
                      <S.TH paddingTop>Total</S.TH>
                      {tableTHs.map((item, index) => (
                        <S.TH key={'day-' + item.index}>
                          <S.THInfo>
                            {item.disabled && (
                              <button
                                onClick={(ev) => handleColumnClick(ev, index)}
                                disabled={tableTHs[index].buttonDisabled}
                              >
                                <img src={IconEdit} alt="editar"></img>
                              </button>
                            )}
                            {!item.disabled && (
                              <button
                                onClick={(ev) => handleColumnSave(ev, index)}
                              >
                                <img src={IconSave} alt="editar"></img>
                              </button>
                            )}
                            Dia {item.day}
                          </S.THInfo>
                        </S.TH>
                      ))}
                    </S.TR>
                  </thead>
                  <tbody>
                    {tableRows.map((assisted) => (
                      <S.TR key={assisted.id}>
                        <S.TD alignLeft>{assisted.name}</S.TD>
                        <S.TD>{assisted.average}</S.TD>
                        <S.TD>{assisted.total}</S.TD>
                        {assisted.attendance.map((attendance, index) => (
                          <S.TD key={'attendance-' + index}>
                            <S.InputNumber
                              type="number"
                              value={attendance.status}
                              onChange={(ev) =>
                                handleAttendanceChange(
                                  ev,
                                  assisted,
                                  assisted.attendance,
                                  index + 1
                                )
                              }
                              disabled={tableTHs[index].disabled}
                            />
                          </S.TD>
                        ))}
                      </S.TR>
                    ))}
                  </tbody>
                </S.Table>
              </S.ContentTable>
            </>
          )}
          {showTable === false && (
            <p>Não há assistidos relacionados a essa atividade.</p>
          )}
          <FooterHome screen="activityAvaliation" />
        </S.Container>
      </LayoutHomeDashboard>
    </>
  );
};

export default ActivityAvaliation;
