import React from 'react';
import * as S from './styles';

//imgs
import { Add } from '../../assets/';

const NovoCriterio = ({ handleClick }) => {

  return (
    <S.Content onClick={handleClick}>
      <S.ImgNovoCriterio
        src={Add}
        alt='Novo critério'
      />
      <S.Text>Novo critério</S.Text>
    </S.Content>
  )
}

export default NovoCriterio;