import React from 'react';

//Components
import Header from '../../../components/Header';
import Slider from '../../../components/Slider';

//Styles
import * as S from './styles';

const Login = ({ children }) => {
  return (
    <div>
      <Header />
      <S.Main>
        <Slider />
        <S.Section>
          {children}
        </S.Section>
      </S.Main>
    </div>
  )
}

export default Login;
