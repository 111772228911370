import { createContext, useState, useContext } from 'react';

import { api } from '../../modules/api';
import transformObjKeys from '../../utils/send_snakecase';

const ApiContext = createContext({});

export const ApiProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('idToken') || '');

  const [user, setUser] = useState({});
  const [ong, setOng] = useState(null);
  /**
   * função que retorna objeto contendo
   * header para axios request, exemplo:
   * ```{ headers: { Authorization: "Bearer JWT" } }```
   */
  const getHeaders = () => {
    const localToken = localStorage.getItem('idToken') || '';
    setToken(localToken);
    return {
      headers: {
        Authorization: `Bearer ${localToken}`,
      },
    };
  };

  /**
   * função que recebe o JWT token para
   * atualiza-lo no localStorage e
   * também no estado em que é guardado
   */
  const updateToken = (token = '') => {
    localStorage.setItem('idToken', token);
    setToken(token);
  };

  /**
   * função que verifica na api se o token é válido
   */
  const verifyToken = async () =>
    await api.post('/verify/', { token }).catch(() => updateToken());

  /**
   * função que recebe objeto contendo as informações
   * necessárias para criação de um usuário e o retorna
   * exemplo de retorno:
   * ```{ id: "uuid", first_name: ... }```
   */
  const createUser = (newUserData) =>
    (async (formattedData) =>
      await api.post('/user/', formattedData).then(({ data }) => data))(
      transformObjKeys(newUserData)
    );

  /**
   * função que recebe objeto contendo email e senha do usuário
   * e realiza o login do mesmo na plataforma, já guardando no
   * localStorage seu JWT de acesso
   */
  const login = async (data) =>
    await api
      .post('/login/', data)
      .then(({ data }) => updateToken(data.access));

  /**
   * função que retorna o usuário logado na aplicação
   * exemplo de retorno:
   * ```{ id: uuid, first_name: ... }```
   */
  const getMyUser = async () =>
    await api.get('/user/me/', getHeaders()).then(({ data: [user] }) => {
      const { ong_id: ong, ...userData } = user;
      setUser(userData);
      setOng(ong);
      return user;
    });

  /**
   * função que envia email de confirmação
   * para usuário não confirmado que for passado
   * de parâmetro para a mesma
   */
  const sendEmailConfirmation = async (email) =>
    await api.post('/user/confirm/send/', { email }).then(({ data }) => data);

  /**
   * função que atualiza informações do usuário
   * LOGADO na aplicação, recebe como parâmetro
   * um objeto contendo os dados a se atualizar
   * e retorna o usuário já atualizado.
   */
  const updateUser = (data) =>
    (async (formattedData) =>
      await api
        .patch(`/user/${user.id}`, formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data));

  /**
   * função que deleta o usuário LOGADO
   * na aplicação, não retornando nada e
   * já limpando as informações pertinente
   * ao mesmo do localStorage
   */
  const deleteUser = async () =>
    api.delete(`/user/${user.id}`, getHeaders()).then(() => updateToken());

  /**
   * função que retorna lista contendo
   * usuários que foram convidados para
   * ong que o usuário logado faz parte
   * caso queira filtrar os convites
   * passe como parâmetro um objeto
   * contendo a propriedade hasAccepted
   * como true para os que já aceitaram
   * e como false para os que ainda não
   * exemplo:
   * ```
   * const invites = await listInvites({ hasAccepted: false })
   * ```
   * invites será uma lista que conterá
   * somente os convites ainda não aceitos
   */
  const listInvites = async ({ hasAccepted }) =>
    api
      .get(
        `/user/invites/${
          typeof hasAccepted !== 'undefined'
            ? `?has_accepted=${hasAccepted}`
            : ''
        }`,
        getHeaders()
      )
      .then(({ data }) => data);

  /**
   * função que recebe objeto contendo
   * todas informações necessárias para
   * se criar uma ong e retorna a mesma
   * já criada na API
   */
  const createOng = (data) =>
    (async (formattedData) =>
      api.post('/ong/', formattedData, getHeaders()).then(({ data }) => data))(
      transformObjKeys(data)
    );

  /**
   * função que retorna a ong de id
   * passada como parâmetro ou a ong
   * que o usuário logado pertence
   */
  const getOng = async (ongId = ong.id) =>
    await api
      .get(`/ong/${ongId}/`, getHeaders())
      .then(({ data }) => data)
      .catch((err) => err);

  /**
   * função que retorna lista contendo
   * lista com todas as ongs da aplicação
   */
  const listOngs = async () =>
    await api
      .get('/ong/', getHeaders())
      .then(({ data }) => data)
      .catch((err) => err);

  /**
   * função que atualiza dados da ong
   * em que o usuário logado pertence
   * recebe como parâmetros objeto
   * contendo todos os dados que se quer
   * atualizar e retorna ong já atualizada
   */
  const updateOng = (data) =>
    (async (formattedData) =>
      await api
        .patch(`/ong/${ong.id}/`, formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data));

  /**
   * função que deleta ong de id que for
   * passado como parâmetro para a mesma
   * caso não seja passado nenhum id,
   * deletará ong atrelada ao usuário logado
   */
  const deleteOng = async (ongId = ong.id) =>
    await api.delete(`/ong/${ongId}/`, getHeaders()).then(() => {
      ongId === ong.id && setOng(null);
    });

  /**
   * função que recebe objeto contendo
   * todas informações para se criar uma
   * atividade e retorna a mesma já
   * criada na API
   */
  const createActivity = (data) =>
    (async (formattedData) =>
      await api
        .post('/activity/', formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data));

  /**
   * função para atualizar uma atividade
   * recebe como parâmetro objeto contendo
   * o que se deseja atualizar e id da
   * atividade que se deseja atualizar
   * retorna a atividade já atualizada
   */
  const updateActivity = (data, activityId) =>
    (async (formattedData, activityId) =>
      api
        .patch(`/activity/${activityId}`, formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data), activityId);

  /**
   * função que retorna lista contendo
   * atividades atraladas a ong do
   * usuário logado
   */
  const listActivities = async () =>
    await api.get('/activity/me/', getHeaders()).then(({ data }) => data);

  const getActivity = async (activityId) =>
    await api
      .get(`/activity/${activityId}/assisteds/`, getHeaders())
      .then(({ data }) => data);

  const getActivityDetails = async (activityId) =>
    await api
      .get(`/activity/${activityId}/`, getHeaders())
      .then(({ data }) => data);

  const deleteActivity = async (activityId) =>
    await api.delete(`/activity/${activityId}/`, getHeaders());

  /**
   * função que recebe objeto contendo
   * informações necessárias para se criar
   * um assistido e retorna o mesmo já
   * criado na API
   */
  const createAssisted = (data) =>
    (async (formattedData) =>
      await api
        .post('/assisted/', formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data));

  /**
   * função que lista assistidos atrelados
   * à ONG em que o usuário logado pertence
   * (caso se trate de usuário admin irá
   * listar todos os assistidos da plataforma)
   */
  const listAssisteds = async () =>
    await api.get('/assisted/', getHeaders()).then(({ data }) => data);

  /**
   * função para atualizar dados de determinado
   * assistido, recebe como parâmetro um objeto
   * contendo esses dados e o id do assistido
   * que se deseja atualizar e retorna o mesmo
   * já atualizado
   */
  const updateAssisted = (data, assistedId) =>
    (async (formattedData, assistedId) =>
      await api
        .patch(`/assisted/${assistedId}/`, formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data), assistedId);

  /**
   * função que realiza a deleção do
   * assistido que tiver seu id passado
   * por parâmetro
   */
  const deleteAssisted = async (assistedId) =>
    await api.delete(`assisted/${assistedId}/`, getHeaders());

  /**
   * função que returna assistido
   * que tiver seu id passado por
   * parâmetro
   */
  const getAssisted = async (assistedId) =>
    await api
      .get(`/assisted/${assistedId}/`, getHeaders())
      .then(({ data }) => data);

  /**
   * função que realiza criação de
   * uma nota, precisa receber de parâmetro
   * um objeto contendo os dados pertinentes
   * à criação de uma nota, retorna a mesma
   * já criada na API
   */
  const createGrade = (data) =>
    (async (formattedData) =>
      await api
        .post('/grade/', formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data));

  /**
   * função que retorna lista de notas pertinentes
   * a ong em que o usuário logado está atrelado
   */
  const listGrades = async () =>
    await api.get('/grade/', getHeaders()).then(({ data }) => data);

  /**
   * função que atualiza dados de uma nota
   * recebendo assim um objeto contendo
   * os dados que se deseja atualizar
   * e o id da nota em que se deseja atualizar
   * retorna a mesma já atualizada
   */
  const updateGrade = (data, gradeId) =>
    (async (formattedData, gradeId) =>
      await api
        .patch(`/grade/${gradeId}/`, formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data), gradeId);

  /**
   * função que realiza a deleção de
   * nota que tiver seu id passado
   * por parâmetro
   */
  const deleteGrade = async (gradeId) =>
    await api.delete(`/grade/${gradeId}/`, getHeaders());

  /**
   * função que retorna nota que
   * tiver seu id passado por parâmetro
   */
  const getGrade = async (gradeId) =>
    await api.get(`/grade/${gradeId}/`, getHeaders()).then(({ data }) => data);

  /**
   * função para criação de um novo documento
   * recebe como parâmetro objeto contendo
   * todas as informações para se criar um
   * documento
   */
  const createDocument = (data) =>
    (async (formattedData) =>
      await api
        .post('/documents/', formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data));

  /**
   * função que realiza a atualização
   * de dados dum documento, recebe como
   * parâmetros um objeto contendo todos
   * os dados que se deseja atualizar e o
   * id do documento em questão, retorna
   * o documento já atualizado
   */
  const updateDocument = (data, documentId) =>
    (async (formattedData, documentId) =>
      await api
        .patch(`/documents/${documentId}/`, formattedData, getHeaders())
        .then(({ data }) => data))(transformObjKeys(data), documentId);

  /**
   * função que realiza a deleção
   * do documento que tiver seu id
   * passado como parâmetro
   */
  const deleteDocument = async (documentId) =>
    await api.delete(`/documents/${documentId}/`, getHeaders());

  /**
   * função que retorna documento de id
   * passado por parâmetro
   */
  const getDocument = async (documentId) =>
    await api
      .get(`/documents/${documentId}`, getHeaders())
      .then(({ data }) => data);

  /**
   * função que retorna lista de documentos
   * relacionados a ong em que o usuário
   * logado está atrelado
   */
  const getMyOngDocuments = async () =>
    await api.get('/documents/me/', getHeaders()).then(({ data }) => data);

  /**
   * maioria das funções são assincronas e,
   * por isso, precisa-se de await para as
   * mesmas, mas como retornam promises
   * se pode encadear .then e .catch
   * para se realizar ações logo em que
   * as requisições forem realizadas, sejam
   * elas bem sucedidas (then) ou não (catch)
   */

  const createAttendance = async (data) =>
    await api.post('/attendance/', data, getHeaders()).then(({ data }) => data);

  const listOngUsers = async () =>
    await api.get('/user/list/', getHeaders()).then(({ data }) => data);

  const addResponsibleToActivity = async ({ activityId, responsibleId }) =>
    await api
      .post(
        `/activity/${activityId}/responsible/${responsibleId}/`,
        null,
        getHeaders()
      )
      .then(({ data }) => data);

  const addAssistedToActivity = async ({ activityId, assistedId }) =>
    await api
      .post(
        `/activity/${activityId}/assisteds/${assistedId}/`,
        null,
        getHeaders()
      )
      .then(({ data }) => data);

  const listResponsible = async ({ activityId }) =>
    await api
      .get(`/activity/${activityId}/responsible/`, getHeaders())
      .then(({ data }) => data);

  const removeResponsibleFromActivity = async ({ activityId, responsibleId }) =>
    await api.delete(
      `/activity/${activityId}/responsible/${responsibleId}/`,
      getHeaders()
    );

  const listActivityAssisteds = async (activity) =>
    await api
      .get(
        `/activity/${activity.id}/assisteds/?evaluation_criteria=${activity.evaluationCriteriaId}&month=${activity.month}&year=${activity.year}`,
        getHeaders()
      )
      .then(({ data }) => {
        return data.assisteds;
      });

  const removeAssistedFromActivity = async ({ activityId, assistedId }) =>
    await api.delete(
      `/activity/${activityId}/assisteds/${assistedId}/`,
      getHeaders()
    );

  return (
    <ApiContext.Provider
      value={{
        getHeaders,
        verifyToken,
        createUser,
        login,
        getMyUser,
        sendEmailConfirmation,
        updateUser,
        deleteUser,
        listInvites,
        createOng,
        listOngs,
        getOng,
        updateOng,
        deleteOng,
        createActivity,
        updateActivity,
        listActivities,
        getActivity,
        getActivityDetails,
        deleteActivity,
        createAssisted,
        listAssisteds,
        updateAssisted,
        deleteAssisted,
        getAssisted,
        createGrade,
        listGrades,
        updateGrade,
        deleteGrade,
        getGrade,
        createDocument,
        updateDocument,
        deleteDocument,
        getDocument,
        getMyOngDocuments,
        createAttendance,
        listOngUsers,
        addResponsibleToActivity,
        addAssistedToActivity,
        listResponsible,
        removeResponsibleFromActivity,
        listActivityAssisteds,
        removeAssistedFromActivity,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
