import React from "react";
import * as S from "./styles";

const Btn = ({
  margin,
  marginBottom,
  marginTop,
  positionBtn,
  onClick,
  text,
  width,
  height,
  left,
  right,
  homeScreen,
  type,
  fontSmall,
  color,
  fontColor,
  marginLeft,
  display,
  widthDesk,
  isDisabled,
  borderRadius,
}) => {
  return (
    <S.ContentBtn positionBtn={positionBtn} left={left} right={right}>
      <S.Btn
        margin={margin}
        type={type || "submit"}
        id="enviar"
        width={width}
        height={height}
        fontSmall={fontSmall}
        widthDesk={widthDesk}
        color={color}
        homeScreen={homeScreen}
        onClick={onClick}
        fontColor={fontColor}
        marginLeft={marginLeft}
        display={display}
        marginBottom={marginBottom}
        marginTop={marginTop}
        disabled={isDisabled}
        borderRadius={borderRadius}
      >
        {text}
      </S.Btn>
    </S.ContentBtn>
  );
};

export default Btn;
