import React from "react";

//Styles
import * as S from './styles';

const InputRadioBoolean = ({
  onChange,
  checked,
  onClickTerms,
  onClickPolicy
}) => {
  return (
    <S.Container>
      <p>Li e aceito o <button type="button" onClick={onClickTerms}>termo de uso</button> e <button type="button" onClick={onClickPolicy}>política de privacidade</button>:</p>
    <S.OptionsContainer>
    <S.ContentInputRadio>
    <input
      type="radio" 
      name="terms" 
      id="terms_sim" 
      onChange={onChange}
      />
    <label 
    htmlFor="terms_sim">
      Sim, aceito
    </label>
    </S.ContentInputRadio>
    <S.ContentInputRadio>
    <input 
      type="radio" 
      name="terms" 
      id="terms_nao" 
      checked={checked} 
      onChange={onChange}/>
    <label
    htmlFor="terms_nao">
      Não aceito
    </label>
    </S.ContentInputRadio>
    </S.OptionsContainer>
    </S.Container>
  );
};

export default InputRadioBoolean;
