import React from "react";
import * as S from "./styles";
import Button from "../../Buttons/button";
const FooterDescktop = ({ none, text, onClick, isError, msgError }) => {
  return (
    <S.Container none={none}>
      {isError && (
        <S.TextError>
          {
            msgError && msgError
            // : "Por favor preencha todos os campos"
          }
        </S.TextError>
      )}

      <Button
        // right
        type="submit"
        text={text ? text : "Próximo"}
        onClick={onClick}
      />
    </S.Container>
  );
};

export default FooterDescktop;
