import styled from 'styled-components';

export const Container = styled.div`
    height: 5vh;
    display: flex;
    justify-content: center;

    @media(min-width: 1024px) {
        display: none;
    }
`

export const ImgBack = styled.img`
    width: 1.5rem;
`