import styled from 'styled-components/macro';
import { colors } from '../../../../styles/Theme';

export const SelectContainer = styled.div`
  width: 100%;
  margin: 8px 0;
  position: relative;

  @media (min-width: 1024px) {
    z-index: 2;
  }
`;

export const Container = styled.section`
  width: 100%;
  height: 100%;
  /* max-height: calc(100vh - 8.438rem); */
  padding: 1.813rem;

  @media (min-width: 768px) {
    max-height: calc(100vh - 8.438rem);
  }

  @media (min-width: 1024px) {
    padding: 1rem 2rem;
    margin: 0 auto;
    width: 70%;
  }
`;

export const FilterWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ContentBox = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  font-family: 'Open Sans';
  line-height: 19px;
  font-weight: 700;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
`;

export const ContentTable = styled.form`
  padding-bottom: 1rem;
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: auto;

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: ${colors.gray05};
  }

  ::-webkit-scrollbar-track {
    background: ${colors.gray05};
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: ${colors.gray};
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.gray02};
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -16px;
  color: red;

  @media (min-width: 1024px) {
    bottom: -20px;
  }
`;

export const Subtitle = styled.h2`
  margin: 16px 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: ${colors.black};

  @media (min-width: 1024px) {
    margin: 32px 0 16px 0;
  }
`;

export const Table = styled.table`
  margin-bottom: 4.063rem;
  border: 1px solid ${colors.darkGreen};
  border-radius: 10px;
  width: 100%;
  padding: 8px;

  @media (min-width: 1024px) {
    padding: 0;
    margin-bottom: 0;
    border-spacing: ${(props) =>
      props.noBorderSpacing ? '0' : '1.25rem .688rem'};
  }
`;

export const TH = styled.th`
  padding: 8px 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '105px')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '56px')};
  padding-top: ${(props) => (props.paddingTop ? '36px' : '0')};
  background: white;

  &:first-child {
    position: sticky;
    left: 0;

    @media (min-width: 1024px) {
      z-index: 1;
    }
  }
`;

export const THInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  button > img {
    margin-bottom: 8px;
  }
`;

export const TR = styled.tr`
  height: 56px;
`;

export const TD = styled.td`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  padding: ${(props) => (props.alignLeft ? '0 16px' : '0')};
  background: white;
  color: #2b2b2b;
  width: ${(props) => (props.width ? props.width : '105px')};
  height: ${(props) => (props.height ? props.height : '56px')};

  &:first-child {
    position: sticky;
    left: 0;

    @media (min-width: 1024px) {
      z-index: 1;
    }
  }

  @media (min-width: 1024px) {
    padding: ${(props) => props.noPadding && '0'};
    position: relative;
    min-width: ${(props) => props.minWidth};
  }
`;

export const InputNumber = styled.input`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  width: 105px;
  height: 56px;
  background: transparent;

  &:enabled {
    border: 1px solid ${colors.blue01};
  }
`;

export const BoxBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    align-items: end;
  }
`;

export const Button = styled.button`
  width: 104px;
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  background: ${colors.blue01};
  border-radius: 4px;
  color: ${colors.white};
  margin: 16px 0;

  &:hover,
  &:focus {
    font-weight: 800;
  }
`;
