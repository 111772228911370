

import styled from "styled-components/macro";

export const MH = styled.div`
z-index: 2;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const DH = styled.div`
  display: none;
  z-index: 2;
  @media (min-width: 1024px) {
    width: 100vw;
    display: flex;
  }
`;
