import styled from "styled-components";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  font-family: "Open Sans";
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  height: 90%;

  @media (min-width: 1024px) {
    height: 95%;
    width: 90%;
    align-items: flex-start;
    border-radius: 0;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;

  @media (min-width: 1024px) {
    width: 50%;
    margin-top: 5%;
  }
`;

export const Icon = styled.img`
  width: 50px;
  height: auto;
`;

export const Title = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  font-size: 24px;
  color: ${colors.black};
  margin: 20px 0;

  @media (min-width: 1024px) {
    font-size: 40px;
    margin: 30px 0;
  }
`;

export const Subtitle = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${colors.black};
  font-size: 16px;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
  }
`;
