import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > div {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    > input {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      background-color: #f6f6f6;
      border-radius: 5px;
      border: 1px solid #bebebe;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  background-color: ${({ add }) => (add ? "#5484ED" : "#C4C4C4")};
  width: 20px;
  height: 20px;
  font-size: 17px;
  font-weight: 600;
`;

export const Add = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  color: #fff;
  background-color: ${({ add }) => (add ? "#5484ED" : "#C4C4C4")};
  font-size: 0.8rem;
  font-weight: 600;
  padding: 8.5px;
`;
