import React, { useState } from "react";

// styles
import * as S from "./styles";

//components
import Btn from "../../Buttons/BtnTrash";
import ModalConfirmDelete from "../../../components/Modals/confirmDelete";

const SelectAccess = ({ label, type, widthDesk, value }) => {
  const [isModalDelete, setIsModal] = useState(false);

  const handleModalDelete = () => {
    setIsModal(!isModalDelete);
  };

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.ContentSelect widthDesk={widthDesk}>
        <S.Select>{value}</S.Select>
        {type === "master" && <Btn onClick={handleModalDelete} />}
        {isModalDelete && (
          <ModalConfirmDelete title="" handleModalDelete={handleModalDelete} />
        )}
      </S.ContentSelect>
    </S.Container>
  );
};

export default SelectAccess;
