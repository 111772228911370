

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Images
import { HomeIcon, IconConfig, Sair } from "../../../assets";

//Styles
import * as S from "./styles";

function FooterHome() {
  const navigate = useNavigate();
  let location = useLocation();
  const [currentScreen, setCurrentScreen] = useState(undefined);

  useEffect(() => {
    const pathname = location.pathname;

    setCurrentScreen(pathname);
  }, [location.pathname]);


  const handleRoute = (route) => {
    navigate(route);
  };

  const handleLogout = () => {
    localStorage.removeItem("idToken");
    navigate('/');
  }

  return (
    <S.Container>
      <S.BoxContentInfo
        screen={currentScreen === '/home'}
        onClick={() => handleRoute('/home')}
      >
        <S.img src={HomeIcon} alt='Home' />
        <S.P>Home</S.P>
      </S.BoxContentInfo>

      <S.BoxContentInfo
        screen={currentScreen === '/configuracoes'}
        onClick={() => handleRoute('/configuracoes')}
      >
        <S.img config src={IconConfig} alt='Config' />
        <S.P>Config</S.P>
      </S.BoxContentInfo>

      <S.BoxContentInfo>
        <S.img src={Sair} alt='Sair'
          onClick={handleLogout}
        />
        <S.P>Sair</S.P>
      </S.BoxContentInfo>
    </S.Container>
  );
}

export default FooterHome;
