export const colors = {
  // ===========================
  // Black
  // ===========================
  black: "#000000",
  black02: "rgba(0,0,0,0.4)",
  black03: "rgba(0,0,0,0.2)",

  // ===========================
  // WHITE
  // ===========================
  white: "#FFFFFF",

  // ===========================
  // GRAY
  // ===========================
  gray: "#707070",
  gray02: "#424242",
  gray03: "#C4C4C4",
  gray04: "#96c23c",
  gray05: "#D9D9D9",
  lightGray: "#F6F6F6",
  darkGray: "#E7E7E7",

  // ===========================
  // BLUE
  // ===========================
  darkBlue: "#0080FF",
  // lightBlue: '#657D9B',
  blue: "#0080FF",
  blue01: "#5484ED",

  // ===========================
  // Green
  // ===========================
  green: "#7EBD2F",
  green01: "#B4FF56",
  darkGreen: "#93DC38",

  // ===========================
  // RED
  // ===========================
  error: "#CC2500",
  lightRed: "#FFE9CC",

  // ===========================
  // ORANGE
  // ===========================
  orange01: "#FFECD3",
  orange: "#FF8F00",
  lightOrange: "#FFE7C9",

  // ===========================
  // GREY
  // ===========================
  grey: "#BEBEBE",
};
