import styled from "styled-components";

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
  margin-bottom: 40px;

  .textareaElement {
    padding: 8px 16px;
    background-color: #f6f6f6;
    color: #707070;
    width: 80%;
    min-height: 132px;
    border-radius: 5px;
    border: 1px solid #bebebe;
    font-family: "Open sans";
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
  }

  input[type="checkbox"] {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;

export const StyledText = styled.h4`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #424242;
`;

export const InputListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 1rem;
  margin: 16px 0 0;

  > li {
    font-style: normal;
    display: flex;
    align-items: center;
  }
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledActiviesWrapper = styled.div`
  margin-bottom: 48px;
`;

export const StyledButtonWrapper = styled.div`
  align-self: flex-end;
  margin: 40px 8px 40px 0;
`;
