import React, { useState, useEffect } from 'react';

// components
import LayoutHomeDashboard from '../../../../components/SideBarSettings';
import Title from '../../../../components/Title';
import FooterHome from '../../../../components/Footer/FooterHome';
import SearchWatched from '../activityManagement/searchWatched';
import { CloseModal } from '../../../../assets';
import {
  skinColorsList,
  scholarityList,
  familyIncomeList,
} from '../../../../utils/lists';

import { useApi } from '../../../../providers/Api';
import { api } from '../../../../modules/api';

//Images
import { IconAssisted } from '../../../../assets';

// styles
import * as S from './styles';
import { Button } from '../activityManagement/searchWatched/modalGrey/styles';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const AssistedList = () => {
  const { getMyUser } = useApi();
  const [user, setUser] = useState('');
  const [assistedList, setAssistedList] = useState([]);
  const [assistedFiltered, setAssistedFiltered] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [isFiltersModalOn, setIsFiltersModalOn] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    getUserInfo();
    getAssistedList();
  }, []);

  const getAssistedList = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
      },
    };

    await api.get('/assisted/', headers).then((response) => {
      setAssistedList(response.data);
      setAssistedFiltered(response.data);
    });

    await api.get('/activity/me/', headers).then((response) => {
      setActivityList(response.data);
    });
  };

  const getUserInfo = async () => {
    const response = await getMyUser();
    setUser(response);
  };

  const toggleFiltersModal = (ev) => {
    setIsFiltersModalOn(!isFiltersModalOn);
  };

  const handleInitialSelect = (ev) => {
    setFilters({ ...filters, initial: ev.value });
  };

  const handleActivitySelect = (ev) => {
    setFilters({ ...filters, activity: ev.value });
  };

  const handleBirthDateSelect = (ev) => {
    setFilters({ ...filters, birth_date: ev.value });
  };

  const handleSkinColorSelect = (ev) => {
    setFilters({ ...filters, skin_color: ev.value });
  };

  const handleFamilyIncomeSelect = (ev) => {
    setFilters({ ...filters, family_income: ev.value });
  };

  const handleScholaritySelect = (ev) => {
    setFilters({ ...filters, scholarity: ev.value });
  };

  const clearInitialSelect = (ev) => {
    ev.preventDefault();
    let updatedFilters = filters;
    delete filters.initial;
    setFilters(updatedFilters);
  };

  const clearActivitySelect = (ev) => {
    ev.preventDefault();
    let updatedFilters = filters;
    delete filters.activity;
    setFilters(updatedFilters);
  };

  const clearBirthDateSelect = (ev) => {
    ev.preventDefault();
    let updatedFilters = filters;
    delete filters.birth_date;
    setFilters(updatedFilters);
  };

  const clearSkinColorSelect = (ev) => {
    ev.preventDefault();
    let updatedFilters = filters;
    delete filters.skin_color;
    setFilters(updatedFilters);
  };

  const clearFamilyIncomeSelect = (ev) => {
    ev.preventDefault();
    let updatedFilters = filters;
    delete filters.family_income;
    setFilters(updatedFilters);
  };

  const clearScholaritySelect = (ev) => {
    ev.preventDefault();
    let updatedFilters = filters;
    delete filters.scholarity;
    setFilters(updatedFilters);
  };

  const updateFilers = (ev) => {
    console.log(ev);
    ev.preventDefault();
    const filtered = assistedList
      .filter((assistido) =>
        filters?.initial ? assistido.fullname.startsWith(filters.initial) : true
      )
      .filter((assistido) =>
        filters?.activity
          ? assistido.activities.filter(
              (activity) => activity.name === filters.activity
            ).length
          : true
      )
      .filter((assistido) =>
        filters?.birth_date
          ? assistido.birth_date.startsWith(filters.birth_date)
          : true
      )
      .filter((assistido) =>
        filters?.skin_color ? assistido.skin_color === filters.skin_color : true
      )
      .filter((assistido) =>
        filters?.family_income
          ? assistido.family_group.family_income === filters.family_income
          : true
      )
      .filter((assistido) =>
        filters?.scholarity ? assistido.scholarity === filters.scholarity : true
      );

    setAssistedFiltered(filtered);
    toggleFiltersModal();
  };

  const initialOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
    { value: 'E', label: 'E' },
    { value: 'F', label: 'F' },
    { value: 'G', label: 'G' },
    { value: 'H', label: 'H' },
    { value: 'I', label: 'I' },
    { value: 'J', label: 'J' },
    { value: 'K', label: 'K' },
    { value: 'L', label: 'L' },
    { value: 'M', label: 'M' },
    { value: 'N', label: 'N' },
    { value: 'O', label: 'O' },
    { value: 'P', label: 'P' },
    { value: 'Q', label: 'Q' },
    { value: 'R', label: 'R' },
    { value: 'S', label: 'S' },
    { value: 'T', label: 'T' },
    { value: 'U', label: 'U' },
    { value: 'V', label: 'V' },
    { value: 'W', label: 'W' },
    { value: 'X', label: 'X' },
    { value: 'Y', label: 'Y' },
    { value: 'Z', label: 'Z' },
  ];

  const activityOptions = activityList.map((item) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  const birthDateOptions = assistedList.map((item) => {
    return {
      label: item.birth_date.split('-').slice(0, -1).reverse().join('/'),
      value: item.birth_date.split('-').slice(0, -1).join('-'),
    };
  });

  const skinColorOptions = skinColorsList.map((item) => {
    return {
      label: item.name,
      value: item.value,
    };
  });

  const scholarityOptions = scholarityList.map((item) => {
    return {
      label: item.name,
      value: item.value,
    };
  });

  const familyIncomeListOptions = familyIncomeList.map((item) => {
    return {
      label: item.value,
      value: item.value,
    };
  });

  const navigate = useNavigate();

  const handleRoute = (route) => {
    navigate(route);
  };

  return (
    <>
      <LayoutHomeDashboard
        seo="/ Gestão de atividades"
        screen="activityManagement"
      >
        <S.Container>
          <Title
            paddingLeftDesk="2.563rem"
            form="true"
            icon={IconAssisted}
            alt="Configurações"
          >
            Gestão de assistidos e atividades
          </Title>
          <S.Box>
            <S.ButtonsAssistedList>
              <Button onClick={toggleFiltersModal}>Filtrar</Button>
              <Button
                onClick={() => handleRoute('/gestao-de-atividades')}
                className="button-activities"
              >
                Acompanhar Atividades
              </Button>
            </S.ButtonsAssistedList>
            <SearchWatched assistedList={assistedFiltered} />
            {isFiltersModalOn && (
              <S.Overlay>
                <S.ContainerModal>
                  <h1>
                    Selecione quais filtros irão exibir os dados dos assistidos
                    <S.ContainerCloseModal onClick={toggleFiltersModal}>
                      <S.ImageCloseModal src={CloseModal} alt="Excluir" />
                      <S.Trash>Fechar</S.Trash>
                    </S.ContainerCloseModal>
                  </h1>
                  <S.ContainerButtonsList>
                    <div className="select-container">
                      <Button
                        className={`big ${filters?.initial ? 'active' : ''}`}
                        onClick={clearInitialSelect}
                      >
                        Inicial do nome do assistido
                      </Button>
                      {!filters?.initial && (
                        <Select
                          className="small"
                          options={initialOptions}
                          onChange={handleInitialSelect}
                        />
                      )}
                      {filters?.initial && (
                        <div className="small filters-value">
                          {filters.initial}
                        </div>
                      )}
                    </div>
                    <div className="select-container">
                      <Button
                        className={`big ${filters?.activity ? 'active' : ''}`}
                        onClick={clearActivitySelect}
                      >
                        Atividade do assistido
                      </Button>
                      {!filters?.activity && (
                        <Select
                          className="small"
                          options={activityOptions}
                          onChange={handleActivitySelect}
                        />
                      )}
                      {filters?.activity && (
                        <div className="small filters-value">
                          {filters.activity}
                        </div>
                      )}
                    </div>
                    <div className="select-container">
                      <Button
                        className={`big ${filters?.birth_date ? 'active' : ''}`}
                        onClick={clearBirthDateSelect}
                      >
                        Data de Nascimento
                      </Button>
                      {!filters?.birth_date && (
                        <Select
                          className="small"
                          options={birthDateOptions}
                          onChange={handleBirthDateSelect}
                        />
                      )}
                      {filters?.birth_date && (
                        <div className="small filters-value">
                          {filters.birth_date.split('-').reverse().join('/')}
                        </div>
                      )}
                    </div>
                    <div className="select-container">
                      <Button
                        className={`big ${filters?.skin_color ? 'active' : ''}`}
                        onClick={clearSkinColorSelect}
                      >
                        Cor
                      </Button>
                      {!filters?.skin_color && (
                        <Select
                          className="small"
                          options={skinColorOptions}
                          onChange={handleSkinColorSelect}
                        />
                      )}
                      {filters?.skin_color && (
                        <div className="small filters-value">
                          {
                            skinColorsList.find(
                              (item) => item.value === filters.skin_color
                            )?.name
                          }
                        </div>
                      )}
                    </div>
                    <div className="select-container">
                      <Button
                        className={`big ${
                          filters?.family_income ? 'active' : ''
                        }`}
                        onClick={clearFamilyIncomeSelect}
                      >
                        Renda Familiar
                      </Button>
                      {!filters?.family_income && (
                        <Select
                          className="small"
                          options={familyIncomeListOptions}
                          onChange={handleFamilyIncomeSelect}
                        />
                      )}
                      {filters?.family_income && (
                        <div className="small filters-value">
                          {filters.family_income}
                        </div>
                      )}
                    </div>
                    <div className="select-container">
                      <Button
                        className={`big ${filters?.scholarity ? 'active' : ''}`}
                        onClick={clearScholaritySelect}
                      >
                        Escolaridade
                      </Button>
                      {!filters?.scholarity && (
                        <Select
                          className="small"
                          options={scholarityOptions}
                          onChange={handleScholaritySelect}
                        />
                      )}
                      {filters?.scholarity && (
                        <div className="small filters-value">
                          {
                            scholarityList.find(
                              (item) => item.value === filters.scholarity
                            )?.name
                          }
                        </div>
                      )}
                    </div>
                    <br />
                    <Button type="submit" onClick={updateFilers}>
                      Filtrar
                    </Button>
                  </S.ContainerButtonsList>
                </S.ContainerModal>
              </S.Overlay>
            )}
          </S.Box>
          <FooterHome screen="activityManagement" />
        </S.Container>
      </LayoutHomeDashboard>
    </>
  );
};

export default AssistedList;
