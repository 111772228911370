import styled from "styled-components/macro";

export const BackgroundGreen = styled.img`
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const BoxEllipse = styled.div`
  width: 100vw;
  position: absolute;
  top: -3rem;
  height: 58vh;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ImgBackground = styled.img`
  width: 100%;
  height: 100%;
`;
