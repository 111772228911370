const formMessages = {
  general: {
    errorMessage: "campo obrigatório",
    onlyLetters: "Somente letras",
  },
  cpf: {
    description: "Formato do CPF: 000.000.000-00",
  },
  fullname: {
    description: "Não abrevie o nome",
  },
  birthDate: {
    description: "Formato da data: 00/00/0000",
  },
  responsibleName: {
    description: "Exemplo: Maria Amaral da Silva",
  },
  contactNum: {
    description: "Formato do celular: (21) 99999-9999",
  },
  comunityArea: {
    description: "Exemplo: Largo do Boiadeiro",
  },
};

export default formMessages;
