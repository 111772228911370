import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useApi } from "../../providers/Api";
import { createAssistedActivitiesSchema } from "../../utils/schemas";
import {
  InputLabelWrapper,
  InputListWrapper,
  StyledActiviesWrapper,
  StyledButtonWrapper,
  StyledText,
  Wrapper,
} from "./styles";
import Btn from "../Buttons/button";
import ModalAlert from "../Modals/modalAlert";
import TitleQuestion from "../Title/TitleQuestion";
import isEmpty from "lodash/isEmpty";
import { Text } from "../Text";
import { getListOfErrorsMessage } from "../../utils/messageUtils";

// assets
import iconAlert from "../../assets/icons/iconAlert.png";

const AssistedActivitiesForm = ({ adderFn }) => {
  const navigate = useNavigate();
  const [activities, setActivities] = useState([]);
  const [returnedError, setReturnedError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    unregister,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createAssistedActivitiesSchema),
  });

  const { listActivities } = useApi();

  const getActivities = useCallback(async () => {
    await listActivities().then(setActivities);
  }, [listActivities]);

  const hasNoActivityValue = watch("hasNoActivity");

  useEffect(() => {
    getActivities()
      .then(setReturnedError(false))
      .catch((error) => setReturnedError(true));
  }, [getActivities, returnedError]);

  useEffect(() => {
    if (hasNoActivityValue) {
      unregister("activitiesId");
    }
  }, [unregister, hasNoActivityValue]);

  const createAssisted = (data) => {
    adderFn({ data, formStage: "thirdPart" });
  };

  const notify = () => {
    if (hasNoActivityValue) {
      return navigate("/cadastro-com-sucesso");
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(createAssisted)}>
      {returnedError && (
          <ModalAlert
            icon={iconAlert}
            title={"Desculpe, estamos passando por uma instabilidade"}
            subtitle={`Por favor, tente novamente`}
            buttonText={"Tentar novamente"}
            handleCloseModal={() => {
              getActivities();
              setReturnedError(false);
            }}
          />
        )}
      <StyledActiviesWrapper>
        <TitleQuestion hasError={errors?.activitiesId}>
          Selecione as atividades da ONG que o assistido participa: *
        </TitleQuestion>
        <TitleQuestion isSubtitle>
          (as atividades selecionadas irão alimentar a área de Gestão de
          atividades)
        </TitleQuestion>
        <InputListWrapper>
          {activities.map(({ id, name }) => (
            <li key={id}>
              <InputLabelWrapper>
                <input
                  type="checkbox"
                  value={id}
                  id={`activitiesId_${name}`}
                  {...register("activitiesId", {
                    disabled: hasNoActivityValue,
                  })}
                />
                <label htmlFor={`activitiesId_${name}`}>{name}</label>
              </InputLabelWrapper>
            </li>
          ))}
          <li>
            <InputLabelWrapper>
              <input
                type="checkbox"
                id="activitiesId_hasNoActivity"
                {...register("hasNoActivity")}
              />
              <label htmlFor="activitiesId_hasNoActivity">
                Ainda não está em nenhuma atividade da ONG
              </label>
            </InputLabelWrapper>
          </li>
        </InputListWrapper>
        {errors?.activitiesId && (
          <Text.Error>{errors?.activitiesId.message}</Text.Error>
        )}
      </StyledActiviesWrapper>
      <StyledText>Observações do Usuário:</StyledText>
      <textarea
        placeholder="Digite aqui quaisquer observações que deseja adicionar"
        className="textareaElement"
      />
      <StyledButtonWrapper>
        <Btn
          text="Finalizar Cadastro"
          color="#5484ED"
          borderRadius="5px"
          marginBottom="0"
          onClick={notify}
        />
      </StyledButtonWrapper>
      {!isEmpty(errors) && (
        <Text.Root>
          <Text.ErrorWithIcon>
            {getListOfErrorsMessage(errors)}
          </Text.ErrorWithIcon>
        </Text.Root>
      )}
    </Wrapper>
  );
};

export default AssistedActivitiesForm;
