import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const BoxAll = styled.div`
  width: 100%;
  height: 100vh;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh-5rem);
`;

export const Line = styled.hr`
  color: 1px solid ${colors.black02};
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const BoxTutorial = styled.section`
  margin: auto;
  width: 65%;
  height: calc(100vh - 10.438rem);
  max-height: 80vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1022px) {
    display: none;
  }
`;

export const BoxElipse3 = styled.div``;

export const BoxInstructions = styled.div`
  margin: 0 auto;
  min-width: 654px;
  min-height: 431px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const NumberBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const BoxInstruction3 = styled.div`
  height: 186px;
`;

export const Text = styled.h2`
  color: #5484ed;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 2.5rem;
`;

export const ContentBox = styled.div`
  width: 90%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

//home
export const Img1home = styled.div``;

export const Titlehome = styled.div`
  align-self: self-end;

  div,
  h1 {
    text-align: -webkit-center;
  }
`;

export const Title = styled.h1`
  color: ${colors.black};
  font-family: "Nunito";
  font-weight: 800;
  font-size: 3.125rem;
`;

export const BoxElipse = styled.div`
  align-self: center;
`;

export const Img4home = styled.div`
  display: flex;
  align-items: end;
  justify-content: start;
`;

export const Img5home = styled.div`
  display: flex;
`;

export const BoxContent = styled.div``;

// ---------- Mobile -------------
export const ContentMob = styled.div`
  border-top: solid 1px ${colors.gray};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ImageCircleGreen = styled.img`
  position: absolute;
  top: 8rem;
  left: -3rem;
`;

export const ImageRectangleGreen = styled.img`
  position: absolute;
  width: 3.183rem;
  top: 13.5rem;
  right: 1.5rem;
  z-index: 1;
`;

export const ImageRectangleOrange = styled.img`
  position: absolute;
  top: 13.5rem;
  right: 0;
`;

export const TitleTutorial = styled.h1`
  font-size: 1.563rem;
  font-weight: 900;
  text-align: center;
  font-family: "Nunito";
  line-height: 2.125rem;
  padding-top: 5rem;
`;

export const BoxTutorialMob = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const ImageBoxTutorial = styled.img`
  max-width: 3.75rem;
  max-height: 4.75rem;
`;

export const BoxText = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 500px) {
    width: 70%;
  }
`;

export const TitleBoxTutorial = styled.h2`
  color: ${colors.blue};
  font-family: "Nunito";
  font-weight: 900;
  font-size: 1rem;
  line-height: 1.375rem;
`;

export const Description = styled.p`
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const ImageRectangleOrangeTwo = styled.img`
  position: absolute;
  top: 30rem;
  transform: rotate(180deg);
  left: 0;
  width: 2rem;
`;

export const ImageRectangleBlue = styled.img`
  position: absolute;
  top: 38rem;
  right: 0;
  width: 2rem;
`;

export const ImageRectangleOrangeThree = styled.img`
  position: absolute;
  top: 47rem;
  left: 1rem;
  width: 3.183rem;
`;

export const ImageRectangleBlueTwo = styled.img`
  position: absolute;
  top: 48.4rem;
  left: 0;
  width: 2rem;
  transform: rotate(-90deg);
`;

export const ImageRectangleOrangeFour = styled.img`
  position: absolute;
  top: 50rem;
  right: 1rem;
  width: 3.183rem;
  transform: rotate(-90deg);
`;

export const ImageCircleBlack = styled.img`
  position: absolute;
  top: 50rem;
  right: 1.5rem;
  width: 2.813rem;
  transform: rotate(-90deg);
`;

export const BoxVi = styled.div`
  width: 100%;
  padding-top: 2.125rem;
  display: flex;
  justify-content: space-around;

  @media (max-width: 330px) {
    padding-top: 3rem;
  }
`;

export const BoxImageVi = styled.div`
  width: 14.063rem;
  height: 20.188rem;
  z-index: 1;
`;

export const ImageVi = styled.img`
  width: 14.063rem;
  height: 20.188rem;
`;

export const BoxContentVi = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 10rem;
  position: relative;
  right: 4rem;

  @media (max-width: 330px) {
    right: 5rem;
  }
`;

export const TitleVi = styled.h2`
  max-width: 164px;
  font-size: 1rem;
  line-height: 1.364rem;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  padding-bottom: 2rem;
`;
