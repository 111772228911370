import React from "react";

// Styles
import * as S from "./styles";

// Logo
import DesktopHeader from './desktopHeader';
import MobileHeader from './mobileHeader';

const Header = ({ currentStep }) => {
  return (
    <>
      <S.MH><MobileHeader currentStep={currentStep}/></S.MH>
      <S.DH><DesktopHeader/></S.DH>
    </>
  );
}

export default Header;
