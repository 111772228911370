import React from "react";
import * as S from "./styles";

//Component
import InputNumber from "../InputNumber";
import InputRadio from "../radio";

const InputRadioNumber = ({
  name,
  title,
  firstLabel,
  secondLabel,
  value,
  onChecked,
  border,
  paddingBottom,
  compSelect,
  onClick,
  firstPaddingRight,
  secondPaddingRight,
  titleWidth,
  firstWidth,
  firstText,
  secondText,
  secondWidth,
  firstNumber,
  firstValue,
  secondNumber,
  secondValue,
  onChange,
  info,
  noInput,
  noAlignSelf,
  noMarginTop,
  fNumberInputHandler,
  fNumberInputValue,
  sNumberInputHandler,
  sNumberInputValue,
}) => {
  const firstInput = firstLabel || "Sim";
  const secondInput = secondLabel || "Não";

  const RenderInputRadio = ({ titleWidth, id, label, name, value }) => (
    <InputRadio
      noInput={noInput}
      border={border}
      titleWidth={titleWidth}
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      title={title}
    />
  );

  return (
    <S.Container noInput={noInput}>
      {noInput ? (
        <S.ContentTitle>
          <S.Title border={border} width={titleWidth}>
            {title}
          </S.Title>
          <S.HR />
        </S.ContentTitle>
      ) : (
        <RenderInputRadio
          id={firstInput}
          name={name}
          label={firstInput}
          value={value}
          onChange={onChange}
          titleWidth={titleWidth}
        />
      )}
      {value && (
        <S.Content noAlignSelf={noAlignSelf} noMarginTop={noMarginTop}>
          <S.BoxInputRadio paddingBottom={paddingBottom || "1.5rem"}>
            <InputNumber
              paddingRight={firstPaddingRight}
              width={firstWidth}
              name={`${name} ${firstNumber}`}
              label={firstText}
              value={fNumberInputValue}
              onChange={fNumberInputHandler}
              info={info}
              compSelect={compSelect}
            />
          </S.BoxInputRadio>
          <S.BoxInputRadio>
            <InputNumber
              paddingRight={secondPaddingRight}
              width={secondWidth || "10rem"}
              name={`${name} ${secondNumber}`}
              label={secondText}
              value={sNumberInputValue}
              onChange={sNumberInputHandler}
              compSelect={compSelect}
            />
          </S.BoxInputRadio>
        </S.Content>
      )}
    </S.Container>
  );
};

export default InputRadioNumber;
