import styled from 'styled-components/macro'

import { colors } from '../../../../styles/Theme'

export const Container = styled.section`
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  max-height: calc(100vh - 8.438rem);

  @media (min-width: 768px) {
    max-height: calc(100vh - 8.438rem);
  }

  @media (min-width: 1024px) {
    margin: 0 auto;
    /* padding: 1rem 5rem 1rem 5rem; */
    width: 56%;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`


export const boxLayoutHome = styled.div`
  @media (max-width: 780px) {
    display: none;
  }
`

export const Content = styled.div``

export const Form = styled.form`
  padding: 1.813rem;
  padding-bottom: 6rem;

  @media (min-width: 1024px) {
    padding: 2.813rem 0 0 0;
  }
`;
export const Box = styled.div`
  width: 100%;
  max-height: 700px;
  height: 100%;
  display: flex;
  padding: 10px;
  overflow: scroll;
  margin-bottom: 26px;

`

export const ContainerComponents = styled.div`
  width: 100%;
  max-height: 700px;
  height: 100%;
`

export const ContainerInputDesktop = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.paddingBottom && '.813rem'};

  @media (min-width: 1024px) {
    padding-bottom: ${props => props.paddingBottomDesck || '1.5rem'};
    padding-right: ${props => props.paddingRightDesck && '2.438rem'};
  }
`

export const ContentForm = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    width: 90%;
    flex-wrap: wrap;
  }
`

export const DataOng = styled.div`
  div > div {
    padding-bottom: 1.063rem;
  }

  @media (min-width: 1024px) {
    padding-bottom: 0;
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    flex-wrap: wrap;
  }

  @media (min-width: 1600px) {
    width: 80%;
  }
`

export const Slider = styled.div``

export const Line = styled.hr`
  width: inherit;
  height: 0;
  color: ${colors.gray};
  margin: 2rem 0 2rem 0;
  background: #966132;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const boxSelectAccess = styled.div``

export const BoxBtn = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  cursor: pointer;

  @media (min-width: 1024px) {
    padding-top: 0;
    align-self: self-end;
  }
`

export const BtnPermissions = styled.button`
  padding: 0.375rem 0 0.438rem 0;
  width: 14.75rem;
  height: 1.875rem;
  background: #7fbe30;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${colors.white};

  @media (min-width: 1024px) {
    margin-top: 8px;
    /* border-radius: 50px; */
  }
`

export const BoxBtnAcess = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @media (min-width: 2000px) {
    gap: 40px;
    width: 80%;
  }
`

export const BoxTitlePermissions = styled.div`
  width: 100%;
  /* height: 21.313rem; */
  background: rgba(217, 217, 217, 0.2);
  border-bottom: 1px solid #000000;
  padding-bottom: ${props => (props.isOpen ? '1.5rem' : '4.813rem')};

  /* transition: opacity ease-in-out 0.4s; */
  transition: ${props => props.nextSlider && `opacity 1s alternate`};
  @keyframes opacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (min-width: 1024px) {
    background: transparent;
  }
`

export const ContentBox = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const BoxTitleOutstanding = styled.div`
  padding-bottom: 2.5rem;
  width: 100%;
  background: rgba(255, 143, 0, 0.3);

  @media (min-width: 1024px) {
    background-color: transparent;
  }
`

export const LabelFormRemove = styled.label`
  position: relative;
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 0)};

  /* div, label, input {
    width: 100%;
  } */

  @media (min-width: 1024px) {
    /* max-width: 208px; */
    width: ${props => props.width};
  }
`

export const Label = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`

export const ContentTable = styled.div`
  padding-bottom: 1rem;
  width: 100%;
  overflow-x: scroll;

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    /* background: #E0E0E0; */
    width: 5px;
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: ${colors.darkGray};
    border-radius: 20px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`

export const TH = styled.th`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
`

export const TR = styled.tr`
  height: 1.875rem;

  div {
    margin-left: 0;
  }
  button {
    margin-bottom: 0;
  }
`

export const ContentProfile = styled.div`
  position: relative;
  top: -0.1rem;
  display: flex;
  height: 1.875rem;
  /* width: 6.938rem; */

  td {
    padding-top: 0.375rem;
    font-weight: 700;
    color: ${colors.black};
  }
`
export const TD = styled.td`
  padding-right: ${props => (props.noStyle ? '0' : '1.25rem')};
  padding-left: ${props => (props.noStyle ? '0' : '.75rem')};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: ${props => (props.bold ? '700' : '400')};
  font-size: 14px;
  line-height: 19px;
  /* height: 2.2rem; */
  background: ${props =>
    props.noStyle
      ? 'transparent'
      : props.table === 'accessPermissions'
      ? colors.darkGray
      : colors.orange01};
  border-radius: 3px;
  color: ${props => (props.orange ? colors.orange : colors.gray)};
  height: 1.875rem;
  border-radius: 5px;
  width: ${props => props.width};
  /* max-width: calc(100% - 3.438rem); */

  input,
  label {
    margin-top: 0;
    padding-left: '.75rem';
    padding-bottom: 0;
    height: ${props => props.noStyle || '1.875rem'};
  }

  @media (min-width: 1024px) {
    padding: ${props => props.noPadding && '0'};
    position: relative;
    min-width: ${props => props.minWidth};
  }
`

export const ParagRemoveText = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  height: 2.2rem;
  background: ${colors.darkGray};
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: ${colors.black};
  padding: 1rem;
  width: inherit;
  border: ${props => props.error && `1px solid ${colors.error}`};

  @media (min-width: 1024px) {
    width: 120px;
  }
`

export const ParagraphInput = styled.p`
  /* margin-top: 0.4rem; */
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 2.2rem;
  background: ${colors.darkGray};
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: ${colors.black};
  padding: 1rem;
  display: flex;

  @media (min-width: 1024px) {
    /* max-width: 208px; */
    /* width: ${props => props.width}; */
  }
`

export const ParagraphInputOutstandingBold = styled.p`
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 22px;
  height: 2.2rem;
  background: #ffecd3;
  border: 1px solid rgba(255, 143, 0, 0.4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff8f00;
  padding: 1rem;
`
export const ParagraphInputOutstanding = styled.p`
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 22px;
  height: 2.2rem;
  background: #ffecd3;
  border: 1px solid rgba(255, 143, 0, 0.4);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #707070;
  padding: 1rem;
`

export const ParagraphInputOutstandingLabel = styled.p`
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  height: 2.2rem;
  background: #ffecd3;
  border: 1px solid rgba(255, 143, 0, 0.4);
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #707070;
  padding: 1rem;
`
export const ContentIconSlider = styled.div`
  position: relative;
  display: flex;
  align-items: end;

  @media (min-width: 1024px) {
  }
`

export const IconSlider = styled.img`
  position: absolute;
  left: ${props => !props.right && '-1rem'};
  right: ${props => props.right && '-1rem'};
  bottom: 3px;
  transform: ${props => props.right && 'rotate(180deg)'};

  @media (min-width: 1024px) {
    display: none;
  }
`

export const ParagraphInputComunityRemove = styled.p`
  min-width: 236px;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 1.875rem;
  background: #f6f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #707070;
  padding: 1rem;
  padding-bottom: 1rem;
`

export const ParagraphInputDesktop = styled.p`
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  height: 2.2rem;
  background: #f6f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #707070;
  padding: 1rem;
  padding-bottom: 1rem;

  @media (min-width: 1024px) {
    min-width: ${props => props.widthDesk};
  }
`

export const ContentEmail = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: end;

    div {
      padding-top: 0;
    }
  }
`

export const BoxSlider = styled.div`
  padding: 1rem 1rem 0 1.5rem;
  width: 100%;

  label {
    width: 100%;
    padding-bottom: 0;
  }

  input {
    background: ${colors.darkGray};
  }

  @media (min-width: 1024px) {
    padding: 0;
    /* margin-right: 1rem; */
    margin-right: ${props => props.noMargin || '1.25rem'};
    width: ${props => props.width};
  }
`

export const ContentDeleteOng = styled.div`
  padding-top: 0.625rem;

  @media (min-width: 1024px) {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
  }
`

export const BoxComunity = styled.div`
  padding: 1rem 0 0 0;

  @media (min-width: 1024px) {
    padding: 0;
    margin-right: ${props => props.paddRight && '2.5rem'};
    min-width: 14.75rem;
  }
`

export const BoxComunityOngs = styled.div`
  margin-bottom: ${props => props.isModal && '9rem'};
  padding-bottom: 0.813rem;
  position: relative;
`

export const BoxRemoveItems = styled.div`
  padding: 1.7rem 1rem 0 1.5rem;
  display: flex;
  justify-content: end;
  position: relative;

  @media (min-width: 1024px) {
    padding: 0;
  }
`

export const ImgSwitch = styled.img`
  width: 1rem;
  height: 1rem;

  @media (min-width: 1024px) {
    width: ${props => (props.checked ? '1rem' : '1.125rem')};
    height: ${props => (props.checked ? '1rem' : '1.125rem')};
    object-fit: scale-down;
  }
`

export const ImgRemove = styled.img`
  width: 1.125rem;
  height: 0.857rem;
`

export const ContentAdd = styled.div`
  margin: 0 auto;
  margin-top: 1.625rem;
  background-color: #e6f9ce;
  border: 1px solid #93dc38;
  border-radius: 5px;
  width: 108px;
  height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;

  @media (min-width: 1024px) {
    margin: 0;
    max-width: 120px;
    height: 30px;
    background-color: #cadbff;
    border: none;
  }
`

export const ImgAdd = styled.img`
  padding: 0 0.313rem 0 0.5rem;

  @media (min-width: 1024px) {
    filter: brightness(0) saturate(100%) invert(47%) sepia(70%) saturate(496%)
      hue-rotate(183deg) brightness(95%) contrast(95%);
  }
`

export const TextAdd = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #67b605;

  @media (min-width: 1024px) {
    color: #5484ed;
  }
`
export const ParagraphChange = styled.p`
  padding-top: 0.2rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5484ed;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const ParagraphRemove = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 8px;
  display: flex;
  align-items: center;
  color: #ff8f00;

  @media (min-width: 1024px) {
    padding-left: 0.625rem;
    font-size: 0.75rem;
  }
`

export const ContentChangeProfile = styled.div`
  position: relative;
  display: flex;
`

export const BoxIcon = styled.button`
  margin-left: ${props => props.marginLeft && '1rem'};
  width: 2.75rem;
  background: #cadbff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  outline: none;

  @media (min-width: 1024px) {
    margin-left: 0.438rem;
    width: auto;
    background: none;
  }
`

export const BoxRemove = styled.button`
  width: 2.75rem;
  height: 1.875rem;
  background: #ffecd3;
  border-radius: 5px;
  border: 1px solid #ff8f00;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;

  @media (min-width: 1024px) {
    width: 6.813rem;
    border-radius: 50px;
    flex-direction: row;
    align-items: center;
  }
`

export const Title = styled.h3`
  padding-top: 0.875rem;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.563rem;
  display: flex;
  justify-content: center;
  padding-bottom: 0.5rem;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`

export const TitleOutstanding = styled.h3``

export const ContentBall = styled.div`
  padding: 1.5rem 0 3.2rem 0;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const Ball = styled.div`
  margin-left: 0.75rem;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${props => (props.currentSlider ? colors.blue : '#D9D9D9')};
  cursor: pointer;

  :hover {
    background-color: ${colors.blue};
  }
`

export const LinePermissions = styled.hr`
  opacity: 0.4;
  border: 1px solid ${colors.black02};

  @media (min-width: 1024px) {
    display: none;
  }
`

export const Overlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 5rem);
  background: rgba(158, 158, 158, 0.5);
  backdrop-filter: blur(50px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  @media (max-width: 1023px) {
    top: 0;
    height: 100%;
    overflow-y: hidden;
  }
`

export const ContainerModal = styled.div`
  position: relative;
  /* width: 80%; */
  max-width: 1304px;
  height: 74vh;
  max-height: 812px;
  background-color: ${colors.white};
  overflow-y: scroll;
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #707070 #e0e0e0;

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #e0e0e0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`

export const Table = styled.table`
  margin-bottom: 4.063rem;
  border-spacing: 0;
  width: inherit;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-left: -1.25rem;
    width: initial;
    border-spacing: ${props =>
      props.noBorderSpacing ? '0' : '1.25rem .688rem'};
  }
`

export const Thead = styled.thead`
  height: 4.5rem;
  background-color: #5484ed;

  @media (max-width: 1023px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 380px) {
    height: 5.5rem;
  }
`

export const TheadTRDesck = styled.tr`
  @media (max-width: 1023px) {
    display: none;
  }
`

export const ModalTitle = styled.h1`
  font-size: 1.125rem;
  font-weight: 800;
  font-family: 'Open Sans';
  color: #b4ff56;
`

export const TheadTRMob = styled.tr`
  padding: 0.813rem 0 3.75rem 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const TheadMobOptions = styled.div`
  display: flex;
  align-items: end;
`

export const ContentPermission = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ali;
  align-items: center;
  flex-direction: ${props => (props.options ? 'row' : 'column')};
  width: ${props => props.options || '20rem'};

  @media (max-width: 380px) {
    width: initial;
    flex-direction: column;
  }
`

export const ImgStep = styled.img`
  margin-bottom: 0.2rem;
  transform: ${props => props.back && 'rotate(180deg)'};

  :hover {
    filter: brightness(0) saturate(100%) invert(57%) sepia(95%) saturate(2095%)
      hue-rotate(0deg) brightness(102%) contrast(105%);
  }

  @media (max-width: 380px) {
    margin-bottom: 0rem;
  }
`

export const ContentStep = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`
export const LineStep = styled.hr`
  width: 15px;
  height: 2px;
  border: 2px solid ${colors.white};
`

export const Step = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props =>
    props.currentData ? colors.orange : colors.gray05};
  border: 2px solid ${colors.white};
  border-radius: 50%;
`

export const ModalHeaderContant = styled.h1`
  display: flex;
`

export const IconModal = styled.img``

export const TheadTitle = styled.th`
  font-size: 1.125rem;
  font-weight: 800;
  font-family: 'Open Sans';
  color: ${colors.green01};
  text-align: left;

  @media (min-width: 1024px) {
    padding-left: 3.75rem;
    font-size: 1.563rem;
    color: ${colors.white};
  }
`

export const TheadTxt = styled.th`
  padding: 0 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: 'Open Sans';
  color: ${colors.white};
  text-decoration: underline;

  @media (max-width: 1024px) {
    /* padding: 0 4.125rem 0 0; */
    text-decoration: none;
    display: ${props => (props.currentData ? 'flex' : 'none')};
  }

  @media (max-width: 380px) {
    padding: 0;
  }
`

export const TheadTxtContentClose = styled.div`
  padding-right: 1.25rem;
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const TheadTxtClose = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.625rem;
  font-weight: 400;
  font-family: 'Open Sans';
  color: ${colors.white};
`

export const Tbody = styled.tbody`
  /* :tr:not(:first-child) {
    border-bottom: 1px solid #D5D5D5;
} */
`

export const TbodyTR = styled.tr`
  @media (max-width: 1023px) {
    display: flex;
    margin-right: 1.25rem;
  }
`

export const TbodyTDTxt = styled.td`
  padding: 0.75rem 3.75rem 0.75rem 3.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: 'Open Sans';
  color: ${colors.black};

  @media (max-width: 1023px) {
    padding-left: 1.688rem;
  }
`

export const Span = styled.span`
  width: 100%;
`

export const HR = styled.hr`
  margin-left: 3.75rem;
  min-width: 87%;
  width: -webkit-fill-available;
  float: right;
  background: black;
  height: 2px;
  margin-block-start: 0;
  margin-block-end: 0;
  overflow: auto;
  border-style: none;

  @media (max-width: 1023px) {
    margin-left: 1.25rem;
  }
`

export const TbodyTDImg = styled.td`
  padding: 0.5rem 0 0.5rem 0;
  text-align: center;
  border-bottom: 2px solid ${colors.black};

  img {
    width: 1rem;
  }

  @media (max-width: 1023px) {
    width: 20%;
    display: ${props => (props.currentData ? 'flex' : 'none')};
    justify-content: center;
  }
`

export const ImgClose = styled.img`
  width: 1.25rem;
`

export const ContainerCloseModal = styled.div`
  position: fixed;
  bottom: 0.688rem;
  right: 1rem;
  margin-inline-start: auto;
  width: 2.563rem;
  padding: 0.375rem 0 0.25rem 0;
  /* height: 2.563rem; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: #ffecd3;
  /* padding:3px; */
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

export const ImageCloseModal = styled.img`
  height: 1.25rem;
`

export const Trash = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  font-family: 'Open Sans';
  color: ${colors.orange};
  line-height: 13.62px;
`

export const BackgSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: #5484ed;
  border-radius: 50px;

  @media (min-width: 1024px) {
    width: 298px;
    margin-bottom: 2.25rem;
  }
`

export const SearchInput = styled.input`
  margin-left: 0.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 85%;
  height: 30px;
  background: #f6f6f6;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  border-radius: 50px;
  display: flex;
  align-items: center;
`

export const BtnSearch = styled.button`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`

export const ImgSearch = styled.img`
  width: 30px;
`

export const ContentButtons = styled.div`
  display: flex;
  margin-left: ${props => (props.marginLeft ? '1rem' : '0')};

  div {
    :last-child {
      margin-left: 1rem;
    }
  }
`

export const TextError = styled.p`
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;

  @media (min-width: 1024px) {
    margin-top: ${props => (props.margin ? '-2rem' : '-.5rem')};
    margin-bottom: ${props => props.margin && '2rem'};
  }
`
