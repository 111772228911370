import { api } from "./modules/api";

export const isAuthLogin = () => {
  const token = localStorage.getItem("idToken");

  if (!token) return false;

  try {
    api.get("/user/me/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return true;
  } catch (error) {
    return false;
  }
};
