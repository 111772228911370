import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  z-index: 2;
`;

export const Main = styled.main`
  height: calc(100vh - 5rem);
  width: 100vw;
  display: flex;

  @media (min-width: 1024px) {
   flex-direction: row-reverse;
  }
`;


export const ContentData = styled.section`
  width: 100%;
  z-index: 2;

  @media (min-width: 1024px) {
   width: 60vw;
   display: flex;
   justify-content: flex-end;
  }
`;

export const ContentBCKGGreen = styled.section`
 display: contents;

 @media (min-width: 1024px) {
  position: relative;
   width: 40vw;
   display: block;
  }
`;


export const Content = styled.div`
  margin: 0 auto;
  padding-top: 3rem;
  width: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    padding-top: 0;
    position: relative;
    width: 60%;
    max-width: 550px;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 0 3rem 0;
  }

  @media (min-width: 1440px) {
    width: 80%;
    margin: 0 auto;
  }
`;

export const ContentVi = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViText = styled.span`
  font-size: 4rem;
  color: #0080ff;
  font-family: "Nunito";
  font-weight: 800;

  @media (min-width: 1024px) {
    font-size: 8rem;
  }
  @media (max-width: 360px) {
    font-size: 3rem;
  }
`;

export const Title = styled.h1`
  font-size: 0.875rem;
  font-weight: 700;
  font-family: "Nunito";
  font-style: normal;
  line-height: 1.188rem;
  z-index: 1;
  width: ${props => props.width ? props.width : '13rem'};
  min-height: 98px;

  @media (max-width: 320px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1024px) {
    min-height: 476px;
    font-size: 2.125rem;
    line-height: 2.25rem;
    width: 100%;
  }

  @media (min-width: 1440px) {
    font-size: 3.125rem;
    line-height: 4.25rem;
  }
`;

export const BoxBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  
  @media (min-width: 1024px) {
    position: absolute;
    bottom: 5.75rem;
    max-height: 55px;
    justify-content: space-between;
    align-items: center;

    button {
      margin: 0;
    }
  }
`;

export const BtnRight = styled.div`
  cursor: pointer;
`;

export const BtnLeft = styled.div`
  padding-right: 1rem;
  cursor: pointer;
`;

export const ImgIconLeft = styled.img`
  width: 1.5rem;
  @media (min-width: 1024px) {
    width: 2.7rem;
  }
`;

export const IconImgRight = styled.img`
  width: 1.5rem;
  @media (min-width: 1024px) {
    width: 2.7rem;
  }
`;

export const ImgVi = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 67vh;

  @media (min-width: 1024px) {
    width: auto;
    height: 98vh;
    right: -14rem;
    bottom: 0;
  }

  @media (min-height: 768px) and (max-height: 723px) {
    height: 64vh;
    object-fit: contain;
  }

  @media (min-height: 768px) and (max-height: 612px) {
    height: 62vh;
  }
`;
