import styled from 'styled-components';
import { colors } from '../../../styles/Theme';

export const Container = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  @media(min-width: 1024px) {
    display: ${props => props.none && 'none'};
    position: absolute;
    right: 0;
    bottom: 15px;
    height: fit-content;
    width: auto;
  }
`;

export const ImgBack = styled.img`
  padding-bottom: .15rem;
  @media(min-width: 1024px) {
    width: 20px;
  } 
`;
