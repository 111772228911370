import React from 'react';
import { useNavigate } from 'react-router-dom';

//Icons
import { BackArrow } from '../../../assets';

// Styles
import * as S from './styles';

export default function BtnBack() {
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate(-1)}>
      <S.ImgBack src={BackArrow} alt='Voltar' />
      <S.Text>Voltar</S.Text>
    </S.Container>
  );
}
