import styled from "styled-components";
import { colors } from "../../../../../styles/Theme";

export const Container = styled.div`
  width: 100%;
  border: 2px solid #7fbe30;
`;

export const Content = styled.div`
  overflow: scroll;
  max-height: 300px;
  @media (min-width: 1024px) {
    max-height: 245px;
  }

  @media (min-width: 1316px) {
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 10px;
    }
  }
`;

export const BoxLabel = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 250px 258px 200px 200px 200px;
  padding: 0 0.813rem 0.813rem 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 250px 200px 200px 200px 200px;
  }
`;

export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.black};
`;

export const BoxInput = styled.div`
  width: 170vh;
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 250px 257px 200px 200px 100px;
  background: rgba(217, 217, 217, 0.5);
  padding: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 250px 200px 200px 200px 200px;
  }
`;

export const Paragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
`;

export const BoxInputTwo = styled.div`
  display: grid;
  grid-template-columns: 173px 257px 205px 100px;
  background: white;
  padding: 1rem;

  @media (min-width: 1024px) {
    grid-template-columns: 150px 250px 200px 150px;
  }
`;
