import styled from "styled-components/macro";
import { CommunityImage } from "../../assets";
import { colors } from "../../styles/Theme";

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colors.white};
  height: calc(100vh - 5rem);

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const Main = styled.main`
  display: flex;
  width: 100vw;
  height: calc(100vh - 5rem);
  background-color: ${colors.white};
`;

export const ContentImage = styled.section`
  position: relative;
  width: 60%;
  height: 100%;
  background-image: url(${CommunityImage});
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.section`
  position: relative;
  width: 100%;
  padding: 2.375rem 1.875rem 4.063rem 2.313rem;
  z-index: 2;
  div {
    text-align: left;
  }

  button {
    margin-top: 1.5rem;
  }

  @media (min-width: 769px) {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: ${(props) =>
      props.directionContent ? props.directionContent : "center"};

    margin-inline: 15px;
  }

  @media (min-width: 1460px) {
    justify-content: ${(props) =>
      props.directionContent ? props.directionContent : "center"};
  }
`;

export const ImgVi = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  pointer-events: none;
  z-index: 1;
  @media (min-width: 769px) {
    display: none;
  }

  @media (max-width: 768px) and (min-width: 320px) {
    width: 280px;
  }

  @media (max-height: 896px) {
    width: 300px;
  }

  @media (max-height: 740px) {
    width: 250px;
  }

  @media (max-height: 667px) {
    width: 200px;
  }
`;
