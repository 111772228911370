import React from "react";
import { LogoMob } from "../../../assets";
import { useNavigate } from 'react-router-dom';

// Styles
import * as S from "./styles";

export default function HeaderMobile({ currentStep }) {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.ImgLogo src={LogoMob} alt="logo" onClick={() => navigate('/')} />
      {currentStep && <S.Step>{currentStep}<span>/4</span></S.Step>}
    </S.Container>
  )
}