import React from 'react';


//Styles
import * as S from './styles';

const InstrucoesHome = ({ number, img, width, text }) => {
  return (
      <>
      <S.Container width={width} > 
        <S.TextNumber>{number}</S.TextNumber>
        <S.TextImage> 
          <S.img src={img} alt='Imagem da etapa'></S.img>
          <S.Text >{text}</S.Text>
        </S.TextImage> 
      </S.Container>
      </>
  )
}

export default InstrucoesHome;