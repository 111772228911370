import styled from 'styled-components';

import { colors } from '../../styles/Theme';

export const img = styled.img`

@media (min-width: 1024px) {
    width: ${props => props.width || '8rem'};
  }

`

