import styled, { css } from "styled-components/macro";
import { colors } from "../../styles/Theme";

export const Container = styled.div`
  position: ${props => props.isOval || 'relative'};
  z-index: 2;

  :hover {
    div {
      display: flex;
      animation: appearContentHelp 1s alternate;
      -webkit-animation: appearContentHelp 1s alternate;
    }
    @keyframes appearContentHelp {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
  }

  @media(max-width:  633px) {
    position: initial;
  }
`;

export const Button = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: .938rem;
  cursor: pointer;
`;

export const Info = styled.div`
  display: none;
  position: absolute;
  top: ${props => props.descImg && '51px'};
  margin-top: .5rem;
  width: 17.813rem;
  max-width: ${props => props.descImg ? '160px' : 'max-content'};
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.descImg ? '5px' : '10px'};
  z-index: 2;
  
  ${props => props.isOval && css`
    top: ${props => props.low ? '-88px;' : '-144px'};
    left: -1px;
  `}

  @media(max-width:  633px) {
    left: 0;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  top: -11px;
  width: 0; 
  height: 0; 
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid ${colors.lightOrange};
  z-index: -1;
`;

export const IconClose = styled.img`
  position: absolute;
  top: .625rem;
  right: .625rem;
  cursor: pointer;
`;

export const InfoText = styled.p`
  padding: ${props => props.descImg ? '.5rem .5rem .5rem .5rem' : '.875rem 2rem .875rem 1.25rem'};
  font-size: .7rem;
  font-weight: 600;
  font-family: 'Open Sans';
  text-align: ${props => props.descImg && 'center'};
  background-color: ${colors.lightOrange};
  border-radius: ${props => props.isOval && '20px'};

  h2 {
    line-height: 1.25rem;
  }
`;
