import React from 'react';

//Styles
import * as S from './styles';

const Title = ({
  paddingLeft,
  noPaddBtn,
  children,
  successScreen,
  homeScreen,
  paddingMob,
  padding,
  paddingLeftDesk,
  width,
  mobWidth,
  form,
  subtitle,
  icon,
  displayImage,
  wrap
}) => {
  return (
    <S.Container form={form} paddingLeft={paddingLeft}>
      <S.ContentTitle noPaddBtn={noPaddBtn}>
        {form && <S.Icon src={icon} alt='Image' displayImage={displayImage} />}
        <S.Title
          successScreen={successScreen}
          homeScreen={homeScreen}
          paddingMob={paddingMob}
          padding={padding}
          width={width}
          mobWidth={mobWidth}
          paddingLeftDesk={paddingLeftDesk}
          wrap={wrap}
        >
          {children}
        </S.Title>
      </S.ContentTitle>
      {form && subtitle &&
        <S.ContentSubtitleLine>
          <S.Text>{subtitle}</S.Text>
          <S.Line></S.Line>
        </S.ContentSubtitleLine>
      }
    </S.Container>

  )
}

export default Title;