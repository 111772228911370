import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  width: 100%;
  margin-top: 3rem;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const TextError = styled.p`
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;
  text-align-last: center;

  @media (min-width: 1024px) {
    padding-top: 0.5rem;
    text-align-last: end;
    font-size: 0.875rem;
  }
`;

export const BtnBack = styled.button`
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1024px) {
    display: none;
  }
`;
