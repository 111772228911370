import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import InputAddItem from "../../../../components/AddItem";
import Info from "../../../../components/Info";
import Modal from "../../../../components/Info/modal";
import InputNumber from "../../../../components/Inputs/InputNumber";
import InputCheckbox from "../../../../components/Inputs/checkbox";
import Title from "../../../../components/Title";
import TitleWithLine from "../../../../components/Title/TitleWithLine";
import { useApi } from "../../../../providers/Api";
import Layout from "../layout";

//Styles
import * as S from "./styles";

// Logo
import {
  Alianca_ODS,
  ODS1,
  ODS10,
  ODS11,
  ODS12,
  ODS13,
  ODS14,
  ODS15,
  ODS16,
  ODS17,
  ODS2,
  ODS3,
  ODS4,
  ODS5,
  ODS6,
  ODS7,
  ODS8,
  ODS9,
  VolunteerActivismBlack,
} from "../../../../assets";
import BtnBack from "../../../../components/Buttons/backGrey";
import Button from "../../../../components/Buttons/button";
import { api } from "../../../../modules/api";
import { changePermission } from "../../../../utils/permissions";

const occupationAreaNames = [
  "shelterCare",
  "temporaryShelterCare",
  "supportDrugAddicts",
  "supportHomelessPeople",
  "supportVictimsViolence",
  "SupportAndCareForTheDeficient",
  "supportEmergencySituations",
  "supportVictimsChildAbuse",
  "supportLGBTQIA+People",
  "leisureActivities",
  "artCulture",
  "combatingRacialInequality",
  "combatingGenderInequality",
  "combatingPoverty",
  "combatingChildAbuse",
  "combatingAnimalAbuse",
  "connectionJobMarket",
  "advocacyLGBTQIA+Rights",
  "defenseIndigenousRights",
  "missing",
  "education",
  "femaleEmpowerment",
  "entrepreneurship",
  "sport",
  "stimulationDiversity",
  "formationSupportNetworks",
  "formationNetworkingNetworks",
  "housingHousing",
  "inclusionPwD",
  "inclusionRefugees",
  "improvementHygieneConditions",
  "improvementHealthConditions",
  "ImprovementWorkingConditions",
  "legalAdvice",
  "foodSecurity",
  "sustainability",
];

const ActingAssisted = () => {
  const navigate = useNavigate();
  const [isAll, setIsAll] = useState(false);
  const [form, setForm] = useState({
    occupationArea: [],
    others: [],
    causesOfAtuation: [],
    quantityAssisted: [],
    gender: [],
    ageRange: [],
    quantityOfPeople: undefined,
  });
  const [error, setError] = useState(undefined);
  const [checkOthers, setCheckOthers] = useState(false);
  const [addItem, setAddItem] = useState("");
  const isDescktop = window.innerWidth > 768;
  const [user, setUser] = useState("");
  const { getMyUser } = useApi();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("idToken")}`,
    },
  };

  const all = ["menCis", "menTrans", "womenCis", "womenTrans", "nonBinary"];
  const checkAll = () => {
    const alreadyInList = form.occupationArea?.filter(
      (occupation) => occupation.name === "gender"
    );
    all.forEach((item) => {
      if (!alreadyInList.find((occupation) => occupation.id === item)) {
        form.occupationArea.push({ name: "gender", id: item, checked: true });
      }
      setError(false);
    });
  };

  const unCheckAll = () => {
    const newOccupationArea = form.occupationArea?.filter(
      (occupation) => occupation.name !== "gender"
    );
    setForm({
      ...form,
      occupationArea: newOccupationArea,
    });
  };

  const handleGetMyUser = async () => {
    const response = await getMyUser();
    setUser(response);
    setForm({
      ...form,
      quantityOfPeople: response?.ong_id?.assisted_people?.qty_people,
      occupationArea: [
        ...response?.ong_id?.acting_assisted?.map((item) => {
          return {
            id: item.name,
            name: "occupationArea",
            checked: true,
          };
        }),
        ...response?.ong_id?.actuation_causes?.map((item) => ({
          checked: true,
          id: item.name,
          name: "causesOfAtuation",
        })),
        ...response?.ong_id?.assisted_people?.age_ranges?.map((item) => ({
          checked: true,
          id: item.age_range,
          name: "ageRange",
        })),
        ...response?.ong_id?.assisted_people?.genders?.map((item) => ({
          checked: true,
          id: item.gender,
          name: "gender",
        })),
      ],
      others: response?.ong_id?.acting_assisted
        ?.map((item) => item.name)
        .filter((other) => !occupationAreaNames.includes(other)),
    });
  };

  const handleChecked = (ev) => {
    if (!ev) {
      return;
    }
    const { name, id } = ev?.target;
    if (name === "all") {
      setIsAll(!isAll);
    }
    if (name === "all" && ev?.target?.checked) {
      checkAll();
      return "";
    } else if (name === "all" && !ev?.target?.checked) {
      unCheckAll();
      return "";
    }
    if (ev?.target?.checked) {
      const alreadyInList = form.occupationArea?.find(
        (occupation) => occupation.id === id
      );
      if (!alreadyInList) {
        // form.occupationArea.push({ name, id, checked: true });
        const newOccupation = [
          ...form.occupationArea,
          { name, id, checked: true },
        ];
        setForm({
          ...form,
          occupationArea: newOccupation,
        });
      }
      setError(false);
    } else {
      setIsAll(false);
      const index = form.occupationArea?.findIndex(
        (occupation) => occupation.id === id
      );
      if (index !== -1) {
        let newOccupationArea = form.occupationArea;
        newOccupationArea?.splice(index, 1);

        setError(false);
        setForm({
          ...form,
          occupationArea: newOccupationArea,
        });
      }
    }
  };

  const handleChangeADD = (ev) => {
    setAddItem(ev?.target?.value);
  };

  useEffect(() => {
    setCheckOthers(form.others.length > 0);
  }, [form]);

  const handleCheckedOthers = (ev) => {
    if (ev) {
      setCheckOthers(!checkOthers);
    }
    if (ev && !ev?.target?.checked) {
      setForm({
        ...form,
        others: [],
      });
      setAddItem("");
      ev.target.checked = true;
    }
  };

  const handleAddOther = (ev) => {
    const exists = form.others.find((element) => element === addItem);
    if (!exists && addItem !== "") {
      const newOthers = [...form.others, addItem];
      setForm({
        ...form,
        others: newOthers,
      });
    }
    setAddItem("");
  };

  const handleDeleteOther = (name, addname, item) => {
    const index = form.others.findIndex((element) => element === item);
    if (index !== -1) {
      let newOthers = form.others;
      newOthers.splice(index, 1);
      setForm({
        ...form,
        others: newOthers,
      });
    }
  };

  useEffect(() => {
    handleGetMyUser();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const isEmptyOthers = checkOthers && !form.others.length;
    const occupationArea = form.occupationArea.filter(
      (i) => i.name === "occupationArea"
    ).length;
    const isErrorOccupationArea = checkOthers ? isEmptyOthers : !occupationArea;

    const causesOfAtuation = form.occupationArea.filter(
      (i) => i.name === "causesOfAtuation"
    ).length;
    const isEmptyCausesOfAtuation = !causesOfAtuation;

    const errorList =
      isErrorOccupationArea ||
      isEmptyCausesOfAtuation ||
      form.quantityOfPeople === 0 ||
      form.quantityOfPeople === undefined;

    if (errorList) {
      setError(errorList);
    } else {
      const data = {
        current_form: "2",
        assisted_people: {
          qty_people: form.quantityOfPeople,

          genders: [
            ...form.occupationArea
              .filter((value) => value.name === "gender")
              .map((value) => {
                return { gender: value.id };
              }),
          ],

          age_ranges: [
            ...form.occupationArea
              .filter((value) => value.name === "ageRange")
              .map((value) => {
                return { age_range: value.id };
              }),
          ],
        },

        acting_assisted: [
          ...form.occupationArea
            .filter((value) => value.name === "occupationArea")
            .map((value) => {
              return { name: value.id };
            }),
          ...form.others.map((item) => {
            return { name: item };
          }),
        ],

        actuation_causes: [
          ...form.occupationArea
            .filter((value) => value.name === "causesOfAtuation")
            .map((value) => {
              return { name: value.id };
            }),
        ],
      };

      console.log(user);

      api
        .patch(`/ong/${user.ong_id.id}/`, data, config)
        .then((res) => {
          changePermission(2);
          localStorage.setItem(
            "formStep",
            JSON.stringify({
              pathname: "/cadastro/estrutura-fisica-e-funcional",
              step: 2,
            })
          );
          isDescktop
            ? navigate("/cadastro/estrutura-fisica-e-funcional")
            : navigate("/transicao");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeQuantity = (ev) => {
    setForm({
      ...form,
      quantityOfPeople: parseInt(ev?.target?.value),
    });
  };

  const renderTitle = () => (
    <S.ContentSubtitle>
      {isDescktop ? (
        <S.Subtitle>Selecione a área de atuação: </S.Subtitle>
      ) : (
        <S.Subtitle>Selecione a(s) área(s) de atuação: *</S.Subtitle>
      )}
      <S.LineSubtitle />
    </S.ContentSubtitle>
  );

  const renderOccupationArea = () => (
    <S.ContentOccupationArea>
      <S.DivCheckbox>
        <InputCheckbox
          label="Abrigo e cuidado"
          id="shelterCare"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "shelterCare"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Acolhimento temporário e cuidado"
          id="temporaryShelterCare"
          name="occupationArea"
          onChange={handleChecked}
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "temporaryShelterCare"
          )}
        />
        <InputCheckbox
          label="Apoio a dependentes químicos"
          id="supportDrugAddicts"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "supportDrugAddicts"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Apoio a pessoas em situação de rua"
          id="supportHomelessPeople"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "supportHomelessPeople"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Apoio a vítimas de violência"
          id="supportVictimsViolence"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "supportVictimsViolence"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Apoio e cuidados com deficientes"
          id="SupportAndCareForTheDeficient"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "SupportAndCareForTheDeficient"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Apoio em situações emergenciais"
          id="supportEmergencySituations"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "supportEmergencySituations"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Apoio a vítimas de  abuso infantil"
          id="supportVictimsChildAbuse"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "supportVictimsChildAbuse"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Apoio a pessoas LGBTQIA+"
          id="supportLGBTQIA+People"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "supportLGBTQIA+People"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Atividades de Lazer"
          id="leisureActivities"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "leisureActivities"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Arte e cultura"
          id="artCulture"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "artCulture"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Combate à desigualdade racial"
          id="combatingRacialInequality"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "combatingRacialInequality"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Combate à desigualdade de gênero"
          id="combatingGenderInequality"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "combatingGenderInequality"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Combate à pobreza"
          id="combatingPoverty"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "combatingPoverty"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Combate ao abuso infantil"
          id="combatingChildAbuse"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "combatingChildAbuse"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Combate a maus tratos aos animais"
          id="combatingAnimalAbuse"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "combatingAnimalAbuse"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Conexão com mercado de trabalho"
          id="connectionJobMarket"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "connectionJobMarket"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Defesa de direitos LGBTQIA+"
          id="advocacyLGBTQIA+Rights"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "advocacyLGBTQIA+Rights"
          )}
          onChange={handleChecked}
        />
      </S.DivCheckbox>
      <S.DivCheckbox>
        <InputCheckbox
          label="Defesa de direitos indígenas"
          id="defenseIndigenousRights"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "defenseIndigenousRights"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Desaparecidos"
          id="missing"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "missing"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Educação"
          id="education"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "education"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Empoderamento feminino"
          id="femaleEmpowerment"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "femaleEmpowerment"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Empreendedorismo"
          id="entrepreneurship"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "entrepreneurship"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Esporte"
          id="sport"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "sport"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Estímulo à diversidade"
          id="stimulationDiversity"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "stimulationDiversity"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Formação de redes de apoio"
          id="formationSupportNetworks"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "formationSupportNetworks"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Formação de redes de networking"
          id="formationNetworkingNetworks"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "formationNetworkingNetworks"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Habitação e moradia"
          id="housingHousing"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "housingHousing"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Inclusão de pessoas com deficiência"
          id="inclusionPwD"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "inclusionPwD"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Inclusão de refugiados"
          id="inclusionRefugees"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "inclusionRefugees"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Melhoria em condições de higiene"
          id="improvementHygieneConditions"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "improvementHygieneConditions"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Melhoria em condições de saúde"
          id="improvementHealthConditions"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "improvementHealthConditions"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Melhoria em condições de trabalho"
          id="ImprovementWorkingConditions"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "ImprovementWorkingConditions"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Orientação jurídica"
          id="legalAdvice"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "legalAdvice"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Segurança alimentar"
          id="foodSecurity"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "foodSecurity"
          )}
          onChange={handleChecked}
        />
        <InputCheckbox
          label="Sustentabilidade"
          id="sustainability"
          name="occupationArea"
          defaultChecked={form?.occupationArea?.find(
            ({ id }) => id === "sustainability"
          )}
          onChange={handleChecked}
        />
      </S.DivCheckbox>
      <S.DivCheckbox>
        <InputCheckbox
          label="Outros"
          id="others"
          name="occupationArea"
          defaultChecked={form?.others?.length > 0}
          checked={checkOthers}
          onChange={handleCheckedOthers}
        />
        {checkOthers && (
          <InputAddItem
            name="others"
            nameADD="others"
            value={addItem}
            handleChangeADD={handleChangeADD}
            handleAddItem={handleAddOther}
            handleDeleteItem={handleDeleteOther}
            list={form?.others}
            hasIcon
            handleNewItemChange={(ev) => setAddItem(ev?.target?.value)}
            defaultChecked={form?.occupationArea?.find(
              ({ name }) => name === "others"
            )}
            noRequired
          />
        )}
      </S.DivCheckbox>
    </S.ContentOccupationArea>
  );

  const renderCausesOfAction = () => (
    <S.BoxActing>
      <S.BoxTitle>
        <S.Titles>Causas de atuação: *</S.Titles>
        <Info>
          Selecione os Objetivos de Desenvolvimento Sustentável da ONU
          relacionados a suas áreas de atuação.
        </Info>
        <S.LineSubtitle />
      </S.BoxTitle>
      <S.SubtitleActing>É possível escolher mais de uma opção</S.SubtitleActing>
      {validateErrorCausesOfAtuation() && (
        <S.ErrorText>
          Campo obrigatório! Selecione ao menos uma causa de atuação:
        </S.ErrorText>
      )}
      <S.FormActing>
        <S.LabelActing>
          <S.ImageODS src={ODS1} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="pobertyEradication"
            value="pobertyEradication"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "pobertyEradication"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Erradicação da pobreza</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS2} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="sustainableAgriculture"
            value="sustainableAgriculture"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "sustainableAgriculture"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Fome Zero e agricultura sustentável</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS3} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="wellBeing"
            value="wellBeing"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "wellBeing"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Saúde e bem-estar</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS4} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="qualityEducation"
            value="qualityEducation"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "qualityEducation"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Educação de qualidade</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS5} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="genderEquality"
            value="genderEquality"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "genderEquality"
            )}
          />

          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Igualdade de gênero</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS6} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="cleanWaterAndSanitation"
            value="cleanWaterAndSanitation"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "cleanWaterAndSanitation"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Água potável e saneamento</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS7} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="accessibleCleanEnergy"
            value="accessibleCleanEnergy"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "accessibleCleanEnergy"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Energia limpa e acessível</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS8} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="economicGrowth"
            value="economicGrowth"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "economicGrowth"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Trabalho decente e crescimento econômico</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS9} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="industryInnovationInfrastructure"
            value="industryInnovationInfrastructure"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "industryInnovationInfrastructure"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Indústria, inovação e infraestrutura</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS10} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="inequatyReduction"
            value="inequatyReduction"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "inequatyReduction"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Redução das desigualdades</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS11} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="sustainableCitiesAndCommunities"
            value="sustainableCitiesAndCommunities"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "sustainableCitiesAndCommunities"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Cidades e comunidades sustentáveis</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS12} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="responsibleConsumptionAndProduction"
            value="responsibleConsumptionAndProduction"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "responsibleConsumptionAndProduction"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Consumo e produção responsáveis</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS13} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="antiGlobalWarming"
            value="antiGlobalWarming"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "antiGlobalWarming"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Ação contra a mudança global do clima</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS14} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="lifeInWater"
            value="lifeInWater"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "lifeInWater"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Vida na água</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS15} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="lifeOnEarth"
            value="lifeOnEarth"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "lifeOnEarth"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Vida terreste</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS16} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="peaceJustice"
            value="peaceJustice"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "peaceJustice"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Paz, justiça e instituições eficazes</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={ODS17} alt="" />
          <S.InputActing
            type="checkbox"
            onChange={handleChecked}
            name="causesOfAtuation"
            id="partnershipAndImplementation"
            value="partnershipAndImplementation"
            defaultChecked={form?.occupationArea?.find(
              ({ id }) => id === "partnershipAndImplementation"
            )}
          />
          {isDescktop ? (
            <S.ContentModal>
              <Modal descImg>Parcerias e meios de implementação</Modal>
            </S.ContentModal>
          ) : null}
        </S.LabelActing>
        <S.LabelActing>
          <S.ImageODS src={Alianca_ODS} alt="" />
        </S.LabelActing>
      </S.FormActing>
    </S.BoxActing>
  );

  const renderGenre = () => (
    <S.FormAssistedGen>
      <S.TitleLabel width border padding="1rem">
        Gênero
      </S.TitleLabel>
      <S.ContentGender>
        <S.ContentGenderBox width="66%">
          <S.ContentGenderBoxItem>
            <InputCheckbox
              label="Homens Cis"
              id="menCis"
              name="gender"
              onChange={handleChecked}
              checked={
                form.occupationArea.find(
                  (item) => item.name === "gender" && item.id === "menCis"
                )
                  ? true
                  : false
              }
            />
            <InputCheckbox
              label="Homens Trans"
              id="menTrans"
              name="gender"
              onChange={handleChecked}
              checked={
                form.occupationArea.find(
                  (item) => item.name === "gender" && item.id === "menTrans"
                )
                  ? true
                  : false
              }
            />
          </S.ContentGenderBoxItem>
          <S.ContentGenderBoxItem>
            <InputCheckbox
              label="Mulher Cis"
              id="womenCis"
              name="gender"
              onChange={handleChecked}
              checked={
                form.occupationArea.find(
                  (item) => item.name === "gender" && item.id === "womenCis"
                )
                  ? true
                  : false
              }
            />
            <InputCheckbox
              label="Mulher Trans"
              id="womenTrans"
              name="gender"
              onChange={handleChecked}
              checked={
                form.occupationArea.find(
                  (item) => item.name === "gender" && item.id === "womenTrans"
                )
                  ? true
                  : false
              }
            />
          </S.ContentGenderBoxItem>
        </S.ContentGenderBox>
        <S.ContentGenderBox width="33%" others>
          <InputCheckbox
            label="Não-Binária"
            id="nonBinary"
            name="gender"
            onChange={handleChecked}
            checked={
              form.occupationArea.find(
                (item) => item.name === "gender" && item.id === "nonBinary"
              )
                ? true
                : false
            }
          />
          <InputCheckbox
            label="Todos"
            id="all"
            name="all"
            onChange={handleChecked}
            checked={isAll}
          />
        </S.ContentGenderBox>
      </S.ContentGender>
    </S.FormAssistedGen>
  );

  const renderFixedAge = () => (
    <S.FormAssistedIdade>
      <S.TitleLabel border padding="1rem">
        Faixa etária
      </S.TitleLabel>
      <S.ContentAgeRange>
        <div>
          <InputCheckbox
            label="Bebês - 0 a 3 anos"
            id="babies-0 to3years"
            name="ageRange"
            onChange={handleChecked}
            defaultChecked={form.occupationArea.find(
              (item) => item.id === "babies-0 to3years"
            )}
          />
          <InputCheckbox
            label="Crianças - 4 a 12 anos"
            id="children-4to12yearsOld"
            name="ageRange"
            onChange={handleChecked}
            defaultChecked={form.occupationArea.find(
              (item) => item.id === "children-4to12yearsOld"
            )}
          />
          <InputCheckbox
            label="Adolescentes - 13 a 18 anos"
            id="teens-13to18yearsOld"
            name="ageRange"
            onChange={handleChecked}
            defaultChecked={form.occupationArea.find(
              (item) => item.id === "teens-13to18yearsOld"
            )}
          />
        </div>
        <div>
          <InputCheckbox
            label="Jovens - 19 a 29 anos"
            id="youngPeople-19to29yearsOld"
            name="ageRange"
            onChange={handleChecked}
            defaultChecked={form.occupationArea.find(
              (item) => item.id === "youngPeople-19to29yearsOld"
            )}
          />
          <InputCheckbox
            label="Adultos - 30 a 60 anos"
            id="adults-30to60yearsOld"
            name="ageRange"
            onChange={handleChecked}
            defaultChecked={form.occupationArea.find(
              (item) => item.id === "adults-30to60yearsOld"
            )}
          />
          <InputCheckbox
            label="Idosos - 60 anos ou mais"
            id="seniors-60yearsOver"
            name="ageRange"
            onChange={handleChecked}
            defaultChecked={form.occupationArea.find(
              (item) => item.id === "seniors-60yearsOver"
            )}
          />
        </div>
      </S.ContentAgeRange>
    </S.FormAssistedIdade>
  );

  const validateErrorOccupationArea = () => {
    const occupationArea = form.occupationArea.filter((item) => {
      return item.name === "occupationArea";
    });
    return error === true && occupationArea.length === 0 ? true : false;
  };

  const validateErrorCausesOfAtuation = () => {
    const occupationArea = form.occupationArea.filter((item) => {
      return item.name === "causesOfAtuation";
    });
    return error === true && occupationArea.length === 0 ? true : false;
  };

  const validateErrorQuantityOfPeople = () => {
    return error === true &&
      (form.quantityOfPeople === 0 || form.quantityOfPeople === undefined)
      ? true
      : false;
  };

  return (
    <Layout descktop currentStep="2">
      <S.Container onSubmit={handleSubmit}>
        <Title form icon={VolunteerActivismBlack} paddingLeftDesk="2.5rem">
          Atuação <div>e assistidos</div>
        </Title>
        <S.BoxForm>
          {renderTitle()}
          {validateErrorOccupationArea() && (
            <S.ErrorText>
              Campo obrigatório! Selecione ao menos uma área de atuação:
            </S.ErrorText>
          )}

          {renderOccupationArea()}
        </S.BoxForm>
        {renderCausesOfAction()}
        <S.BoxAssisted>
          <TitleWithLine>
            {isDescktop
              ? "Pessoas assistidas pela ONG *"
              : "Pessoas assistidas pela iniciativa"}
          </TitleWithLine>

          {validateErrorQuantityOfPeople() && (
            <S.ErrorText>
              Campo obrigatório! Selecione ao menos uma quantidade:
            </S.ErrorText>
          )}

          <S.FormAssistedQuant>
            <S.TitleLabel padding="0.391rem">Quantidade</S.TitleLabel>
            <InputNumber
              name="quantityAssisted"
              value={form.quantityOfPeople}
              onChange={handleChangeQuantity}
              noRequired
            />
          </S.FormAssistedQuant>

          <S.ContentCheckbox>
            {renderGenre()}
            {renderFixedAge()}
          </S.ContentCheckbox>
        </S.BoxAssisted>
        {isDescktop && (
          <S.BoxFooter>
            <Button
              text="Voltar"
              fontColor="#5484ED"
              color="#CADBFF"
              onClick={() => navigate("/cadastro/contatos-e-dados-oficiais")}
            />
            <Button text="Próximo" type="submit" />
          </S.BoxFooter>
        )}
        {!isDescktop && (
          <>
            <Button text="Próximo" type="submit" />
            <BtnBack />
          </>
        )}
      </S.Container>
    </Layout>
  );
};

export default ActingAssisted;
