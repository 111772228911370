import React from "react";
import * as S from "./styles";

const InputRadio = ({
  noMarginRight,
  positionText,
  small,
  paddingTopMob,
  id,
  label,
  name,
  value,
  checked,
  onChecked,
  onClick,
  paddingTop,
  widthMob,
  paddingLeft,
  isError,
  ...rest
}) => {
  return (
    <S.Label
      paddingLeft={paddingLeft}
      widthMob={widthMob}
      positionText={positionText}
      paddingTopMob={paddingTopMob}
      small={small}
      paddingTop={paddingTop}
      noMarginRight={noMarginRight}
      for={id}
      isError={isError}
    >
      <S.Input
        name={name}
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onClick={onClick && onClick}
        required
        isError={isError}
        {...rest}
      />
      {label}
    </S.Label>
  );
};

export default InputRadio;
