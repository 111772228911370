import React, { useState, useEffect } from "react";
import * as S from "./styles";

// modules
import { api } from "../../modules/api";

//imgs
import { CardVi } from "../../assets/";

const CardViHome = () => {
  const [user, setUser] = useState("");
  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    if (localStorage.getItem("idToken")) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("idToken")}`,
        },
      };
      const response = await api.get("/user/me/", config);
      setUser(response.data[0].first_name);
    }
  };

  return (
    <S.Container>
      <S.img src={CardVi} />
      <S.Text>Seja bem-vindo(a) ao Vision, {user} !</S.Text>
    </S.Container>
  );
};

export default CardViHome;
