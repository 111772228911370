import styled from "styled-components";

export const Dropdown = styled.div`
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  height: 180px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  ::-webkit-scrollbar-thumb {
    background: #707070;
    width: 3.64px;
  }

  @media (min-width: 1024px) {
    margin-left: 2.5rem;
  }
`;
export const Person = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  &:hover {
    cursor: pointer;
  }
  input {
    margin: 0 20px 0 20px;
    display: none;
  }
  input + label:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #d9d9d9;
    display: inline-block;
    margin-right: 4px;
  }
  label {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
  }
  input:checked + label:before {
    background-color: #5484ed;
    color: #5484ed;
  }
`;
export const LineGrey = styled.div`
  width: 345px;
  height: 2px;
  background-color: #d9d9d9;
`;
