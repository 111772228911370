import React from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';

const FormP = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/esqueceu-a-senha');
  };

  return (
    <>
      <S.RememberLabel>
        <S.ButtonReset onClick={handleClick}>Esqueceu sua senha?</S.ButtonReset>
      </S.RememberLabel></>
  );
};

export default FormP;
