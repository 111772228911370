import React, { useState, useEffect } from 'react';
import { dateFormatter } from '../../utils/dateFormatter';

//Styles
import * as S from './styles';

const Conclusion = ({ activityData, small }) => {
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <S.BoxConclusion displayNone={counter === 0} displayFlex={counter === 10} small={small}>
      <S.Content>
        <S.ActivityName>Atividade {activityData?.name} </S.ActivityName>
        <S.TextSave>Salvo em {dateFormatter(new Date())}</S.TextSave>
        <S.DescriptionSave>As informações se encontram na página de <S.Bold>Gestão de Atividades </S.Bold>
          de cada ONG e podem ser acessadas através da Home</S.DescriptionSave>
        <S.Count>{counter}s</S.Count>
      </S.Content>
    </S.BoxConclusion>
  )
};

export default Conclusion;
