import InputMask from "react-input-mask";
import { Wrapper } from "../Input/styles";
import formMessages from "../../utils/formMessages";

const InputMasked = ({
  label,
  name,
  error,
  mask,
  description,
  currentValue,
  fullWidth = false,
  ...rest
}) => {
  return (
    <Wrapper
      isBorderColorRed={error && error !== formMessages.general.errorMessage}
      fullWidth={fullWidth}
    >
      <InputMask
        id={name}
        type="text"
        className={`inputElement`}
        mask={mask}
        maskPlaceholder={null}
        {...rest}
      />
      <label
        htmlFor={name}
        className={`labelElement ${currentValue ? "non-empty" : ""} ${
          error ? "error" : ""
        }`}
      >
        {label}
      </label>
      {!!error ? (
        <span className="error">{error}</span>
      ) : (
        <span className="description">{description}</span>
      )}
    </Wrapper>
  );
};

export default InputMasked;
