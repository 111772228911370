import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Layout from "../../../components/Slider/LayoutSlider";
import Title from "../../../components/ProvisionalTitle";
import Input from "../../../components/Inputs";
import Btn from "../../../components/Buttons/button/index";
import SuccessScreen from "../../../components/SuccessScreen";
import TermsScreen from "../../../components/TermsScreen";
import PolicyScreen from "../../../components/PolicyScreen";
import InputRadioBoolean from "../../../components/Inputs/InputRadioBoolean";
import { cpfMask } from "../../../utils/Masks/cpf";
import { api } from "../../../modules/api";

//Styles
import * as S from "./styles";
import { BackArrow } from "../../../assets";
import Subtitle from "../../../components/Subtitle";

const EnterYourInformation = () => {
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const [isTermsModal, setIsTermsModal] = useState(false);
  const [isPolicyModal, setIsPolicyModal] = useState(false);
  const [hasError, setHasError] = useState("");
  const [form, setForm] = useState({
    name: "",
    surname: "",
    cpf: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  });

  const [isError, setIsError] = useState({
    name: undefined,
    surname: undefined,
    cpf: undefined,
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
  });

  const handleGoLogin = () => {
    navigate("/login");
  };

  const handleModalSuccess = () => {
    setIsModal(true);
  };

  const handleTermsModal = () => {
    setIsTermsModal(true);
  };

  const closeTermsModal = () => {
    setIsTermsModal(false);
  };

  const handlePolicyModal = () => {
    setIsPolicyModal(true);
  };

  const closePolicyModal = () => {
    setIsPolicyModal(false);
  };

  const handleChange = (ev) => {
    ev.preventDefault();
    const value = ev.target.value;
    const name = ev.target.name;
    const newName = name === "confirmPassword" ? "password" : name;

    const updateForm = {
      ...form,
      [name]: value,
    };

    setIsError({
      ...isError,
      [newName]: false,
    });
    setHasError(false);
    setForm(updateForm);
  };

  const handleScreenValidation = () => {
    const { name, surname, password, confirmPassword } = form;

    const isErrorQtdName = name.length > 35;
    const msgErrorQtdName = isErrorQtdName
      ? "Sobrenome deve conter máximo de 35 caracteres."
      : false;

    const isErrorQtdSurname = surname.length > 35;
    const msgErrorQtdSurname = isErrorQtdSurname
      ? "Sobrenome deve conter máximo de 35 caracteres."
      : false;

    const isDifferentPassword = password !== confirmPassword; //true
    const msgErrorPassword = isDifferentPassword
      ? "As senhas não são iguais. Tente novamente."
      : false;

    const isErrorQtdPassword =
      password.length < 8 && confirmPassword.length < 8;
    const msgErrorQtdPassword = isErrorQtdPassword
      ? "Sua senha deve conter mínimo de oito caracteres."
      : false;

    if (
      isDifferentPassword ||
      isErrorQtdPassword ||
      isErrorQtdName ||
      isErrorQtdSurname
    ) {
      setIsError({
        ...isError,
        name: msgErrorQtdName,
        surname: msgErrorQtdSurname,
        password: msgErrorPassword || msgErrorQtdPassword,
      });
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleScreenValidation();

    // faz o post no backend
    if (handleScreenValidation()) {
      const { name, surname, cpf, email, password } = form;

      let formatted_cpf = cpf.replace(/[^\d]+/g, "");

      const data = {
        first_name: name,
        last_name: surname,
        cpf: formatted_cpf,
        role: "Master",
        email: email,
        password: password,
      }; //data para o backend
      api
        .post("/user/", data)
        .then((res) => {
          handleModalSuccess();
        })
        .catch((err) => {
          console.log("error", err);
          if (
            err?.response?.data?.email?.filter(
              (i) => i === "user with this email already exists."
            ).length >= 1
          ) {
            setIsError({
              ...isError,
              email: "E-mail já cadastrado.",
            });
            return;
          }
          if (
            err?.response?.data?.cpf?.filter(
              (i) => i === "user with this cpf already exists."
            ).length >= 1
          ) {
            setIsError({
              ...isError,
              cpf: "CPF já cadastrado.",
            });
            return;
          }
          setHasError(true);
        });
    }
  };

  const renderTitle = () => {
    return (
      <>
        Parabéns!
        <Title
          successScreen
          paddingTop="0"
          paddingBottom="3.5rem"
          paddingMob="0"
          wrap
        >
          Sua conta foi criada
          <div>com sucesso.</div>
          <Subtitle
            widthDesk="100%"
            paddingTop="1.5rem"
            color="#7EBD2F"
            weight="bold"
            subtitle="(Por favor verifique seu e-mail para confirmar sua conta."
          />
          <Subtitle
            widthDesk="100%"
            color="#7EBD2F"
            weight="bold"
            subtitle="Caso não encontre o e-mail, verifique sua caixa de spam.)"
          />
        </Title>
      </>
    );
  };

  const BtnBack = () => {
    return (
      <S.ContentBack onClick={() => navigate(-1)}>
        <S.ImgBack src={BackArrow} alt="Voltar" />
        <S.Text>Voltar</S.Text>
      </S.ContentBack>
    );
  };

  return (
    <Layout>
      <S.Container onSubmit={handleSubmit}>
        <S.Content>
          <S.Button onClick={handleGoLogin}>Já é cadastrado ?</S.Button>
          <S.BoxTitle>
            <Title>
              Insira suas
              <Title paddingTop="0" paddingBottom="3.5rem">
                informações
              </Title>
            </Title>
          </S.BoxTitle>
          <S.BoxWrap>
            <Input
              paddingBottom
              label="Nome"
              name="name"
              width="100%"
              widthInput="100%"
              value={form.name}
              onChange={handleChange}
              error={isError.name}
              maxLength="15"
            />
            <Input
              paddingBottom
              label="Sobrenome"
              name="surname"
              width="100%"
              value={form.surname}
              onChange={handleChange}
              error={isError.surname}
              widthInput="100%"
            />
          </S.BoxWrap>
          <Input
            label="CPF"
            place="000.000.000-00"
            width="100%"
            name="cpf"
            value={cpfMask(form.cpf)}
            onChange={handleChange}
            maxLength="11"
            error={isError.cpf}
            marginTop="20px"
            widthInput="100%"
          />
          <S.Line />
          <Input
            paddingBottom
            width="100%"
            label="E-mail (usuário)"
            place="nossolar@home.org"
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            error={isError.email}
            marginTop="8px"
            widthInput="100%"
          />
          <S.BoxWrap>
            <Input
              paddingBottom
              label="Senha"
              place="*******"
              name="password"
              width="100%"
              type="password"
              value={form.password}
              onChange={handleChange}
              error={isError.password}
              marginTop="10px"
              widthInput="100%"
            />
            <Input
              paddingBottom
              label="Confirmar senha"
              place="*******"
              width="100%"
              name="confirmPassword"
              type="password"
              value={form.confirmPassword}
              onChange={handleChange}
              error={isError.password}
              marginTop="10px"
              widthInput="100%"
            />
          </S.BoxWrap>
          <InputRadioBoolean
            onClickPolicy={handlePolicyModal}
            onClickTerms={handleTermsModal}
            onChange={() => setForm({ ...form, terms: !form.terms })}
            checked={!form.terms}
          />
        </S.Content>

        <S.ContentButton>
          {hasError && <S.TextError>Erro ao cadastrar</S.TextError>}
          <Btn
            widthDesk="236px"
            text="Enviar"
            marginTop="20px"
            isDisabled={!form.terms}
          />
          <BtnBack />
        </S.ContentButton>
      </S.Container>

      {isModal && <SuccessScreen margin wrap title={renderTitle()} />}
      {isTermsModal && <TermsScreen onClose={closeTermsModal} />}
      {isPolicyModal && <PolicyScreen onClose={closePolicyModal} />}
    </Layout>
  );
};

export default EnterYourInformation;
