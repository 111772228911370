import styled from 'styled-components';

import { colors } from '../../../styles/Theme';

export const Container = styled.div`
  padding-bottom: 1.125rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ( max-width: 768px ) {
    line-height: 1;
  }

  @media (min-width: 1024px) {
    padding-bottom: 0;;
  }
`

export const Paragraph = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  font-style: normal;
  font-family: 'Open Sans';
  line-height: 1rem;
  color: ${colors.gray};
  text-align: center;
`

export const ParagraphLink = styled.a`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${colors.blue};
  cursor: pointer;
  text-decoration-line: ${props => props.underline && 'underline'};
;
`