import React from "react";

import * as S from './styles'

//Images
import {
  CloseModal,
} from '../../assets'

const TermsScreen = ({ onClose }) => {
  return (
    <S.Overlay>
      <S.ContainerModal>
      <h1>TERMOS E CONDI&Ccedil;&Otilde;ES DE USO.         
          <S.ContainerCloseModal onClick={onClose}>
          <S.ImageCloseModal src={CloseModal} alt="Excluir" />
          <S.Trash>Fechar</S.Trash>
          </S.ContainerCloseModal>
      </h1>
      <p>TERMOS E CONDI&Ccedil;&Otilde;ES DE USO.</p>
<p>Os&nbsp;Termos e Condi&ccedil;&otilde;es de Uso&nbsp;da plataforma Vision almejam garantir transpar&ecirc;ncia nos servi&ccedil;os, e clareza na seguran&ccedil;a das informa&ccedil;&otilde;es na coleta e utiliza&ccedil;&atilde;o dos dados dos usu&aacute;rios.&nbsp;</p>
<p>AO CLICAR NO BOT&Atilde;O DE ACEITE OU AO ACESSAR, USAR OU INSTALAR QUALQUER PARTE DO SERVI&Ccedil;O, O USU&Aacute;RIO EXPRESSAMENTE CONSENTE E CONCORDA COM OS TERMOS DESTE CONTRATO. SE O USU&Aacute;RIO N&Atilde;O CONCORDA COM OS TERMOS DESTE CONTRATO, O BOT&Atilde;O INDICANDO O &ldquo;N&Atilde;O ACEITE&rdquo; DEVE SER SELECIONADO; A PLATAFORMA VISION, ASSIM, IMEDIATAMENTE CANCELAR&Aacute; A OPERA&Ccedil;&Atilde;O, DE MODO QUE O USU&Aacute;RIO N&Atilde;O PODER&Aacute; ACESSAR, USAR OU ACESSAR QUALQUER PARTE DO SERVI&Ccedil;O DISPON&Iacute;VEL NESTA PLATAFORMA.</p>
<p>Todos os direitos de propriedade intelectual desta plataforma s&atilde;o reservados &agrave;&nbsp;FAVELA INC. pessoa jur&iacute;dica, com sede na Av. Pres. Jo&atilde;o Goulart, 825 - Vidigal, Rio de Janeiro - RJ, 22452-000, inscrita no CNPJ sob o n&ordm; 34.249.794/0001-43. O uso indevido de qualquer ferramenta, dado ou produ&ccedil;&atilde;o textual ou gr&aacute;fica, ensejar&aacute; viola&ccedil;&atilde;o aos direitos autorais desta plataforma e estar&atilde;o sujeitas a san&ccedil;&otilde;es administrativas e judiciais, conforme prev&ecirc; o Art. 12, da Lei n&ordm; 9.613/98, e outras.&nbsp;</p>
<p><br/></p>
<p>PRE&Acirc;MBULO</p>
<p>1. Termos de servi&ccedil;o e limita&ccedil;&otilde;es</p>
<p>a.&nbsp;Descri&ccedil;&atilde;o: O servi&ccedil;o aqui disponibilizado &eacute; propriedade imaterial do Instituto Favela INC. e &eacute; protegido por leis de propriedade intelectual e tratados internacionais de prote&ccedil;&atilde;o &agrave; propriedade intelectual. O acesso de usu&aacute;rios ao servi&ccedil;o tem natureza de licenciamento e n&atilde;o de aquisi&ccedil;&atilde;o. O Instituto Favela Inc, atrav&eacute;s do projeto Vision, neste ato chamado de &ldquo;plataforma Vision&rdquo;, concorda em providenciar &agrave; ONG parceira uma conta pessoal, n&atilde;o transfer&iacute;vel e n&atilde;o exclusiva, que habilite o captador de dados a acessar e usar o servi&ccedil;o.</p>
<p>b.&nbsp;Acessibilidade: A ONG usu&aacute;ria compreende que, periodicamente, o servi&ccedil;o poder&aacute; se tornar inacess&iacute;vel ou inoperante por qualquer raz&atilde;o, incluindo, mas n&atilde;o se limitando a (i) falhas de equipamentos; (ii) manuten&ccedil;&otilde;es, ou, procedimentos peri&oacute;dicos, ou, reparos que Instituto Favela INC pode ser obrigado a realizar ocasionalmente; ou, (iii) causas alheias ao controle da empresa ou que n&atilde;o sejam razoavelmente previs&iacute;veis ao Instituto Favela INC.</p>
<p>c.&nbsp;Equipamento: A ONG usu&aacute;ria ser&aacute; exclusivamente respons&aacute;vel por providenciar, manter e garantir a compatibilidade com o servi&ccedil;o. Todo requerimento de&nbsp;hardware,&nbsp;software&nbsp;bem como f&iacute;sicos ou el&eacute;tricos s&atilde;o de responsabilidade da ONG usu&aacute;ria, incluindo, sem reservas, acesso &agrave; internet e telecomunica&ccedil;&otilde;es em geral, navegadores de internet ou outros equipamentos, programas ou servi&ccedil;os necess&aacute;rios ao acesso e uso do servi&ccedil;o.</p>
<p>&nbsp;</p>
<p>2. Limita&ccedil;&otilde;es</p>
<p>a.&nbsp;Seguran&ccedil;a: A ONG usu&aacute;ria ser&aacute; &uacute;nica e exclusivamente respons&aacute;vel pela seguran&ccedil;a, conte&uacute;do, confidencialidade e integridade de todas as alimenta&ccedil;&otilde;es de informa&ccedil;&otilde;es em seu login, al&eacute;m de todo seu conte&uacute;do recebido, transmitido ou armazenado atrav&eacute;s dos canais do servi&ccedil;o. A ONG usu&aacute;ria ser&aacute; &uacute;nica e exclusivamente respons&aacute;vel por qualquer acesso n&atilde;o autorizado de terceiros a sua conta atrav&eacute;s de sua chave de acesso. A ONG usu&aacute;ria concorda em assumir toda a responsabilidade pela confidencialidade de sua palavra-chave e quaisquer responsabiliza&ccedil;&otilde;es c&iacute;veis e/ou criminais, bem como usos ou cobran&ccedil;as advindas do uso das funcionalidades de sua conta mediante uso de sua palavra-chave.</p>
<p>b.&nbsp;Privacidade: O Instituto Favela INC guardar&aacute; respeito &agrave; privacidade do usu&aacute;rio. O Instituto Favela INC n&atilde;o editar&aacute; ou publicar&aacute; qualquer informa&ccedil;&atilde;o pessoal sobre o usu&aacute;rio ou a conta do usu&aacute;rio, incluindo seus conte&uacute;dos ou a forma como o usu&aacute;rio usa o servi&ccedil;o sem o consentimento pr&eacute;vio do usu&aacute;rio, salvo nos casos em que a empresa, de boa-f&eacute;, acredite que tais cursos de a&ccedil;&atilde;o sejam necess&aacute;rios a: (i) cumprir leg&iacute;tima ordem judicial ou requisi&ccedil;&otilde;es legais de qualquer autoridade governamental; (ii) proteger e defender direitos ou propriedade da empresa; (iii) fazer valer os termos do presente contrato; (iv) proteger os interesses de usu&aacute;rios do servi&ccedil;o; ou (v) operar ou conduzir reparos ou manuten&ccedil;&otilde;es dos servi&ccedil;os ou equipamentos da empresa. O usu&aacute;rio compreende n&atilde;o ter expectativa de privacidade em respeito &agrave; generalidade da experi&ecirc;ncia de uso da Internet. O IP do usu&aacute;rio ser&aacute; transmitido e gravado com cada mensagem ou intera&ccedil;&atilde;o com a plataforma do servi&ccedil;o.</p>
<p>Modo e local de processamento dos Dados</p>
<p><br/></p>
<ol>
    <li>
        <p>M&eacute;todo de processamento</p>
    </li>
</ol>
<p>O administrador do sistema tomar&aacute; as medidas de seguran&ccedil;a adequadas para impedir o acesso n&atilde;o autorizado, divulga&ccedil;&atilde;o, altera&ccedil;&atilde;o ou destrui&ccedil;&atilde;o n&atilde;o autorizada dos Dados.</p>
<p>O processamento dos Dados &eacute; realizado utilizando computadores e /ou ferramentas de TI habilitadas, seguindo procedimentos organizacionais e meios estritamente relacionados com os fins indicados. Al&eacute;m do administrador do sistema, em alguns casos, os Dados podem ser acessados por certos tipos de pessoas encarregadas (tratadores de dados), envolvidas com a opera&ccedil;&atilde;o deste Servi&ccedil;o (administra&ccedil;&atilde;o, vendas, marketing, administra&ccedil;&atilde;o legal do sistema) ou pessoas externas (como fornecedores terceirizados de servi&ccedil;os t&eacute;cnicos, provedores de hospedagem, empresas de TI, ag&ecirc;ncias de comunica&ccedil;&atilde;o) nomeadas, quando necess&aacute;rio, como Processadores e Tratadores de Dados por parte do Administrador do sistema. A lista atualizada destas partes pode ser solicitada ao Administrador a qualquer momento.</p>
<p><br/></p>
<ol start="2">
    <li>
        <p>Base jur&iacute;dica para o processamento</p>
    </li>
</ol>
<p>O Propriet&aacute;rio poder&aacute; processar os Dados Pessoais relacionados ao Usu&aacute;rio se uma ou mais das seguintes hip&oacute;teses se aplicar:</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Os&nbsp;USU&Aacute;RIOS&nbsp;tenham dado a sua anu&ecirc;ncia para uma ou mais finalidades espec&iacute;ficas; Observa&ccedil;&atilde;o: De acordo com algumas legisla&ccedil;&otilde;es o Propriet&aacute;rio poder&aacute; ter a permiss&atilde;o para processar os Dados Pessoais at&eacute; que&nbsp;USU&Aacute;RIO&nbsp;fa&ccedil;a obje&ccedil;&atilde;o a isto (&ldquo;opt-out&rdquo;), sem ter que se basear em anu&ecirc;ncia ou em quaisquer outras bases jur&iacute;dicas a seguir. Isto contudo n&atilde;o se aplica sempre que o processamento de Dados Pessoais estiver sujeito &agrave; legisla&ccedil;&atilde;o europeia de prote&ccedil;&atilde;o de dados;</p>
<p>&bull;&nbsp; &nbsp;&nbsp;o fornecimento dos Dados for necess&aacute;rio para o cumprimento de um contrato com o Usu&aacute;rio e/ou quaisquer obriga&ccedil;&otilde;es pr&eacute;-contratuais do presente instrumento;&nbsp;</p>
<p>&bull;&nbsp; &nbsp;&nbsp;o processamento for necess&aacute;rio para o cumprimento de uma obriga&ccedil;&atilde;o jur&iacute;dica &agrave; qual o Propriet&aacute;rio estiver sujeito;</p>
<p>&bull;&nbsp; &nbsp;&nbsp;o processamento estiver relacionado a uma tarefa que for executada no interesse p&uacute;blico ou no exerc&iacute;cio de uma autoriza&ccedil;&atilde;o oficial na qual o Propriet&aacute;rio estiver investido;</p>
<p>&bull;&nbsp; &nbsp;&nbsp;o processamento for necess&aacute;rio para a finalidade de interesses leg&iacute;timos perseguidos pelo Propriet&aacute;rio ou por um terceiro;</p>
<p>Em qualquer caso, o Administrador colaborar&aacute; de bom grado para esclarecer qual a base jur&iacute;dica que se aplica ao processamento, e em especial se o fornecimento de Dados for um requisito obrigat&oacute;rio por for&ccedil;a de lei ou contratual, ou uma exig&ecirc;ncia necess&aacute;ria para celebrar um contrato.</p>
<p><br/></p>
<ol start="3">
    <li>
        <p>Lugar</p>
    </li>
</ol>
<p>Os dados s&atilde;o processados nas sedes de opera&ccedil;&atilde;o do Administrador, e em quaisquer outros lugares onde as partes envolvidas com o processamento de dados estiverem localizadas.</p>
<p>Dependendo da localiza&ccedil;&atilde;o do Usu&aacute;rio as transfer&ecirc;ncias de dados poder&atilde;o envolver a transfer&ecirc;ncia dos Dados do Usu&aacute;rio para outro pa&iacute;s que n&atilde;o seja o seu. Para descobrirem mais sobre o local de processamento de tais Dados transferidos os Usu&aacute;rios poder&atilde;o verificar a se&ccedil;&atilde;o contendo os detalhes sobre o processamento de Dados Pessoais.</p>
<p>Os Usu&aacute;rios tamb&eacute;m possuem o direito de serem informados sobre a base jur&iacute;dica das transfer&ecirc;ncias de Dados para pa&iacute;ses de fora da Uni&atilde;o Europeia ou para quaisquer organiza&ccedil;&otilde;es internacionais regidas pelo direito internacional p&uacute;blico ou formadas por dois ou mais pa&iacute;ses, tal como a ONU, e sobre as medidas de seguran&ccedil;a tomadas pelo Propriet&aacute;rio para proteger os seus Dados.</p>
<p>Se ocorrerem quaisquer tais transfer&ecirc;ncias os Usu&aacute;rios poder&atilde;o descobrir mais a respeito verificando as se&ccedil;&otilde;es pertinentes deste documento ou perguntando ao Administrador utilizando as informa&ccedil;&otilde;es fornecidas na se&ccedil;&atilde;o de contatos.</p>
<p><br/></p>
<ol start="4">
    <li>
        <p>Per&iacute;odo de conserva&ccedil;&atilde;o</p>
    </li>
</ol>
<p>Os Dados Pessoais ser&atilde;o processados e armazenados pelo tempo que for necess&aacute;rio para as finalidades para as quais forem coletados.</p>
<p>Portanto:</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Os Dados Pessoais coletados para as finalidades relacionadas com a execu&ccedil;&atilde;o de um contrato entre o Administrador e o Usu&aacute;rio ser&atilde;o conservados at&eacute; que tal contrato tenha sido completamente cumprido.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Os Dados Pessoais coletados para as finalidades relacionadas com os leg&iacute;timos interesses do Administrador ser&atilde;o conservados pelo tempo que for necess&aacute;rio para cumprir tais finalidades. Os Usu&aacute;rios poder&atilde;o obter informa&ccedil;&otilde;es espec&iacute;ficas sobre os interesses leg&iacute;timos perseguidos pelo Administrador dentro das se&ccedil;&otilde;es pertinentes deste documento ou entrando em contato com o Administrador.</p>
<p>O Administrador poder&aacute; ter a permiss&atilde;o de conservar os Dados Pessoais por um prazo maior sempre que o Usu&aacute;rio tiver dado a sua autoriza&ccedil;&atilde;o para tal processamento, enquanto tal autoriza&ccedil;&atilde;o n&atilde;o tiver sido retirada. Al&eacute;m disso, o Administrador poder&aacute; ficar obrigado a conservar os Dados Pessoais por um prazo maior em todas as ocasi&otilde;es em que estiver obrigado a faz&ecirc;-lo para o cumprimento de uma obriga&ccedil;&atilde;o jur&iacute;dica ou em cumprimento de um mandado de uma autoridade.</p>
<p>Assim que o prazo de conserva&ccedil;&atilde;o expirar os Dados Pessoais ser&atilde;o apagados. Desta forma o direito de acessar, o direito de apagar, o direito de corrigir e o direito &agrave; portabilidade dos dados n&atilde;o poder&aacute; ter o seu cumprimento exigido ap&oacute;s o vencimento do prazo de conserva&ccedil;&atilde;o.</p>
<p><br/></p>
<ol start="5">
    <li>
        <p>Os direitos dos Usu&aacute;rios</p>
    </li>
</ol>
<p>Os Usu&aacute;rios poder&atilde;o exercer determinados direitos a respeito dos seus Dados processados pelo Administrador.</p>
<p>Em especial, os Usu&aacute;rios possuem os direitos a fazer o seguinte:</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Retirar a sua anu&ecirc;ncia a qualquer momento.&nbsp;Os Usu&aacute;rios possuem o direito de retirar a sua anu&ecirc;ncia nos casos em que tenham dado a sua anu&ecirc;ncia anteriormente para o processamento dos seus Dados Pessoais.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Objetar o processamento dos seus Dados.&nbsp;Os Usu&aacute;rios possuem o direito de objetar o processamento dos seus Dados se o processamento for executado sobre outra base jur&iacute;dica que n&atilde;o a anu&ecirc;ncia. Em havendo incid&ecirc;ncia de casos no qual a legisla&ccedil;&atilde;o permite base legal diversa da anu&ecirc;ncia para o tratamento dos dados, ou exige a manuten&ccedil;&atilde;o dos dados sob a guarda do Administrador, o Usu&aacute;rio ser&aacute; adequadamente notificado, quando se objetar. S&atilde;o fornecidos detalhes adicionais na se&ccedil;&atilde;o espec&iacute;fica abaixo.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Acessar os seus Dados.&nbsp;Os Usu&aacute;rios possuem o direito de saber se os seus Dados est&atilde;o sendo processados pelo Propriet&aacute;rio, obter revela&ccedil;&otilde;es sobre determinados aspectos do processamento e conseguir uma c&oacute;pia dos Dados que estiverem sendo processados. Em casos impossibilidade de demonstra&ccedil;&atilde;o dos dados, como na eventualidade de anonimiza&ccedil;&atilde;o irrevers&iacute;vel, ser&aacute; informado o status dos dados e a exist&ecirc;ncia ou n&atilde;o de dados de uma determinada titularidade dentro do sistema.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Verificar e pedir retifica&ccedil;&atilde;o.&nbsp;Os Usu&aacute;rios possuem o direito de verificar a exatid&atilde;o dos seus Dados e de pedir que os mesmos sejam atualizados ou corrigidos.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Restringir o processamento dos seus Dados.&nbsp;Os Usu&aacute;rios possuem o direito de, sob determinadas circunst&acirc;ncias, restringir o processamento dos seus Dados para qualquer outra finalidade que n&atilde;o seja o armazenamento dos mesmos.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Ter os seus Dados Pessoais apagados ou retirados de outra maneira.&nbsp;Os Usu&aacute;rios possuem o direito de, sob determinadas circunst&acirc;ncias, obter a elimina&ccedil;&atilde;o dos seus Dados perante o Administrador.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Receber os seus Dados e ter os mesmos transferidos para outro controlador.&nbsp;Os Usu&aacute;rios possuem o direito de receber os seus Dados em um formato estruturado, utilizado comumente e apto a ser lido por m&aacute;quinas e, se for vi&aacute;vel tecnicamente, fazer com que os mesmos sejam transmitidos para outro controlador sem nenhum empecilho. Esta determina&ccedil;&atilde;o se aplica condicionada a que os Dados sejam processados por meios automatizados e que o processamento esteja baseado na anu&ecirc;ncia do Usu&aacute;rio, em um contrato do qual o Usu&aacute;rio seja uma das partes ou por obriga&ccedil;&otilde;es pr&eacute;-contratuais do mesmo.</p>
<p>&bull;&nbsp; &nbsp;&nbsp;Registrar uma reclama&ccedil;&atilde;o.&nbsp;Os Usu&aacute;rios possuem o direito de apresentar reclama&ccedil;&atilde;o perante a autoridade de prote&ccedil;&atilde;o de dados competente.</p>
<p><br/></p>
<ol start="6">
    <li>
        <p>Detalhes sobre o direito de objetar ao processamento</p>
    </li>
</ol>
<p>Nos casos em que os Dados Pessoais forem processados por um interesse p&uacute;blico, no exerc&iacute;cio de uma autoriza&ccedil;&atilde;o oficial na qual o Propriet&aacute;rio estiver investido ou para finalidades dos interesses leg&iacute;timos perseguidos pelo Propriet&aacute;rio, os Usu&aacute;rios poder&atilde;o objetar tal processamento atrav&eacute;s do fornecimento de um motivo relacionado com a sua situa&ccedil;&atilde;o em especial para justificar a obje&ccedil;&atilde;o.</p>
<p>Os Usu&aacute;rios devem saber, entretanto, que caso os seus Dados Pessoais sejam processados para finalidades de marketing direto eles podem objetar tal processamento a qualquer momento sem fornecer nenhuma justificativa. Os Usu&aacute;rios podem consultar as se&ccedil;&otilde;es respectivas deste documento.</p>
<p><br/></p>
<ol start="7">
    <li>
        <p>Como exercer estes direitos</p>
    </li>
</ol>
<p>Quaisquer pedidos para exercer os direitos dos Usu&aacute;rios podem ser direcionados ao Propriet&aacute;rio atrav&eacute;s dos dados para contato fornecidos neste documento. Estes pedidos podem ser exercidos sem nenhum custo e ser&atilde;o atendidos pelo Administrador com a maior brevidade poss&iacute;vel e em todos os casos em prazo inferior a um m&ecirc;s.</p>
<p><br/></p>
<p>Informa&ccedil;&otilde;es adicionais sobre a coleta e processamento de Dados</p>
<p><br/></p>
<ol start="8">
    <li>
        <p>A&ccedil;&atilde;o jur&iacute;dica</p>
    </li>
</ol>
<p>Os Dados Pessoais dos Usu&aacute;rios podem ser utilizados para fins jur&iacute;dicos pelo Propriet&aacute;rio em ju&iacute;zo ou nas etapas conducentes &agrave; poss&iacute;vel a&ccedil;&atilde;o jur&iacute;dica decorrente de uso indevido deste Servi&ccedil;o (este Aplicativo) ou dos Servi&ccedil;os relacionados.</p>
<p>O Usu&aacute;rio declara estar ciente de que o Administrador poder&aacute; ser obrigado a revelar os Dados Pessoais mediante solicita&ccedil;&atilde;o das autoridades governamentais.</p>
<p><br/></p>
<ol start="9">
    <li>
        <p>Informa&ccedil;&otilde;es adicionais sobre os Dados Pessoais do Usu&aacute;rio</p>
    </li>
</ol>
<p>Al&eacute;m das informa&ccedil;&otilde;es contidas nesta pol&iacute;tica de privacidade, este Servi&ccedil;o poder&aacute; fornecer ao Usu&aacute;rio informa&ccedil;&otilde;es adicionais e contextuais sobre os servi&ccedil;os espec&iacute;ficos ou a coleta e processamento de Dados Pessoais mediante solicita&ccedil;&atilde;o.</p>
<p><br/></p>
<ol start="10">
    <li>
        <p>Logs do sistema e manuten&ccedil;&atilde;o</p>
    </li>
</ol>
<p>Para fins de opera&ccedil;&atilde;o e manuten&ccedil;&atilde;o, este Servi&ccedil;o e quaisquer servi&ccedil;os de terceiros poder&atilde;o coletar arquivos que gravam a intera&ccedil;&atilde;o com este Servi&ccedil;o (logs do sistema) ou usar outros Dados Pessoais (tais como endere&ccedil;o IP) para esta finalidade.</p>
<p><br/></p>
<ol start="11">
    <li>
        <p>As informa&ccedil;&otilde;es n&atilde;o contidas nesta pol&iacute;tica</p>
    </li>
</ol>
<p>Mais detalhes sobre a coleta ou processamento de Dados Pessoais podem ser solicitados ao Administrador, a qualquer momento. Favor ver as informa&ccedil;&otilde;es de contato no in&iacute;cio deste documento.</p>
<p><br/></p>
<ol start="12">
    <li>
        <p>Como s&atilde;o tratados os pedidos de &ldquo;N&atilde;o me Rastreie&rdquo;</p>
    </li>
</ol>
<p>Este Servi&ccedil;o n&atilde;o suporta pedidos de &ldquo;N&atilde;o Me Rastreie&rdquo;.</p>
<p>Para determinar se qualquer um dos servi&ccedil;os de terceiros que utiliza honram solicita&ccedil;&otilde;es de &ldquo;N&atilde;o Me Rastreie&rdquo;, por favor leia as pol&iacute;ticas de privacidade.</p>
<p><br/></p>
<ol start="13">
    <li>
        <p>Mudan&ccedil;as neste termo de uso.</p>
    </li>
</ol>
<p>O Administrador se reserva o direito de fazer altera&ccedil;&otilde;es nesta pol&iacute;tica de privacidade a qualquer momento, mediante comunica&ccedil;&atilde;o aos seus Usu&aacute;rios nesta p&aacute;gina e possivelmente dentro deste Servi&ccedil;o este Aplicativo e/ou &ndash; na medida em que for vi&aacute;vel tecnicamente e juridicamente &ndash; enviando um aviso para os Usu&aacute;rios atrav&eacute;s de quaisquer informa&ccedil;&otilde;es de contato dispon&iacute;veis para o Administrador. &Eacute; altamente recomend&aacute;vel que esta p&aacute;gina seja consultada v&aacute;rias vezes em rela&ccedil;&atilde;o &agrave; &uacute;ltima modifica&ccedil;&atilde;o descrita na parte inferior.&nbsp;</p>
<p>Caso as mudan&ccedil;as afetem as atividades de processamento realizadas com base na anu&ecirc;ncia do Usu&aacute;rio, o Administrador coletar&aacute; nova anu&ecirc;ncia do Usu&aacute;rio, onde for exigida.</p>
<p><br/></p>
<p><br/></p>
        </S.ContainerModal>
    </S.Overlay>
  );
};

export default TermsScreen;
