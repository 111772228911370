import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Layout from "../../../components/Slider/LayoutSlider";
import Title from "../../../components/ProvisionalTitle";
import Subtitle from "../../../components/Subtitle";
import Footer from "../../../components/Footer";
import Btn from "../../../components/Buttons/button/index";
import Input from "../../../components/Inputs/index";

//Styles
import * as S from "./styles";
// import Cookies from "js-cookie";
import { api } from "../../../modules/api";
const RenderMain = () => (
  <>
    <S.BoxTitle>
      <Title paddingBottom="2.5rem">Esqueceu a senha?</Title>
    </S.BoxTitle>
    <Subtitle
      subtitle="Vamos te ajudar!
        Insira o seu e-mail no campo abaixo
        e clique no botão."
    />
    <S.Container>
      <Subtitle
        subtitle="Abra o seu
        e-mail para alterar a senha."
      />
    </S.Container>
  </>
);
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (ev) => {
    ev.preventDefault();
    const value = ev.target.value;

    setEmail(value);
    setError(false);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    api
      .post("/user/password_reset/", {
        email: email,
      })
      .then((res) => {
        alert("Código de verificação enviado para o seu e-mail");
        navigate("/alterar-senha");
      })
      .catch((err) => {
        setError("E-mail não encontrado");
        console.error(err);
      });
    navigate("");
  };

  return (
    <Layout>
      <S.Content onSubmit={handleSubmit}>
        <RenderMain />
        <S.BoxHr>
          <hr />
        </S.BoxHr>
        <S.BoxForm>
          <Input
            width="100%"
            label="E-mail (usuário)"
            place="nossolar@home.org"
            name="email"
            value={email}
            onChange={handleChange}
            error={error}
            widthInput="100%"
          />
        </S.BoxForm>
        <S.BoxBtn>
          <Btn
            widthDesk="374px"
            text="Solicitar alteração de senha"
            width="16rem"
          />
        </S.BoxBtn>
        <Footer
          text="Ainda precisa de ajuda?"
          link="Consultar equipe de suporte"
          href="mailto:suporte.vision@favelainc.com?"
        />
      </S.Content>
    </Layout>
  );
};
export default ForgotPassword;
