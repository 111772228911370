

import React, { useState } from "react";

//Assets
import {
  peopleLecturing,
  childOnWhiteboard,
  peopleWithBasicFoodBasket,
  IconBtnSlider,
} from "../../assets";

// Styles
import * as S from "./styles";

export default function Footer({ text, link }) {
  const [currentImage, setCurrentImage] = useState(0);

  const listSlider = [
    peopleLecturing,
    childOnWhiteboard,
    peopleWithBasicFoodBasket,
  ];

  const handleBackSlider = () => {
    const backSlider = currentImage - 1;
    setCurrentImage(backSlider);
  };

  const handleNextSlider = () => {
    const nextSlider = currentImage + 1;
    setCurrentImage(nextSlider);
  };

  const handleSlider = (number) => {
    setCurrentImage(number);
  };

  const renderIconSlider = (isNext, onClick, alt) => (
    <S.Figure next={isNext} onClick={onClick}>
      <S.IconImg next={isNext} src={IconBtnSlider} alt={alt} />
    </S.Figure>
  );

  const renderBallSlider = () =>
    listSlider.map((i, index) => (
      <S.Ball
        key={index}
        currentSlider={index === currentImage}
        onClick={() => handleSlider(index)}
      />
    ));

  return (
    <S.Container bckg={listSlider[currentImage]}>
      {0 !== currentImage && renderIconSlider(null, handleBackSlider, "Voltar")}
      {listSlider.length - 1 !== currentImage &&
        renderIconSlider("isNext", handleNextSlider, "Próximo")}
      <S.ContentBall>{renderBallSlider()}</S.ContentBall>
    </S.Container>
  );
}
