import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`

export const Main = styled.main`
  width: 100%;
  height: calc(100vh - 5rem);

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`

export const ContentForm = styled.section`
  margin: 0 auto;
  width: ${props => (props.path === '/configuracoes' ? '100%' : '80%')};

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 375px) {
    width: 90%;
  }

  @media (min-width: 1024px) {
    padding:  ${props => (props.path === '/configuracoes' ? '0' : '4.938rem 0 0 2.5rem')};
    width: ${props => (props.path === '/configuracoes' ? '100%' : '60%')};
    height: calc(100vh - 5rem);
    overflow: auto;

    form {
      width: 90%;
    }
  }

  ${props => {
    if (props.screen < 1024) {
      css`
        display: none;
      `
    }
  }}
`
