import React, { useEffect } from "react";
import { useState } from "react";
import { VscTriangleDown } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

//Components
import LayoutHomeDashboard from "../../../../components/SideBarSettings";
import AssistedDataForm from "../../../../components/AssistedDataForm";
import ModalAlert from "../../../../components/Modals/modalAlert";

//Styles
import * as S from "./styles";

// Logo
import { IconAssisted } from "../../../../assets";
import Title from "../../../../components/Title";
import FamilyGroupDataForm from "../../../../components/FamilyGroupDataForm";
import AssistedActivitiesForm from "../../../../components/AssistedActivitiesForm";
import { useApi } from "../../../../providers/Api";
import { clearObject } from "../../../../utils/clearObject";
import LostConnection from "../../../../assets/icons/iconLostConnection.png";

const RegistrationOfNewAssisted = () => {
  const navigate = useNavigate();
  const { getMyUser } = useApi();
  const [user, setUser] = useState({});
  const [firstPartForm, setFirstPartForm] = useState(false);
  const [secondPartForm, setSecondPartForm] = useState(false);
  const [thirdPartForm, setThirdPartForm] = useState(false);
  const [formStage, setFormStage] = useState({
    firstPart: false,
    secondPart: false,
    thirdPart: false,
  });
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [buttonText, setButtonText] = useState("");

  const getUser = async () => {
    await getMyUser().then(setUser);
  };

  useEffect(
    () => {
      getUser();
      if (user?.role === "Analista" || user?.role === "Auxiliar") {
        return navigate("/gestao-de-atividades");
      }
      document.body.style.overflow = "auto";
      // Network Connection
      const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
        if (navigator.onLine) {
          setButtonText("Tentar novamente");
        }
      };

      window.addEventListener("online", handleStatusChange);
      window.addEventListener("offline", handleStatusChange);

      return () => {
        window.removeEventListener("online", handleStatusChange);
        window.removeEventListener("offline", handleStatusChange);
      };
    },
    [user?.role, navigate],
    [isOnline],
  );

  const [assistedData, setAssistedData] = useState({});

  const { createAssisted } = useApi();

  const resetAll = () => {
    setFirstPartForm(false);
    setSecondPartForm(false);
    setThirdPartForm(false);
    setAssistedData({});
    setFormStage({
      firstPart: false,
      secondPart: false,
      thirdPart: false,
    });
  };

  const addToAssistedData = async ({ data, formStage }) => {
    const newData = clearObject(
      formStage === "secondPart"
        ? {
            ...assistedData,
            familyGroup: { ...assistedData.familyGroup, ...data },
          }
        : { ...assistedData, ...data },
    );

    setAssistedData(newData);

    setFormStage((v) => ({ ...v, [formStage]: true }));

    switch (formStage) {
      case "firstPart":
        setFirstPartForm(false);
        return setSecondPartForm(true);
      case "secondPart":
        setSecondPartForm(false);
        return setThirdPartForm(true);
      case "thirdPart":
        await createAssisted(newData)
          .then((res) => {
            resetAll();
            return navigate("/cadastro-com-sucesso");
          })
          .catch((error) => {
            localStorage.setItem("user", JSON.stringify(newData));
            return navigate("/erro");
          });

        break;
      default:
        return;
    }
  };

  const openForm = (setterFn) => {
    setterFn !== setFirstPartForm && setFirstPartForm(false);
    setterFn !== setSecondPartForm && setSecondPartForm(false);
    setterFn !== setThirdPartForm && setThirdPartForm(false);

    setterFn((v) => !v);
  };

  const checkConnection = () => {
    if (!isOnline) return setButtonText("Tentando conexão");
  };

  return (
    <LayoutHomeDashboard
      seo="/ Cadastro de novos assistidos"
      screen="registrationOfNewAssisted"
    >
      <S.Wrapper>
        {!isOnline && (
          <ModalAlert
            icon={LostConnection}
            title={"Você está sem Internet"}
            subtitle={`Parece que há um problema com a sua conexão. Por favor, verifique e tente novamente.`}
            buttonText={buttonText || "Tentar novamente"}
            handleCloseModal={checkConnection}
          />
        )}
        <Title paddingLeftDesk="2.563rem" form="true" icon={IconAssisted}>
          Cadastro de novos assistidos
        </Title>
        <S.Content>
          <S.DropDownButton
            onClick={() => openForm(setFirstPartForm)}
            isOpen={firstPartForm}
          >
            <VscTriangleDown
              style={{
                opacity: 0,
              }}
            />
            <p className="title">Dados do Assistido</p>
            <VscTriangleDown />
          </S.DropDownButton>
          {firstPartForm && (
            <AssistedDataForm data={assistedData} adderFn={addToAssistedData} />
          )}
          <S.DropDownButton
            onClick={() => formStage.firstPart && openForm(setSecondPartForm)}
            isOpen={secondPartForm}
          >
            <VscTriangleDown
              style={{
                opacity: 0,
              }}
            />
            <p className="title">Dados do Grupo Familiar</p>
            <VscTriangleDown />
          </S.DropDownButton>
          {secondPartForm && (
            <FamilyGroupDataForm
              data={assistedData.familyGroup || {}}
              adderFn={addToAssistedData}
            />
          )}
          <S.DropDownButton
            onClick={() => formStage.secondPart && openForm(setThirdPartForm)}
            isOpen={thirdPartForm}
          >
            <VscTriangleDown
              style={{
                opacity: 0,
              }}
            />
            <p className="title">Atividades na ONG</p>
            <VscTriangleDown />
          </S.DropDownButton>
          {thirdPartForm && (
            <AssistedActivitiesForm adderFn={addToAssistedData} />
          )}
        </S.Content>
      </S.Wrapper>
    </LayoutHomeDashboard>
  );
};

export default RegistrationOfNewAssisted;
