import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Text } from "../Text";
import * as S from "./styles";
import isEmpty from "lodash/isEmpty";

import {
  birthGenderList,
  chronicDiseaseList,
  diseasesList,
  domesticViolenceList,
  familyIncomeList,
  familyItemsList,
  genderList,
  governmentAidsList,
  hobbiesList,
  physicalDisabilityList,
  scholarityList,
  skinColorsList,
  workActivitiesList,
  workList,
} from "../../utils/lists";
import { createAssistedSchema } from "../../utils/schemas";
import { useExtraFields } from "../../providers/ExtraFields";
import { reverseDate } from "../../utils/reverseDate";
import Input from "../Input";
import InputList from "../InputList";
import InputMasked from "../InputMasked";
import Btn from "../Buttons/button";
import formMessages from "../../utils/formMessages";
import TitleQuestion from "../Title/TitleQuestion";
import { getListOfErrorsMessage } from "../../utils/messageUtils";
import { isArray } from "lodash";

const AssistedDataForm = ({ data, adderFn }) => {
  const [fullnameLabel, setFullnameLabel] = useState(
    "Nome completo de registro*",
  );
  const [cpfLabel, setCpfLabel] = useState("CPF*");
  const [responsibleNameLabel, setResponsibleNameLabel] = useState(
    "Nome do responsável*",
  );
  const [contactNumLabel, setContactNumLabel] = useState(
    "Celular para contato*",
  );
  const [othersWorkActivities, setOthersWorkActivities] = useState(
    data?.hasAnothersWAct ? true : false,
  );
  const [othersGovernmentAids, setOthersGovernmentAids] = useState(
    data?.hasAnothersGAids ? true : false,
  );
  const [othersDiseases, setOthersDiseases] = useState(
    data?.hasAnothersDiseases ? true : false,
  );
  const [othersHobbies, setOthersHobbies] = useState(
    data?.hasAnothersHobbies ? true : false,
  );
  const [workActivitiesHasError, setWorkActivitiesHasError] = useState(false);
  const [submitWasClicked, setSubmitWasClicked] = useState(false);
  const [additionalListOfErrors, setAdditionalListOfErrors] = useState([]);
  const {
    anothersWAct,
    anothersGAids,
    graduationNameList,
    anotherWActValue,
    anotherGAidValue,
    pDisabilities,
    pDisabilityValue,
    anothersDiseases,
    anotherDiseaseValue,
    anothersHobbies,
    anotherHobbieValue,
    graduationNameValue,
    handleWActChange,
    handleGraduationValue,
    handleGAidChange,
    handlePDisabilityChange,
    handleDiseaseChange,
    handleHobbiesChange,
    addAnotherWAct,
    addAnotherGAid,
    addPDisability,
    addAnotherDisease,
    addAnotherHobbie,
    addAnotherGratuation,
    removeAnotherWAct,
    removeAnotherGAid,
    removePDisability,
    removeAnotherDisease,
    removeAnotherHobbie,
    removeGraduationName,
    resetAnothersWact,
    resetAnothersGAid,
    resetPDisability,
    resetAnothersDisease,
    resetAnothersHobbie,
    addExtraFields,
  } = useExtraFields();

  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    setValue,
    clearErrors,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(createAssistedSchema),
    defaultValues: data,
  });

  const socialNameValue = watch("socialName");
  const cpfValue = watch("cpf");
  const cpfStatusValue = watch("cpfStatus");
  const responsibleNameValue = watch("responsibleName");
  const dontApplyResponsibleNameValue = watch("dontApplyResponsibleName");
  const contactNumValue = watch("contactNum");
  const doesNotHaveContactNumValue = watch("doesNotHaveContactNum");
  const scholarityValue = watch("scholarity");
  const governmentAidValue =
    watch("governmentAid") === "true" || data?.hasAnothersGAids;
  const physicalDisabilityValue =
    watch("physicalDisability") === "true" || data?.hasPDisabilities;
  const chronicDiseaseValue =
    watch("chronicDisease") === "true" || data?.hasAnothersDiseases;
  const hasHobbiesValue =
    watch("hasHobbies") === "true" || data?.hasAnothersHobbies;
  const familyGroupInternetAccessValue =
    watch("familyGroup.internetAccess") === "true" ||
    data?.familyGroup?.internetAccess;
  const workActivities = watch("workActivities");

  useEffect(() => {
    socialNameValue
      ? setFullnameLabel("Nome social*")
      : setFullnameLabel("Nome completo de registro*");

    cpfStatusValue ? setCpfLabel("CPF") : setCpfLabel("CPF*");

    dontApplyResponsibleNameValue
      ? setResponsibleNameLabel("Nome do responsável")
      : setResponsibleNameLabel("Nome do responsável*");

    doesNotHaveContactNumValue
      ? setContactNumLabel("Celular para contato")
      : setContactNumLabel("Celular para contato*");

    if (cpfValue?.length) {
      resetField("cpfStatus");
    }

    if (responsibleNameValue?.length) {
      setValue("dontApplyResponsibleName", null);
    }

    if (contactNumValue?.length) {
      setValue("doesNotHaveContactNum", null);
    }

    if (!othersWorkActivities) {
      resetAnothersWact();
    }

    if (!othersGovernmentAids || !governmentAidValue) {
      resetAnothersGAid();
    }

    if (!physicalDisabilityValue) {
      resetPDisability();
    }

    if (!chronicDiseaseValue) {
      resetAnothersDisease();
    }

    if (!hasHobbiesValue) {
      resetAnothersHobbie();
    }
  }, [
    socialNameValue,
    setValue,
    resetField,
    cpfValue,
    cpfStatusValue,
    dontApplyResponsibleNameValue,
    responsibleNameValue,
    doesNotHaveContactNumValue,
    contactNumValue,
    othersWorkActivities,
    resetAnothersWact,
    othersGovernmentAids,
    resetAnothersGAid,
    resetPDisability,
    governmentAidValue,
    physicalDisabilityValue,
    chronicDiseaseValue,
    resetAnothersDisease,
    hasHobbiesValue,
    resetAnothersHobbie,
  ]);

  useEffect(() => {
    if (submitWasClicked) {
      validateCustomFields();
    }
  }, [othersWorkActivities, workActivities, anothersWAct]);

  const getAssistedData = (data) =>
    ((data) => {
      if (!data) return;

      adderFn({ data, formStage: "firstPart" });

      reset();
    })(addExtraFields(data, setError));

  const handleOnClickResetCPF = () => {
    clearErrors("cpf");
    setValue("cpf", "");
  };

  const handleClick = () => {
    setSubmitWasClicked(true);
    validateCustomFields();
  };

  const validateCustomFields = () => {
    const workActivitiesIsEmpty = isArray(workActivities)
      ? isEmpty(workActivities)
      : !workActivities;
    const nothingIsSelected =
      workActivitiesIsEmpty && othersWorkActivities === false;
    const otherIsCheckedButListIsEmpty =
      othersWorkActivities && isEmpty(anothersWAct);
    if (otherIsCheckedButListIsEmpty || nothingIsSelected) {
      setWorkActivitiesHasError(true);
      !additionalListOfErrors.includes("trabalho") &&
        setAdditionalListOfErrors([...additionalListOfErrors, "trabalho"]);
    } else {
      setWorkActivitiesHasError(false);
      const newList = additionalListOfErrors.filter(
        (item) => item !== "trabalho",
      );
      setAdditionalListOfErrors(newList);
    }
  };

  const onSubmitCustom = (data) => {
    if (!workActivitiesHasError) {
      getAssistedData(data);
    }
  };

  const showErrorsList = () => {
    return !isEmpty(errors) || !isEmpty(additionalListOfErrors);
  };

  return (
    <S.Wrapper onSubmit={handleSubmit(onSubmitCustom)} id="wrapper">
      <S.StyledSection id="dados-pessoais">
        <S.StyledTitle>Dados pessoais</S.StyledTitle>
        <div>
          <Input
            label={fullnameLabel}
            name="fullname"
            register={register}
            hasSomeValue={watch("fullname") !== ""}
            error={errors?.fullname?.message}
            description={formMessages.fullname.description}
          />
          <S.InputLabelWrapper>
            <input
              id="socialName"
              type="checkbox"
              {...register("socialName")}
            />
            <label htmlFor="socialName">
              Quero apenas informar o Nome Social
            </label>
          </S.InputLabelWrapper>
        </div>
        <div>
          <Controller
            control={control}
            name="cpf"
            render={({ field: { onChange, value } }) => (
              <InputMasked
                label={cpfLabel}
                mask="999.999.999-99"
                error={errors?.cpf?.message}
                value={value}
                currentValue={value}
                onChange={onChange}
                description={formMessages.cpf.description}
              />
            )}
          />
          <S.StyledItems>
            <S.InputLabelWrapper>
              <input
                type="radio"
                value="doesnt_have"
                id="cpf_doesnt_have"
                {...register("cpfStatus")}
                onClick={handleOnClickResetCPF}
              />
              <label htmlFor="cpf_doesnt_have">Não possui</label>
            </S.InputLabelWrapper>
            <S.InputLabelWrapper>
              <input
                type="radio"
                value="doesnt_inform"
                id="cpf_doesnt_inform"
                {...register("cpfStatus")}
                onClick={handleOnClickResetCPF}
              />
              <label htmlFor="cpf_doesnt_inform">Não quer informar</label>
            </S.InputLabelWrapper>
          </S.StyledItems>
          {errors?.cpfStatus && <span>{errors.cpfStatus.message}</span>}
        </div>
        <div>
          <Controller
            control={control}
            name="birthDate"
            render={({ field: { onChange, value, name } }) => (
              <InputMasked
                label="Data de nascimento*"
                value={value}
                currentValue={value}
                mask="99/99/9999"
                error={errors?.birthDate?.message}
                onChange={onChange}
                defaultValue={reverseDate(data.birthDate) || ""}
                description={formMessages.birthDate.description}
                name={name}
              />
            )}
          />
        </div>
        <div>
          <Input
            label={responsibleNameLabel}
            name="responsibleName"
            error={errors?.responsibleName?.message}
            register={register}
            description={formMessages.responsibleName.description}
            hasSomeValue={watch("responsibleName") !== ""}
          />
          <S.InputLabelWrapper>
            <input
              type="radio"
              id="dontApplyResponsibleName"
              {...register("dontApplyResponsibleName")}
              onClick={() => {
                clearErrors("responsibleName");
                setValue("responsibleName", "");
              }}
            />
            <label htmlFor="dontApplyResponsibleName">Não se aplica</label>
          </S.InputLabelWrapper>
        </div>
        <div>
          <Controller
            control={control}
            name="contactNum"
            render={({ field: { onChange, value, name } }) => (
              <InputMasked
                label={contactNumLabel}
                mask="(99) 99999-9999"
                error={errors?.contactNum?.message}
                value={value}
                currentValue={value}
                onChange={onChange}
                description={formMessages.contactNum.description}
                name={name}
              />
            )}
          />
          <S.InputLabelWrapper>
            <input
              type="radio"
              id="doesNotHaveContactNum"
              {...register("doesNotHaveContactNum")}
              onClick={() => {
                clearErrors("contactNum");
                setValue("contactNum", "");
              }}
            />
            <label htmlFor="doesNotHaveContactNum">Não possui</label>
          </S.InputLabelWrapper>
          {errors?.doesNotHaveContactNum && (
            <span>{errors.doesNotHaveContactNum.message}</span>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.skinColor}>Cor*</TitleQuestion>
          <S.InputCheckboxListWrapper>
            {skinColorsList.map(({ value, name }) => (
              <li key={value}>
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={value}
                    id={`color_${value}`}
                    {...register("skinColor")}
                  />
                  <label htmlFor={`color_${value}`}>{name}</label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.InputCheckboxListWrapper>
          {errors?.skinColor && (
            <Text.Error>{errors.skinColor.message}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.birthGender?.message}>
            Sexo de nascimento*
          </TitleQuestion>
          <S.InputCheckboxListWrapper columns={1}>
            {birthGenderList.map(({ value, name }) => (
              <li key={value}>
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={value}
                    id={`birth-gender_${value}`}
                    {...register("birthGender")}
                  />
                  <label htmlFor={`birth-gender_${value}`}>{name}</label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.InputCheckboxListWrapper>
          {errors?.birthGender && (
            <Text.Error>{errors?.birthGender?.message}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.gender?.message}>
            Gênero*
          </TitleQuestion>
          <S.InputCheckboxListWrapper>
            {genderList.map(({ value, name }) => (
              <li key={value}>
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={value}
                    id={`gender_${value}`}
                    {...register("gender")}
                  />
                  <label htmlFor={`gender_${value}`}>{name}</label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.InputCheckboxListWrapper>
          {errors?.gender && <Text.Error>{errors?.gender?.message}</Text.Error>}
        </div>
      </S.StyledSection>
      <S.StyledSection id="dados-socioeconomicos">
        <S.StyledTitle>Dados socioeconômicos</S.StyledTitle>
        <div>
          <TitleQuestion
            hasError={errors?.scholarity || errors?.graduationName}
          >
            Escolaridade*
          </TitleQuestion>
          <S.StyledList>
            {scholarityList.map(({ value, name }) => (
              <li key={value} className="list-item">
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={value}
                    id={`scholarity_${value}`}
                    {...register("scholarity")}
                    onClick={() => {
                      if (value !== "completed_college") {
                        setValue("graduationName", "");
                      }
                    }}
                    defaultChecked={graduationNameList.length ? true : false}
                  />
                  <label htmlFor={`scholarity_${value}`}>{name}</label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.StyledList>
          {scholarityValue === "completed_college" && (
            <S.AddContent>
              <InputList
                label="Qual curso superior o assistido é graduado?*"
                name="graduationName"
                value={graduationNameValue}
                onChangeFn={handleGraduationValue}
                adderFn={(event) => {
                  if (graduationNameList.length) {
                    return;
                  } else {
                    addAnotherGratuation(event);
                    setValue("graduationName", event);
                  }
                }}
                removerFn={(event) => {
                  removeGraduationName(event);

                  setValue("graduationName", undefined);
                }}
                list={graduationNameList}
              />
            </S.AddContent>
          )}
          {errors?.scholarity && (
            <Text.Error>{errors?.scholarity?.message}</Text.Error>
          )}
          {errors?.graduationName && (
            <Text.Error>{errors?.graduationName?.message}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.work?.message}>
            O assistido possui trabalho formal?*
          </TitleQuestion>
          <S.StyledList>
            {workList.map(({ value, name }) => (
              <li key={value} className="list-item">
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={value}
                    id={`work_${value}`}
                    {...register("work")}
                  />
                  <label htmlFor={`work_${value}`}>{name}</label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.StyledList>
          {errors?.work && <Text.Error>{errors?.work?.message}</Text.Error>}
        </div>
        <div>
          <TitleQuestion hasError={workActivitiesHasError}>
            Quais atividades desempenha, como trabalho, mesmo que não seja
            trabalho formal?*
          </TitleQuestion>
          <div>
            <S.StyledList>
              {workActivitiesList.map(({ name }) => (
                <li key={name} className="list-item">
                  <S.InputLabelWrapper>
                    <input
                      type="checkbox"
                      value={name}
                      id={`work-activities_${name}`}
                      defaultChecked={data?.workActivities?.some(
                        (v) => v.name === name,
                      )}
                      {...register("workActivities")}
                    />
                    <label htmlFor={`work-activities_${name}`}>{name}</label>
                  </S.InputLabelWrapper>
                </li>
              ))}
              <li>
                <S.InputLabelWrapper>
                  <input
                    type="checkbox"
                    defaultChecked={othersWorkActivities}
                    value={othersWorkActivities}
                    id="work-activities_other"
                    onChange={(e) => setOthersWorkActivities(e.target.checked)}
                  />
                  <label htmlFor="work-activities_other">Outras</label>
                </S.InputLabelWrapper>
              </li>
            </S.StyledList>
            {othersWorkActivities && (
              <S.StyledInputListWrapper>
                <InputList
                  label="Quais?"
                  name="anotherWorkActivity"
                  value={anotherWActValue}
                  onChangeFn={handleWActChange}
                  adderFn={addAnotherWAct}
                  removerFn={removeAnotherWAct}
                  list={anothersWAct}
                />
              </S.StyledInputListWrapper>
            )}
          </div>
          {workActivitiesHasError && (
            <Text.Error>{formMessages.general.errorMessage}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.governmentAid}>
            Recebe algum auxílio do governo?*
          </TitleQuestion>
          <div className="answer-wrapper">
            <S.StyledItems>
              <S.InputLabelWrapper>
                <input
                  type="radio"
                  defaultChecked={data?.governmentAid ? "on" : null}
                  value={true}
                  id="governmentAid_yes"
                  {...register("governmentAid")}
                />
                <label htmlFor="governmentAid_yes">Sim</label>
              </S.InputLabelWrapper>
              <S.InputLabelWrapper>
                <input
                  type="radio"
                  defaultChecked={data?.governmentAid === false ? "on" : null}
                  value={false}
                  id="governmentAid_no"
                  {...register("governmentAid")}
                />
                <label htmlFor="governmentAid_no">Não</label>
              </S.InputLabelWrapper>
            </S.StyledItems>
          </div>
          {governmentAidValue && (
            <S.StyledGovernmentAidWrapper>
              {governmentAidsList.map(({ name }) => (
                <S.InputLabelWrapper key={name}>
                  <input
                    type="checkbox"
                    value={name}
                    id={`governmentAids_${name}`}
                    defaultChecked={data?.governmentAids?.some(
                      (v) => v.name === name,
                    )}
                    {...register("governmentAids")}
                  />
                  <label htmlFor={`governmentAids_${name}`}>{name}</label>
                </S.InputLabelWrapper>
              ))}
            </S.StyledGovernmentAidWrapper>
          )}
          {governmentAidValue && (
            <S.InputLabelWrapper hasMarginTop>
              <input
                type="checkbox"
                value={othersGovernmentAids}
                id="governmentAids_other"
                defaultChecked={othersGovernmentAids}
                onChange={(e) => setOthersGovernmentAids(e.target.checked)}
              />
              <label htmlFor="governmentAids_other">Outros</label>
            </S.InputLabelWrapper>
          )}
          {othersGovernmentAids && governmentAidValue && (
            <S.StyledInputListWrapper>
              <InputList
                label="Quais?"
                name="anotherGovernmentAid"
                value={anotherGAidValue}
                onChangeFn={handleGAidChange}
                adderFn={addAnotherGAid}
                removerFn={removeAnotherGAid}
                list={anothersGAids}
              />
            </S.StyledInputListWrapper>
          )}
          {errors?.governmentAid && (
            <Text.Error>{errors?.governmentAid?.message}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.physicalDisability?.message}>
            Possui alguma deficiência física?*
          </TitleQuestion>
          <div>
            <S.StyledList>
              {physicalDisabilityList.map(({ value, name }) => (
                <li key={value} className="list-item">
                  <S.InputLabelWrapper>
                    <input
                      type="radio"
                      value={value}
                      id={`physicalDisability_${value}`}
                      {...register("physicalDisability")}
                    />
                    <label htmlFor={`physicalDisability_${value}`}>
                      {name}
                    </label>
                  </S.InputLabelWrapper>
                </li>
              ))}
            </S.StyledList>
          </div>
          {physicalDisabilityValue && (
            <InputList
              label="Quais?"
              name="physicalDisabilities"
              value={pDisabilityValue}
              onChangeFn={handlePDisabilityChange}
              adderFn={addPDisability}
              removerFn={removePDisability}
              list={pDisabilities}
            />
          )}
          {errors?.physicalDisability && (
            <Text.Error>{errors?.physicalDisability?.message}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion>Possui alguma doença crônica?</TitleQuestion>
          <div>
            <S.StyledList>
              {chronicDiseaseList.map(({ value, name }) => (
                <li key={value} className="list-item">
                  <S.InputLabelWrapper>
                    <input
                      type="radio"
                      value={value}
                      id={`chronicDisease_${value}`}
                      {...register("chronicDisease")}
                    />
                    <label htmlFor={`chronicDisease_${value}`}>{name}</label>
                  </S.InputLabelWrapper>
                </li>
              ))}
            </S.StyledList>
          </div>
          {chronicDiseaseValue && (
            <div>
              <label htmlFor="diseases">Quais?</label>
              {diseasesList.map(({ name }) => (
                <div key={name}>
                  <input
                    type="checkbox"
                    value={name}
                    defaultChecked={data?.diseases?.some(
                      (v) => v.name === name,
                    )}
                    {...register("diseases")}
                  />
                  <label htmlFor="diseases">{name}</label>
                </div>
              ))}
              <div>
                <input
                  type="checkbox"
                  value={othersDiseases}
                  defaultChecked={othersDiseases}
                  onChange={(e) => setOthersDiseases(e.target.checked)}
                />
                <label>Outras</label>
              </div>
              {othersDiseases && (
                <div>
                  <InputList
                    label="Quais?"
                    name="diseases"
                    value={anotherDiseaseValue}
                    onChangeFn={handleDiseaseChange}
                    adderFn={addAnotherDisease}
                    removerFn={removeAnotherDisease}
                    list={anothersDiseases}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <TitleQuestion>O assistido possui hobbies?</TitleQuestion>
          <div className="answer-wrapper">
            <S.StyledItems>
              <S.InputLabelWrapper>
                <input
                  type="radio"
                  value={true}
                  id="hasHobbies_yes"
                  defaultChecked={data?.hasHobbies ? "on" : null}
                  {...register("hasHobbies")}
                />
                <label htmlFor="hasHobbies_yes">Sim</label>
              </S.InputLabelWrapper>
              <S.InputLabelWrapper>
                <input
                  type="radio"
                  value={false}
                  id="hasHobbies_no"
                  defaultChecked={data?.hasHobbies === false ? "on" : null}
                  {...register("hasHobbies")}
                />
                <label htmlFor="hasHobbies_no">Não</label>
              </S.InputLabelWrapper>
            </S.StyledItems>
          </div>
          {hasHobbiesValue && (
            <>
              <S.StyledInputListWrapper>
                <label htmlFor="hobbies">Quais?</label>
                <S.StyledHobbiesWrapper>
                  {hobbiesList.map(({ name }) => (
                    <S.InputLabelWrapper key={name}>
                      <input
                        type="checkbox"
                        value={name}
                        id={`hobbies_${name}`}
                        defaultChecked={data?.hobbies?.some(
                          (v) => v.name === name,
                        )}
                        {...register("hobbies")}
                      />
                      <label htmlFor={`hobbies_${name}`}>{name}</label>
                    </S.InputLabelWrapper>
                  ))}
                </S.StyledHobbiesWrapper>
                <S.InputLabelWrapper hasMarginTop>
                  <input
                    type="checkbox"
                    defaultChecked={othersHobbies}
                    id="hobbies_other"
                    value={othersHobbies}
                    onChange={(e) => setOthersHobbies(e.target.checked)}
                  />
                  <label htmlFor="hobbies_other">Outros</label>
                </S.InputLabelWrapper>
              </S.StyledInputListWrapper>
              {othersHobbies && (
                <S.StyledInputListWrapper>
                  <InputList
                    label="Quais?"
                    name="anotherHobbies"
                    value={anotherHobbieValue}
                    onChangeFn={handleHobbiesChange}
                    adderFn={addAnotherHobbie}
                    removerFn={removeAnotherHobbie}
                    list={anothersHobbies}
                  />
                </S.StyledInputListWrapper>
              )}
            </>
          )}
        </div>
        <div>
          <TitleQuestion hasError={errors?.domesticViolence?.message}>
            O assistido já sofreu violência doméstica?
          </TitleQuestion>
          <div>
            <S.StyledList>
              {domesticViolenceList.map(({ value, name }) => (
                <li key={value} className="list-item">
                  <S.InputLabelWrapper>
                    <input
                      type="radio"
                      value={value}
                      id={`domesticViolence_${value}`}
                      {...register("domesticViolence")}
                    />
                    <label htmlFor={`domesticViolence_${value}`}>{name}</label>
                  </S.InputLabelWrapper>
                </li>
              ))}
            </S.StyledList>
          </div>
          {errors?.domesticViolence && (
            <Text.Error>{errors?.domesticViolence?.message}</Text.Error>
          )}
        </div>
      </S.StyledSection>
      <S.StyledSection id="informacoes-familia">
        <S.StyledTitle>Informações sobre a Família</S.StyledTitle>
        <div>
          <div>
            <TitleQuestion hasError={errors?.familyGroup?.internetAccess}>
              A família tem acesso à internet banda larga?*
            </TitleQuestion>
            <div className="answer-wrapper">
              <S.StyledItems>
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={true}
                    id="familyGroup.internetAccess_yes"
                    defaultChecked={
                      data?.familyGroup?.internetAccess ? "on" : null
                    }
                    {...register("familyGroup.internetAccess")}
                  />
                  <label htmlFor="familyGroup.internetAccess_yes">Sim</label>
                </S.InputLabelWrapper>
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={false}
                    id="familyGroup.internetAccess_no"
                    defaultChecked={
                      data?.familyGroup?.internetAccess === false ? "on" : null
                    }
                    {...register("familyGroup.internetAccess")}
                  />
                  <label htmlFor="familyGroup.internetAccess_no">Não</label>
                </S.InputLabelWrapper>
              </S.StyledItems>
            </div>
          </div>
          {familyGroupInternetAccessValue && (
            <>
              <S.StyledInternetAccess>
                <h4 className="title">
                  Quantas pessoas da família possuem celular com acesso à
                  internet?*
                </h4>
                <input
                  type="number"
                  defaultValue={0}
                  {...register("familyGroup.cellQty", {
                    valueAsNumber: true,
                  })}
                />
              </S.StyledInternetAccess>
              <S.StyledInternetAccess>
                <h4 className="title">
                  Quantos computadores ou notebooks a família possui?*
                </h4>
                <input
                  type="number"
                  defaultValue={0}
                  {...register("familyGroup.computerQty", {
                    valueAsNumber: true,
                  })}
                />
              </S.StyledInternetAccess>
            </>
          )}
          {errors?.familyGroup?.internetAccess && (
            <Text.Error>
              {errors?.familyGroup?.internetAccess?.message}
            </Text.Error>
          )}
          {errors?.familyGroup?.cellQty && (
            <Text.Error>{errors?.familyGroup?.cellQty?.message}</Text.Error>
          )}
          {errors?.familyGroup?.computerQty && (
            <Text.Error>{errors?.familyGroup?.computerQty?.message}</Text.Error>
          )}
        </div>
        <div>
          <TitleQuestion>
            Quais dos itens abaixo a família possui em casa?
          </TitleQuestion>
          <S.StyledList>
            {familyItemsList.map(({ registerName, name }) => (
              <li key={name} className="list-item">
                <S.InputLabelWrapper>
                  <input
                    type="checkbox"
                    id={registerName}
                    {...register(registerName)}
                  />
                  <label htmlFor={registerName}>{name}</label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.StyledList>
        </div>
        <div>
          <TitleQuestion hasError={errors?.familyGroup?.familyIncome}>
            Qual a renda total da família?*
          </TitleQuestion>
          <h5 className="question-subtitle">
            (esta renda deve ser desconsiderado o valor que recebe de auxílio)
          </h5>
          <S.StyledList>
            {familyIncomeList.map(({ value }) => (
              <li key={value} className="list-item">
                <S.InputLabelWrapper>
                  <input
                    type="radio"
                    value={value}
                    id={`familyGroup.familyIncome_${value}`}
                    {...register("familyGroup.familyIncome")}
                  />
                  <label htmlFor={`familyGroup.familyIncome_${value}`}>
                    {value}
                  </label>
                </S.InputLabelWrapper>
              </li>
            ))}
          </S.StyledList>
          {errors?.familyGroup?.familyIncome && (
            <Text.Error>
              {errors?.familyGroup?.familyIncome?.message}
            </Text.Error>
          )}
        </div>
      </S.StyledSection>
      <S.StyledButtonWrapper>
        <Btn
          text={"Próximo"}
          color={"#5484ED"}
          borderRadius={"5px"}
          marginBottom={"0"}
          onClick={handleClick}
        />
      </S.StyledButtonWrapper>
      {showErrorsList() && (
        <Text.Root>
          <Text.ErrorWithIcon>
            {getListOfErrorsMessage(errors, additionalListOfErrors)}
          </Text.ErrorWithIcon>
        </Text.Root>
      )}
    </S.Wrapper>
  );
};

export default AssistedDataForm;
