export const getYearOptions = () => {
  let currentYear = new Date().getFullYear();
  let years = [];

  for (let i = 5; i > 0; i--) {
    years.push({
      label: parseInt(currentYear - i),
      value: parseInt(currentYear - i),
    });
  }

  for (let i = 0; i < 5; i++) {
    years.push({
      label: parseInt(currentYear + i),
      value: parseInt(currentYear + i),
    });
  }

  return years;
};
