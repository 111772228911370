import React, { useState, useEffect } from 'react';
import { api } from '../../../modules/api';
import { useNavigate } from 'react-router-dom';

//Components
import Title from '../../../components/Title';
import Conclusion from '../../../components/CardConclusion';
import LayoutHomeDashboard from '../../../components/SideBarSettings';

import Btn from '../../../components/Buttons/button';
import NovoCriterio from '../../../components/NovoCriterio';
import ModalNewActivities from '../../../components/Modals/modalNewActivities';

//Styles
import * as S from './styles';
// Logo
import { RemoveDataForm, RemoveForm, TeamworkHome } from '../../../assets';
import { evaluationCriteriaList } from '../../../utils/lists';
import { useApi } from '../../../providers/Api';

const initialState = {
  name: '',
  initialDate: false,
  finalDate: false,
  isDeterminedTime: false,
  evaluationCriteria: [],
  newCriteria: [],
  selectCommunityOng: [],
  selectOng: [],
};

const RegisterNewActivities = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [newActivity, setNewActivity] = useState(initialState);
  const [counter, setCounter] = useState(10);
  const [allOngs, setAllOngs] = useState([]);

  const [activityInRegistration, setActivityInRegistration] = useState([]);
  const [savedActivity, setSavedActivity] = useState([]);

  const [isError, setIsError] = useState({
    name: undefined,
    initialDate: undefined,
    finalDate: undefined,
    evaluationCriteria: undefined,
    newCriteria: undefined,
    selectOng: undefined,
  });

  const { getMyUser, createActivity } = useApi();

  const handleRouter = () => {
    if (user?.role === 'Analista' || user?.role === 'Auxiliar') {
      return navigate('/gestao-de-atividades');
    }
  };

  useEffect(() => {
    handleRouter();
  }, [user?.role, navigate]);

  const handleGetOngs = async () => {
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('idToken')}`,
      },
    };

    await api
      .get('/ong/', headers)
      .then((res) => {
        const ong = res.data.map((i) => {
          if (i?.document_type === 'CNPJ') {
            return {
              id: i.id,
              name: i.trade_name,
            };
          } else {
            return {
              id: i.id,
              name: i.iniciative_name,
            };
          }
        });

        setAllOngs(ong);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetOngs();
    (async () => await getMyUser().then((res) => setUser(res)))();
  }, []);

  const handleChange = (ev) => {
    ev.preventDefault();

    const { name, value } = ev.target;

    setNewActivity({
      ...newActivity,
      [name]: value,
    });

    setIsError({
      ...isError,
      [name]: false,
    });
  };

  const handleEditChange = (ev, activityIndex) => {
    ev.preventDefault();
    const { name, value } = ev.target;

    const allActivityInRegistration = [...activityInRegistration];
    allActivityInRegistration[activityIndex][name] = value;

    setActivityInRegistration(allActivityInRegistration);
  };

  const handleChecked = (ev) => {
    const { name, checked } = ev.target;

    setNewActivity({
      ...newActivity,
      [name]: checked,
    });
  };

  const handleSelectOngs = (ev) => {
    const { name, id, checked, value } = ev.target;

    if (checked) {
      setNewActivity({
        ...newActivity,
        [name]: [...newActivity[name], { id, name: value }],
      });
    }
  };

  const handleCheckedList = (ev) => {
    const { id, name, checked } = ev.target;

    if (checked && !newActivity[name].includes(id)) {
      setNewActivity({
        ...newActivity,
        [name]: [...newActivity[name], id],
      });

      setIsError({
        ...isError,
        [name]: false,
      });
    } else if (!checked && newActivity[name].includes(id)) {
      setNewActivity({
        ...newActivity,
        [name]: newActivity[name].filter((item) => item !== id),
      });
    }
  };

  const handleChangeNewCriterion = (ev, idx) => {
    ev.preventDefault();

    const { name, value } = ev.target;

    const updateNewCriteria = newActivity.newCriteria;
    updateNewCriteria[idx] = value;

    setNewActivity({
      ...newActivity,
      [name]: updateNewCriteria,
    });

    setIsError({
      ...isError,
      newCriteria: false,
    });
  };

  const handleRemoveNewCriterion = (criterion) => {
    const removeCriterion = newActivity.newCriteria.filter(
      (i, idx) => idx !== criterion
    );

    setNewActivity({
      ...newActivity,
      newCriteria: removeCriterion,
    });
  };

  const handleCreateNewCriterion = () => {
    setNewActivity({
      ...newActivity,
      newCriteria: [...newActivity.newCriteria, ''],
    });
  };
  const handleSaveNewActivity = (ev) => {
    ev.preventDefault();

    const errorFinalDate =
      !newActivity.finalDate && !newActivity.isDeterminedTime;
    const errorNewCriteria =
      newActivity?.newCriteria.length > 0 &&
      !newActivity?.newCriteria?.filter((i) => i !== '').length;
    const errorSelectOng = !newActivity.selectOng.length && !!user?.is_admin;

    if (
      !newActivity.name ||
      !newActivity.initialDate ||
      errorFinalDate ||
      errorNewCriteria ||
      errorSelectOng
    ) {
      setIsError({
        name: !newActivity.name,
        initialDate: !newActivity.initialDate,
        finalDate: errorFinalDate,
        newCriteria: errorNewCriteria,
        selectOng: errorSelectOng,
      });
    } else {
      const newAtc = {
        ...newActivity,
        newCriteria: newActivity.newCriteria.filter((i) => i !== ''),
      };

      setActivityInRegistration([...activityInRegistration, newAtc]);
      setNewActivity(initialState);
    }
  };

  const handleDeleteActivity = (name) => {
    setActivityInRegistration(
      activityInRegistration.filter((i) => i.name !== name)
    );
  };

  const handleRemoveItem = (activityIndex, field, index) => {
    let removeItem = activityInRegistration[activityIndex][field].filter(
      (i, idx) => idx !== index
    );

    let updateActivity = [...activityInRegistration];
    updateActivity[activityIndex] = {
      ...activityInRegistration[activityIndex],
      [field]: removeItem,
    };

    setActivityInRegistration(updateActivity);
  };

  const handleSaveAllActivities = () => {
    activityInRegistration.forEach(async (activity) => {
      const ongs = user?.is_admin
        ? activity.selectCommunityOng
        : [{ id: user?.ong_id.id }];
      const evaluation_criteria = activity.evaluationCriteria.map((i) => ({
        evaluation: i,
      }));
      const new_Criteria = activity.newCriteria.map((i) => ({
        evaluation: i,
      }));
      const evaluation_criterias = [...evaluation_criteria, new_Criteria];
      const data = {
        ...activity,
        evaluationCriterias: evaluation_criterias.flat(1),
        ongs: ongs,
        beginingDate: activity.initialDate,
        finishDate: activity.finalDate || null,
      };
      setSavedActivity([...savedActivity, activity]);
      await createActivity(data);
    });
    setActivityInRegistration([]);
  };

  const renderNewActivity = () => (
    <>
      <S.Package>
        <S.Box>
          <S.Label>
            <S.Bold>Nome da atividade</S.Bold>
            <S.InputText
              type="text"
              placeholder="Atividade A"
              name="name"
              value={newActivity.name}
              onChange={handleChange}
              isError={isError.name}
            />
          </S.Label>
        </S.Box>
        <S.Box>
          <S.Label>
            <S.Bold>Duração da atividades</S.Bold>
          </S.Label>
          <S.Label>
            Início:
            <S.InputDate
              type="date"
              name="initialDate"
              value={newActivity.initialDate}
              onChange={handleChange}
              isError={isError.initialDate}
            />
          </S.Label>
          <S.Label margin>
            Final:
            <S.InputDate
              type="date"
              name="finalDate"
              value={newActivity.finalDate}
              onChange={handleChange}
              isError={isError.finalDate}
              disabled={newActivity.isDeterminedTime}
            />
          </S.Label>

          <S.LabelCheckbox paddingTop>
            <S.InputCheckbox
              type="checkbox"
              id={newActivity.isDeterminedTime.toString()}
              name="isDeterminedTime"
              onChange={handleChecked}
              checked={newActivity.isDeterminedTime}
            />
            Tempo indeterminado
          </S.LabelCheckbox>
        </S.Box>
        <S.Box>
          <S.Label>
            <S.Bold>Critério de avaliação</S.Bold>
          </S.Label>
          <S.LabelCheckbox>
            <S.InputCheckbox
              type="checkbox"
              placeholder=""
              id="nota"
              name="evaluationCriteria"
              checked={
                !!newActivity.evaluationCriteria.filter(
                  (freq) => freq === 'nota'
                ).length
              }
              onChange={handleCheckedList}
              isError={isError.evaluationCriteria}
            />
            Nota
          </S.LabelCheckbox>
          <S.LabelCheckbox>
            <S.InputCheckbox
              type="checkbox"
              placeholder=""
              id="frequencia"
              name="evaluationCriteria"
              checked={
                !!newActivity.evaluationCriteria.filter(
                  (freq) => freq === 'frequencia'
                ).length
              }
              onChange={handleCheckedList}
              isError={isError.evaluationCriteria}
            />
            Frequência
          </S.LabelCheckbox>
          <S.LabelCheckbox>
            <S.InputCheckbox
              type="checkbox"
              placeholder=""
              id="participacao"
              name="evaluationCriteria"
              checked={
                !!newActivity.evaluationCriteria.filter(
                  (freq) => freq === 'participacao'
                ).length
              }
              onChange={handleCheckedList}
              isError={isError.evaluationCriteria}
            />
            Participação
          </S.LabelCheckbox>
          {newActivity?.newCriteria.map((i, idx) => (
            <S.HoverRemove key={idx} marginTop={idx === 0}>
              <S.InputRemoveCriterion
                value={i}
                placeholder="Digite aqui"
                onChange={(ev) => handleChangeNewCriterion(ev, idx)}
                isError={isError.newCriteria}
              />
              <S.ImageRemoveData
                src={RemoveForm}
                onClick={() => handleRemoveNewCriterion(idx)}
              />
            </S.HoverRemove>
          ))}
          <S.NovoCriterioBox>
            <NovoCriterio handleClick={handleCreateNewCriterion} />
          </S.NovoCriterioBox>
        </S.Box>
        {!!user?.is_admin && (
          <S.Box>
            <S.FontSize>
              <S.Label>
                <S.Bold>Associar ONGs ao projeto</S.Bold>
              </S.Label>
              <S.Label>
                <S.Bold>Comunidade</S.Bold>
              </S.Label>
              <S.Label>
                <ModalNewActivities
                  title="Selecionar ONGs"
                  label={allOngs}
                  bgTitle="#5484ED"
                  paddingTop="0.5rem"
                  paddingTopModal="0"
                  selectOng={newActivity?.selectOng}
                  handleSelectOng={handleSelectOngs}
                />

                {isError.selectOng && (
                  <S.TextError>Selecione uma ONG</S.TextError>
                )}
              </S.Label>
              {!!newActivity?.selectOng?.length && (
                <>
                  <S.Label>
                    <S.Bold>Selecionar ONGs</S.Bold>
                  </S.Label>
                  {newActivity?.selectOng.map((i, idx) => {
                    return (
                      <S.LabelCheckbox key={idx}>
                        <S.InputCheckbox
                          type="checkbox"
                          id="rocinha10"
                          name="selectCommunityOng"
                          onChange={handleCheckedList}
                          isError={isError.selectCommunityOng}
                          checked
                        />
                        {i.name}
                      </S.LabelCheckbox>
                    );
                  })}
                </>
              )}
            </S.FontSize>
          </S.Box>
        )}
      </S.Package>
      <S.BoxBtnAdd>
        <Btn text="Adicionar" onClick={handleSaveNewActivity} />
      </S.BoxBtnAdd>
    </>
  );

  const renderActivityInRegistration = () => (
    <>
      <S.BoxTitle>
        <Title
          form
          displayImage="none"
          subtitle="Atividade em cadastramento"
          paddingMob="1.5rem"
          mobWidth="18rem"
          widthSub="90%"
        />
        <S.P>
          Todas as atividades cadastradas aqui serão direcioandas para área de
          Gestão de Atividades.
        </S.P>
      </S.BoxTitle>
      {activityInRegistration?.map((i, activityIndex) => (
        <S.FormRemove key={`atividade-${activityIndex}`}>
          <S.ContentFormRemove>
            <S.Box>
              <S.Label>
                <S.Bold>Nome da atividade:</S.Bold>
              </S.Label>
              <S.Label>
                <S.ParagRemoveText>{i.name}</S.ParagRemoveText>
              </S.Label>
            </S.Box>

            <S.Box>
              <S.Label>
                <S.Bold>Duração da atividade</S.Bold>
              </S.Label>
              <S.Label>
                Início:
                <S.HoverEdit>
                  <S.InputDate
                    type="date"
                    placeholder="MM/AAAA"
                    data-mask="99/99/9999"
                    name="initialDate"
                    value={i.initialDate}
                    onChange={(ev) => handleEditChange(ev, activityIndex)}
                    isError={isError.initialDate}
                    colorful
                  />
                </S.HoverEdit>
                <S.ImageRemoveData src={RemoveDataForm} />
              </S.Label>
              {!i.isDeterminedTime && (
                <S.Label margin>
                  Final:
                  <S.HoverEdit>
                    <S.InputDate
                      type="date"
                      placeholder="MM/AAAA"
                      name="finalDate"
                      value={i.finalDate}
                      onChange={(ev) => handleEditChange(ev, activityIndex)}
                      isError={isError.finalDate}
                      colorful
                    />
                    <S.ImageRemoveData src={RemoveDataForm} />
                  </S.HoverEdit>
                </S.Label>
              )}
              {i.isDeterminedTime && (
                <S.LabelCheckbox paddingTop>
                  <S.InputCheckbox
                    type="checkbox"
                    id={newActivity.isDeterminedTime.toString()}
                    name="isDeterminedTime"
                    onChange={handleChecked}
                    checked
                  />
                  Tempo indeterminado
                </S.LabelCheckbox>
              )}
            </S.Box>
            <S.Box>
              <S.Label>
                <S.Bold>Critério de avaliação</S.Bold>
              </S.Label>
              {evaluationCriteriaList.map((crit, critIndex) => (
                <S.Label key={critIndex}>
                  <S.InputCheckbox
                    type="checkbox"
                    checked={
                      i?.evaluationCriteria.filter((i) => i === crit.name)[0]
                    }
                    disabled
                  />
                  {crit.item}
                </S.Label>
              ))}
              {i?.newCriteria?.map((criteria, criterionIdx) => (
                <S.HoverRemove key={criterionIdx}>
                  <S.ParagRemoveCriterion>{criteria}</S.ParagRemoveCriterion>
                  <S.ImageRemoveData
                    src={RemoveForm}
                    onClick={() =>
                      handleRemoveItem(
                        activityIndex,
                        'newCriteria',
                        criterionIdx
                      )
                    }
                  />
                </S.HoverRemove>
              ))}
            </S.Box>
            {!!i.selectOng.length && (
              <S.Box paddingBottom>
                <S.Label>
                  <S.Bold>ONGs associadas</S.Bold>
                </S.Label>
                <S.ParagraphOng>
                  Todas as atividades cadastradas aqui serão direcionadas para
                  área de Gestão de Atividades.
                </S.ParagraphOng>
                {i.selectOng.map((ong, ongIndex) => (
                  <S.HoverRemove key={ongIndex}>
                    <S.ParagRemoveOng>{ong.name}</S.ParagRemoveOng>
                    {i.selectOng.length > 1 && (
                      <S.ImageRemoveData
                        src={RemoveForm}
                        onClick={() =>
                          handleRemoveItem(activityIndex, 'selectOng', ongIndex)
                        }
                      />
                    )}
                  </S.HoverRemove>
                ))}
              </S.Box>
            )}
            <S.BoxButtons>
              <S.ButtonRemove onClick={() => handleDeleteActivity(i.name)}>
                Excluir
              </S.ButtonRemove>
            </S.BoxButtons>
          </S.ContentFormRemove>
        </S.FormRemove>
      ))}
    </>
  );

  return (
    <LayoutHomeDashboard
      seo="/ Cadastro de novas atividades"
      screen="registrationOfNewActivities"
    >
      <S.Container onSubmit={(ev) => ev.preventDefault()}>
        <S.BoxTitle>
          <Title
            form="true"
            icon={TeamworkHome}
            subtitle="Nova atividade"
            paddingMob="1.5rem"
            mobWidth="18rem"
            widthSub="90%"
            paddingLeftDesk="1.016rem"
            paddingLeft="1rem"
          >
            Cadastro<div>de novas atividades</div>
          </Title>
        </S.BoxTitle>
        <S.Content>
          <S.FormAdd>{renderNewActivity()}</S.FormAdd>

          {!!activityInRegistration.length && renderActivityInRegistration()}

          {!!savedActivity &&
            savedActivity.map((i, idx) => (
              <Conclusion key={idx} activityData={i} counter={counter} />
            ))}

          {activityInRegistration.length > 0 && (
            <S.BoxBtn>
              <S.Line />
              <Btn
                text="Salvar todos"
                color="#FF8F00"
                width="12rem"
                marginBottom="0"
                onClick={handleSaveAllActivities}
              />
            </S.BoxBtn>
          )}
        </S.Content>
      </S.Container>
    </LayoutHomeDashboard>
  );
};

export default RegisterNewActivities;
