import styled from 'styled-components';
import { colors } from '../../../styles/Theme';

export const Container = styled.button`
  display:flex;
  cursor: ${props => props.disabled && 'pointer'};
`;

export const P = styled.p`
   font-weight:bold ;
   font-size:15px;
   line-height:19.1px;
   color:#FFFFFF;
   margin-left:3rem;
   margin-top:1.1rem;
   white-space: nowrap;

   :hover {
      border-bottom: ${props => props.disabled && `1px solid ${colors.white}`};
   }
`;

export const Icon = styled.img`
  margin-top:1rem;
`;

export const Check = styled.img`
  margin-left: 1rem;
  margin-top: 1rem;
  width: 20px;
  height: 20px;
`;
