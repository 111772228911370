/** @format */

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
`;

export const BoxLogo = styled.div`
  display: flex;
  padding: 4rem 0 3rem 8rem;

  img {
    width: 13rem;
  }

  @media (min-width: 1920px) and (max-height: 1080px) {
    padding: 5rem 0 4rem 8rem;

    img {
      width: 20rem;
    }
  }

  @media (max-width: 1722px) {
    padding: 3rem 0 1rem 8rem;

  }

  @media (max-width: 1440px) {
    padding: 2rem 0 1rem 5rem;

    img {
      width: 10rem;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const ContentInfo = styled.div`
  width: 75%;
  height: 100%;
  padding-left: 8rem;
  h1 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 3.75rem;
    line-height: 3.75rem;
  }
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    padding-top: 2rem;
  }

  @media (min-width: 1920px) and (max-height: 1080px) {
    padding-top: 3rem;
    width: 65%;
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }

  @media (max-width: 1680px) {
    width: 75%;
    padding-top: 2.5rem;
  }

  @media (max-width: 1617px) {
    width: 75%;
  }

  @media (max-width: 1440px) {
    width: 70%;
    padding-top: 3rem;
    padding-left: 5rem;

    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media (max-width: 1200px) {
      p {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100vh;
    padding-left: 0;
    padding: 1rem;
  }
`;
export const BackgroundLeft = styled.img`
  position: absolute;
  left: 0;
  width: 90px;
  height: 134px;

  @media (max-width: 1440px) {
    width: 60px;
    height: 90px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const BoxBtn = styled.div`
  padding-top: 5rem;
  button {
    padding: 2rem;
    height: 5.625rem;
    background: #5483ea;
    border-radius: 56px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    display: flex;
    align-items: center;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    color: #ffffff;
  }

  @media (max-width: 1440px) {
    padding-top: 2rem;

    button {
      padding: 1rem;
      height: 4rem;
      font-size: 1.5rem;
    }
  }

  @media (max-width: 1200px) {
    padding-top: 1.5rem;
    button {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50vh;
    button {
      font-size: 1rem;
      font-weight: 600;
      padding: 1rem;
      text-transform: inherit;
      letter-spacing: 0;
      height: 2rem;
    }
  }
`;
// ......................BACKGROUNS............................ //

export const BoxBackgrounds = styled.section`

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContentImgs = styled.section`
  img {
    margin-top: 2rem;
    width: 390px;
    height: 340px;
    position: absolute;
    right: 32rem;
  }

  @media (min-width: 1920px) and (max-height: 1080px) {
    img {
      width: 500px;
      height: 450px;
    }
  }

  @media (max-width: 1722px) {
    img {
      width: 350px;
      height: 300px;
      right: 25rem;
    }
  }

  @media (max-width: 1440px) {
    img {
      width: 290px;
      height: 250px;
      right: 23rem;
      margin-top: 1.8rem;
    }
  }

  @media (max-width: 1200px) {
    img {
      width: 250px;
      right: 18rem;
    }
  }
`;

export const ContentBasket = styled.section`
  padding-right: 5rem;
  padding-top: 15rem;
  img {
    width: 335px;
    height: 325px;
    border-radius: 100px 100px 100px 162px;
  }

  @media (min-width: 1920px) and (max-height: 1080px) {
    padding-top: 22rem;
    img {
      width: 350px;
      height: 350px;
    }
  }

  @media (max-width: 1680px) {
    img {
      width: 300px;
      height: 300px;
    }
  }

  @media (max-width: 1440px) {
    padding-right: 2rem;

    img {
      width: 250px;
      height: 250px;
    }
  }

  @media (max-width: 1200px) {
    padding-top: 9rem;
    img {
      width: 220px;
      height: 220px;
    }
  }
`;

export const ContentChild = styled.section`
  img {
    width: 340px;
    height: 300px;
    position: absolute;
    right: 27rem;
    bottom: 1.5rem;
    z-index: 9999;
    border-radius: 116px 162px 116px 116px;
  }

  @media (max-width: 1680px) {
    img {
      width: 300px;
      height: 250px;
      right: 25rem;
    }
  }

  @media (max-width: 1440px) {
    img {
      width: 250px;
      height: 200px;
      right: 20rem;
    }
  }

  @media (max-width: 1200px) {
    img {
      width: 220px;
      height: 180px;
      right: 18rem;
    }
  }
`;

export const BackgroundRight = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;

  width: 150px;

  @media (max-width: 1200px) {
    width: 120px;
  }
`;

export const Elipse = styled.img`
  width: 330px;
  position: absolute;
  right: 28rem;
  bottom: 0;

  @media (max-width: 1680px) {
    right: 25rem;
  }

  @media (max-width: 1440px) {
    width: 300px;
    right: 20rem;
  }

  @media (max-width: 1200px) {
    width: 250px;
    right: 18rem;
  }
`;
