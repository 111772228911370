import React from 'react';

import Logo from "../../assets/icons/logo.svg";

import * as S from './styles';

const Animation = () => {
   return (
    <S.ContainerAnimation>
      <S.Blue></S.Blue>
      <S.ImageAnimation src={Logo} alt="Logo Vision" />
      <S.Green></S.Green>
    </S.ContainerAnimation>
  );
};

export default Animation;
