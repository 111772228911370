import styled from "styled-components/macro";
// import { colors } from "../../styles/Theme";

export const Container = styled.div`
  /* display: flex; */
  /* flex-direction: column; */

  @media (min-width: 1024px) {
    margin-left: ${(props) => props.marginLeft || props.marginLeft};
  }
`;

export const ContentAdd = styled.div`
  display: flex;
  padding-bottom: 1rem;

  input {
    /* width: ${(props) => (props.widthLabel ? "80%" : "100%")}; */
    width: 13.125rem;
  }
`;

export const Title = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 1rem 0 1rem 0;
  width: 20rem;
  @media (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: 1.563rem;
  }
  @media (min-width: 1600px) {
    width: 27rem;
  }
`;

export const IconAdd = styled.img`
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  width: 23px;
`;
