import formMessages from "../../utils/formMessages";
import { Wrapper } from "./styles";
import parse from "html-react-parser";

const Input = ({
  label,
  name,
  register,
  type = "text",
  description = "",
  hasSomeValue,
  error,
  fullWidth = false,
  ...rest
}) => {
  return (
    <Wrapper
      isBorderColorRed={error && error !== formMessages.general.errorMessage}
      fullWidth={fullWidth}
    >
      <input
        id={name}
        type={type}
        className={`inputElement`}
        {...register(name)}
        {...rest}
      />
      <label
        htmlFor={name}
        className={`labelElement ${hasSomeValue ? "non-empty" : ""} ${
          error ? "error" : ""
        }`}
      >
        {label}
      </label>
      {!!error ? (
        <span className="error">{error}</span>
      ) : (
        <span className="description">{parse(description)}</span>
      )}
    </Wrapper>
  );
};

export default Input;
