import styled from "styled-components/macro";

import { colors } from '../../styles/Theme';

export const ContainerAnimation = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @keyframes go-top {
    0%, 10% {
      bottom: 50%;
    }
    30% {
       bottom: 61%;
    }
    50% {
      bottom: 50%;
    }
    100% {
      bottom: 65%;
    }
  }
  
  @keyframes go-bottom {
    0%, 10% {
      top: 50%;
    }
    30% {
      top: 61%;
    }
    50% {
      top: 50%;
    }
    100% {
      top: 65%;
    }
  }
`;

export const Blue = styled.div`
  background: #0080FF;
  width: 100rem;
  height: 40rem;
  transform: rotate(-30deg) translate(-6%,7%);
  position: fixed;
  top: 50%;
  animation: go-bottom 4s;
`;

export const ImageAnimation = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6rem;
  z-index: -1;
`;

export const Green = styled.div`
  background: ${colors.darkGreen};
  width: 100rem;
  height: 40rem;
  transform: rotate(-30deg) translate(-6%,-6%);
  position: fixed;
  bottom: 50%;
  animation: go-top 4s;
`;