import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import * as S from "./styles";

// modules
import { api } from "../../../modules/api";

// Logo
import logo from "../../../assets/logoDesktop.png";

// icon
import { IconLogout } from "../../../assets";
export default function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    if (localStorage.getItem("idToken")) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("idToken")}`,
        },
      };
      const response = await api.get("/user/me/", config);
      setUser(response.data[0].first_name);
    }
  };

  const Logout = () => {
    localStorage.removeItem("idToken");
    navigate("/");
  };

  if (localStorage.getItem("idToken") === null) {
    return (
      <S.Header>
        <S.Logo src={logo} alt="Logo" onClick={() => navigate("/")} />
      </S.Header>
    );
  } else {
    return (
      <S.Header>
        <S.Logo src={logo} alt="Logo" onClick={() => navigate("/")} />

        <S.BoxContent>
          <S.BoxProfile>
            <S.Profile>Olá, {user}</S.Profile>
          </S.BoxProfile>

          <S.BoxLogout onClick={Logout}>
            <S.BtnLogout>
              <S.ImgLogout src={IconLogout} alt="Logout" />
              Sair
            </S.BtnLogout>
          </S.BoxLogout>
        </S.BoxContent>
      </S.Header>
    );
  }
}
