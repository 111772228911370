import React from 'react';
import { EllipseTransition } from '../../../assets'
import { BackgroundGreen } from '../../../assets';


import * as S from "./styles";

const FooterGreen = () => {
  return (
    <>
      <S.BackgroundGreen src={BackgroundGreen} alt='Imagem verde' />
      <S.BoxEllipse>
        <S.ImgBackground src={EllipseTransition} alt='Elipse'/>
      </S.BoxEllipse></>
  );
};

export default FooterGreen;
