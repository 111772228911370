import styled from "styled-components/macro";

export const Dropdown = styled.div`
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  height: 180px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #d9d9d9;
  }
  ::-webkit-scrollbar-thumb {
    background: #707070;
    width: 3.64px;
  }

  @media (min-width: 1024px) {
    margin-left: 2.5rem;
  }
`;
export const Person = styled.div`
  display: flex;
  /* flex-direction: column; */
  padding-top: 0.5rem;
  height: 35px;

  display: flex;
  align-items: center;
  input {
    margin-bottom: 0.5rem;
    width: 15px;
  }

  label {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
  }
`;
export const LineGrey = styled.div`
  width: 345px;
  height: 2px;
  background-color: #d9d9d9;
`;

export const Loader = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    border: ${({ borderWidth }) => borderWidth + "px"} solid blue;
    border-top: ${({ borderWidth }) => borderWidth + "px"} solid #0000ff80;
    border-radius: 50%;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    animation: spin 0.5s linear infinite;
    align-self: center;
  }
`;
