import styled from "styled-components/macro";

import { colors } from "../../styles/Theme";

export const BoxLabelLine = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.div`
  line-height: 1.375rem;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  padding-right: 0.25rem;
  font-weight: ${(props) => (props.bold ? props.bold : "600")};
  color: ${colors.black};
  white-space: nowrap;

  @media (min-width: 1024px) {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 49px;
  }
`;

export const LineTwo = styled.div`
  width: inherit;
  height: 0;
  border: 1px solid #00000040;
`;
