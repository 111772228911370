import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Btn from '../../../../components/Buttons/button/index'
import BtnBackWhite from '../../../../components/Buttons/backWhite/index'
import TitleTransitionTasks from '../../../../components/Title/TitleTransitionTasks'

//Images
import {
  ViOneHand,
  fileIcon,
  handLove,
  carIcon,
  paperIcon
} from '../../../../assets'

import * as S from './styles'

const Transition = ({ descktop, setInTransition }) => {
  const [largura, setlargura] = useState(window.innerWidth)
  const navigate = useNavigate()


  const localstorage = localStorage.getItem('@Vision/Permission')
  const parseLocalStorage = localstorage && JSON.parse(localstorage)
  const lastItem = parseLocalStorage
    ? parseLocalStorage[parseLocalStorage.length - 1]
    : false

  const list = [
    {
      icon: fileIcon,
      text: 'Contatos e dados oficiais',
      title:
        'A primeira seção são as informações de contato e oficiais. Vamos lá!',
      name: 'officialContactsAndData',
      state: 'toDo',
      route: '/cadastro/contatos-e-dados-oficiais'
    },
    {
      icon: handLove,
      text: 'Atuação e assistidos',
      title:
        'Bom trabalho ! Agora conta pra gente suas áreas de atuação e o perfil dos seus assistidos. Vamos lá!',
      name: 'actingAssisted',
      state: 'toDo',
      route: '/cadastro/atuacao-e-assistidos'
    },
    {
      icon: carIcon,
      text: 'Estrutura física e funcional',
      title:
        'Isso aí! Agora é o momento de informar os dados sobre estrutura física e funcional de sua ONG.',
      name: 'physicalAndFunctionalStructure',
      state: 'toDo',
      isFinished: false,
      route: '/cadastro/estrutura-fisica-e-funcional'
    },
    {
      icon: paperIcon,
      text: 'Envio de documentos',
      title:
        'Chegamos na última etapa! Nesta seção vamos solicitar alguns documentos oficiais.',
      name: 'sendingDocuments',
      state: 'toDo',
      isFinished: false,
      route: '/cadastro/envio-de-documentos'
    }
  ]

  useEffect(() => {
    const handleResize = () => {
      setlargura(window.innerWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [largura])

  const handleOpenForm = () => {
    lastItem === false || lastItem === 0
      ? navigate('/cadastro/contatos-e-dados-oficiais')
      : lastItem === 4
      ? navigate('/cadastro/envio-de-documentos')
      : navigate(list[lastItem].route)
  }

  return (
    <S.ContainerTransition descktop={descktop}>
      <S.Content largura={largura}>
        <S.IllustrationContainer>
          <S.ImgIllustration descktop src={ViOneHand} />
          <S.IllustrationText>
            {lastItem === false
              ? 'A primeira seção são as informações de contato e oficiais. Vamos lá!'
              : list[lastItem]?.title}
          </S.IllustrationText>
        </S.IllustrationContainer>
        <S.ContentFooter>
          <S.ImgIllustration mobile src={ViOneHand} />
          <S.BoxTransitionTasks>
            {list?.map((i, index) => {
              return (
                <TitleTransitionTasks
                  key={index}
                  index={index + 1}
                  icon={i.icon}
                  text={i.text}
                  route={i.route}
                />
              )
            })}
          </S.BoxTransitionTasks>
          {largura < 1024 && (
            <S.Footer>
              <Btn text="Abrir formulário" onClick={handleOpenForm} />
              <BtnBackWhite
                text="Voltar"
                onClick={() => setInTransition(false)}
              />
            </S.Footer>
          )}
        </S.ContentFooter>
      </S.Content>
    </S.ContainerTransition>
  )
}

export default Transition
