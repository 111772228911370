import styled from "styled-components";

import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  @media (min-width: 1024px) {
    margin-bottom: 3.4rem;
    display: flex;
    flex-direction: ${(props) => props.noInput && "column"};
    flex-wrap: wrap;

    div {
      margin: ${(props) => props.noInput && "0"};
    }
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    margin-top: ${(props) => props.noMarginTop || "3.4rem"};
    display: flex;
    justify-content: space-between;
    align-self: ${(props) => props.noAlignSelf || "self-end"};
    width: 33rem;
  }
`;
export const BoxInputRadio = styled.div`
  padding-bottom: ${(props) => props.paddingBottom};
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Titles = styled.h1`
  margin-top: 1rem;
  font-size: 1.125rem;
  width: 20rem;
  font-family: "Open Sans";
  color: ${colors.black};

  @media (min-width: 1024px) {
    width: 17rem;
  }
`;

export const ContentInput = styled.div`
  display: flex;
  padding-top: 0.5rem;
  width: 50vw;
  justify-content: space-between;
`;

export const BoxTextInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  label {
    padding-bottom: 0;
  }

  input {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.125rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    margin-right: 5rem;
  }
`;

export const BoxCollaborators = styled.div`
  display: grid;
  position: relative;
`;

export const BoxInput = styled.div`
  margin-top: -0.7rem;
  padding-left: 1rem;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const ContentTitle = styled.div`
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Title = styled.h3`
  margin-bottom: 0.625rem;
  width: ${(props) => props.width && "11rem"};
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.231rem;
  color: ${colors.black};
  align-items: center;
  white-space: nowrap;

  @media (min-width: 1024px) {
    width: initial;
    font-size: 1.125rem;
    line-height: 1.563rem;
  }
`;

export const HR = styled.hr`
  @media (min-width: 1024px) {
    margin-left: 0.75rem;
    width: 100%;
    border: 1px solid ${colors.black03};
    height: fit-content;
  }
`;
