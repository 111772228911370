import React from "react";
import * as S from "./styles";

//Component
import Input from "../Inputs";

//Icons
import { IconDelete } from "../../assets";

const AddItem = ({
  name,
  nameADD,
  value,
  handleChangeADD,
  handleAddItem,
  handleDeleteItem,
  list,
  hasIcon,
  boxBreak,
  positionIcon,
  noPadding,
  isError,
  handleNewItemChange,
}) => {
  const renderNewInput = () => {
    return list?.map((item, index) => (
      <S.ContentAdd
        noPadding={noPadding}
        boxBreak={boxBreak}
        idx={index}
        key={index}
      >
        <S.Input>{item}</S.Input>
        <S.IconAdd
          src={IconDelete}
          alt="Deletar"
          boxBreak={boxBreak}
          onClick={() => handleDeleteItem(name, nameADD, item)}
        />
      </S.ContentAdd>
    ));
  };

  return (
    <S.Container boxBreak={boxBreak}>
      <S.ContentAdd>
        <Input
          sPaddingBottom
          width="auto"
          label="Quais ?"
          paddingBottom
          place="Digite"
          name={name}
          value={value}
          onChange={handleNewItemChange}
          noRequired={true}
          error={isError}
        />
        <S.Add alt="Adicionar" onClick={() => handleAddItem(value, name)}>
          Adicionar
        </S.Add>
      </S.ContentAdd>
      {renderNewInput()}
    </S.Container>
  );
};

export default AddItem;
