import styled from "styled-components";

export const StyledText = styled.p`
  color: #b71c1c;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &::before {
    display: inline;
    content: "⚠ ";
    font-weight: 500;
  }
`;
