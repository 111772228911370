import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`;

export const ContentSelect = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  display: flex;
  width: 100%;
  align-items: self-end;

  @media (min-width: 1024px) {
    width: ${(props) => props.widthDesk};
  }
`;

export const Label = styled.label`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.188rem;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;

export const Select = styled.p`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2.199rem;
  background: #c5c5c5;
  border-radius: 5px;
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.563rem;
  text-transform: uppercase;
  align-items: center;

  @media (min-width: 1024px) {
    margin-top: 3px;
  }
`;
