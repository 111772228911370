import styled from "styled-components/macro";
// import { colors } from "../../../styles/Theme";

export const BoxModal = styled.div`
  padding: 1rem 0 1rem 0;
  margin-left: 0;
  max-width: 20.25rem;

  @media (min-width: 1024px) {
    padding-top: ${(props) => props.paddingTopModal || "1.6rem"};
    max-width: 18.25rem;
  }

  @media (min-width: 1500px) {
    min-width: 19.25rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border-bottom: solid 2px #d9d9d9;
  border-left: solid 2px #d9d9d9;
  border-right: solid 2px #d9d9d9;

  label {
    font-family: "Open Sans";
    font-style: normal;
    padding-left: 1.375rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0;
    border-radius: 4px 4px 4px 4px;
  }

  @media (min-width: 1024px) {
  }
`;

export const LabelTitle = styled.label`
  justify-content: space-between;
  background: ${(props) => props.bgTitle};
  border-radius: 5px;
  width: 100%;
  font-weight: 700;
  font-size: 1rem;
  color: #f5f5f5;
  margin-top: 0;
  height: 1.875rem;
  font-family: "Open Sans";
  font-style: normal;
  padding-left: 1.375rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px 4px 4px 4px;

  @media (min-width: 1024px) {
  }
`;

export const Image = styled.img`
  padding-right: 1rem;

  @media (min-width: 1024px) {
    margin-left: 5rem;
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  background: #ffffff;
  display: block;
  position: relative;
  min-height: 2.5rem;

  :hover {
    color: #5484ed;
    cursor: pointer;
  }
`;

export const Input = styled.input`
  background-color: #d9d9d9;
  margin-right: 0.685rem;
  color: #d9d9d9;
  border: none;
  cursor: pointer;
`;

export const Line = styled.span`
  display: ${(props) => props.displayNone && "none"};
  height: 1px;
  width: 16.375rem;
  background: #d9d9d9;
`;
