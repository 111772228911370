import React, { useEffect } from "react";
import AddItem from "../../AddItem";

import * as S from "./styles";

const InputCheckbox = ({
  width,
  widthTablet,
  widthDesk,
  height,
  label,
  name,
  id,
  onChange,
  checked,
  marginright,
  onClick,
  nameADD,
  value,
  onChecked,
  valueAdd,
  handleChangeADD,
  handleAddItem,
  selfEnd,
  handleDeleteItem,
  list,
  hasIcon,
  wrap,
  isError,
  alignItems,
  fontWeight,
  lineHeight,
  ...rest
}) => {
  useEffect(() => {
    if (onChange && checked) {
      onChange();
    }
  }, [checked]);
  return (
    <>
      <S.ContentLabel
        selfEnd={selfEnd}
        widthTablet={widthTablet}
        widthDesk={widthDesk}
        width={width}
      >
        <S.Label
          alignItems={alignItems}
          marginright={marginright}
          wrap={wrap}
          isError={isError}
          fontWeight={fontWeight}
          lineHeight={lineHeight}
        >
          <S.Input
            onClick={onClick}
            id={id}
            type="checkbox"
            name={name}
            onChange={onChange}
            value={value}
            checked={checked}
            isError={isError}
            {...rest}
          />
          {label}
        </S.Label>
      </S.ContentLabel>
      {value === "Outros" && (
        <AddItem
          name={name}
          nameADD={nameADD}
          value={valueAdd}
          handleChangeADD={handleChangeADD}
          handleAddItem={() => handleAddItem(name, nameADD)}
          handleDeleteItem={handleDeleteItem}
          list={list}
          hasIcon={hasIcon}
        />
      )}
    </>
  );
};

export default InputCheckbox;
