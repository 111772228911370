import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  position: fixed;
  z-index: 999999999999;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.3s;
  overflow: hidden;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 300px;
  padding: 10px 10px;
  border-radius: 6px;
  background-color: #ffffff;
  z-index: 99999999999;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 1024px) {
    margin-top: 12px;
  }

  @media (max-width: 518px) {
    width: 100%;
    height: 40%;
    bottom: 0;
    left: 0;
    transform: translate(0, 25%);
    border-radius: 6px 6px 0 0;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 30px;
  top: 10px;
  width: 15px;
  height: 15px;

  :before,
  :after {
    position: absolute;
    content: " ";
    height: 25px;
    width: 2px;
    background-color: #000000;
  }

  :before {
    transform: rotate(45deg);
  }

  :after {
    transform: rotate(-45deg);
  }
`;

export const InnerModal = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
`;

export const Icon = styled.img`
  width: 50px;
  height: auto;
`;

export const Title = styled.h1`
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
`;

export const Subtitle = styled.h2`
  width: 90%;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;
