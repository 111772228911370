import styled from "styled-components";
import { colors } from "../../../styles/Theme";

export const Container = styled.form`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 1.063rem;
  padding-inline: 15px;
  /* border: 2px solid red; */

  /* @media (min-width: 1024px) {
    width: 80%;
  }

  @media (min-width: 1440px) {
    width: 100%;
    max-width: 500px;
    
  }

  @media (max-width: 768px) {
    width: 100%;
    
  }

  @media (max-width: 625px) {
    width: 80%;
  } */
`;

export const Content = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    padding-top: 3.375rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  @media (min-height: 900px) {
    height: 80vh;
  }
`;

export const ContentBack = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: ${(props) => props.none && "none"};
    position: absolute;
    right: 0;
    bottom: 8px;
    height: fit-content;
    width: auto;
  }
`;

export const ImgBack = styled.img`
  padding-bottom: 0.15rem;
  @media (min-width: 1024px) {
    width: 20px;
  }
`;

export const Text = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${colors.gray};

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }
`;

export const Button = styled.p`
  margin-bottom: 1.75rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 700;
  font-family: "Open Sans";
  color: ${colors.darkBlue};
  text-align: right;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-top: -1rem;
    font-size: 0.8rem;
  }
`;

export const SubTitle = styled.h2`
  margin-bottom: 1.75rem;
  font-size: 1rem;
  font-weight: 800;
  font-family: "Open Sans";
  color: ${colors.black};

  @media (min-width: 1024px) {
    font-size: 1.563rem;
  }
`;

export const BoxWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0")};

  @media (min-width: 769px) {
    display: flex;
    flex-wrap: row;
    justify-content: space-between;

    display: grid;
    grid-template-columns: 50% 1fr;
    grid-column-gap: 10px;
    rpw-gap: 10px;
  }

  @media (min-width: 1440px) {
    input {
      max-width: 15rem;
    }
  }
`;

export const Line = styled.hr`
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
  width: 100%;
  border: 1px solid ${colors.black02};

  @media (min-width: 1024px) {
    margin-bottom: 0.688rem;
  }
`;

export const ContentButton = styled.div`
  @media (min-width: 1024px) {
    position: relative;

    button {
      margin-bottom: 1.2rem;
    }
  }
`;

export const BoxTitle = styled.div`
  @media (min-width: 1024px) {
    width: 15rem;
  }
`;

export const ContentModal = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const TxtError = styled.p`
  margin-top: -1.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;
`;

export const TextError = styled.p`
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;
