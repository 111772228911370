import styled from "styled-components/macro";
import { colors } from "../../../../styles/Theme";

export const Container = styled.form``;

export const Boxtitle = styled.div`
  text-align: center;
`;

export const Content = styled.form`
  border-bottom: solid 1px #707070;
  width: 100%;
  min-height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Description = styled.p`
  max-width: 240px;
  color: ${colors.black};
  font-size: 0.875rem;
  font-family: "Open Sans";
  margin-bottom: 1rem;
  @media (min-width: 1024px) {
    max-width: 400px;
  }
`;

export const ContentOccupationArea = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    height: auto;
    margin-bottom: 1%;
    overflow-y: scroll;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 40% 20% 30%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const DivCheckbox = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, 30%);
  }
`;

export const ErrorText = styled.p`
  color: red;
  margin-top: 2%;
  font-size: 1.23rem;
`;

export const Label = styled.label`
  padding-right: 1rem;
  font-size: 1.125rem;
  padding-bottom: 1rem;
  color: ${colors.gray02};
  font-family: "Open Sans";
  line-height: 1.25rem;
  font-style: normal;
  font-weight: 600;
`;

export const Input = styled.input``;

export const BoxInputRadio = styled.div``;

export const ContentInput = styled.div`
  display: flex;
  padding-top: 0.5rem;
  width: 50vw;
  justify-content: space-between;
`;

export const BoxForm = styled.div`
  padding-bottom: ${(props) => props.padding && "2.375rem"};
  margin: 0 auto;
  justify-content: center;
`;

export const Titles = styled.h1`
  margin-top: 1rem;
  font-size: 1.125rem;
  width: 20rem;
  font-family: "Open Sans";
  color: ${colors.black};

  @media (min-width: 1024px) {
    width: 17rem;
  }
`;

export const BoxTextForm = styled.h1`
  width: 4rem;
`;

export const BoxCarOptions = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const BoxTextInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  label {
    padding-bottom: 0;
  }

  input {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.125rem;
  }

  @media (min-width: 1024px) {
    display: flex;
    margin-right: 5rem;
  }
`;
export const ImageAdd = styled.img`
  margin-left: 0.5rem;
  margin-top: 1.3rem;
`;

export const ImageRemove = styled.img`
  margin-left: 0.5rem;
  margin-bottom: 0.6rem;
`;

export const BoxParagraph = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  p {
    height: 2.5rem;
    background: #f6f6f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.125rem;
    color: ${colors.gray};
  }
  @media (min-width: 1024px) {
    width: 50%;
    min-height: 4rem;
    padding-top: 0;
    display: flex;
    align-items: flex-end;
  }
`;

export const ParagraphCar = styled.p`
  width: 10.563rem;
`;

export const ParagraphNumber = styled.p`
  width: 4.375rem;
  margin-left: 0.5rem;
`;

export const BoxRadioNumber = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    padding-top: 1rem;
  }
`;

export const BoxClassrom = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    padding-top: 2rem;
  }
`;

export const BoxInput = styled.div`
  margin-top: -0.7rem;
  padding-left: 1rem;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const BoxInputNumber = styled.div`
  margin-left: 0.5rem;
`;

export const BoxClassromContent = styled.div`
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const ButtonHelp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1rem;
  margin-left: 7rem;
`;

export const HoverHelp = styled.p``;
export const TitleInfo = styled.h1`
  margin-top: 2rem;
  font-size: 1.125rem;
  width: 11rem;
  font-family: "Open Sans";
  color: ${colors.black};

  @media (min-width: 1024px) {
    width: 40rem;
  }
`;

// export const LineDesk = styled.hr`
//   margin-top: 0;
//   width: 100%;
//   height: 0;
//   color: ${colors.gray};
//   display: none;

//   @media (min-width: 1024px) {
//     width: 51.125rem;
//     display: flex;
//   }
// `;

export const LineMob = styled.hr`
  width: 100%;
  height: 0;
  color: ${colors.gray};
  margin: 1.5rem 0 0 0;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const LineTwo = styled.hr`
  @media (min-width: 1024px) {
    width: 60%;
    height: 0;
    color: ${colors.gray};
    margin-top: 3rem;
  }
`;

export const BoxLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BoxCollaborators = styled.div`
  display: grid;
  position: relative;
`;

export const BoxInfo = styled.div`
  position: relative;
`;

export const BoxFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
