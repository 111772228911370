import styled from "styled-components";
// icon
import CheckIcon from "../../../../../../assets/icons/checked-icon.png";
import { colors } from "../../../../../../styles/Theme";

export const BoxModal = styled.form`
  border: 1px solid #7fbe30;
  background-color: rgba(147, 220, 56, 0.15);
  width: 100%;
  @media (min-width: 1024px) {
    overflow: scroll;
    margin-top: 1rem;
    border-radius: 10px;
    padding-inline: 22px;
    max-height: 360px;
  }
`;
export const FirstPart = styled.div`
  @media (min-width: 1024px) {
    display: flex;
  }
`;
export const DivLeft = styled.div`
  padding: 1rem 0 2rem 1rem;
  p {
    font-size: 1rem;
    font-weight: 400;
  }

  @media (min-width: 1024px) {
    padding: 40px 0 0 41px;
  }
`;
export const Name = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    font-size: 18px;
    font-weight: 700;
  }
`;
export const Duration = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  padding-bottom: 1rem;

  @media (min-width: 1024px) {
    margin-top: 40px;
    font-size: 18px;
  }
`;
export const Start = styled.div`
  display: flex;
  margin-top: 7px;
  align-items: center;

  input {
    margin: 7px 0 0 7px;
    width: 85px;
    height: 21px;
    background-color: #e7e7e7;
    border: none;
    border: solid 1px #c9cbc8;
    border-radius: 3px;
    text-align: center;
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  }
  img {
    margin: 4px 0 0 4px;
    width: 12px;
    height: 12px;
  }
`;

export const BoxDropdown = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const Final = styled.div`
  display: flex;
  margin-top: 7px;
  align-items: center;

  input {
    margin: 7px 0 0 10px;
    width: 85px;
    height: 21px;
    background-color: #e7e7e7;
    border: none;
    border: solid 1px #c9cbc8;
    border-radius: 3px;
    text-align: center;
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  }
  img {
    margin: 4px 0 0 4px;
    width: 12px;
    height: 12px;
  }
`;

export const InputDate = styled.input`
  padding-left: 0.5rem;
  padding-right: 10px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  width: 13.5rem;
  height: 1.563rem;
  margin-left: 0.5rem;
  font-size: 16px;
  font-family: monospace;
  background-color: transparent;
  text-transform: uppercase;
  border: ${(props) => props.isError && `1px solid ${colors.error}`};
  background: ${(props) => props.colorful && `${colors.darkGray}`};
  border: ${(props) => props.colorful && `1px solid ${colors.gray03}`};
  color: ${(props) => props.colorful && `${colors.gray}`};
`;
export const ContainerDurationActivity = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContentDurationActivity = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-weight: 400;
`;

export const HoverEdit = styled.span`
  width: 100%;
  max-width: 180px;
  display: flex;

  @media (min-width: 1024px) {
    min-width: 15%;
  }

  &:hover {
    input {
      background: #ffffff;
      border: 1px solid #ff8f00;
    }

    img {
      filter: brightness(0) saturate(100%) invert(76%) sepia(46%)
        saturate(6725%) hue-rotate(360deg) brightness(102%) contrast(105%);
    }
  }
`;

export const Input = styled.input`
  margin-top: 7px;
  padding-left: 4px;
  width: 236px;
  height: 26px;
  background-color: #e7e7e7;
  border: 1px solid #c4c4c4;
  outline: none;
  font-size: 16px;
  padding-left: 5px;
`;

export const DivRight = styled.div``;

export const DivDrop = styled.section`
  display: flex;
  justify-content: center;
`;

export const Responsible = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
  padding: 1.688rem 0 0.688rem 2.5rem;
  @media (min-width: 1024px) {
    padding: 40px 0 0 41px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const ParagRemoveText = styled.p`
  width: 20rem;
  height: 2.2rem;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #707070;
  padding: 1rem;

  @media (min-width: 1024px) {
    width: 13rem;
    height: 1.2rem;
  }
`;

export const DropdownResponsible = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  padding-left: 1rem;
  width: 300px;
  height: 30px;
  background-color: #5484ed;
  border-radius: 5px;
  p {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
  }

  img {
    width: 17.48px;
    height: 12px;
  }

  @media (min-width: 1024px) {
    margin: 4px 0 0 41px;
    width: 370px;
    height: 30px;
    border-radius: 5px;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const IconArrow = styled.img`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Watched = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  padding: 1.688rem 0 0.688rem 2.3rem;
  @media (min-width: 1024px) {
    padding: 40px 0 0 41px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const WatchedInput = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const DropdownWatched = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  height: 30px;
  background-color: #5484ed;
  padding: 0 10px 0 10px;
  border-radius: 5px;
  @media (min-width: 1024px) {
    margin: 4px 0 0 41px;
    width: 370px;

    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }
  }
  p {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const ResponsibleInput = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
`;

export const BoxLine = styled.div`
  padding-top: ${(props) => (props.paddingTop ? "1.75rem" : "0")};

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const GreenLine = styled.div`
  width: 100%;
  border: 1px solid #68b804;
  @media (min-width: 1024px) {
    height: 1px;
  }
`;
export const SecondPart = styled.div`
  margin: 30px 0 0 41px;
  /* input {
    display: none;
    &:hover {
      cursor: pointer;
    }
  } */
  /* input + label:before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 5px;
    background-color: #d9d9d9;
    display: inline-block;
    margin-right: 4px;
  } */
  /* input:checked + label:before {
    background-color: #5484ed;
    color: #5484ed;
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: center;
  } */
  /* label {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #424242;
    &:hover {
      cursor: pointer;
    }
  } */
`;
export const ThirdPart = styled.div`
  padding: 1rem 0 0.5rem 0;
  /* display: flex;
  justify-content: space-between;
  margin: 30px 0 0 41px;
  width: 95%;
  input {
    background-color: #e7e7e7;
    border: solid 1px #c4c4c4;
    border-radius: 3px;
  } */

  @media (max-width: 1024px) {
    padding: 0px 16px;
  }
`;
export const Avaliation = styled.h4`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 0.438rem;
  @media (min-width: 1024px) {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 0;
  }
`;

export const BoxAvaliation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContainerCriterios = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxCriteries = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const InputCriteries = styled.input`
  max-width: 100px;
  padding: 4px 8px;

  font-weight: 400;
  font-size: 14px;
  background-color: #e7e7e7;
  border: 1px solid #c4c4c4;
`;
export const InputCriteriesSmall = styled.input`
  width: 12px;
  height: 12px;
  background-color: #e7e7e7;
  border: 1px solid #c4c4c4;
`;

export const InputsChecks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* input {
    display: none;
    &:hover {
      cursor: pointer;
    }
  }
  input + label:before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 5px;
    background-color: #d9d9d9;
    display: inline-block;
    margin-right: 4px;
  }
  input:checked + label:before {
    background-color: #68b804;
    color: #5484ed;
  }
  display: flex;
  justify-content: space-between;
  label {
    margin-right: 40px;
  } */

  @media (min-width: 1024px) {
    /* padding: 0.5rem 0 0 1.813rem; */
    flex-direction: row;
    margin-block: 10px;
  }
`;

export const HoverRemove = styled.div`
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  min-height: 1.563rem;
  height: auto;
  display: flex;
  align-items: left;

  div {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
`;

export const ParagRemoveCriterion = styled.p`
  padding: 0.4rem;
  width: 9.328rem;
  height: auto;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #000000;
  overflow-wrap: anywhere;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: none;
  @media (min-width: 1024px) {
    width: 8rem;
    height: 1rem;
    font-size: 0.9rem;
    padding: 0 0.7rem;
    margin-left: 0.5rem;
  }
`;

export const ImageRemoveData = styled.img`
  padding-left: 0.5rem;
  width: 1.5rem;
  filter: brightness(0) saturate(100%) invert(55%) sepia(0%) saturate(6025%)
    hue-rotate(39deg) brightness(86%) contrast(90%);
  cursor: pointer;
`;

export const Disabled = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  /* input {
    &:hover {
      cursor: pointer;
    }
  }
  input:nth-child(2) {
    width: 126px;
    height: 25px;
    margin-right: 40px;
    margin-left: 5px;
  }
  input:nth-child(4) {
    width: 126px;
    height: 25px;
    margin-right: 40px;
    margin-left: 5px;
  }
  input:nth-child(6) {
    width: 126px;
    height: 25px;
    margin-right: 40px;
    margin-left: 5px;
  } */

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;
export const Buttons = styled.div`
  margin: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;

  @media (min-width: 1024px) {
    justify-content: flex-end;
    padding-bottom: 2rem;
  }
`;
export const Delete = styled.button`
  width: 187px;
  height: 27px;
  background: rgba(104, 184, 4, 0.3);
  border-radius: 50px;
  border: solid 1px #68b804;
  color: #68b804;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;
export const Edit = styled.button`
  margin-left: 20px;
  width: 98px;
  height: 27px;
  background-color: #68b804;
  border-radius: 50px;
  border: solid 1px #68b804;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

export const MonthDropdown = styled.th`
  padding: 0 5px;
  width: 5rem;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${colors.white};
  border-radius: 5px;
  background-color: ${colors.blue01};
  display: flex;
  cursor: pointer;

  img {
    padding-left: 0.5rem;
    width: 18px;
    object-fit: contain;
  }
`;

export const TH = styled.th`
  width: 46px;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${colors.black};
`;

export const THTBody = styled.th`
  width: 46px;
  font-weight: 700;
  font-size: 0.75rem;
  color: ${colors.black};
`;

export const TR = styled.tr`
  width: 46px;
`;

export const InputEvaluationCriteria = styled.input`
  width: 45px;
  height: 39px;
  border: 1px solid ${colors.black};
  background-color: ${colors.darkGray};
`;

export const ContentFreqPerio = styled.div`
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  margin-bottom: 25px;

  @media (max-width: 1024px) {
    padding-inline: 16px;
  }
`;

export const ContainerFrequency = styled.div`
  height: 100%;
  flex: 1;
  margin-block: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
`;

export const BoxFrequency = styled.section`
  margin-bottom: 23px;
  display: flex;
  width: 100%;

  label {
    padding-top: 0.5rem;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1.188rem;
  }

  input {
    font-size: 1rem;
    margin-left: 1rem;
    padding: 0.3rem;
    background-color: #5484ed;
    border-radius: 5px;
    color: white;
  }

  @media (min-width: 1024px) {
  }
`;

export const ContentPeriod = styled.section`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  gap: 10px;
  margin-block: 16px;

  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
  }
`;

export const BoxInputPeriod = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  .label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
  }

  @media (min-width: 450px) {
    flex-direction: row;
  }
`;
export const BoxInputPeriodResponsive = styled.div`
  display: flex;
  gap: 10px;

  span {
    width: 20px;
  }
`;

export const InputMonth = styled.input`
  width: 100%;
  max-width: 200px;
  height: 30px;
  font-size: 0.8rem;
  padding: 0.3rem;
  background-color: #5484ed;
  border-radius: 5px;
  color: white;
`;

export const ContentListTotal = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const HeaderListTota = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
  }
`;

export const InputHeandingInputTotal = styled.input`
  width: 40px;
  height: 40px;
  padding: 5px;

  border: 1px solid #000000;
`;

export const ContentListUsers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BoxHeadingInput = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const InputHeandingInput = styled.input`
  width: 200px;
  height: 40px;
  border: 1px solid #000000;
  padding-left: 0.5rem;
`;

export const TitleHeandingInput = styled.span`
  font-weight: 700;
  font-family: "Open Sans";
  font-size: 14px;
`;

export const BoxInputsTotal = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContentDatas = styled.div`
  @media (min-width: 1024px) {
    padding-top: 0.8rem;
    section {
      padding: 1rem;
    }

    label {
      padding-right: 1rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }

    input {
      padding: 0.3rem;
      border: 1px solid #000000;
    }

    span {
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      padding-left: 2rem;
    }
  }
`;

export const ContentAverage = styled.div`
  @media (min-width: 1024px) {
    display: flex;

    p {
      font-family: "Open Sans";
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      padding-right: 1rem;
    }

    input {
      margin-left: 0.3rem;
      width: 35px;
      height: 35px;
      border: 1px solid #000000;
      padding-left: 0.5rem;
    }
  }
`;

export const Box = styled.div`
  @media (min-width: 1024px) {
    width: 70%;
    display: flex;
    justify-content: space-between;
  }
`;
