// Libs
import React from "react";
import { Navigate } from "react-router-dom";

import { isAuthenticated } from "../auth";

const PrivateRoute = ({
	redirectPath = '/bem-vindo',
	beforeForm,
	children,
  }) => {

	const idToken = localStorage.getItem("idToken");
	const RegistrationCompleted = JSON.parse(localStorage.getItem("@Vision/ongRegistrationCompleted")) || false
	
	const redirectPathRote = RegistrationCompleted === false ? redirectPath : '/'
	const currentStep = JSON.parse(localStorage.getItem("formStep")) || {
		pathname: idToken ? redirectPathRote : '/login',
	};


	return isAuthenticated(beforeForm={beforeForm}) ? children : 	<Navigate to={currentStep.pathname} replace />;
};

export default PrivateRoute;
