import styled from "styled-components";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  font-family: "Open Sans";
  width: 100%;
  height: 92vh;
  background-color: #93dc38;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 18rem;
  background-color: white;
  border-radius: 0 0 55% 55% / 0% 0% 35% 35%;

  @media (min-width: 1024px) {
    height: 100%;
    width: 60%;
    justify-content: center;
    border-radius: 0;
  }
`;

export const Image = styled.img`
  height: 65%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);

  @media (min-width: 1024px) {
    height: 85%;
    transform: translate(-85%, 2%);
  }

  @media (min-width: 1380px) {
    transform: translate(-95%, 2%);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 60%;

  @media (min-width: 1024px) {
    align-items: end;
  }
`;

export const TextContainer = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    width: 90%;
    margin-top: 5rem;
  }
`;

export const Title = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  font-size: 16px;
  color: ${colors.black};
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    font-size: 40px;
    margin-bottom: 30px;
  }
`;

export const Subtitle = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: ${colors.black};
  font-size: 14px;

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-top: 10px;
`;
