export const isAuthenticated = ({beforeForm }) => {
  
  const idToken = localStorage.getItem("idToken");
  const formStep = JSON.parse(localStorage.getItem("formStep")) || false
  const RegistrationCompleted = JSON.parse(localStorage.getItem("FormOngfinished")) 

  if (idToken) {
    if((!RegistrationCompleted && beforeForm === true) || (RegistrationCompleted && !formStep)){
      return true;
    }
    else {
      return false
    }
  }
  };
  
  export default isAuthenticated;
