import React from 'react';
import * as S from "./styles"

//Icons
import { RemoveForm} from '../../../assets';

const BtnTrash = ({marginTop, text, icon, onClick, addField, noIcon}) => {
  const isDescktop = window.innerWidth > 768;
  const renderTxt = isDescktop ? 'Excluir ONG' : 'Excluir';

  return(  
    <S.Container marginTop={marginTop} onClick={onClick}  addField={addField}>
      {noIcon || <S.Image src={icon || RemoveForm} alt="Excluir" />}
      <S.Trash addField={addField}>{ text || renderTxt}</S.Trash>
    </S.Container>
  )
}

export default BtnTrash;
