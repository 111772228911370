import React from 'react'

import * as S from './styles';

const TitleWithLine = ({ line, children, paddingBottom, paddingTop, width}) => {
  return (
    <S.Container paddingBottom={paddingBottom} paddingTop={paddingTop} width={width}>
      <S.Title>{children}</S.Title>
      <S.LineSubtitle line={line} />
    </S.Container>
  );
}

export default  TitleWithLine;
