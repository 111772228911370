import React from "react";
import * as S from "./styles";

const Select = ({ label, value, isError, onChangeSelectVehicle, ...rest }) => {
  return (
    <S.Container>
      <S.Label>
        {label}
        <S.Select
          name="cars"
          value={value}
          placeholder="Selecione"
          isError={isError}
          onChange={onChangeSelectVehicle}
          {...rest}
        >
          <option value="" disabled selected hidden>
            Selecione
          </option>
          <option value="carro">Carro</option>
          <option value="moto">Moto</option>
          <option value="kombi">Kombi</option>
          <option value="ônibus">Ônibus</option>
          <option value="van">Van</option>
        </S.Select>
      </S.Label>
    </S.Container>
  );
};

export default Select;
