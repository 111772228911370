import React from "react";
import { Navigate } from "react-router-dom";

import { isAuthLogin } from "../authLogin";

const isDescktop = window.innerWidth > 768;
const localstorage = localStorage.getItem("@Vision/ongRegistrationCompleted");
const handleDeviceRoute = isDescktop
  ? "/cadastro-novos-assistidos"
  : "/tutorial";
const handleInitialRoute = localstorage ? handleDeviceRoute : "/bem-vindo";

const UnauthenticatedRoute = ({
  redirectPath = handleInitialRoute,
  children,
}) => {
  return isAuthLogin() ? <Navigate to={redirectPath} replace /> : children;
};

export default UnauthenticatedRoute;
