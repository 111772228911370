

import React from "react";

//Styles
import * as S from "./styles";

//Icons
import { IconClose } from "../../../assets";

const ModalModalDelete = ({ top, title, handleCloseModal, handleDeleteData }) => {
  return (
    <S.Modal top={top}>
      <S.BtnClose onClick={handleCloseModal}>
        <S.ImageClose src={IconClose} alt="Fechar" />
      </S.BtnClose>
      <S.ModalInternal>
        <S.ModalText1>{title || 'Esta ação irá deletar os dados da ONG.'}</S.ModalText1>
        <S.ModalText2>Tem certeza que deseja deletar esses dados?</S.ModalText2>
        <S.ModalButtons>
          <S.BtnCancel onClick={handleCloseModal}>Cancelar</S.BtnCancel>
          <S.BtnDelete onClick={handleDeleteData}>Sim. deletar dados</S.BtnDelete>
        </S.ModalButtons>
      </S.ModalInternal>
    </S.Modal>
  );
};

export default ModalModalDelete;

