import React from 'react'
import * as S from './styles';
import { useNavigate } from 'react-router-dom';

import {
  checkIcon
} from '../../../assets';

const TitleTransitionTasks = ({ index, icon, text, route }) => {
  const navigate = useNavigate();
  const localstorage = localStorage.getItem('@Vision/Permission');
  const parseLocalStorage = localstorage && JSON.parse(localstorage);
  const completedScreens = parseLocalStorage?.filter(i => i === (index)).length > 0 ;

  const verifyRoute = () => {
    let permissions
    if (localstorage)
      permissions = parseLocalStorage
    let atual = 0;
    
    // eslint-disable-next-line default-case
    switch (route) {
      case '/cadastro/contatos-e-dados-oficiais':
        atual = 0
        break;

      case '/cadastro/atuacao-e-assistidos':
        atual = 1
        break;

      case '/cadastro/estrutura-fisica-e-funcional':
        atual = 2
        break;

      case '/cadastro/envio-de-documentos':
        atual = 3
        break;

    }
    if (permissions?.includes(atual)) {
      navigate(route)
    }
  }

  return (
    <S.Container onClick={verifyRoute} disabled>
      <S.Icon src={icon}></S.Icon>
      <S.P>{text}</S.P>
      {completedScreens ? <S.Check src={checkIcon}></S.Check> : <p />}
    </S.Container>
  );
}

export default TitleTransitionTasks;