import styled from 'styled-components';
import { colors } from '../../../../styles/Theme';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 8.438rem);

  @media (min-width: 768px) {
    max-height: calc(100vh - 8.438rem);
  }

  @media (min-width: 1024px) {
    padding-top: 2.813rem;
    margin: 0 auto;
    width: 56%;
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    width: 48%;
  }
`;

export const ContentTwo = styled.div`
  @media (min-width: 1024px) {
    width: 48%;
  }
`;

export const Box = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  button.button-activities {
    margin-left: 20px;
  }
`;

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    display: block;
    height: 45px;
  }
`;

export const SelectActivities = styled.button`
  width: 100%;
  height: 48px;
  background: #7fbe30;
  border: none;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0.688rem;

  @media (min-width: 1024px) {
  }
`;

export const SelectWatched = styled.button`
  width: 100%;
  height: 48px;
  background: #5484ed;
  border: none;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;

  @media (min-width: 1024px) {
  }
`;

export const Paragraph = styled.p``;

export const Form = styled.form``;

export const BoxLink = styled.div`
  padding-top: 1rem;
  display: flex;
`;

export const Link = styled.a`
  color: red;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: 'Open Sans';
`;

export const Overlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 5rem);
  background: rgba(158, 158, 158, 0.5);
  backdrop-filter: blur(50px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  @media (max-width: 1023px) {
    top: 0;
    height: 100%;
    overflow-y: hidden;
  }
`;

export const ContainerModal = styled.div`
  position: relative;
  width: 80%;
  max-width: 1304px;
  height: 74vh;
  max-height: 812px;
  background-color: ${colors.white};
  border-radius: 1rem;
  border: 4px solid ${colors.green};
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #707070 #e0e0e0;
  padding: 1rem 2rem;

  h1,
  h2 {
    margin: 8px 0;
    font-weight: 100;
  }

  table {
    border: 1px solid;
    margin: 8px 0;
  }

  li {
    margin: 8px 0;
  }

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #e0e0e0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export const ButtonsAssistedList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0;
  }
`;

export const ContainerButtonsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div.select-container {
    width: 75%;
    display: flex;
  }

  div > .big {
    width: 60%;
  }

  div > .small {
    width: 30%;
  }

  div > .filters-value {
    padding: 2px 8px;
    border: 1px solid ${colors.gray};
    border-radius: 0.25rem;
  }

  div > button {
    margin: 0.25rem 0;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGreen};
    color: ${colors.green};
    font-weight: bolder;
    flex: 1;
  }

  div > button.active {
    background-color: ${colors.green};
    color: ${colors.white};
  }

  div > button.active:hover {
    background-color: ${colors.error};
    color: ${colors.white};
  }

  button {
    background-color: ${colors.blue};
    width: 75%;
    color: ${colors.white};
    border: 0;
  }
`;

export const ContainerCloseModal = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin-inline-start: auto;
  width: 2.563rem;
  padding: 0.375rem 0 0.25rem 0;
  /* height: 2.563rem; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: #ffecd3;
  /* padding:3px; */
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1024px) {
    //display: none;
  }
`;

export const ImageCloseModal = styled.img`
  height: 1.25rem;
`;

export const Trash = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  font-family: 'Open Sans';
  color: ${colors.orange};
  line-height: 13.62px;
`;
