

import styled, { css } from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: solid 1px #00000060;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 2;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (min-width: 770px) {
    margin-top:125rem;
  }
`;

export const BoxContentInfo = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #5483ea;
  font-size: 0.875rem;

  &:hover {
    cursor: pointer;
    color: #FF8F00;
    filter: brightness(0) saturate(100%) invert(76%) sepia(46%) saturate(6725%) hue-rotate(360deg) brightness(102%) contrast(105%);
  }

  ${props => props.screen && css`
    color: ${props => props.screen && '#FF8F00'};
    filter: ${props => props.screen && `brightness(0) saturate(100%) invert(76%) sepia(46%) saturate(6725%) hue-rotate(360deg) brightness(102%) contrast(105%)`};
  `}
`;

export const img = styled.img`
  padding-top: 0.5rem;
  width: ${props => props.config ? '2.1rem' : '1.725rem'};
  filter: brightness(0) saturate(100%) invert(51%) sepia(26%) saturate(1111%) hue-rotate(183deg) brightness(94%) contrast(106%);
`;

export const P = styled.p`
  font-size: 0.688rem;
  font-weight: 400;
  font-family: 'Open Sans';
`;
