import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div``;

export const ContainerSelectedItem = styled.div`
  margin-top: 0.688rem;
  padding-bottom: ${(props) => props.padding && "0.625rem"};
  width: max-content;
  display: flex;

  @media (min-width: 1024px) {
    margin-top: ${(props) => (props.margin ? "1.888rem" : ".688rem")};
  }
`;

export const ContainerSelect = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 12rem;
    align-content: flex-start;
  }
`;

export const Title = styled.h3`
  margin-top: 2.688rem;
  margin-bottom: 0.938rem;
  width: 20rem;
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: ${colors.black};

  @media (min-width: 1024px) {
    margin-top: 4rem;
  }
`;

export const Label = styled.label`
  padding-right: 2.5rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${colors.gray02};
  font-family: "Open Sans";
`;

export const Fill = styled.div`
  margin-right: 0.4rem;
  height: 1.25rem;
  width: 1.25rem;
`;

export const ContentSelectedItem = styled.div`
  margin: ${(props) => props.margin && "0 .6rem 0 .5rem"};
  padding-left: 0.5rem;
  width: ${(props) => props.width};
  height: 2.5rem;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Open Sans";
  color: ${colors.gray};
  background-color: ${colors.lightGray};
  text-transform: capitalize;
  border-radius: 5px;
`;

export const BoxTextInput = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 0.5rem;

  label {
    padding-bottom: 0;
  }

  input {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.125rem;
  }

  @media (min-width: 1024px) {
    width: max-content;
    min-width: 50%;
    min-height: 4rem;
  }
`;

export const ItemSelected = styled.div`
  div:nth-child(3) {
    margin-top: 1.688rem;
  }
`;

export const BoxInputNumber = styled.div`
  margin-left: 0.5rem;
`;

export const ImageAdd = styled.img`
  margin-bottom: 0.7rem;
  cursor: pointer;
`;

export const IconAdd = styled.img`
  cursor: pointer;
`;

export const LineDesk = styled.hr`
  margin-top: 0;
  width: 100%;
  height: 0;
  color: ${colors.black02};
  display: none;

  @media (min-width: 1024px) {
    ${"" /* width: 51.125rem; */}
    display: flex;
    margin-bottom: 1rem;
  }
`;

export const TextError = styled.p`
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;
`;
