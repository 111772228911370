export const clearObject = (obj) => {
  const output = {};

  Object.keys(obj).forEach((key) => {
    const prop = obj[key];

    if (prop || prop === false) {
      output[key] = prop;
    }

    if (prop && typeof prop === "object" && !Array.isArray(prop)) {
      output[key] = clearObject(prop);
    }
  });

  return output;
};
