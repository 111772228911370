import React from "react";

//components
import LayoutHome from "../../../components/SideBarSettings";

//Styles
import * as S from "./styles";

const HomeDashboard2 = () => {
  return (
    <S.BoxAll>
      <S.Container>
        <LayoutHome homeScreen seo="/ Gestão de atividades" />
      </S.Container>
    </S.BoxAll>
  );
};

export default HomeDashboard2;
