import React from "react";
import { useNavigate } from 'react-router-dom';

// Components
import Header from "../../../components/Header";
import FooterGreen from "../../../components/Footer/FooterGreen";
import Btn from "../../../components/Buttons/button";

// Styles
import * as S from "./styles";
import { Vi3 } from "../../../assets";

const RegistrationCreatedSuccessfully = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem('@Vision/ongRegistrationCompleted', true);
    navigate('/tutorial');
  }

  const RenderTitle = () => (
    <S.Content>
      <S.Title>
        Parabéns! Chegamos ao final do seu cadastro e agora você está pronto
        para utilizar o Vision.
      </S.Title>
      <S.Title>
        Vamos lá!
      </S.Title>
      <S.BoxBtn>
      <Btn width='8rem' text="Concluir" onClick={handleClick} />
      </S.BoxBtn>
    </S.Content>
  );
  
  return (
    <S.Container>
      <Header />
      <S.Main>
        <S.ContentBCKGGreen>
          <FooterGreen />
          <S.ImgVi src={Vi3} alt="Vi" />
        </S.ContentBCKGGreen>
        <S.ContentData>
          <RenderTitle />
        </S.ContentData>
      </S.Main>
    </S.Container>
  );
};

export default RegistrationCreatedSuccessfully;
