import styled from "styled-components/macro";
import { colors } from "../../../../styles/Theme";

export const Container = styled.form`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const ImageActingAssisted = styled.img`
  width: 3rem;
  height: 5.5rem;
`;

export const BoxForm = styled.div`
  border-top: solid 1px ${colors.gray};
  padding: 1rem;
  border-bottom: solid 1px ${colors.gray};

  width: 100%;

  @media (min-width: 1024px) {
    border: none;
  }
`;

export const Description = styled.h2`
  padding-top: 0.375rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans";
  text-shadow: 0px 4px 4px #00000040;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ContentSubtitle = styled.div`
  padding-top: 1.688rem;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`;

export const Subtitle = styled.h2`
  /* display: none; */
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans";
  text-shadow: 0px 4px 4px #00000040;
  /* border-top: 1px solid ${colors.gray}; */

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    font-size: 1.563rem;
  }
`;

export const LineSubtitle = styled.div`
  display: none;

  @media (min-width: 1024px) {
    margin-left: 1.625rem;
    display: flex;
    height: 1px;
    width: 100%;
    background: ${colors.black02};
  }
`;

export const LineSubtitleActing = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    height: 1px;
    width: 50%;
    background: ${colors.gray};
  }

  @media (min-width: 1300px) {
    min-width: 48%;
  }

  @media (min-width: 1500px) {
    min-width: 65%;
  }

  @media (min-width: 2000px) {
    min-width: 70%;
  }
`;

export const ContentOccupationArea = styled.div`
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    /* height: 820px; */
    overflow-y: scroll;
    display: grid;
    grid-column-gap: 20px;
    /* grid-template-columns: repeat(auto-fill, 30%); */
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Label = styled.label`
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: "Open Sans";
  color: ${colors.gray02};
`;

export const Input = styled.input`
  background: #bebebe;
  border: ${colors.gray};
  border-radius: 5px;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
`;

export const DivCheckbox = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    margin: 0 auto;
  }
`;
export const ContentOthers = styled.div``;

export const BoxActing = styled.div`
  border-bottom: solid 1px ${colors.gray};
  padding-top: 1.875rem;
  padding-bottom: 1rem;

  @media (min-width: 1024px) {
    width: 100%;
    border: none;
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 633px) {
    position: relative;
  }
`;

export const Titles = styled.h1`
  font-size: 1.563rem;
  white-space: nowrap;
  font-family: "Open Sans";
  font-weight: 700;
`;

export const TitleInfo = styled.h1`
  font-size: 1.563rem;
  width: 15rem;
  font-family: "Open Sans";
  font-weight: 700;
  color: ${colors.black};

  @media (min-width: 1024px) {
    width: 36rem;
  }
`;

export const SubtitleActing = styled.p`
  font-size: 1rem;
`;
export const ErrorText = styled.p`
  color: red;
  margin-top: 2%;
  font-size: 1.23rem;
`;
export const FormActing = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 8px;

  @media (min-width: 1000px) {
    gap: 0px 40px;
  }
`;

export const LabelActing = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 23%;
  padding: 0.5rem 0.5rem 2.688rem 0;
  cursor: pointer;

  @media (min-width: 768px) {
    max-width: 7.125rem;
  }

  :hover {
    div {
      display: flex;
      animation: appearContentHelp 1s alternate;
      -webkit-animation: appearContentHelp 1s alternate;
    }

    @keyframes appearContentHelp {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
`;

export const ContentModal = styled.div`
  display: none;
  position: relative;
  top: -3rem;
  bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputActing = styled.input`
  background: #bebebe;
  border: ${colors.gray};
  border-radius: 5px;
  width: 1.25rem;
  height: 1.25rem;
`;
export const ImageODS = styled.img`
  max-width: 100%;
  padding-bottom: 0.625rem;
`;

export const BoxAssisted = styled.div`
  padding-top: 1.875rem;
`;

export const ContentCheckbox = styled.div`
  width: 20rem;

  @media (min-width: 1024px) {
    padding-top: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    label {
      width: 68%;
    }
  }
  @media (min-width: 1200px) {
    label {
      width: 100%;
    }
  }
`;

export const FormAssistedQuant = styled.div`
  padding-top: 2.125rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    width: 100%;
  }
`;

export const LabelAssisted = styled.label`
  width: 80%;
  font-size: 0.875rem;
`;

export const TitleLabel = styled.h2`
  width: 98%;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: ${(props) => props.padding && props.padding};

  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? "95%" : "100%")};
    border-bottom: ${(props) => props.border && `solid 1px ${colors.black02}`};
  }
`;

export const InputAssistedCont = styled.input`
  background: #f6f6f6;
  border: ${colors.gray};
  border-radius: 5px;
  width: 4.375rem;
  height: 2.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
`;

export const FormAssistedGen = styled.div`
  padding-top: 2.125rem;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 1024px) {
    padding-top: 0;
    width: 50%;
    height: fit-content;
  }
`;

export const ContentGender = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  /* flex-direction: row; */

  input {
    place-self: self-end;
    margin-right: 0.2rem;
  }

  @media (max-width: 1580px) {
    flex-direction: column;
  }
`;

export const ContentGenderBox = styled.div`
  width: ${(props) => props.width};
  @media (max-width: 1580px) {
    display: ${(props) => props.others && "flex"};
    width: 100%;
  }
`;

export const ContentGenderBoxItem = styled.div`
  display: flex;
`;

export const FormAssistedIdade = styled.div`
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding-top: 0;
    width: 50%;
    margin-left: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ContentAgeRange = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:first-child {
    margin-right: 2rem;
  }
`;

export const BoxFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
