import styled from "styled-components";

import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  height: min-content;
  align-items: ${(props) => !props.column && "center"};
  padding-bottom: ${(props) => props.paddingBottom && props.paddingBottom};
  padding-top: ${(props) => props.paddingTop && props.paddingTop};
  &:focus {
    box-shadow: 0 0 0 0;
  }

  input::-webkit-inner-spin-button {
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    filter: ${(props) => props.compSelect && "invert(20%)"};
  }

  @media (min-width: 1024px) {
    padding-left: ${(props) => props.paddingLeft && props.paddingLeft};
    /* align-items: baseline; */
  }

  @media (min-width: 1024px) and (max-width: 1460px) {
    padding-left: ${(props) =>
      props.paddingLeftMedia && props.paddingLeftMedia};
  }
  /* // tela 4k
  @media (min-width: 2560px) {
    width: 30%;
  } */
`;

export const ContentLabel = styled.div`
  position: relative;
  width: ${(props) => props.width};
  /* align-self: center; */

  @media (min-width: 1024px) {
    width: ${(props) => props.widthDesk};
  }
`;

export const Label = styled.label`
  padding-right: ${(props) => props.paddingRight};
  width: ${(props) => props.width};
  font-size: 1rem;
  color: ${colors.black};
  font-family: "Open Sans";
  /* align-self: center; */
  font-weight: ${(props) => props.weight};
  @media (min-width: 1024px) {
    order: ${(props) => props.order};
  }
`;

export const ButtonInfo = styled.div`
  position: absolute;
  /* top: -1.25rem;
  right: -3rem; */
  top: 1.75rem;
  right: 2rem;
`;

export const Input = styled.input`
  border-radius: 5px;
  height: ${(props) => (props.height ? props.height : "2.188rem")};
  width: ${(props) => (props.width ? props.width : "3.75rem")};
  max-width: 100%;
  font-size: 1.125rem;
  font-weight: 400;
  font-family: "Open Sans";
  text-align: left;
  padding-left: 0.5rem;
  background: ${(props) =>
    props.compSelect ? colors.gray03 : colors.lightGray};
  border: ${(props) =>
    props.border
    ? props.border
    : props.isError
      ? `1px solid ${colors.error}`
      : "1px solid #BEBEBE"};

  @media (min-width: 1024px) {
    margin-top: 0.1rem;
    width: ${(props) => props.widthNumber && props.widthNumber};
  }
`;
