// css
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/Theme";
import InputMask from "react-input-mask";

export const ContentLabel = styled.div`
  width: ${props => props.width ? props.width : 'initial '};
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.marginTop ? props.marginTop : '0px'};

  padding-bottom: ${props =>
    props.sPaddingBottom
      ? props.sPaddingBottom
      : props.paddingBottom
      ? '1rem'
      : '1.864rem'};

  @media (min-width: 1024px) {
    padding-right: ${(props) => props.paddingRight && "2.5rem"};
    ${(props) => props.flex && "flex: 1;"}
    justify-content: space-between;
    width: ${(props) => props.widthLabel || props.widthLabel};
    margin-left: ${(props) => props.marginLabel || props.marginLabel};
  }

  width: ${props => props.contentWidth && '100%'};
  margin-right: ${props => props.marginLabelMob || props.marginLabelMob};

  @media (min-width: 1024px) {
    padding-right: ${props => props.paddingRight && '2.5rem'};
    ${props => props.flex && 'flex: 1;'}
    justify-content: space-between;
    width: ${props => props.widthLabel || props.widthLabel};
    margin-left: ${props => props.marginLabel || props.marginLabel};
  }
  
  @media(max-width: 1024px) {
    width: ${props => props.ResponseWidth ? props.ResponseWidth : 'inital'};
  }
 
`;

export const ContentInfo = styled.label`
  display: inherit;
  position: relative;
  width: ${(props) => props.subLabel && "13rem"};
  font-size: ${(props) => (props.subLabel ? "1rem" : "0.875rem")};
  font-weight: 600;
  color: ${(props) => (props.subLabel ? colors.gray02 : colors.black)};
  font-family: "Open Sans";
`;

export const Input = styled(InputMask)`
  margin: 0.391rem 0 0 0;
  padding-left: ${(props) => (props.icon ? "3rem" : ".688rem")};
  background: ${(props) =>
    props.isError ? colors.lightRed : colors.lightGray};
  border: ${(props) =>
    props.isError
      ? `1px solid ${colors.error}`
      : `1px solid ${colors.darkGray}`};
  border-radius: 5px;
  height: 2.5rem;
  max-width: ${(props) => (props.width ? props.width : "100%")};
  /* max-width: 100%; */
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Open Sans";
  background: ${colors.lightGray};
  background: ${colors.lightGray} url(${(props) => props.icon}) no-repeat scroll
    12px 5px;
  background-size: 28px;
  background-position-y: center;
  

  :-webkit-autofill {
    -webkit-box-shadow: ${(props) =>
      props.isError
        ? `0 0 0px 1000px ${colors.lightRed} inset`
        : `0 0 0px 1000px ${colors.lightGray} inset`};
  }

  ::placeholder {
    color: ${(props) => props.isError && colors.error};
  }

  @media (min-width: 1024px) {
    background-size: 20px;
    padding-left: ${(props) => (props.icon ? "2.5rem" : ".75rem")};
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    -moz-appearance: textfield;
    appearance: textfield;
  }

  
`;

export const CNPJAdress = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 860px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* display: grid;
    grid-template-columns: ${(props) =>
      props.grid ? props.grid : "1fr 1fr 1fr"};
    grid-column-gap: 20px; */
  }
`;

export const Form = styled.form`
  width: 100%;
  overflow: hidden;
  /* margin: 0 auto;
  width: 80%; */

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`;

export const ContentRow = styled.div`
  
  display: flex;
  flex-direction: column;
  
  @media(max-width: 1024px) {
    margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
  } 

  div {
    padding-top: 10px;
  }

  h3 {
    font-size: 1rem;
    margin: 6px 0 0;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 40px;
  }
`;

export const ContentInsertNumber = styled.div`
  
  @media (min-width: 1024px) {
    padding-top: 2.225rem;
  }

  @media(max-width: 1024px) {
    margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
  } 
`;

export const BoxDataCpf = styled.div`

  @media (min-width: 1024px) {
    display: flex;
    /* flex-direction: row-reverse;
    justify-content: space-between; */

    /* display: grid;
    grid-template-columns: ${(props) => (props.grid ? props.grid : "50% 1fr")};
    grid-column-gap: 20px; */
  }
`;

// export const BoxComu = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 20rem;

//   @media(min-width: 1024px) {
//     width: 21rem;
//   }
// `

export const ContainerAddressData = styled.div`
  @media (min-width: 1024px) {
    max-width: 860px;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* display: grid;
    grid-template-columns: ${(props) =>
      props.grid ? props.grid : "1fr 1fr 1fr"};
    grid-column-gap: 20px; */
  }
`;

export const BoxDisplay = styled.div` 
  width: 100%;
  max-width: 1000px;
  display: flex;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : 'auto'};
  
  justify-content: ${props => props.justify ? props.justify : 'start'};

  gap: ${props => props.gap ? props.gap : '0px'};
  column-gap: 16px;

  flex-wrap: wrap;
  @media(max-width: 1555px) {
  }

`;

export const ContentAddressData = styled.div`
  @media (min-width: 1024px) {
    display: flex;

    /* flex-wrap: wrap; */
  }
`;

export const BoxWrap = styled.div`
  
  display: flex;
  grid-template-columns: ${(props) => (props.grid ? props.grid : "50% 1fr")};
  /* grid-column-gap: 20px; */
  /* } */
  @media (max-width: 1024px) {
    flex-direction: ${(props) => (props.row ? "row" : "column")};
  }
`;

export const BoxRegistrationCnpj = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "auto")};
  display: flex;
  column-gap: 16px;
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};

  @media(max-width: 1555px) {

    flex-wrap: wrap;
    width: ${(props) => (props.widthReponsive ? props.widthReponsive : "auto")};

  }

`

export const BoxWrapMob = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.grid ? props.grid : "50% 1fr")};
  grid-column-gap: 20px;
`;

export const ContentLine = styled.div`
  display: none;

  @media (min-width: 1024px) {
    padding: 1.875rem 0 0.875rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    display: flex;
  }
`;

export const ContentContact = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;


    label {
      display: block;
      padding-right: 1.5rem;
      /* width: 33%; */
    }

    ${(props) =>
      props.width &&
      css`
        label {
          ${"" /* width: 25%; */}
        }
      `}
  }

  @media (min-width: 1920px) {
    label {
      padding-right: 2.5rem;
      /* width: 30%; */
    }

    ${(props) =>
      props.width &&
      css`
        label {
          ${"" /* width: 25%; */}
        }
      `}
  }
`;

export const NamePresident = styled.div`
  @media (min-width: 1024px) {
    padding-left: 2.5rem;
  }
`;

export const TitleLine = styled.h3`
  margin: 0 0.75rem 1rem 0;
  align-items: center;
  white-space: nowrap;
  font-size: 1.563rem;
  font-weight: 700;
  font-family: "Open Sans";

  @media (min-width: 1024px) {
    font-size: 1.563rem;
    font-weight: 700;
  }
`;

export const LineTwo = styled.hr`
  width: 100%;
  height: 0;
  color: ${colors.gray};
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Line = styled.hr`
  display: ${(props) => (props.descktop ? "none" : "flex")};
  margin-block: 20px;
  width: 100%;
  height: 0;
  color: ${colors.black02};

  @media (min-width: 1024px) {
    display: ${(props) => (props.mobile ? "none" : "flex")};
    /* display: none; */
  }
`;

export const ContainerOrder = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ContentOrder = styled.div`
  @media (min-width: 1024px) {
    width: ${(props) => (props.order ? "100%" : "50%")};
    order: ${(props) => props.order && "1"};
  }
`;
