import React from 'react';


//Styles
import * as S from './styles';

const ImgHome = ({ img, width, marginTop, marginLeft }) => {
  return (
        <S.img src={img} width={width} marginTop={marginTop} marginLeft={marginLeft}></S.img>
  )
}

export default ImgHome;
