import styled from "styled-components/macro";
import { colors } from "../../styles/Theme";
import { DroppingModal } from '../../assets';

export const Container = styled.div``

export const Label = styled.label`
    font-size: 1rem;
    font-family: 'Open Sans';
    font-weight: 400;
    color: ${colors.black};
    display: flex;
    flex-direction: column;
`

export const Select = styled.select`
    width: 10.563rem;
    height: 2.5rem;
    padding-left:.5rem;
    border-radius:5px;
    background-color:#C4C4C4;
    border:none;
    color:#707070;
    font-size:1.125rem;
    line-height: 1.563rem;
    font-weight: 600;
    font-style: normal;
    font-family: 'Open Sans';
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #C4C4C4 url(${DroppingModal}) 95.5% 50% no-repeat;
    outline: none;
    border: ${props => props.isError && `1px solid ${colors.error}`};

`