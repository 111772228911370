import React from "react";

// componentes
import Header from "../Header";

// styles
import * as S from "./styles";
import { Vi } from "../../assets";

const LayoutScreen = ({ children, directionContent }) => {
  return (
    <S.Container>
      <Header />
      <S.Main>
        <S.ContentImage />
        <S.Content directionContent={directionContent}>
          {children}
          <S.ImgVi src={Vi} alt="Vi" />
        </S.Content>
      </S.Main>
    </S.Container>
  );
};

export default LayoutScreen;
