export const skinColorsList = [
  { value: "black", name: "Preto(a)" },
  { value: "white", name: "Branco" },
  { value: "brown", name: "Pardo(a)" },
  { value: "indigenous", name: "Indigena" },
  { value: "asian", name: "Amarelo" },
  { value: "non_declared", name: "Prefiro não declarar" },
];

export const birthGenderList = [
  { value: "female", name: "Feminino" },
  { value: "male", name: "Masculino" },
  { value: "non_declared", name: "Prefiro não declarar" },
];

export const genderList = [
  { value: "agender", name: "Agênero" },
  { value: "cisgender", name: "Cisgênero" },
  { value: "transgender", name: "Transgênero" },
  { value: "non_binary", name: "Gênero não-binário" },
  { value: "non_declared", name: "Prefiro não declarar" },
];

export const chronicDiseaseList = [
  { value: "yes", name: "Sim" },
  { value: "no", name: "Não" },
  { value: "non_declared", name: "Prefiro não declarar" },
];

export const physicalDisabilityList = [
  { value: "yes", name: "Sim" },
  { value: "no", name: "Não" },
  { value: "non_declared", name: "Prefiro não declarar" },
];

export const domesticViolenceList = [
  { value: "yes", name: "Sim" },
  { value: "no", name: "Não" },
  { value: "non_declared", name: "Prefiro não declarar" },
];

export const scholarityList = [
  {
    value: "uncompleted_elementary",
    name: "Fundamental incompleto",
  },
  {
    value: "in_progress_elementary",
    name: "Fundamental em andamento",
  },
  {
    value: "completed_elementary",
    name: "Fundamental completo",
  },
  {
    value: "uncompleted_highschool",
    name: "Médio incompleto",
  },
  {
    value: "in_progress_highschool",
    name: "Médio em andamento",
  },
  {
    value: "completed_highschool",
    name: "Médio completo",
  },
  {
    value: "uncompleted_college",
    name: "Superior Incompleto",
  },
  {
    value: "in_progress_college",
    name: "Superior em andamento",
  },
  {
    value: "completed_college",
    name: "Superior completo",
  },
  {
    value: "uncompleted_postgraduate",
    name: "Pós-graduação incompleta",
  },
  {
    value: "in_progress_postgraduate",
    name: "Pós-graduação em Andamento",
  },
  {
    value: "completed_postgraduate",
    name: "Pós-graduação Completa",
  },
];

export const graduationStatusList = [
  { value: "on_progress", name: "Pós-gradução em andamento" },
  { value: "completed", name: "Pós-gradução Completa" },
];

export const workList = [
  { value: "no", name: "Não" },
  { value: "mei", name: "MEI" },
  { value: "clt", name: "Emprego com CLT" },
  { value: "independent", name: "Autônomo registrado formalmente" },
  { value: "freelance", name: "Contratado por tempo determinado" },
  { value: "entrepreneur", name: "Empresa registrada em seu nome" },
];

export const workActivitiesList = [
  { name: "Agente de Saúde" },
  { name: "Auxiliar Administrativo" },
  { name: "Contador" },
  { name: "Costureira" },
  { name: "Cozinheira(o)" },
  { name: "Designer Gráfico" },
  { name: "Doméstica | Diarista" },
  { name: "Educador Físico" },
  { name: "Eletricista" },
  { name: "Enfermeira(o)" },
  { name: "Estudante" },
  { name: "Fisioterapeuta" },
  { name: "Gari" },
  { name: "Lojista" },
  { name: "Marketing Digital" },
  { name: "Massoterapeuta" },
  { name: "Motorista" },
  { name: "Porteiro" },
  { name: "Programador" },
  { name: "Revendedora de Cosméticos" },
  { name: "Técnica de Enfermagem" },
  { name: "Vendedor | Consultor de vendas" },
  { name: "Zelador" },
];

export const governmentAidsList = [
  { name: "Bolsa Família" },
  { name: "Aposentadoria" },
  { name: "Benefício de Prestação Continuada (BPC - LOAS)" },
];

export const diseasesList = [
  { name: "Asma" },
  { name: "Câncer" },
  { name: "Diabetes" },
  { name: "Alzheimer" },
  { name: "HIV | AIDS" },
  { name: "Obesidade" },
  { name: "Parkinson" },
  { name: "Depressão" },
  { name: "Hipertensão" },
  { name: "Síndrome de Down" },
  { name: "Espectro Austista (TEA)" },
  { name: "Paralisia Cerebral" },
];

export const hobbiesList = [
  { name: "Ler" },
  { name: "Ouvir música" },
  { name: "Praticar esporte" },
];

export const familyItemsList = [
  { registerName: "familyGroup.radio", name: "Rádio" },
  { registerName: "familyGroup.fridge", name: "Geladeira" },
  { registerName: "familyGroup.microwave", name: "Micro-ondas" },
  { registerName: "familyGroup.washingMachine", name: "Máquina de lavar" },
  { registerName: "familyGroup.stove", name: "Fogão" },
  { registerName: "familyGroup.tv", name: "Televisão" },
];

export const familyIncomeList = [
  { value: "0 - 1.000,00 R$" },
  { value: "1.001,00 - 2.000,00 R$" },
  { value: "2.001,00 - 3.000,00 R$" },
  { value: "3.001,00 R$ 4.000,00 R$" },
  { value: "4.001,00 ou mais" },
  { value: "Prefiro não declarar" },
];

export const monthOptions = [
  { value: 1, label: "Janeiro" },
  { value: 2, label: "Fevereiro" },
  { value: 3, label: "Março" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Maio" },
  { value: 6, label: "Junho" },
  { value: 7, label: "Julho" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Setembro" },
  { value: 10, label: "Outubro" },
  { value: 11, label: "Novembro" },
  { value: 12, label: "Dezembro" },
];

export const evaluationCriteriaList = [
  {
    id: 0,
    item: "Nota",
    name: "nota",
  },
  {
    id: 1,
    item: "Frequência",
    name: "frequencia",
  },
  {
    id: 3,
    item: "Participação",
    name: "participacao",
  },
];
