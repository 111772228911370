import React from "react";

import * as S from './styles'

//Images
import {
  CloseModal,
} from '../../assets'

const PolicyScreen = ({ onClose }) => {
  return (
    <S.Overlay>
      <S.ContainerModal>
      <h1>POL&Iacute;TICA DE PRIVACIDADE
        <S.ContainerCloseModal onClick={onClose}>
          <S.ImageCloseModal src={CloseModal} alt="Excluir" />
          <S.Trash>Fechar</S.Trash>
        </S.ContainerCloseModal>
      </h1>
      <p>POL&Iacute;TICA DE PRIVACIDADE</p>
<p>A presente&nbsp;Pol&iacute;tica de Privacidade&nbsp;tem por finalidade demonstrar o compromisso da&nbsp;FAVELA INC. pessoa jur&iacute;dica, com sede na Av. Pres. Jo&atilde;o Goulart, 825 - Vidigal, Rio de Janeiro - RJ, 22452-000, inscrita no CNPJ sob o n&ordm; 34.249.794/0001-43; com a privacidade e a prote&ccedil;&atilde;o dos dados pessoais coletados de seus&nbsp;USU&Aacute;RIOS, estabelecendo as regras sobre a coleta, registro, armazenamento, uso, compartilhamento, enriquecimento e elimina&ccedil;&atilde;o dos dados coletados dentro do escopo dos servi&ccedil;os e funcionalidades da plataforma Vision e do Instituto Favela Inc., de acordo com as leis em vigor. Como condi&ccedil;&atilde;o para acesso e uso das funcionalidades exclusivas da plataforma Vision.</p>
<p>O&nbsp;USU&Aacute;RIO&nbsp;declara que fez a leitura completa e atenta das regras deste documento e dos&nbsp;Termos de Uso, estando plenamente ciente conferindo assim sua livre e expressa concord&acirc;ncia com os termos aqui estipulados. Caso n&atilde;o esteja de acordo com estas Diretivas, dever&aacute; descontinuar o seu acesso. Leia-se&nbsp;USU&Aacute;RIO&nbsp;qualquer pessoa civil ou jur&iacute;dica, entes p&uacute;blicos e afins, para fins de adequa&ccedil;&atilde;o &agrave; pol&iacute;tica de privacidade desta plataforma.&nbsp;</p>
<p>&nbsp;1. DADOS COLETADOS, FORMA E FINALIDADE DA COLETA</p>
<p>1.1. Os dados s&atilde;o coletados quando as ONGs inserem ou submetem voluntariamente ao acessar e interagir com as funcionalidades disponibilizadas na plataforma Vision, que inclui:</p>
<div align="center">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>TIPOS DE DADOS</p>
                </td>
                <td>
                    <p>DADOS PESSOAIS</p>
                </td>
                <td>
                    <p>FINALIDADE DE USO DOS DADOS</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>DADOS CADASTRAIS</p>
                </td>
                <td><br/>
                    <p>Nome completo;</p>
                    <p>E-mail;</p>
                    <p>Data de Nascimento;</p>
                    <p>N&uacute;mero do telefone;</p>
                    <p>Endere&ccedil;o Completo;</p>
                    <p>Grupo Familiar;</p>
                </td>
                <td><br/>
                    <div align="left">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Identificar o&nbsp;USU&Aacute;RIO;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Portabilidade dos dados cadastrais para outro Controlador do mesmo ramo de nossa atua&ccedil;&atilde;o, caso solicitado pelo&nbsp;USU&Aacute;RIO;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cumprir as obriga&ccedil;&otilde;es decorrentes do uso dos servi&ccedil;os da plataforma Vision;&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Informar sobre novidades, funcionalidades, conte&uacute;dos, not&iacute;cias e demais eventos relevantes para a manuten&ccedil;&atilde;o do relacionamento com o&nbsp;USU&Aacute;RIO;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Promover nossos produtos e servi&ccedil;os;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Responder a solicita&ccedil;&otilde;es e pedidos de informa&ccedil;&otilde;es do&nbsp;USU&Aacute;RIO;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cumprimento de obriga&ccedil;&otilde;es legais e regulat&oacute;rias;</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div><br/>
                </td>
            </tr>
            <tr>
                <td>
                    <p>DADOS DE IDENTIFICA&Ccedil;&Atilde;O DIGITAL</p>
                </td>
                <td><br/>
                    <p>Endere&ccedil;o IP</p>
                    <p>Registros de intera&ccedil;&otilde;es com este website,</p>
                    <p>Telas acessadas, dispositivo (vers&atilde;o do sistema operacional, Geolocaliza&ccedil;&atilde;o,</p>
                    <p>Aplicativos instalados, se necess&aacute;rio),</p>
                    <p>Session ID</p><br/>
                </td>
                <td><br/>
                    <div align="left">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>Cumprir obriga&ccedil;&atilde;o estabelecida pelo Marco Civil da Internet - Lei 12.965/2014;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Identificar o&nbsp;USU&Aacute;RIO;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Avalia&ccedil;&atilde;o do uso e utilidade dos servi&ccedil;os que prestamos no&nbsp;PORTAL FAVELA.INC;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Fins estat&iacute;sticos e de seguran&ccedil;a;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cumprimento de obriga&ccedil;&otilde;es legais e regulat&oacute;rias;</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div><br/>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>&nbsp;</p>
<p>1.2. N&atilde;o somos respons&aacute;veis pela precis&atilde;o, veracidade ou falta dela nas informa&ccedil;&otilde;es que o&nbsp;USU&Aacute;RIO&nbsp;prestar &agrave; plataforma Vision ou pela sua desatualiza&ccedil;&atilde;o, quando &eacute; de sua responsabilidade prest&aacute;-las com exatid&atilde;o ou atualiz&aacute;-las.</p>
<p>1.2.1. Todas as tecnologias utilizadas respeitar&atilde;o sempre a legisla&ccedil;&atilde;o vigente e os termos desta&nbsp;Pol&iacute;tica de Privacidade.</p>
<p>1.3. O consentimento que o&nbsp;USU&Aacute;RIO&nbsp;fornece para as finalidades de uso dos dados &eacute; coletado de forma individual, clara, espec&iacute;fica e leg&iacute;tima.</p>
<p>1.4. Por meio do canal de atendimento&nbsp;CONTATO&nbsp;o&nbsp;USU&Aacute;RIO&nbsp;poder&aacute; alterar suas concess&otilde;es de consentimento para tratamento de seus dados, conceder novas permiss&otilde;es ou retirar seu consentimento para as permiss&otilde;es atuais, sendo avisado das consequ&ecirc;ncias que a retirada de consentimento poder&aacute; causar.</p>
<p>1.5. Os dados coletados e as atividades registradas tamb&eacute;m poder&atilde;o ser compartilhados:</p>
<p>a) Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver determina&ccedil;&atilde;o legal, requerimento, requisi&ccedil;&atilde;o ou ordem judicial;</p>
<p>b) De forma autom&aacute;tica em caso de movimenta&ccedil;&otilde;es societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e incorpora&ccedil;&atilde;o.</p>
<p>1.6. A base de dados formada por meio da coleta de dados na plataforma Vision &eacute; de nossa propriedade e responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necess&aacute;rios, ser&atilde;o feitos dentro dos limites e prop&oacute;sitos dos nossos neg&oacute;cios e descritos nesta&nbsp;Pol&iacute;tica de Privacidade.</p>
<p>1.6.1.&nbsp;Informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o pessoal n&atilde;o ser&atilde;o vendidas ou alugadas pela plataforma Vision ou FAVELA.INC.</p>
<p>1.6.2. O&nbsp;USU&Aacute;RIO&nbsp;&eacute;&nbsp;correspons&aacute;vel&nbsp;pelo sigilo de seus dados pessoais. O compartilhamento de senhas e dados de acesso viola esta&nbsp;Pol&iacute;tica de Privacidade&nbsp;e os&nbsp;Termos de Uso&nbsp;da plataforma Vision.</p>
<p>1.6.3. N&atilde;o ser&atilde;o enviados e-mails solicitando que o&nbsp;USU&Aacute;RIOS&nbsp;encaminhe seus dados cadastrais.</p>
<p>1.7. Internamente, os dados que coletamos s&atilde;o acessados somente por profissionais devidamente autorizados, respeitando os princ&iacute;pios de proporcionalidade, necessidade e relev&acirc;ncia para os objetivos da plataforma Vision, al&eacute;m do compromisso de confidencialidade e preserva&ccedil;&atilde;o da privacidade nos termos desta&nbsp;Pol&iacute;tica de Privacidade.</p>
<p>2. COMO ARMAZENAMOS OS DADOS E REGISTROS</p>
<p>2.1. Os dados coletados e os registros de atividades ser&atilde;o armazenados em ambiente seguro e controlado pelo prazo m&iacute;nimo estipulado conforme a tabela abaixo:</p>
<div align="center">
    <table>
        <tbody>
            <tr>
                <td>
                    <p>DADOS PESSOAIS</p>
                </td>
                <td>
                    <p>PRAZO DE ARMAZENAMENTO</p>
                </td>
                <td>
                    <p>FUNDAMENTO LEGAL</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Dados cadastrais</p>
                </td>
                <td>
                    <p>5 anos ap&oacute;s o t&eacute;rmino da rela&ccedil;&atilde;o</p>
                </td>
                <td>
                    <p>Art. 12 e 34 do C&oacute;digo de Defesa do Consumidor</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Dados de identifica&ccedil;&atilde;o digital</p>
                </td>
                <td>
                    <p>6 meses ap&oacute;s o t&eacute;rmino da rela&ccedil;&atilde;o</p>
                </td>
                <td>
                    <p>Art. 15, Marco Civil da Internet</p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<p>&nbsp;</p>
<p>&nbsp;2.2. Caso haja solicita&ccedil;&atilde;o do&nbsp;USU&Aacute;RIOS, os dados poder&atilde;o ser apagados antes desse prazo. No entanto, pode ocorrer de os dados precisarem ser mantidos por per&iacute;odo superior, por motivo de lei, ordem judicial, preven&ccedil;&atilde;o &agrave; fraude (art. 11, II, &ldquo;a&rdquo; da Lei Geral de Prote&ccedil;&atilde;o de Dados &ldquo;LGPD&rdquo;, Lei n&ordm; 13.709/2018), prote&ccedil;&atilde;o ao cr&eacute;dito (art. 7&ordm;, X, LGPD) e outros interesses leg&iacute;timos, em conformidade com o artigo 10 da LGPD. Findo o prazo e a necessidade legal, ser&atilde;o exclu&iacute;dos com uso de m&eacute;todos de descarte seguro, ou utilizados de forma anonimizada para fins estat&iacute;sticos.</p>
<p>2.3. Os dados coletados ser&atilde;o armazenados no banco de dados AWS. Da empresa&nbsp;Amazon Web Service.Inc, em servidores localizados em diversos pa&iacute;ses, bem como em ambiente de uso de recursos ou servidores na nuvem&nbsp;(cloud computing and AWS Cloud Formation), o que enseja, neste &uacute;ltimo caso, transfer&ecirc;ncia ou processamento dos dados fora do Brasil, na Regi&atilde;o de S&atilde;o Paulo / Brasil.</p>
<p>2.4. Poder&aacute; ser necess&aacute;rio que a plataforma Vision, transmita relat&oacute;rio com dados pessoais a outras empresas afiliadas dentro do&nbsp;Favela INC., ou a um prestador de servi&ccedil;os externo, que poder&aacute; igualmente estar sediado fora do Brasil, mas que atua exclusivamente em nome da&nbsp;FAVELA.INC. Tais prestadores de servi&ccedil;o podem, por exemplo, ser contratados para o envio de amostras de produtos e distribui&ccedil;&atilde;o de material social. A plataforma Vision e o&nbsp;FAVELA.INC&nbsp;exigem que todas as empresas afiliadas e seus prestadores de servi&ccedil;o externos utilizem os dados pessoais do&nbsp;USU&Aacute;RIO&nbsp;em conformidade com esta&nbsp;Pol&iacute;tica de Privacidade.</p>
<p><br/></p>
<p>3. EXIBI&Ccedil;&Atilde;O, RETIFICA&Ccedil;&Atilde;O, LIMITA&Ccedil;&Atilde;O, OPOSI&Ccedil;&Atilde;O E EXCLUS&Atilde;O DE DADOS</p>
<p>3.1. O&nbsp;USU&Aacute;RIO&nbsp;pode solicitar a exibi&ccedil;&atilde;o ou retifica&ccedil;&atilde;o de seus dados pessoais, por meio dos seguintes canais:</p>
<p>(i) CONTATO: acess&iacute;vel atrav&eacute;s do presente;</p>
<p>(ii) SAC: acess&iacute;vel atrav&eacute;s do seguinte endere&ccedil;o de e-mail&nbsp;<a href="mailto:suporte.vision@favelainc.com">suporte.vision@favelainc.com</a>;</p>
<p>3.2 Pela mesma ferramenta, o&nbsp;USU&Aacute;RIO&nbsp;poder&aacute; tamb&eacute;m:</p>
<p>(i) requerer a limita&ccedil;&atilde;o do uso de seus dados pessoais;</p>
<p>(ii) manifestar sua oposi&ccedil;&atilde;o ao uso de seus dados pessoais;</p>
<p>(iii) solicitar a portabilidade dos dados cadastrais, em formato leg&iacute;vel, para outro Controlador do mesmo ramo de nossa atua&ccedil;&atilde;o;</p>
<p>(iv) solicitar a exclus&atilde;o de seus dados pessoais que coletamos, desde que eventuais Contas de Acesso tenham sido canceladas e decorrido o prazo legal m&iacute;nimo relacionado &agrave; guarda de dados.</p>
<p>3.2.1. Caso o&nbsp;USU&Aacute;RIO&nbsp;retire seu consentimento para finalidades fundamentais ao regular funcionamento da plataforma Vision, os servi&ccedil;os e funcionalidades poder&atilde;o ficar indispon&iacute;veis.</p>
<p>3.2.2. Caso o&nbsp;USU&Aacute;RIO&nbsp;n&atilde;o conceda seu consentimento para as finalidades facultativas, relacionadas ao envio de informa&ccedil;&otilde;es, novidades, conte&uacute;dos, not&iacute;cias e demais eventos relevantes para a manuten&ccedil;&atilde;o do relacionamento, os servi&ccedil;os e funcionalidades da plataforma Vision continuar&atilde;o sendo disponibilizados regularmente.</p>
<p>3.3. Para fins de auditoria, seguran&ccedil;a, controle de fraudes e preserva&ccedil;&atilde;o de direitos, poderemos permanecer com o hist&oacute;rico de registro dos dados do&nbsp;USU&Aacute;RIO&nbsp;por prazo maior nas hip&oacute;teses que a lei ou norma regulat&oacute;ria assim estabelecer ou para preserva&ccedil;&atilde;o de direitos.</p>
<p><br/></p>
<p>4.&nbsp;NOT&Iacute;CIAS E INFORMA&Ccedil;&Otilde;ES</p>
<p>4.1. Caso tenha se cadastrado para receber as informa&ccedil;&otilde;es da plataforma Vision, concorda em receber not&iacute;cias, publicidade personalizada e programas dos nossos produtos, servi&ccedil;os e outras informa&ccedil;&otilde;es do Instituto&nbsp;FAVELA INC.</p>
<p>4.1.1. A&nbsp;newsletter&nbsp;FAVELA.INC&nbsp;inclui novidades e outras informa&ccedil;&otilde;es sobre a&nbsp;FAVELA.INC&nbsp;ou outras organiza&ccedil;&otilde;es e projetos sociais vinculados.</p>
<p>4.1.2. Ao se cadastrar para receber a newsletter&nbsp;FAVELA.INC, o&nbsp;USU&Aacute;RIO&nbsp;declara concordar que, a&nbsp;FAVELA.INC&nbsp;realize uma compila&ccedil;&atilde;o personalizada de not&iacute;cias e outras informa&ccedil;&otilde;es, bem como avalie seus padr&otilde;es de uso do site, para o envio de newsletter personalizada que atenda &agrave;s necessidades e interesses do&nbsp;USU&Aacute;RIO.</p>
<p>4.1.3. Os dados tamb&eacute;m s&atilde;o guardados e usados para efeitos de pesquisas. Para tal, s&oacute; entraremos em contato com o&nbsp;USU&Aacute;RIO&nbsp;por e-mail. Por &uacute;ltimo, tamb&eacute;m usaremos seus dados para analisar e melhorar a efic&aacute;cia dos nossos sites.</p>
<p>4.1.4. Se o&nbsp;USU&Aacute;RIO&nbsp;n&atilde;o quiser mais receber a&nbsp;newsletter&nbsp;FAVELA.INC, poder&aacute; cancelar o cadastro a qualquer momento. Para isso, o&nbsp;USU&Aacute;RIO&nbsp;poder&aacute; clicar no link presente em todas as&nbsp;newsletters&nbsp;para ser encaminhado ao processo de cancelamento ou poder&aacute; utilizar um dos meios de comunica&ccedil;&atilde;o a seguir:</p>
<p>(i) CONTATO: acess&iacute;vel atrav&eacute;s do presente&nbsp;<a href="mailto:suporte.vision@favelainc.com">suporte.vision@favelainc.com</a>;&nbsp;</p>
<p>(ii) SAC: acess&iacute;vel atrav&eacute;s do seguinte endere&ccedil;o de e-mail:&nbsp;<a href="mailto:suporte.vision@favelainc.com">suporte.vision@favelainc.com</a>;&nbsp;</p>
<p><br/></p>
<p>5. DISPOSI&Ccedil;&Otilde;ES GERAIS</p>
<p><br/>5.1. N&atilde;o utilizamos nenhum tipo de decis&atilde;o automatizada que impacte o USU&Aacute;RIO.<br/><br/>5.2. Temos o direito de alterar o teor desta Pol&iacute;tica de Privacidade a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequa&ccedil;&atilde;o e conformidade legal de disposi&ccedil;&atilde;o de lei ou norma que tenha for&ccedil;a jur&iacute;dica equivalente, cabendo ao USU&Aacute;RIO verific&aacute;-la sempre que efetuar o acesso na plataforma Vision, ou, no PORTAL FAVELA.INC.<br/><br/>5.2.1. Ocorrendo atualiza&ccedil;&otilde;es neste documento e que demandem nova coleta de consentimento, ser&atilde;o notificadas por meio dos contatos que nos fornece no cadastro.<br/><br/>5.3 Em caso de qualquer d&uacute;vida com rela&ccedil;&atilde;o &agrave;s disposi&ccedil;&otilde;es constantes desta Pol&iacute;tica de Privacidade, o USU&Aacute;RIO poder&aacute; entrar em contato por meio dos canais de atendimento apontados a seguir:</p>
<p><br/>(i) CONTATO: acess&iacute;vel atrav&eacute;s do presente e-mail:&nbsp;<a href="mailto:suporte.vision@favelainc.com">suporte.vision@favelainc.com</a>;&nbsp;</p>
<p><br/>(ii) SAC: acess&iacute;vel atrav&eacute;s do seguinte endere&ccedil;o de e-mail:&nbsp;<a href="mailto:suporte.vision@favelainc.com">suporte.vision@favelainc.com</a>;&nbsp;</p>
<p><br/>5.4. Caso empresas terceirizadas realizem o processamento de quaisquer dados que coletamos, elas dever&atilde;o respeitar as condi&ccedil;&otilde;es aqui estipuladas e nossas normas de Seguran&ccedil;a da Informa&ccedil;&atilde;o, obrigatoriamente.</p>
<p><br/><br/>5.5. Caso alguma disposi&ccedil;&atilde;o desta&nbsp;Pol&iacute;tica de Privacidade&nbsp;seja considerada ilegal ou ileg&iacute;tima por autoridade da localidade em que resida ou da sua conex&atilde;o &agrave; Internet, as demais condi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e efeito.</p>
<p><br/>5.6. O&nbsp;USU&Aacute;RIO&nbsp;reconhece que toda comunica&ccedil;&atilde;o realizada por e-mail (aos endere&ccedil;os informados no seu cadastro), SMS, aplicativos de comunica&ccedil;&atilde;o instant&acirc;nea ou qualquer outra forma digital, virtual e digital tamb&eacute;m s&atilde;o v&aacute;lidas, eficazes e suficiente para a divulga&ccedil;&atilde;o de qualquer assunto que se refira aos servi&ccedil;os que prestamos, bem como &agrave;s condi&ccedil;&otilde;es de sua presta&ccedil;&atilde;o ou a qualquer outro assunto nele abordado, ressalvadas as disposi&ccedil;&otilde;es expressamente diversas previstas nesta Pol&iacute;tica de Privacidade.</p>
<p><br/>6. LEI APLIC&Aacute;VEL E JURISDI&Ccedil;&Atilde;O</p>
<p>6.1. A presente&nbsp;Pol&iacute;tica de Privacidade&nbsp;ser&aacute; regida e interpretada segundo a legisla&ccedil;&atilde;o brasileira, no idioma portugu&ecirc;s, sendo eleito o Foro da Comarca da Capital do Estado do Rio de Janeiro competente para dirimir qualquer lit&iacute;gio ou controv&eacute;rsia envolvendo o presente documento, salvo ressalva espec&iacute;fica de compet&ecirc;ncia pessoal, territorial ou funcional pela legisla&ccedil;&atilde;o aplic&aacute;vel.</p>
<p><br/></p>
<p><br/></p>
<p><br/></p>

        </S.ContainerModal>
    </S.Overlay>
  );
};

export default PolicyScreen;
