import React from 'react';
import { useNavigate } from 'react-router-dom';

//Icons
import { BackArrowWhite } from '../../../assets';

// Styles
import * as S from './styles';

export default function BtnBackWhite() {
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate(-1)}>
      <S.ImgBack src={BackArrowWhite} alt='Voltar' />
    </S.Container>
  );
}