import React from "react";
import { Navigate } from "react-router-dom";

export default function ONGRegistrationRoute({
  children,
  step,
  defaultRedirect = "/cadastro/contatos-e-dados-oficiais",
}) {
  const idToken = localStorage.getItem("idToken");
  const RegistrationCompleted =
    JSON.parse(localStorage.getItem("FormOngfinished")) || false;
    
  const currentStep = JSON.parse(localStorage.getItem("formStep")) || {
    pathname: RegistrationCompleted === false ? defaultRedirect : "/tutorial",
    step: 0,
  };

  return (
    <>
      {currentStep && RegistrationCompleted === false && idToken ? (
        currentStep.step >= step ? (
          children
        ) : (
          <Navigate to={currentStep.pathname} replace />
        )
      ) : (
        <Navigate to="/login" replace />
      )}
    </>
  );
}
