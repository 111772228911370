import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 8.438rem);

  @media (min-width: 768px) {
    max-height: calc(100vh - 8.438rem);
  }

  @media (min-width: 1024px) {
    padding-top: 2.813rem;
    margin: 0 auto;
    width: 56%;
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    width: 48%;
  }
`;

export const ContentTwo = styled.div`
  @media (min-width: 1024px) {
    width: 48%;
  }
`;

export const Box = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    display: block;
    height: 45px;
  }
`;

export const SelectActivities = styled.button`
  width: 100%;
  height: 48px;
  background: #7fbe30;
  border: none;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0.688rem;

  @media (min-width: 1024px) {
  }
`;

export const SelectWatched = styled.button`
  width: 100%;
  height: 48px;
  background: #5484ed;
  border: none;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;

  @media (min-width: 1024px) {
  }
`;

export const Paragraph = styled.p``;

export const Form = styled.form``;

export const BoxLink = styled.div`
  padding-top: 1rem;
  display: flex;
`;

export const Link = styled.a`
  color: red;
  font-size: 1.125rem;
  font-weight: 600;
  font-family: "Open Sans";
`;
