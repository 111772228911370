

import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Modal = styled.div`
  position: absolute;
  top: ${props => props.top ? props.top : '2.5rem'};
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 416px;
  height: 10rem;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  background-color: ${colors.orange};
  z-index: 1;
  
  @media (max-width: 425px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    margin-top: 12px;
  }

  @media (max-width: 425px) {
    height: auto;
    right: 0;
    z-index: 1;
  }
`;

export const BtnClose = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

export const ImageClose = styled.img`
  width: 100%;
`;

export const ModalInternal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  height: 80%;
`;

export const ModalText1 = styled.p`
  font-size: 0.9375rem;
  font-weight: 700;
  font-family: Open Sans;
`;

export const ModalText2 = styled.p`
  font-size: 0.8125rem;
  font-weight: 400;
  font-family: Open Sans;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  margin-top: 18px;
`;

export const BtnCancel = styled.span`
  width:97px;
  height:30px;
  border-radius: 1000px;
  background: ${colors.white};
  color: ${colors.orange};
  font-size: 0.875rem;
  font-family: Open Sans;
  font-weight:700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 425px) {
    margin-bottom: 1rem;
  }
`;

export const BtnDelete  = styled.span`
  width:159px;
  height:30px;
  border-radius: 1000px;
  background-color: #0080ff;
  color: #fff;
  font-size: 0.875rem;
  font-family: Open Sans;
  font-weight:700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 425px) {
    margin-bottom: .375rem;
  }
`;
