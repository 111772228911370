import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// libs
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useApi } from "../../../../providers/Api";

//Component
import Footer from "../../../../components/Footer/footerButtons";
import Input from "../../../../components/Inputs";
import InputRadioAddItem from "../../../../components/Inputs/InputRadioAddItem";
import InputRadio from "../../../../components/Inputs/radio";
import Title from "../../../../components/Title";
import Layout from "../layout";

import MaskedInput from "../../../../utils/Masks/MaskGlobal";
import { cpfMask } from "../../../../utils/Masks/cpf";

//Assets
import {
  Email,
  Facebook,
  IconContacts,
  Instagram,
  Linkedin,
  Site,
  Telephone,
  Twitter,
  Whatsapp,
  Youtube,
} from "../../../../assets";

import { api } from "../../../../modules/api";
import { changePermission } from "../../../../utils/permissions";
import * as S from "./styles";

const OfficialContactsAndData = () => {
  const navigate = useNavigate();
  const { getMyUser } = useApi();
  const [user, setUser] = useState({});
  const isDescktop = window.innerWidth > 768;
  const [hasError, setHasError] = useState(undefined);
  const [isError, setIsError] = useState({
    cpfNumber: undefined,
    cnpjNumber: undefined,
    politicalParty: undefined,
    religiousInstitution: undefined,
    partnerships: undefined,
    initiativeName: undefined,
    year: undefined,
    presidentName: undefined,
    community: undefined,
    referencePoint: undefined,
    cell: undefined,
  });
  const [form, setForm] = useState({
    registration: "",
    cpfNumber: undefined,
    year: undefined,
    initiativeName: undefined,
    cnpjNumber: undefined,
    socialReason: undefined,
    foundedIn: undefined,
    fantasyName: undefined,
    presidentName: undefined,
    zipCodeCNPJ: undefined,
    streetCNPJ: undefined,
    numberCNPJ: undefined,
    countyCNPJ: undefined,
    neighborhoodCNPJ: undefined,
    stateCNPJ: undefined,
    community: undefined,
    cep: undefined,
    referencePoint: undefined,
    street: undefined,
    number: undefined,
    complement: undefined,
    neighborhood: undefined,
    county: undefined,
    state: undefined,
    email: undefined,
    telephone: undefined,
    cell: undefined,
    site: undefined,
    whatsapp: undefined,
    facebook: undefined,
    instagram: undefined,
    twitter: undefined,
    linkedin: undefined,
    youTube: undefined,

    politicalParty: {
      value: "",
      values: [],
      has: false,
    },
    religiousInstitution: {
      value: "",
      values: [],
      has: false,
    },
    partnerships: {
      value: "",
      values: [],
      has: false,
    },
  });
  const [largura, setlargura] = useState(window.innerWidth);
  const [formErrors, setFormErrors] = useState({});

  const handleGetMyUser = async () => {
    const response = await getMyUser();
    setUser(response);
    setForm({
      ...form,
      registration: response?.ong_id.document_type,
      cnpjNumber: response?.ong_id.document_number,
      cpfNumber: response?.ong_id?.document_number,
      initiativeName: response?.ong_id.iniciative_name,
      year: response?.ong_id.birth_year,
      foundedIn: response?.ong_id.birth_year,
      fantasyName: response?.ong_id.trade_name,
      presidentName: response?.ong_id?.president_name,
      cep: response?.ong_id.ong_address.postal_code,
      community: response?.ong_id.ong_address.community,
      referencePoint: response?.ong_id.ong_address.landmark,
      street: response?.ong_id.ong_address.street,
      number: response?.ong_id.ong_address.number,
      neighborhood: response?.ong_id.ong_address.district,
      socialReason: response?.ong_id.iniciative_name,
      complement: response?.ong_id.ong_address.adjunct,
      county: response?.ong_id?.cnpj_address?.city,
      state: response?.ong_id?.cnpj_address?.state,
      zipCodeCNPJ: response?.ong_id?.cnpj_address?.postal_code,
      numberCNPJ: response?.ong_id?.cnpj_address?.number,
      streetCNPJ: response?.ong_id?.cnpj_address?.street,
      countyCNPJ: response?.ong_id?.cnpj_address?.city,
      neighborhoodCNPJ: response?.ong_id?.cnpj_address?.district,
      stateCNPJ: response?.ong_id?.cnpj_address?.state,
      telephone: response?.ong_id?.ong_contacts?.phone,
      cell: response?.ong_id?.ong_contacts?.cell_num,
      email: response?.ong_id?.ong_contacts?.email,
      site: response?.ong_id?.ong_contacts?.website,
      whatsapp: response?.ong_id?.ong_social_medias?.whatsapp,
      facebook: response?.ong_id?.ong_social_medias?.facebook,
      twitter: response?.ong_id?.ong_social_medias?.twitter,
      linkedin: response?.ong_id?.ong_social_medias?.linkedin,
      youTube: response?.ong_id?.ong_social_medias?.youtube,
      instagram: response?.ong_id?.ong_social_medias?.instagram,
      politicalParty: {
        values: response?.ong_id?.political_parties?.map((item) => item.name),
        value: "",
        has: !!response?.ong_id?.political_parties?.length,
      },
      partnerships: {
        values: response?.ong_id?.ongs_parties?.map((item) => item.name),
        value: "",
        has: !!response?.ong_id?.ongs_parties?.length,
      },
      religiousInstitution: {
        values: response?.ong_id?.religious_parties?.map((item) => item.name),
        value: "",
        has: !!response?.ong_id?.religious_parties?.length,
      },
    });
  };

  useEffect(() => {
    handleGetMyUser();
    const handleResize = () => {
      setlargura(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const validateForm = () => {
    const errors = {};
    const requiredFieldsCPF = [
      "initiativeName",
      "cpfNumber",
      "year",
      "presidentName",
      "community",
      "referencePoint",
      "street",
      "number",
      "complement",
      "cell",
    ];

    const requiredFieldsCNPJ = [
      "cnpjNumber",
      "socialReason",
      "foundedIn",
      "fantasyName",
      "presidentName",
      "zipCodeCNPJ",
      "streetCNPJ",
      "numberCNPJ",
      "neighborhoodCNPJ",
      "stateCNPJ",
      "community",
      "referencePoint",
      "street",
      "number",
      "complement",
      "cell",
      "countyCNPJ",
    ];

    if (!form.registration) {
      errors.registration = true;
    }

    if (form.registration === "CPF") {
      for (const field of requiredFieldsCPF) {
        if (!form[field]) {
          errors[field] = true;
        }
      }
    }

    if (form.registration === "CNPJ") {
      for (const field of requiredFieldsCNPJ) {
        if (!form[field]) {
          errors[field] = true;
        }
      }
    }

    setFormErrors(errors);
  };
  const handleChange = (ev) => {
    ev.preventDefault();
    let validated = form.registration === "CPF";
    if (validated) {
    }
    const value = ev.target.value;
    const name = ev.target.name;

    const updateForm = {
      ...form,
      [name]: value,
    };

    setForm(updateForm);
  };

  const handleCheckedRegistration = (ev) => {
    const { name, id } = ev.target;
    const updateForm = {
      ...form,
      [name]: id,
    };

    setForm(updateForm);
  };

  const handleChangeMask = (ev) => {
    const { value } = ev.target;
    const updateForm = {
      ...form,
      [ev.target.name]: value,
    };
    setForm(updateForm);
  };

  const onChange = (e) => {
    const { value, name } = e.target;

    setForm((v) => ({
      ...v,
      [name]: {
        ...v[name],
        values: value ? [...v[name].values] : [],
        has: value === "true",
      },
    }));

    setIsError({
      ...isError,
      [name]: false,
    });
  };

  const handleNewItemChange = (e) => {
    const { value, name } = e.target;

    setForm((v) => ({
      ...v,
      [name]: {
        ...v[name],
        value,
      },
    }));
  };

  const addNewItem = (value, name) => {
    if (!value || form[name].values.includes(value)) return;

    setForm((v) => ({
      ...v,
      [name]: {
        ...v[name],
        values: [...v[name].values, value],
        value: "",
      },
    }));
  };

  const handleDeleteItem = (name, nameADD, item) => {
    setForm((v) => ({
      ...v,
      [name]: {
        ...v[name],
        values: v[name].values.filter((value) => value !== item),
      },
    }));
  };

  const handleScreenValidation = () => {
    const { religiousInstitution, politicalParty, partnerships } = form;

    const political_party =
      politicalParty.type === "Sim" &&
      politicalParty.politicalPartyList.length === 0;
    const religious_institution =
      religiousInstitution.type === "Sim" &&
      religiousInstitution.religiousInstitutionList.length === 0;
    const partner_ships =
      partnerships.type === "Sim" && partnerships.partnershipsList.length === 0;

    if (political_party || religious_institution || partner_ships) {
      setIsError({
        ...isError,
        politicalParty: political_party,
        religiousInstitution: religious_institution,
        partnerships: partner_ships,
      });

      return false;
    } else {
      return true;
    }
  };

  changePermission(0);
  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (handleScreenValidation()) {
      localStorage.setItem("@Vision/document", form.registration);
      const religious_parties = !form.religiousInstitution.has
        ? []
        : [
            ...form.religiousInstitution.values.map((value) => {
              return { name: value };
            }),
          ];

      const political_parties = !form.politicalParty.has
        ? []
        : [
            ...form.politicalParty.values.map((value) => {
              return { name: value };
            }),
          ];

      const ongs_parties = !form.partnerships.has
        ? []
        : [
            ...form.partnerships.values.map((value) => {
              return { name: value };
            }),
          ];

      let formatted_cpf =
        form.registration === "CPF"
          ? form.cpfNumber.replaceAll(".", "").replaceAll("-", "")
          : form.cnpjNumber.replaceAll(".", "").replaceAll("-", "");

      let data = {
        current_form: "1",
        document_type: form.registration,
        document_number: formatted_cpf,
        iniciative_name: form.initiativeName,
        birth_year: form.registration === "CPF" ? form.year : form.foundedIn,
        trade_name: form.fantasyName,
        president_name: form.presidentName,

        ong_address: {
          postal_code: form.cep,
          community: form.community,
          landmark: form.referencePoint,
          street: form.street,
          number: form.number,
          district: form.neighborhood,
          adjunct: form.complement,
          county: form.county,
          state: form.state,
        },

        cnpj_address: {
          postal_code: form.zipCodeCNPJ,
          number: form.numberCNPJ,
          street: form.streetCNPJ,
          city: form.countyCNPJ,
          community: form.community,
          district: form.neighborhoodCNPJ,
          state: form.stateCNPJ,
        },
        ong_contacts: {
          phone: form.telephone,
          cell_num: form.cell,
          email: form.email,
          website: form.site,
        },
        ong_social_medias: {
          whatsapp: form.whatsapp,
          facebook: form.facebook,
          twitter: form.twitter,
          linkedin: form.linkedin,
          youtube: form.youTube,
          instagram: form.instagram,
        },
        political_parties: political_parties,
        ongs_parties: ongs_parties,
        religious_parties: religious_parties,
      };
      if (form.registration === "CPF") {
        delete data.cnpj_address;
      }
      if (form.registration === "CNPJ") {
        data.iniciative_name = form.socialReason;
        data.ong_address.community = data.ong_address.street;
      }
      const idToken = localStorage.getItem("idToken");
      (user.ong_id
        ? api.patch(`/ong/${user.ong_id.id}/`, data, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
        : api.post("/ong/", data, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
      )
        .then((res) => {
          changePermission(1);
          localStorage.setItem(
            "formStep",
            JSON.stringify({
              pathname: "/cadastro/atuacao-e-assistidos",
              step: 1,
            })
          );
          isDescktop
            ? navigate("/cadastro/atuacao-e-assistidos")
            : navigate("/transicao");
        })
        .catch((err) => {
          if (
            err?.response?.data?.document_number?.filter(
              (i) => i === "ong with this document number already exists."
            ).length >= 1
          ) {
            toast.error("CPF já associado para essa ONG");
          }

          if (err.response.data.detail === "User is not confirmed") {
            setHasError(
              "Usuário não confirmado, por favor confirma através do seu e-mail"
            );

            return;
          }
          setHasError("Erro ao criar ong");
        });
    }
  };

  const renderInsertNumber = () => {
    return (
      <S.ContentInsertNumber>
        {form.registration === "CPF" ? (
          <Input
            paddingBottom
            width="100%"
            label="N° do CPF *"
            place="000.000.000-00"
            name="cpfNumber"
            type="text"
            value={form.cpfNumber && cpfMask(form.cpfNumber)}
            onChange={handleChange}
            error={formErrors.cpfNumber ? true : false}
          />
        ) : (
          form.registration === "CNPJ" && (
            <MaskedInput
              maxWidth="500px"
              paddingBottom
              label="N° do CNPJ *"
              place="00.000.000/0000-00"
              name="cnpjNumber"
              mask="99.999.999/9999-99"
              value={form.cnpjNumber}
              onChange={handleChangeMask}
              error={formErrors.cnpjNumber ? true : false}
            />
          )
        )}
      </S.ContentInsertNumber>
    );
  };

  const renderDataCPF = () => {
    return (
      <S.BoxDataCpf>
        <S.Line mobile />
        <Input
          paddingRight
          width="100%"
          label="Nome da iniciativa *"
          place="Digite"
          name="initiativeName"
          value={form.initiativeName}
          onChange={handleChange}
          sPaddingBottom="0px"
          marginTop="20px"
          widthInput="100%"
          error={formErrors.initiativeName ? true : false}
        />
        <S.Line mobile />
        <Input
          paddingRight
          width="100%"
          label="Ano de início das atividades *"
          place="AAAA"
          name="year"
          value={form.year}
          onChange={handleChange}
          maxLength="4"
          marginTop="20px"
          widthInput="100%"
          sPaddingBottom="0px"
          error={formErrors.year ? true : false}
        />
        <Input
          width="100%"
          label="Nome do(a) presidente *"
          place="Digite"
          name="presidentName"
          value={form.presidentName}
          onChange={handleChange}
          sPaddingBottom="0px"
          marginTop="20px"
          widthInput="100%"
          error={formErrors.presidentName ? true : false}
        />
      </S.BoxDataCpf>
    );
  };

  const renderDataCNPJ = () => {
    return (
      <>
        <S.ContentRow>
          <Input
            flex
            label="Razão social *"
            place="Digite"
            name="socialReason"
            value={form.socialReason}
            onChange={handleChange}
            sPaddingBottom="0px"
            marginTop="20px"
            widthInput="100%"
            error={formErrors.socialReason ? true : false}
          />
          <Input
            flex
            label="Ano de fundação *"
            place="AAAA"
            name="foundedIn"
            type="number"
            value={form.foundedIn}
            onChange={handleChange}
            maxLength="4"
            sPaddingBottom="0px"
            marginTop="20px"
            widthInput="100%"
            error={formErrors.foundedIn ? true : false}
          />
        </S.ContentRow>
        <S.ContentRow>
          <Input
            flex
            width="100%"
            label="Nome fantasia *"
            place="Digite"
            name="fantasyName"
            value={form.fantasyName}
            onChange={handleChange}
            sPaddingBottom="0px"
            marginTop="20px"
            widthInput="100%"
            error={formErrors.fantasyName ? true : false}
          />

          <Input
            flex
            width="100%"
            label="Nome do(a) presidente *"
            place="Digite"
            name="presidentName"
            value={form.presidentName}
            onChange={handleChange}
            sPaddingBottom="0px"
            marginTop="20px"
            widthInput="100%"
            error={formErrors.presidentName ? true : false}
          />
        </S.ContentRow>
        <S.ContentLine descktop>
          <S.TitleLine>Endereço de registro do CNPJ</S.TitleLine>
          <S.Line />
        </S.ContentLine>
        <S.CNPJAdress>
          <S.BoxDisplay>
            <S.BoxRegistrationCnpj maxWidth="700px" width="100%">
              <MaskedInput
                label="CEP conforme o CNPJ *"
                place="Digite"
                width="100%"
                mask="99999-999"
                name="zipCodeCNPJ"
                value={form.zipCodeCNPJ}
                onChange={handleChangeMask}
                ResponseWidth="100%"
                sPaddingBottom="0px"
                marginTop="20px"
                widthInput="100%"
                error={formErrors.zipCodeCNPJ ? true : false}
              />

              <Input
                label="Rua *"
                place="Digite"
                width="100%"
                name="streetCNPJ"
                type="text"
                value={form.streetCNPJ}
                onChange={handleChange}
                noRequired
                widthInputResponse="100%"
                sPaddingBottom="0px"
                marginTop="20px"
                widthInput="100%"
                error={formErrors.streetCNPJ ? true : false}
              />
            </S.BoxRegistrationCnpj>

            <S.BoxRegistrationCnpj widthReponsive="100%">
              <Input
                width="55px"
                label="N ° *"
                place="12"
                name="numberCNPJ"
                type="number"
                value={form.numberCNPJ}
                onChange={handleChange}
                sPaddingBottom="0px"
                marginTop="20px"
                widthInput="100%"
                error={formErrors.numberCNPJ ? true : false}
              />

              {largura < 1555 && (
                <Input
                  label="Bairro *"
                  width="150px"
                  place="Digite"
                  name="neighborhoodCNPJ"
                  value={form.neighborhoodCNPJ}
                  onChange={handleChange}
                  sPaddingBottom="0px"
                  marginTop="20px"
                  widthInput="100%"
                  error={formErrors.neighborhoodCNPJ ? true : false}
                />
              )}
            </S.BoxRegistrationCnpj>
          </S.BoxDisplay>

          <S.BoxRegistrationCnpj
            maxWidth="500px"
            width="100%"
            marginBottom="30px"
          >
            {largura > 1555 && (
              <Input
                label="Bairro *"
                width="150px"
                place="Digite"
                name="neighborhoodCNPJ"
                value={form.neighborhoodCNPJ}
                onChange={handleChange}
                sPaddingBottom="0px"
                marginTop="20px"
                widthInput="100%"
                error={formErrors.neighborhoodCNPJ ? true : false}
              />
            )}

            <Input
              width="200px"
              label="Município *"
              place="Digite"
              name="countyCNPJ"
              value={form.countyCNPJ}
              onChange={handleChange}
              sPaddingBottom="0px"
              marginTop="20px"
              widthInput="100%"
              error={formErrors.countyCNPJ ? true : false}
            />
            <Input
              width="65px"
              label="Estado *"
              place="RJ"
              name="stateCNPJ"
              value={form.stateCNPJ}
              onChange={handleChange}
              sPaddingBottom="0px"
              marginTop="20px"
              widthInput="100%"
              error={formErrors.stateCNPJ ? true : false}
            />
          </S.BoxRegistrationCnpj>
        </S.CNPJAdress>
        <S.LineTwo />
      </>
    );
  };

  const renderContact = () => (
    <S.ContentContact>
      <S.BoxDisplay gap="16px">
        <Input
          sPaddingBottom="0px"
          width="200px"
          label="E-mail"
          place="Digite"
          name="email"
          type="email"
          value={form.email}
          onChange={handleChange}
          icon={Email}
          ResponseWidth="100%"
          noRequired
          marginTop="20px"
          widthInput="100%"
        />
        <MaskedInput
          sPaddingBottom="0px"
          label="Telefone"
          place="(21) 1234-5678"
          mask="(99) 9999-9999"
          name="telephone"
          value={form.telephone}
          onChange={handleChangeMask}
          icon={Telephone}
          ResponseWidth="100%"
          noRequired
          marginTop="20px"
          widthInput="100%"
        />

        <MaskedInput
          sPaddingBottom="0px"
          label="Celular *"
          place="(21) 91234-5678"
          mask="(99) 99999-9999"
          name="cell"
          value={form.cell}
          onChange={handleChangeMask}
          icon={Telephone}
          ResponseWidth="100%"
          marginTop="20px"
          widthInput="100%"
          complemen
          error={formErrors.cell ? true : false}
        />
        <Input
          sPaddingBottom="0px"
          width="200px"
          label="Site"
          place="Digite"
          name="site"
          value={form.site}
          onChange={handleChange}
          icon={Site}
          ResponseWidth="100%"
          noRequired
          marginTop="20px"
          widthInput="100%"
        />
      </S.BoxDisplay>
    </S.ContentContact>
  );

  const renderSocialNetworks = () => {
    return (
      <S.ContentContact>
        <S.Line mobile />
        <S.BoxDisplay width="100%" gap="16px" marginBottom="30px">
          <MaskedInput
            sPaddingBottom="0px"
            label="Whatsapp"
            place="Digite"
            name="whatsapp"
            mask="(99) 99999-9999"
            value={form.whatsapp}
            onChange={handleChangeMask}
            icon={Whatsapp}
            ResponseWidth="100%"
            noRequired
            marginTop="20px"
            widthInput="100%"
          />
          <Input
            sPaddingBottom="0px"
            width="200px"
            label="Facebook"
            place="Digite"
            name="facebook"
            value={form.facebook}
            onChange={handleChange}
            icon={Facebook}
            ResponseWidth="100%"
            noRequired
            marginTop="20px"
            widthInput="100%"
          />

          <Input
            sPaddingBottom="0px"
            width="200px"
            label="Instagram"
            place="Digite"
            name="instagram"
            value={form.instagram}
            onChange={handleChange}
            icon={Instagram}
            ResponseWidth="100%"
            noRequired
            marginTop="20px"
            widthInput="100%"
          />

          <Input
            sPaddingBottom="0px"
            width="200px"
            label="Twitter"
            place="Digite"
            name="twitter"
            value={form.twitter}
            onChange={handleChange}
            icon={Twitter}
            ResponseWidth="100%"
            noRequired
            marginTop="20px"
            widthInput="100%"
          />

          <Input
            sPaddingBottom="0px"
            width="200px"
            label="LinkedIn"
            place="Digite"
            name="linkedin"
            value={form.linkedin}
            onChange={handleChange}
            icon={Linkedin}
            ResponseWidth="100%"
            noRequired
            marginTop="20px"
            widthInput="100%"
          />

          <Input
            sPaddingBottom="0px"
            width="200px"
            label="YouTube"
            place="Digite"
            name="youTube"
            value={form.youTube}
            onChange={handleChange}
            icon={Youtube}
            ResponseWidth="100%"
            noRequired
            marginTop="20px"
            widthInput="100%"
          />
        </S.BoxDisplay>
      </S.ContentContact>
    );
  };

  const renderAddressData = () => {
    return (
      <S.ContainerAddressData>
        <S.BoxDisplay gap="16px" width="100%">
          <MaskedInput
            // order='1'
            sPaddingBottom="0px"
            label="CEP de atuação"
            place="00000-000"
            name="cep"
            mask="99999-999"
            value={form.cep}
            onChange={handleChangeMask}
            noRequired
            ResponseWidth="100%"
            marginTop="20px"
            widthInput="100%"
          />

          <Input
            // order='2'
            paddingBottom
            width="200px"
            label="Comunidade *"
            name="community"
            id="community"
            value={form.community}
            onChange={handleChange}
            ResponseWidth="100%"
            marginTop="20px"
            widthInput="100%"
            error={formErrors.community ? true : false}
          />

          <Input
            // order='3'
            paddingBottom
            label="Ponto de referência *"
            width="200px"
            name="referencePoint"
            value={form.referencePoint}
            onChange={handleChange}
            ResponseWidth="100%"
            marginTop="20px"
            widthInput="100%"
            error={formErrors.referencePoint ? true : false}
          />

          {largura < 825 && (
            <Input
              paddingBottom
              paddingRight
              label="Rua *"
              width="100%"
              name="street"
              value={form.street}
              onChange={handleChange}
              marginLabel="0"
              ResponseWidth="100%"
              marginTop="20px"
              widthInput="100%"
              error={formErrors.street ? true : false}
            />
          )}
        </S.BoxDisplay>

        {largura > 825 && (
          <S.BoxDisplay gap="10px">
            <Input
              paddingBottom
              label="Rua *"
              width="400px"
              name="street"
              value={form.street}
              onChange={handleChange}
              widthInput="100%"
              marginLabel="0"
              marginTop="20px"
              error={formErrors.street ? true : false}
            />
            <Input
              // order='4'
              width="90px"
              paddingBottom
              label="N ° *"
              place="000"
              name="number"
              type="number"
              value={form.number}
              onChange={handleChange}
              marginTop="20px"
              widthInput="100%"
              error={formErrors.number ? true : false}
            />

            {largura < 825 && (
              <Input
                width="150px"
                paddingBottom
                paddingRight
                label="Complemento *"
                name="complement"
                value={form.complement}
                onChange={handleChange}
                marginLabel="0"
                widthInput="100%"
                marginTop="20px"
                error={formErrors.complement ? true : false}
              />
            )}

            {largura < 825 && (
              <Input
                // order='7'
                width="150px"
                paddingBottom
                label="Bairro"
                place="Vidigal"
                name="neighborhood"
                value={form.neighborhood}
                onChange={handleChange}
                noRequired
                widthInput="100%"
                marginTop="20px"
                error={formErrors.neighborhoodCNPJ ? true : false}
              />
            )}
            {largura > 825 && (
              <Input
                width="150px"
                paddingBottom
                label="Complemento *"
                name="complement"
                value={form.complement}
                onChange={handleChange}
                marginLabel="0"
                widthInput="100%"
                marginTop="20px"
                error={formErrors.complement ? true : false}
              />
            )}
          </S.BoxDisplay>
        )}

        {largura < 825 && (
          <S.BoxDisplay isMobile gap="16px">
            <Input
              // order='4'
              width="50px"
              paddingBottom
              paddingRight
              label="N ° *"
              place="007"
              name="number"
              type="number"
              value={form.number}
              onChange={handleChange}
              widthInput="100%"
              marginTop="20px"
              error={formErrors.number ? true : false}
            />

            <Input
              // order='6'
              width="100px"
              paddingBottom
              label="Complemento*"
              name="complement"
              place="Rua Um"
              value={form.complement}
              onChange={handleChange}
              widthInput="100%"
              marginTop="20px"
              error={formErrors.complement ? true : false}
            />
            {largura < 825 && (
              <Input
                // order='7'
                width="100px"
                paddingBottom
                label="Bairro"
                place="Vidigal"
                name="neighborhood"
                value={form.neighborhood}
                onChange={handleChange}
                noRequired
                widthInput="100%"
                marginTop="20px"
              />
            )}
          </S.BoxDisplay>
        )}

        <S.BoxDisplay gap="16px">
          <Input
            // order='6'
            paddingBottom
            label="Município"
            placeholder="Digite"
            name="county"
            value={form.county}
            onChange={handleChange}
            mobWidth="100%"
            noRequired
            widthInput="100%"
            marginTop="20px"
          />

          {largura > 825 && (
            <Input
              // order='7'
              paddingBottom
              label="Bairro"
              placeholder="Digite"
              name="neighborhood"
              value={form.neighborhood}
              onChange={handleChange}
              mobWidth="100%"
              marginLabel="0"
              noRequired
              widthInput="100%"
              marginTop="20px"
            />
          )}

          <Input
            width="45px"
            paddingBottom
            label="Estado"
            place="RJ"
            name="state"
            value={form.state}
            onChange={handleChange}
            marginLabel="0"
            noRequired
            widthInput="100%"
            marginTop="20px"
          />
        </S.BoxDisplay>
      </S.ContainerAddressData>
    );
  };

  const verifyHasError = (obj) => {
    for (const key in obj) {
      if (obj[key]) {
        return true;
      }
    }
    return false;
  };

  const notify = () => {
    if (verifyHasError(isError)) {
      toast.error("Preencha os campos obrigatórios");
    }
    validateForm();
  };

  return (
    <Layout currentStep="1">
      <S.Form onSubmit={handleSubmit}>
        <Title
          form
          paddingLeftDesk="5.125rem"
          subtitle="Dados da Iniciativa"
          icon={IconContacts}
        >
          Contatos <div>e dados oficiais</div>
        </Title>
        <S.ContentRow>
          <InputRadio
            small
            title="O cadastro será feito por:"
            name="registration"
            firstLabel="CPF"
            secondLabel="CNPJ"
            value={form.registration}
            firstValue="CPF"
            secondValue="CNPJ"
            onChange={handleCheckedRegistration}
          />
          {renderInsertNumber()}
        </S.ContentRow>
        {form.registration === "CPF"
          ? renderDataCPF()
          : form.registration === "CNPJ" && renderDataCNPJ()}

        <S.ContentLine descktop>
          <S.TitleLine>Endereço do local de atuação</S.TitleLine>
          <S.Line />
        </S.ContentLine>
        {renderAddressData()}

        <S.ContentLine descktop>
          <S.TitleLine>Contatos do responsável</S.TitleLine>
          <S.Line />
        </S.ContentLine>
        {renderContact()}
        <S.ContentLine descktop>
          <S.TitleLine>Redes Sociais</S.TitleLine>
          <S.Line />
        </S.ContentLine>
        {renderSocialNetworks()}

        <S.Line />
        <S.ContainerOrder>
          <S.ContentOrder>
            <InputRadioAddItem
              title="A ONG tem ligação com algum partido político ?"
              name="politicalParty"
              value={form?.politicalParty?.has}
              onChange={onChange}
              handleNewItemChange={handleNewItemChange}
              addNewItem={addNewItem}
              inputValue={form?.politicalParty?.value || ""}
              list={form?.politicalParty?.values}
              hasIcon
              isError={isError.politicalParty}
              handleDeleteItem={handleDeleteItem}
            />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </S.ContentOrder>
          <S.ContentOrder order>
            <S.Line descktop />
            <InputRadioAddItem
              title="A ONG tem ligação com alguma instituição religiosa ?"
              name="religiousInstitution"
              value={form?.religiousInstitution?.has}
              onChange={onChange}
              handleNewItemChange={handleNewItemChange}
              addNewItem={addNewItem}
              inputValue={form?.religiousInstitution?.value || ""}
              list={form?.religiousInstitution?.values}
              hasIcon
              isError={isError.religiousInstitution}
              handleDeleteItem={handleDeleteItem}
            />
          </S.ContentOrder>
          <S.ContentOrder>
            <InputRadioAddItem
              title="A ONG possui parcerias com outras ONGs ?"
              name="partnerships"
              value={form?.partnerships?.has}
              onChange={onChange}
              handleNewItemChange={handleNewItemChange}
              addNewItem={addNewItem}
              inputValue={form?.partnerships?.value || ""}
              list={form?.partnerships?.values}
              hasIcon
              isError={isError.partnerships}
              handleDeleteItem={handleDeleteItem}
            />
          </S.ContentOrder>
        </S.ContainerOrder>
        <Footer onClick={notify} isError={hasError} msgError={hasError} />
      </S.Form>
    </Layout>
  );
};

export default OfficialContactsAndData;
