import styled, { css } from "styled-components";
import { colors } from "../../styles/Theme";

export const Container = styled.div`
  ${(props) =>
    props.form &&
    css`
      text-align: center;
    `}

  @media(min-width: 1024px) {
    padding-left: ${(props) => props.paddingLeft && props.paddingLeft};
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    padding-bottom: ${(props) => (props.noPaddBtn ? "0" : "2.5rem")};
    display: flex;
    flex-direction: row;
  }
`;

export const Icon = styled.img`
  padding-bottom: 0.375rem;
  display: ${(props) => props.displayImage && props.displayImage};
  width: ${(props) => (props.width ? "100%" : "1.958rem")};

  @media (min-width: 1024px) {
    width: 3.563rem;
  }
`;

export const Title = styled.h1`
  padding-bottom: ${(props) =>
    props.paddingMob ? props.paddingMob : "1.563rem"};
  display: inline-block;
  max-width: ${(props) => (props.mobWidth ? props.mobWidth : "17rem")};
  font-size: 1.875rem;
  font-weight: 900;
  color: ${(props) => (props.successScreen ? colors.green : colors.black)};
  line-height: 2rem;

  @media (min-width: 1024px) {
    padding-bottom: ${(props) => (props.padding ? props.padding : 0)};
    display: inline-flex;
    font-size: ${(props) => (props.homeScreen ? "4rem" : "2.1rem")};
    line-height: ${(props) => (props.homeScreen ? "4.938rem" : "2.213rem")};
    max-width: ${(props) => (props.width ? props.width : "40rem")};
    padding-left: ${(props) => props.paddingLeftDesk && props.paddingLeftDesk};
    white-space: ${(props) => (props.wrap ? "wrap" : "nowrap")};

    div {
      padding-left: 0.75rem;
    }
  }
`;

export const ContentSubtitleLine = styled.div`
  padding-bottom: 1.313rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  padding-right: 0.25rem;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: "Open Sans";
  font-style: normal;
  color: ${colors.black};
  white-space: nowrap;

  @media (min-width: 1024px) {
    font-size: 1.563rem;
    font-weight: 700;
  }
`;

export const Line = styled.hr`
  width: inherit;
  height: 0;
  color: ${colors.black02};
`;
