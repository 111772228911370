import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import LabelLine from '../../../../components/LabelLine'
import LayoutHomeDashboard from '../../../../components/SideBarSettings'
import Title from '../../../../components/Title'
import SelectAccess from '../../../../components/Select/SelectAccess'
import FooterHome from '../../../../components/Footer/FooterHome'
import Input from '../../../../components/Inputs'
import ModalConfirmDelete from '../../../../components/Modals/confirmDelete'
import ContentModal from '../../../../components/Modals/ContentModal'
import BtnIcon from '../../../../components/Buttons/BtnTrash'
import ModalSettingPage from '../../../../components/Modals/modalSettingPage'

// pages
import OfficialContactsAndData from '../../../Dashboard/formSectionsList/officialContactsAndData'
import ActingAssisted from '../../formSectionsList/actingAssisted'
import PhysicalAndFunctionalStructure from '../../formSectionsList/physicalAndFunctionalStructure'
import SendDocument from '../../formSectionsList/sendingDocuments'

// modules
import { api } from '../../../../modules/api'

// providers
import { useApi } from '../../../../providers/Api'

//Images
import {
  SimpleArrow,
  IconClose,
  GreenCheck,
  IconBlocked,
  CloseModal,
  ConfigHome,
  IconRemoveProfile,
  IconSwitch,
  IconLeft,
  IconSearch,
  AddPendingAccesses,
  CheckGreen
} from '../../../../assets'

// styles
import * as S from './styles'
import { toast, ToastContainer } from 'react-toastify'

const initialState = {
  newAccessPermissions: {
    profile: '',
    name: '',
    email: ''
  }
}

const SettingsPage = () => {
  const navigate = useNavigate()
  const isDescktop = window.innerWidth > 1024
  const { getMyUser } = useApi()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [currentData, setCurrentData] = useState(0)
  const [animation, setAnimation] = useState(false)
  const [search, setSearch] = useState('')
  const [user, setUser] = useState()
  const [ongFound, setOngFound] = useState(undefined)
  const [currentAccessPermissions, setCurrentAccessPermissions] = useState(0)
  const [currentPendingAccesses, setCurrentPendingAccesses] = useState(0)
  const [listProfile, setListProfile] = useState([])
  const [isModal, setIsModal] = useState({ type: '', isOpen: undefined })
  const [date, setDate] = useState({})
  const [isError, setIsError] = useState(undefined)
  const [form, setForm] = useState(initialState)
  const [componentRender, setComponentRender] = useState(null)
  const [listOngsWithComunity, setListOngsWithComunity] = useState([])
  const [filtro, setFiltro] = useState([])
  const [isFiltro, setIsFiltro] = useState(false)

  const [error, setError] = useState({
    newAccessPermissions: {
      profile: '',
      name: '',
      email: ''
    }
  })
  const [accessPermissions, setAccessPermissions] = useState([
    {
      id: 0,
      profile: 'Em aberto',
      name: '',
      email: ''
    }
  ])

  const [pendingAccesses, setPendingAccesses] = useState([])

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`
    }
  }

  const handleEditOng = ev => {
    setComponentRender(+ev.target.id)
  }

  useEffect(() => {
    switch (user?.role) {
      case 'Geral':
        setListProfile(['Gerência', 'Analista'])
        break
      case 'Gerência':
        setListProfile(['Analista'])
        break
      case 'Master':
        setListProfile(['Supervisão', 'Auxiliar'])
        break
      case 'Supervisão':
        setListProfile(['Auxiliar'])
        break
      default:
        break
    }
  }, [user])


  const getUser = async () => {
    const response = await getMyUser()
    setUser(response)
  }

  const handleRouter = () => {
    if (user?.role === 'Analista' || user?.role === 'Auxiliar') {
      navigate('/gestao-de-atividades')
    }
  }
  handleRouter()
  const handleGetData = async () => {
    await api
      .get('/user/me/', headers)
      .then(res => {
        const getDate = {
          user_id: res?.data[0]?.id,
          ong_id: res?.data[0]?.ong_id?.id,
          user_name: res?.data[0]?.first_name,
          last_name: res?.data[0]?.last_name,
          telphone: res?.data[0]?.ong_id?.ong_contacts?.cell_num,
          email: res?.data[0]?.email,
          ong_name: res?.data[0]?.ong_id?.iniciative_name,
          community: res?.data[0]?.ong_id?.ong_address.community
        }
        setDate(getDate)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUser()
    handleGetData()
  }, [])

  const formattingData = data => {
    return String(data).length === 1 ? `${'0' + data}` : data
  }

  const formatDate = date => {
    const newDate = new Date(date)
    const updateDate =
      formattingData(newDate.getDate()) +
      '/' +
      formattingData(newDate.getMonth() + 1) +
      '/' +
      newDate.getFullYear()

    return updateDate
  }

  const ModalDeleteOngsComponent = (props) => {
    const [isModalOng, setIsModalOng] = useState(false)

    const handleDeleteOng = async () => {
      await api
        .delete(`/ong/${props.item.id}/`, headers)
        .then(res => {
          setOngFound(undefined)

          const data = [...listOngsWithComunity]
          const deleteOng = data.filter(item => item.id !== props.item.id)
          setListOngsWithComunity(deleteOng)
          toast.success('Ong excluída com sucesso')
        })
        .catch(err => {
          console.log(err)
        })
    }

    return (
      <S.BoxComunityOngs
        isModal={isModalOng}
      >
        <S.LabelFormRemove>
          <S.ParagraphInputComunityRemove>
            {props.item.iniciative_name}
          </S.ParagraphInputComunityRemove>
          <BtnIcon onClick={() => setIsModalOng(true)} />
        </S.LabelFormRemove>
        {isModalOng && (
          <ModalConfirmDelete
            top="2.5rem"
            title="Esta ação irá deletar a ONG."
            handleCloseModal={() => setIsModalOng(false)}
            handleDeleteData={() => handleDeleteOng()}
          />
        )}
      </S.BoxComunityOngs>
    )
  }

  const ModalDeleteUserComponent = (props) => {
    const [isModalOng, setIsModalOng] = useState(false)

    const handleDeleteOng = async () => {
      await api
        .delete(`/user/${props.item.id}/`, headers)
        .then(res => {
          setOngFound(undefined)

          const data = [...listOngsWithComunity]
          const deleteOng = data.filter(item => item.id !== props.item.id)
          setListOngsWithComunity(deleteOng)
          toast.success('Ong excluída com sucesso')
        })
        .catch(err => {
          console.log(err)
        })
    }

    return (
      <S.BoxComunityOngs
        isModal={isModalOng}
      >
        <S.LabelFormRemove>
          <S.ParagraphInputComunityRemove>
            {props.item.iniciative_name}
          </S.ParagraphInputComunityRemove>
          <BtnIcon onClick={() => setIsModalOng(true)} />
        </S.LabelFormRemove>
        {isModalOng && (
          <ModalConfirmDelete
            top="2.5rem"
            title="Esta ação irá deletar a ONG."
            handleCloseModal={() => setIsModalOng(false)}
            handleDeleteData={() => handleDeleteOng()}
          />
        )}
      </S.BoxComunityOngs>
    )
  }

  const handleGetInvites = async () => {
    await api
      .get('/user/invites/', headers)
      .then(res => {
        const updatePendingAccesses = res.data
          .filter(i => i.has_accepted === false)
          .map(item => ({
            id: item?.id,
            date: formatDate(item?.updated_at),
            profile: item?.ong_role,
            name: item?.user?.first_name,
            email: item?.user?.email
          }))

        const updateAccessPermissions = res.data
          .filter(i => i.has_accepted === true)
          .map(item => ({
            id: item?.id,
            date: formatDate(item?.updated_at),
            profile: item?.ong_role,
            name: item?.user?.first_name,
            email: item?.user?.email
          }))

        setPendingAccesses(updatePendingAccesses)
        setAccessPermissions([...accessPermissions, ...updateAccessPermissions])
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    handleGetInvites()


    async function handle () {
      await api
        .get('/ong/', headers)
        .then(res => {
          const filterOng = res.data.filter(i => i.iniciative_name === search)
          const renderOng = filterOng.map(i => ({
            id: i?.id,
            name: i?.iniciative_name,
            community: i?.ong_address?.community
          }))
          setOngFound(renderOng)
          setListOngsWithComunity(res.data)
          setSearch('')
        })
        .catch(err => {
          console.log(err)
        }
      )
    } 

    handle()    
  }, [])


  const listPermissions = [
    {
      text: 'Filtra dados das pessoas cadastradas',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Acessa a todas as atividades desenvolvidas na própria ONG',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Acessa as atividades desenvolvidas na própria ONG das quais fui associado',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: true
        }
      ]
    },
    {
      text: 'Editar critério de avaliação da própria ONG',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: true
        }
      ]
    },
    {
      text: 'Concede permissão a outros usuários',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Altera o nível de permissão do usuário cadastrado',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Solicita exclusão da própria ONG do sistema',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: false
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Exclui, cadastra e altera dados de assistidos',
      permissions: [
        {
          name: 'general',
          typePermission: false
        },
        {
          name: 'management',
          typePermission: false
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Realiza download dos dados dos assistidos (da própria ONG)',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Exclui o usuário de nível Geral',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: false
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Excluir os usuários de nível Gerência',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: true
        }
      ]
    },
    {
      text: 'Excluir os usuários de nível Analista',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: false
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Exclui o usuário de nível Master',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Excluir os usuários de nível Supervisão',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Excluir os usuários de nível Auxiliar',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Exclui ONGs do sistema',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Realiza o download dos dados de pessoas cadastradas pelas ONGs.',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Realiza o download dos dados de pessoas cadastradas pela própria ONG.',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Realiza o download dos dados cadastrados sobre as ONGs',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
      // general: true,
      // management: true,
      // analyst: true,
      // master: false,
      // supervision: false,
      // auxiliar: false,
    },
    {
      text: 'Realiza o download dos dados cadastrados sobre a sua própria ONG',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Excluir as oportunidades ou projetos disponibilizadas para as ONGs',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Visualiza os projetos ou oportunidades disponibilizados pela Favela Inc',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: true
        },
        {
          name: 'master',
          typePermission: true
        },
        {
          name: 'supervision',
          typePermission: true
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    },
    {
      text: 'Cadastra os projetos ou oportunidades disponibilizados pela Favela Inc',
      permissions: [
        {
          name: 'general',
          typePermission: true
        },
        {
          name: 'management',
          typePermission: true
        },
        {
          name: 'analyst',
          typePermission: false
        },
        {
          name: 'master',
          typePermission: false
        },
        {
          name: 'supervision',
          typePermission: false
        },
        {
          name: 'auxiliar',
          typePermission: false
        }
      ]
    }
  ]

  const permissions = [
    'Geral',
    'Gerência',
    'Analista',
    'Master',
    'Supervisão',
    'Auxiliar'
  ]

  const listOngs = [
    {
      name: 'Contatos e dados oficiais',
      component: <OfficialContactsAndData />,
      id: 1
    },
    {
      name: 'Atuação e assistidos',
      component: <ActingAssisted />,
      id: 2
    },
    {
      name: 'Estrutura física e funcional',
      component: <PhysicalAndFunctionalStructure />,
      id: 3
    },
    {
      name: 'Visualizar documentos',
      component: <SendDocument />,
      id: 4
    }
  ]

  const handleSubmit = ev => {
    ev.preventDefault()
  }

  const handleModalConsultPermissions = () => {
    setIsOpenModal(!isOpenModal)
  }

  const handleNextPermission = () => {
    setCurrentData(currentData + 1)
  }

  const handleBackPermission = () => {
    setCurrentData(currentData - 1)
  }

  const handleChange = ev => {
    const { name, value, id } = ev.target
    const isValue = id ? id : value

    setForm({
      newAccessPermissions: {
        ...form.newAccessPermissions,
        [name]: isValue
      }
    })

    if (name === 'profile') {
      handleModal()
    }

    setError({
      newAccessPermissions: {
        ...error.newAccessPermissions,
        [name]: false
      }
    })
  }

  useEffect(() => {
    if(search.length === 0) {
      setFiltro([])
      setIsFiltro(true)
    }
  }, [search])

  const handleSearchChange = ev => {
    const { value } = ev.target

    setOngFound(undefined)
    setSearch(value)
  }

  const handleSearchOng = () => {
    const filtro = listOngsWithComunity.filter(item => item.iniciative_name.toLowerCase().includes(search.toLowerCase()))
    
    setFiltro(filtro)
    setIsFiltro(false)
    
  }

  const renderCloseModal = () => (
    <S.ContainerCloseModal onClick={handleModalConsultPermissions}>
      <S.ImageCloseModal src={CloseModal} alt="Excluir" />
      <S.Trash>Fechar</S.Trash>
    </S.ContainerCloseModal>
  )

  const handlePrevSlider = nameSlider => {
    let currentName =
      nameSlider === 'accessPermissions' ? accessPermissions : pendingAccesses
    let currentState =
      nameSlider === 'accessPermissions'
        ? currentAccessPermissions
        : currentPendingAccesses
    let currentSetState =
      nameSlider === 'accessPermissions'
        ? setCurrentAccessPermissions
        : setCurrentPendingAccesses

    if (currentState !== 0) {
      currentSetState(currentState - 1)
    } else if (currentState === 0) {
      currentSetState(currentName.length - 1)
    }
  }

  const handleNextSlider = nameSlider => {
    let currentName =
      nameSlider === 'accessPermissions' ? accessPermissions : pendingAccesses
    let currentState =
      nameSlider === 'accessPermissions'
        ? currentAccessPermissions
        : currentPendingAccesses
    let currentSetState =
      nameSlider === 'accessPermissions'
        ? setCurrentAccessPermissions
        : setCurrentPendingAccesses

    if (currentState !== currentName.length - 1) {
      currentSetState(currentState + 1)
    } else if (currentState === currentName.length - 1) {
      currentSetState(0)
    }
  }

  const handleBallSlider = (nameSlider, idx) => {
    let currentSetState =
      nameSlider === 'accessPermissions'
        ? setCurrentAccessPermissions
        : setCurrentPendingAccesses

    currentSetState(idx)
  }

  const handleModal = (type, item, ev) => {
    ev && ev.preventDefault()

    setIsModal({
      type,
      item,
      isOpen: !isModal.isOpen
    })
  }


  const handleChangeProfileMob = (ev, idx) => {
    ev.preventDefault()
    const { id } = ev.target

    const newList = [...accessPermissions]

    newList[idx + 1] = {
      ...accessPermissions[idx + 1],
      profile: id
    }

    setAccessPermissions(newList)
    setForm({
      newAccessPermissions: {
        ...form.newAccessPermissions,
        profile: id
      }
    })
    setIsModal({ isOpen: false })

    setError({
      newAccessPermissions: {
        ...error.newAccessPermissions,
        profile: false
      }
    })
  }

  const handleChangeProfile = (ev, idx, isMob) => {
    ev.preventDefault()
    const { id } = ev.target

    const newList = [...accessPermissions]

    newList[idx + 1] = {
      ...accessPermissions[idx + 1],
      profile: id
    }

    setAccessPermissions(newList)

    if (isMob) {
      setForm({
        newAccessPermissions: {
          ...form.newAccessPermissions,
          profile: id
        }
      })
    } else {
    }
    setIsModal({ isOpen: false })

    setError({
      newAccessPermissions: {
        ...error.newAccessPermissions,
        profile: false
      }
    })
  }

  const handleSendNewAccessPermissionsMob = () => {
    const { profile, name, email } = form.newAccessPermissions
    if (!profile || !name || !email) {
      setError({
        newAccessPermissions: {
          profile: !profile,
          name: !name,
          email: !email
        }
      })
    } else {
      let newDate = new Date()
      let date =
        newDate.getDate() +
        '/' +
        newDate.getMonth() +
        '/' +
        newDate.getFullYear()

      const newList = [
        ...pendingAccesses,
        {
          date,
          profile,
          name,
          email
        }
      ]

      setAccessPermissions([...accessPermissions])

      setPendingAccesses(newList)

      setForm(initialState)
      handleModal()

      handleSendInvite()
    }
  }

  const handleSendInvite = async () => {
    const { profile, email } = form.newAccessPermissions
    const getDate = {
      emails: [
        {
          email: email,
          ong_role: profile
        }
      ]
    }

    await api
      .post('/user/invite/', getDate, headers)
      .then(res => {
        handleGetInvites()
        setForm(initialState)
        handleModal()
      })
      .catch(err => {
        if (err.response.statusText === 'Not Found') {
          setIsError('Email não encontrado')
        } else {
          setIsError('Erro ao cadastrar permissão')
        }
      })
  }

  const handleSendNewAccessPermissions = () => {
    const { profile, name, email } = form.newAccessPermissions
    if (!profile || !name || !email) {
      setError({
        newAccessPermissions: {
          profile: !profile,
          name: !name,
          email: !email
        }
      })
    } else {
      handleSendInvite()
    }
  }

  const handleCancelNewAccessPermissions = () => {
    setIsModal({ type: '', isOpen: undefined })
    setForm(initialState)
    setIsError(false)
  }

  const renderBallSlider = (nameList, currentItem) => {
    let numberSlider =
      currentItem === 'accessPermissions'
        ? currentAccessPermissions
        : currentPendingAccesses
    return nameList.map((i, index) => (
      <S.Ball
        key={index}
        currentSlider={index === numberSlider}
        onClick={() => handleBallSlider(currentItem, index)}
      />
    ))
  }

  const renderContentSlider = (label, text, nameSlider, isOpen) => (
    <S.ContentIconSlider>
      <S.IconSlider
        src={IconLeft}
        alt="Anterior"
        onClick={() => handlePrevSlider(nameSlider)}
      />
      <S.BoxSlider width="14.75rem" marginR>
        <S.Label>{label}</S.Label>
        <S.LabelFormRemove width="14.75rem">
          {isOpen ? (
            <Input
              sPaddingBottom
              contentWidth="100%"
              width="100%"
              name="name"
              value={form.newAccessPermissions.name}
              onChange={handleChange}
              error={
                error.newAccessPermissions.name && 'Por favor, insira um nome'
              }
            />
          ) : nameSlider === 'accessPermissions' ? (
            <S.ParagraphInput>{text}</S.ParagraphInput>
          ) : (
            <S.ParagraphInputOutstanding>{text}</S.ParagraphInputOutstanding>
          )}
        </S.LabelFormRemove>
      </S.BoxSlider>
      <S.IconSlider
        right
        src={IconLeft}
        alt="Próximo"
        onClick={() => handleNextSlider(nameSlider)}
      />
    </S.ContentIconSlider>
  )

  const renderModal = () => (
    <S.Overlay>
      <S.ContainerModal>
        <S.Table noBorderSpacing margin id="customers">
          <S.Thead>
            <S.TheadTRDesck>
              <S.TheadTitle>Permissões de cada nível</S.TheadTitle>
              {permissions.map((i, index) => (
                <S.TheadTxt key={index}>{i}</S.TheadTxt>
              ))}
              <S.TheadTxtContentClose>
                <S.TheadTxtClose onClick={handleModalConsultPermissions}>
                  <img src={IconClose} alt="Fechar" />
                  Fechar
                </S.TheadTxtClose>
              </S.TheadTxtContentClose>
            </S.TheadTRDesck>
            <S.TheadTRMob>
              <S.TheadMobOptions>
                {currentData > 0 && (
                  <S.ImgStep
                    src={SimpleArrow}
                    alt="Próximo"
                    onClick={handleBackPermission}
                  />
                )}
                <S.ContentPermission>
                  <S.TheadTitle>Permissões de cada nível</S.TheadTitle>
                  <S.ContentPermission options>
                    {permissions.map((i, idx) => (
                      <S.TheadTxt key={idx} currentData={idx === currentData}>
                        {i}
                      </S.TheadTxt>
                    ))}
                    <S.ContentStep>
                      {permissions.map((i, idx) => (
                        <>
                          <S.LineStep />
                          <S.Step key={idx} currentData={idx === currentData} />
                        </>
                      ))}
                    </S.ContentStep>
                  </S.ContentPermission>
                </S.ContentPermission>
                {permissions.length - 1 !== currentData && (
                  <S.ImgStep
                    back
                    src={SimpleArrow}
                    alt="Voltar"
                    onClick={handleNextPermission}
                  />
                )}
              </S.TheadMobOptions>
            </S.TheadTRMob>
          </S.Thead>
          <S.Tbody>
            {listPermissions.map((item, idx) => (
              <S.TbodyTR key={idx}>
                <S.Span>
                  <S.TbodyTDTxt>{item.text}</S.TbodyTDTxt> <S.HR />
                </S.Span>
                {item.permissions.map((i, idx) => (
                  <S.TbodyTDImg key={idx} currentData={idx === currentData}>
                    <img
                      src={i.typePermission ? GreenCheck : IconBlocked}
                      alt="Permissão"
                    />
                  </S.TbodyTDImg>
                ))}
              </S.TbodyTR>
            ))}
          </S.Tbody>
        </S.Table>
        {renderCloseModal()}
      </S.ContainerModal>
    </S.Overlay>
  )

  const ParagraphInputDesktop = ({
    children,
    label,
    widthDesk,
    paddingBottom,
    paddingBottomDesck,
    paddingRightDesck
  }) => {
    return (
      <S.ContainerInputDesktop
        paddingBottom={paddingBottom}
        paddingBottomDesck={paddingBottomDesck}
        paddingRightDesck={paddingRightDesck}
      >
        <S.Label>{label}</S.Label>
        <S.LabelFormRemove paddingBottom={paddingBottom}>
          <S.ParagraphInputDesktop widthDesk={widthDesk}>
            {children}
          </S.ParagraphInputDesktop>
        </S.LabelFormRemove>
      </S.ContainerInputDesktop>
    )
  }

  const renderAccessPermissions = list => {
    const newList = list.filter(i => i?.profile !== 'Em aberto')
    
    
    return (
      <>
        <S.Title>Permissões de Acesso</S.Title>
        <S.ContentTable>
          <S.Table width="300">
            {accessPermissions && (
              <thead>
                <S.TH>Perfil</S.TH>
                <S.TH>Nome</S.TH>
                <S.TH>E-mail</S.TH>
              </thead>
            )}
            <tbody>
              {newList.map((i, idx) => {
                return (
                  <S.TR key={idx}>
                    <S.ContentProfile>
                      <>
                        <S.TD minWidth="6.8rem" table="accessPermissions">
                          {i.profile}
                        </S.TD>
                        {user?.role === 'Geral' && (
                          <S.BoxIcon
                            onClick={() => handleModal('changeProfile', idx)}
                          >
                            <S.ImgSwitch src={IconSwitch} alt="Trocar" />
                            <S.ParagraphChange>Alterar</S.ParagraphChange>
                          </S.BoxIcon>
                        )}
                      </>
                      {isModal.type === 'changeProfile' &&
                        isModal.item === idx &&
                        isModal.isOpen && (
                          <ContentModal
                            top="31px"
                            name="changeProfile"
                            checked={form.newAccessPermissions.profile}
                            list={listProfile}
                            handleChange={ev => handleChangeProfile(ev, idx)}
                          />
                        )}
                    </S.ContentProfile>
                    <S.TD minWidth="236px" table="accessPermissions">
                      {i.name}
                    </S.TD>
                    <S.TD minWidth="378px" table="accessPermissions">
                      {i.email}
                    </S.TD>
                    {user?.role === 'Geral' && (
                      <S.BoxRemoveItems>
                        <BtnIcon
                          icon={IconRemoveProfile}
                          text="Remover"
                          onClick={() => handleModal('accessPermissions', idx)}
                        />
                        {isModal.type === 'accessPermissions' &&
                          isModal.isOpen &&
                          isModal.item === idx && (
                            <ModalConfirmDelete
                              top="1.5rem"
                              title="Esta ação irá deletar a permissão de acesso."
                              handleCloseModal={handleModal}
                            />
                          )}
                      </S.BoxRemoveItems>
                    )}
                  </S.TR>
                )
              })}
              <S.TR>
                <S.TD noStyle table="accessPermissions">
                  {/* <S.ContentAdd onClick={handleAddAccessPermissions}> */}
                  {form.newAccessPermissions.profile ? (
                    <S.ContentProfile>
                      <S.TD minWidth="6.8rem" table="accessPermissions">
                        {form.newAccessPermissions.profile}
                      </S.TD>
                      <S.BoxIcon>
                        <S.ImgSwitch checked src={CheckGreen} alt="Trocar" />
                        <S.ParagraphChange>Alterar</S.ParagraphChange>
                      </S.BoxIcon>
                    </S.ContentProfile>
                  ) : (
                    <S.ContentAdd
                      onClick={() => handleModal('newAccessPermissions')}
                    >
                      <S.ImgAdd src={AddPendingAccesses} alt="Adicionar" />
                      <S.TextAdd>Adicionar</S.TextAdd>
                    </S.ContentAdd>
                  )}
                  {isModal.type === 'newAccessPermissions' &&
                    isModal.isOpen && (
                      <ContentModal
                        checked={form.newAccessPermissions.profile}
                        list={listProfile}
                        handleChange={handleChange}
                      />
                    )}
                </S.TD>
                {form.newAccessPermissions.profile && (
                  <>
                    <S.TD noPadding table="accessPermissions">
                      <Input
                        sPaddingBottom
                        width="auto"
                        name="name"
                        value={form.newAccessPermissions.name}
                        onChange={handleChange}
                        error={!!error.newAccessPermissions.name}
                      />
                    </S.TD>
                    <S.TD noPadding table="accessPermissions">
                      <Input
                        sPaddingBottom
                        width="auto"
                        name="email"
                        type="email"
                        value={form.newAccessPermissions.email}
                        onChange={handleChange}
                        error={!!error.newAccessPermissions.email}
                      />
                    </S.TD>
                    <S.ContentButtons>
                      <BtnIcon
                        addField
                        noIcon
                        icon={IconRemoveProfile}
                        text="Enviar"
                        onClick={handleSendNewAccessPermissions}
                      />
                      <BtnIcon
                        marginLeft
                        noIcon
                        text="Cancelar"
                        onClick={handleCancelNewAccessPermissions}
                      />
                    </S.ContentButtons>
                  </>
                )}
              </S.TR>
              <S.TextError>{isError}</S.TextError>
            </tbody>
          </S.Table>
        </S.ContentTable>
      </>
    )
  }

  const renderPendingAccesses = list => {
    return (
      <>
        {!!list.length && <S.Title>Acessos pendentes</S.Title>}
        <S.ContentTable>
          <S.Table>
            {!!list.length && (
              <thead>
                <S.TH>Data</S.TH>
                <S.TH>Perfil</S.TH>
                <S.TH>Nome</S.TH>
                <S.TH>E-mail</S.TH>
              </thead>
            )}
            <tbody>
              {list.map(i => {
                return (
                  <S.TR>
                    <S.TD bold orange minWidth="98px">
                      {i.date}
                    </S.TD>
                    <S.TD bold minWidth="110px">
                      {i.profile}
                    </S.TD>
                    <S.TD minWidth="235px">{i.name}</S.TD>
                    <S.TD minWidth="374px"> {i.email}</S.TD>
                  </S.TR>
                )
              })}
            </tbody>
          </S.Table>
        </S.ContentTable>
      </>
    )
  }

  const renderAccessPermissionsSlider = (item, nameList) => {
    let isManagement = item?.profile === 'Gerência'
    let isOpen = item?.profile === 'Em aberto'
    let firstItem = item.id === 0
    let isGeneral = item?.profile === 'Geral' || firstItem

    return (
      <>
        <S.BoxTitlePermissions isOpen={isOpen} nextSlider={animation}>
          <S.Title>Permissões de Acesso</S.Title>
          <S.LinePermissions />
          <S.ContentBox>
            <div>
              <S.BoxSlider>
                <S.Label>Perfil</S.Label>
                <S.LabelFormRemove>
                  <S.ParagRemoveText
                    onClick={ev =>
                      handleModal('changeProfile', currentAccessPermissions, ev)
                    }
                    error={error.newAccessPermissions.profile}
                  >
                    {item?.profile}
                  </S.ParagRemoveText>
                  {!isGeneral && (
                    <S.BoxIcon
                      marginLeft
                      onClick={() =>
                        handleModal(
                          'changeProfile',
                          currentAccessPermissions - 1
                        )
                      }
                    >
                      <S.ImgSwitch src={IconSwitch} alt="Trocar" />
                      <S.ParagraphChange>Alterar</S.ParagraphChange>
                    </S.BoxIcon>
                  )}
                  {isModal.type === 'changeProfile' &&
                    isModal.item === currentAccessPermissions &&
                    isModal.isOpen &&
                    firstItem && (
                      <ContentModal
                        width="17.40rem"
                        top
                        name="changeProfile"
                        checked={form.newAccessPermissions.profile}
                        list={listProfile}
                        handleChange={ev =>
                          handleChangeProfile(
                            ev,
                            currentAccessPermissions - 1,
                            'isMob'
                          )
                        }
                      />
                    )}
                </S.LabelFormRemove>
              </S.BoxSlider>
            </div>
            {renderContentSlider(
              'Nome',
              item?.name,
              'accessPermissions',
              firstItem
            )}
            <S.ContentEmail>
              <div>
                <S.BoxSlider width="23.625rem" noMargin>
                  <S.Label>E-mail</S.Label>
                  {firstItem ? (
                    <Input
                      width="100%"
                      name="email"
                      type="email"
                      value={form.newAccessPermissions.email}
                      onChange={handleChange}
                      error={
                        error.newAccessPermissions.email &&
                        'Por favor, insira um e-mail'
                      }
                    />
                  ) : (
                    <S.ParagraphInput> {item?.email} </S.ParagraphInput>
                  )}
                </S.BoxSlider>
              </div>
              {isManagement && !firstItem && (
                <S.BoxRemoveItems>
                  <BtnIcon
                    icon={IconRemoveProfile}
                    text="Remover"
                    onClick={() => handleModal('accessPermissions')}
                  />
                  {isModal.type === 'accessPermissions' && isModal.isOpen && (
                    <ModalConfirmDelete
                      top="4rem"
                      title="Esta ação irá deletar a permissão de acesso."
                      handleCloseModal={handleModal}
                    />
                  )}
                </S.BoxRemoveItems>
              )}
              {firstItem && (
                <S.ContentAdd onClick={handleSendNewAccessPermissionsMob}>
                  <S.ImgAdd src={AddPendingAccesses} alt="Adicionar" />
                  <S.TextAdd>Adicionar</S.TextAdd>
                </S.ContentAdd>
              )}
            </S.ContentEmail>
          </S.ContentBox>
        </S.BoxTitlePermissions>
        <S.ContentBall>
          {renderBallSlider(accessPermissions, nameList)}
        </S.ContentBall>
      </>
    )
  }

  const renderPendingAccessesSlider = (item, nameList) => {
    return (
      <>
        <S.BoxTitleOutstanding>
          <S.Title>Acessos pendentes</S.Title>
          <S.LinePermissions />
          <S.ContentBox>
            <S.BoxSlider width="6.125rem">
              <S.Label>Data</S.Label>
              <S.LabelFormRemove>
                <S.ParagraphInputOutstandingBold>
                  {item?.date}
                </S.ParagraphInputOutstandingBold>
              </S.LabelFormRemove>
            </S.BoxSlider>
            {renderContentSlider('Tipo', item?.profile, 'pendingAccesses')}
            <S.BoxSlider width="14.723rem">
              <S.Label>Nome</S.Label>
              <S.LabelFormRemove>
                <S.ParagraphInputOutstandingLabel>
                  {item?.name}
                </S.ParagraphInputOutstandingLabel>
              </S.LabelFormRemove>
            </S.BoxSlider>
            <S.BoxSlider width="23.625rem">
              <S.Label>E-mail</S.Label>
              <S.LabelFormRemove>
                <S.ParagraphInputOutstandingLabel>
                  {item?.email}
                </S.ParagraphInputOutstandingLabel>
              </S.LabelFormRemove>
            </S.BoxSlider>
          </S.ContentBox>
        </S.BoxTitleOutstanding>
        <S.ContentBall>
          {renderBallSlider(pendingAccesses, nameList)}
        </S.ContentBall>
      </>
    )
  }

  return (
    <LayoutHomeDashboard seo="/ Configurações" screen="settings">
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <Title
            paddingLeftDesk="2.563rem"
            form
            subtitle="Meu Perfil"
            icon={ConfigHome}
            alt="Configurações"
          >
            Configurações
          </Title>
          <S.ContentForm>
            <ParagraphInputDesktop label="Nome" paddingBottom>
              {date?.user_name}
            </ParagraphInputDesktop>

            <ParagraphInputDesktop
              widthDesk="235px"
              label="Sobrenome"
              paddingBottom
            >
              {date?.last_name}
            </ParagraphInputDesktop>

            <ParagraphInputDesktop
              widthDesk="164px"
              label="Telefone"
              paddingBottom
            >
              {date?.telphone}
            </ParagraphInputDesktop>

            <ParagraphInputDesktop
              widthDesk="448px"
              label="E-mail"
              paddingBottom
            >
              {date?.email}
            </ParagraphInputDesktop>
            <S.Line></S.Line>

            <S.BoxBtnAcess>
              {(user?.role === 'Geral' || user?.role === 'Gerência') && (
                <ParagraphInputDesktop
                  widthDesk="215px"
                  paddingBottomDesck="0"
                  label="Nome da ONG"
                >
                  {date?.ong_name}
                </ParagraphInputDesktop>
              )}

              <SelectAccess
                widthDesk="170px"
                label="Perfil de Acesso"
                type={user?.role}
                value={user?.role}
              />
              <S.BoxBtn>
                <S.BtnPermissions onClick={handleModalConsultPermissions}>
                  Consultar permissões
                </S.BtnPermissions>
              </S.BoxBtn>
            </S.BoxBtnAcess>
          </S.ContentForm>
          <LabelLine label="Dados da ONG" />

          {(user?.role === 'Master' || user?.role === 'Supervisão') && (
            <S.DataOng>
              <ParagraphInputDesktop
                paddingBottom
                paddingRightDesck
                widthDesk="215px"
                label="Comunidade"
              >
                {date?.community}
              </ParagraphInputDesktop>
              <ParagraphInputDesktop
                paddingBottom
                widthDesk="215px"
                label="Nome da ONG"
              >
                {date?.ong_name}
              </ParagraphInputDesktop>
              <ModalSettingPage
                form
                title="Editar dados da ONG"
                label={listOngs}
                bgTitle="#7FBE30"
                paddingTop="0.5rem"
                handleSelectOng={handleEditOng}
              />
            </S.DataOng>
          )}

          <S.ContainerComponents>
            {componentRender === 1 ? (
              <S.Box>
                <OfficialContactsAndData />
              </S.Box>
            ) : componentRender === 2 ? (
              <S.Box>
                <ActingAssisted />
              </S.Box>
            ) : componentRender === 3 ? (
              <S.Box>
                <PhysicalAndFunctionalStructure />
              </S.Box>
            ) : componentRender === 4 ? (
              <S.Box>
                <SendDocument />
              </S.Box>
            ) : (
              <></>
            )}
          </S.ContainerComponents>

          {isDescktop
            ? renderAccessPermissions(accessPermissions)
            : renderAccessPermissionsSlider(
                accessPermissions[currentAccessPermissions],
                'accessPermissions'
              )}

          {!pendingAccesses.lenght &&
            (isDescktop
              ? renderPendingAccesses(pendingAccesses)
              : renderPendingAccessesSlider(
                  pendingAccesses[currentPendingAccesses],
                  'pendingAccesses'
                ))}

          {(user?.role === 'Geral' || user?.role === 'Gerência') && (
            <>
              <LabelLine label="Excluir ONGs" />
              <S.BackgSearch>
                <S.SearchInput
                  type="search"
                  placeholder="Buscar ONG ou comunidade"
                  name="search"
                  value={search}
                  onChange={handleSearchChange}
                />
                <S.BtnSearch onClick={handleSearchOng} type="button">
                  <S.ImgSearch src={IconSearch} alt="Buscar" />
                </S.BtnSearch>
              </S.BackgSearch>

              {/* {ongFound?.map((i) => ( */}
              {isFiltro ? (
                listOngsWithComunity.map(item => (
                  <S.ContentDeleteOng>
                    <S.BoxComunity paddRight>
                      <S.Label>Comunidade</S.Label>
                      <S.LabelFormRemove>
                        <S.ParagraphInputComunityRemove>
                          {item.ong_address?.community}
                        </S.ParagraphInputComunityRemove>
                      </S.LabelFormRemove>
                    </S.BoxComunity>
                    <S.BoxComunity>
                      <S.Label>Nome da ONG</S.Label>
                      <ModalDeleteOngsComponent item={item}/>
                    </S.BoxComunity>
                  </S.ContentDeleteOng>
                ))): filtro.map(item => (
                  <S.ContentDeleteOng>
                    <S.BoxComunity paddRight>
                      <S.Label>Comunidade</S.Label>
                      <S.LabelFormRemove>
                        <S.ParagraphInputComunityRemove>
                          {item.ong_address?.community}
                        </S.ParagraphInputComunityRemove>
                      </S.LabelFormRemove>
                    </S.BoxComunity>
                    <S.BoxComunity>
                      <S.Label>Nome da ONG</S.Label>
                      <ModalDeleteOngsComponent item={item}/>
                    </S.BoxComunity>
                  </S.ContentDeleteOng>
                ))} 
              {filtro.length === 0 && !isFiltro && (
                <S.TextError margin> Ong não encontrado</S.TextError>
              )}
            </>
          )}
        </S.Form>
        {isOpenModal && renderModal()}
        <FooterHome screen="settings" />
      </S.Container>
      <ToastContainer/>
    </LayoutHomeDashboard>
  )
}

export default SettingsPage
