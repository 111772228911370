import styled from "styled-components/macro";
import { CommunityImage } from '../../assets';
import { colors } from "../../styles/Theme";

export const Container = styled.div`
  /*  */
`;
export const Content = styled.div`
  display: flex;
  width: 100vw ;
  height: calc(100vh - 5rem);
`;

export const ContentImage = styled.div`
  position: relative;
  width: 60%;
  background-image: url(${CommunityImage});
  background-repeat: no-repeat;
  background-position: 38%;
  background-size: cover;
  background-position-x: right;

  @media ( max-width: 768px ) {
    display: none;
  }
`;


export const Main = styled.main`
  width: 100%;
  padding: 2.375rem 1.875rem 4.063rem 1rem;

  div {
    text-align: left;
  }

  button {
    margin-top: 1.5rem;
  }

  @media ( min-width: 768px ) {
    padding: 9rem 0 0 8.625rem;
    width: 40%;
  }

  @media ( max-width: 1060px ) {
    padding-left: 4.625rem;
  }
`;

export const BoxVi = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 60.5vh;

  @media (min-width: 320px) {
    height: 53vh;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

export const ImgVi = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;

  @media (max-width: 768px) and (min-width: 320px) {
    width: 300px;
  }
`
export const Overlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 5rem);
  background: rgba(158, 158, 158, 0.5);
  backdrop-filter: blur(50px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  @media (max-width: 1023px) {
    top: 0;
    height: 100%;
    overflow-y: hidden;
  }
`

export const ContainerModal = styled.div`
  position: relative;
  width: 80%;
  max-width: 1304px;
  height: 74vh;
  max-height: 812px;
  background-color: ${colors.white};
  overflow-y: scroll;
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #707070 #e0e0e0;
  padding: 1rem 2rem;

  h1, h2 {
    margin: 8px 0;
  }

  table {
    border: 1px solid;
    margin: 8px 0;
  }

  li {
    margin: 8px 0;
  }

  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #707070;
    border-radius: 20px;
    border: 3px solid #e0e0e0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
`

export const ContainerCloseModal = styled.div`
  position:absolute;
  top:1rem;
  right:1rem;
  margin-inline-start: auto;
  width: 2.563rem;
  padding: 0.375rem 0 0.25rem 0;
  /* height: 2.563rem; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background: #ffecd3;
  /* padding:3px; */
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1024px) {
    //display: none;
  }
`

export const ImageCloseModal = styled.img`
  height: 1.25rem;
`

export const Trash = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  font-family: 'Open Sans';
  color: ${colors.orange};
  line-height: 13.62px;
`
