import * as yup from "yup";

import {
  birthGenderList,
  familyIncomeList,
  genderList,
  skinColorsList,
  workList,
} from "./lists";
import formMessages from "./formMessages";

const { general, fullname, cpf, birthDate, contactNum } = formMessages;

export const createAssistedSchema = yup.object().shape({
  fullname: yup
    .string()
    .required(general.errorMessage)
    .min(3, fullname.description)
    .matches(/^[^\d]*$/, general.onlyLetters),
  socialName: yup.boolean(),
  cpfStatus: yup.string().nullable(),
  cpf: yup.string().when("cpfStatus", {
    is: (option) => option === null || option === undefined,
    then: (schema) =>
      schema
        .required(general.errorMessage)
        .test(
          "test cpf length",
          cpf.description,
          (val) => val?.replace(/\D/g, "")?.length === 11,
        ),
  }),
  birthDate: yup
    .string()
    .transform((val) => {
      const [day, month, year] = val.split("/");
      return `${year}-${month}-${day}`;
    })
    .required(general.errorMessage)
    .test(
      "test birthdate length",
      birthDate.description,
      (val) =>
        val?.length === 10 &&
        /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(val),
    ),
  dontApplyResponsibleName: yup.string().oneOf(["on", null]).nullable(),
  responsibleName: yup.string().when("dontApplyResponsibleName", {
    is: (option) => option === null || option === undefined,
    then: (schema) =>
      schema
        .required(general.errorMessage)
        .min(3, fullname.description)
        .matches(/^[^\d]*$/, general.onlyLetters),
  }),
  doesNotHaveContactNum: yup.string().oneOf(["on", null]).nullable(),
  contactNum: yup.string().when("doesNotHaveContactNum", {
    is: (option) => option === null || option === undefined,
    then: yup
      .string()
      .required(general.errorMessage)
      .transform((val) => val.replace(/\D/g, ""))
      .min(9, contactNum.description),
  }),
  skinColor: yup
    .string()
    .nullable()
    .oneOf(skinColorsList.map(({ value }) => value))
    .required(general.errorMessage),
  birthGender: yup
    .string()
    .nullable()
    .oneOf(birthGenderList.map(({ value }) => value))
    .required(general.errorMessage),
  gender: yup
    .string()
    .nullable()
    .oneOf(genderList.map(({ value }) => value))
    .required(general.errorMessage),
  scholarity: yup.string().typeError(general.errorMessage).required(),
  graduationName: yup.string().when("scholarity", {
    is: "completed_college",
    then: yup.string().required(general.errorMessage),
  }),
  work: yup
    .string()
    .nullable()
    .oneOf(workList.map(({ value }) => value))
    .required(general.errorMessage),
  workActivities: yup
    .array()
    .transform((val) => [...new Set(val)].map((name) => ({ name }))),
  governmentAid: yup.boolean().nullable().required(general.errorMessage),
  governmentAids: yup
    .array()
    .transform((val) => [...new Set(val)].map((name) => ({ name }))),
  physicalDisability: yup.string().nullable().required(general.errorMessage),
  physicalDisabilities: yup
    .array()
    .transform((val) => [...new Set(val)].map((name) => ({ name }))),
  chronicDisease: yup.string().nullable(),
  diseases: yup
    .array()
    .transform((val) => [...new Set(val)].map((name) => ({ name }))),
  hasHobbies: yup.boolean().nullable().notRequired(),
  hobbies: yup
    .array()
    .transform((val) => [...new Set(val)].map((name) => ({ name }))),
  domesticViolence: yup.string().nullable().required(general.errorMessage),
  familyGroup: yup.object().shape({
    internetAccess: yup.boolean().nullable().required(general.errorMessage),
    cellQty: yup.number().when("internetAccess", {
      is: true,
      then: yup
        .number()
        .typeError("insira um número")
        .required(general.errorMessage)
        .min(0, "somente números positivos")
        .max(100, "limite é de até 100 celulares"),
    }),
    computerQty: yup.number().when("internetAccess", {
      is: true,
      then: yup
        .number()
        .typeError("insira um número")
        .required(general.errorMessage)
        .min(0, "somente números positivos")
        .max(100, "limite é de até 100 computadores"),
    }),
    radio: yup.boolean(),
    fridge: yup.boolean(),
    microwave: yup.boolean(),
    washingMachine: yup.boolean(),
    stove: yup.boolean(),
    tv: yup.boolean(),
    familyIncome: yup
      .string()
      .nullable()
      .oneOf(familyIncomeList.map(({ value }) => value))
      .required(general.errorMessage),
  }),
});

export const createFamilyGroupSchema = yup.object().shape({
  comunityName: yup.string(),
  comunityArea: yup.string().required(general.errorMessage),
  street: yup.string().required(general.errorMessage),
  number: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(general.errorMessage)
    .min(0, "somente números positivos"),
  adjunct: yup.string().required(general.errorMessage),
  landmark: yup.string().required(general.errorMessage),
  familyGroupResidents: yup.array().of(
    yup.object().shape({
      name: yup.string().required(general.errorMessage),
      birthdate: yup
        .string()
        .transform((val) => {
          if (!val) return "";

          const [day, month, year] = val.split("/");
          return `${year}-${month}-${day}`;
        })
        .test(
          "test birthdate length",
          birthDate.description,
          (val) =>
            !val ||
            (val?.length === 10 &&
              /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/.test(
                val,
              )),
        ),
      cpf: yup
        .string()
        .transform((val) => val.replace(/[\.\-\_]/g, ""))
        .test(
          "test cpf length",
          cpf.description,
          (val) => !val || val?.length === 11,
        ),
    }),
  ),
});

export const createAssistedActivitiesSchema = yup.object().shape({
  hasNoActivity: yup.boolean(),
  activitiesId: yup.array().when("hasNoActivity", {
    is: false,
    then: yup
      .array()
      .transform((val) => (!val ? [] : val.map((id) => ({ id }))))
      .min(1, general.errorMessage),
    otherwise: yup.array().optional(),
  }),
});

export const activitySchema = yup.object().shape({
  begining_date: yup.string().transform((val) => val + "-01"),
  finish_date: yup.string().transform((val) => val + "-01"),
  date: yup.string(),
  assisteds: yup.array().of(
    yup.object().shape({
      grade: yup.number().positive(),
      average: yup.number().positive(),
      status: yup.string().max(15, "status muito longo"),
      assisted: yup.string(),
    }),
  ),
});

export const gradeDataSchema = yup.object().shape({
  average: yup.number(),
  grade: yup.number(),
  begining_date: yup.string(),
  finish_date: yup.string(),
});

export const attendanceDataSchema = yup.object().shape({
  status: yup.string().min(1),
  date: yup.string(),
});
