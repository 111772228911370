import React from "react";

//Components
import FooterText from "./footerTexts";
import BtnBack from "../Buttons/back";

//Styles
import * as S from "./styles";

const Footer = ({ text, link, href, underline, paddingTop }) => {
  return (
    <S.Container paddingTop={paddingTop}>
      <FooterText text={text} link={link} href={href} underline={underline} />
      <BtnBack />
    </S.Container>
  );
};

export default Footer;
