import React, { useState, useEffect } from "react";
import * as S from "./styles";

import { useApi } from "../../providers/Api";

import { IconHome } from "../../assets";

const HeaderHome = ({ seo }) => {
  const { getMyUser } = useApi();
  const [user, setUser] = useState("");

  const handleGetUser = async () => {
    const response = await getMyUser();
    setUser(response);
  };

  useEffect(() => {
    handleGetUser();
  }, []);
  return (
    <S.Container>
      <S.IconHome src={IconHome} alt="Icon Home" />
      <S.P>
        /Perfil {user?.role} {seo}
      </S.P>
    </S.Container>
  );
};
export default HeaderHome;
