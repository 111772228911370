import styled from "styled-components/macro";

import { colors } from "../../styles/Theme";

export const Container = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    border-bottom: 1px solid ${colors.black02};
    padding: 1rem;
  }
`;

export const P = styled.p`
  font-weight: 700;
  font-size: 1rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const IconHome = styled.img`
  @media (min-width: 1024px) {
    margin-left: 4rem;
    width: 1.15rem;
    height: 1.041rem;
    margin-top: 0.1rem;
  }
`;
