export const fieldToTextMapping = {
  birthDate: "data de nascimento",
  birthGender: "sexo de nascimento",
  chronicDisease: "doença crônica",
  contactNum: "celular para contato",
  cpf: "CPF",
  domesticViolence: "violência doméstica",
  familyIncome: "renda total da família",
  computerQty: "quantidade de computadores",
  cellQty: "quantidade de celulares",
  internetAccess: "acesso à internet banda larga",
  fullname: "nome completo",
  gender: "gênero",
  governmentAid: "auxílio do governo",
  hasHobbies: "hobbies",
  physicalDisability: "deficiência física",
  responsibleName: "nome do responsável",
  skinColor: "cor",
  scholarity: "escolaridade",
  work: "trabalho formal",
  adjunct: "complemento",
  comunityArea: "área de comunidade",
  landmark: "ponto de referência",
  number: "número da sua rua principal",
  street: "rua",
  name: "nome",
  activitiesId: "atividades que o assistido participa",
  graduationName: "nome da graduação",
};
