

import React from "react";

// styles
import * as S from "./styles";
const LabelLine = ({label, bold}) => {
  return (
    <S.BoxLabelLine>
      <S.Label bold={bold}>{label}</S.Label>
      <S.LineTwo />
    </S.BoxLabelLine>
  );
};

export default LabelLine;
