import styled from "styled-components/macro";

// icon
import CheckIcon from '../../../../../../../assets/icons/checked-icon.png'

export const ModalInputs = styled.div`
    margin: 20px 0 0 71px;
    height: 34.375rem;
    overflow: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

@media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 1rem 0 1rem 2.5rem ;
}
`
export const Person = styled.div`
    height: 35px;
`
export const Left = styled.div`
`
export const Right = styled.div`

@media (min-width: 1024px) {
    padding-left: 3rem;
}
`