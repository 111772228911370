import React, { useState, useEffect } from "react";

import { api } from "../../../../../modules/api";
import { useApi } from "../../../../../providers/Api";

// components
import DataTable from 'react-data-table-component-with-filter';
import ModalGrey from "./modalGrey";
import { skinColorsList, scholarityList } from "./../../../../../utils/lists";

// styles
import * as S from "./styles";

const SearchWatched = ({ assistedList }) => {
  const { getMyUser } = useApi();
  const [isModal, setIsModal] = useState(false);
  const [user, setUser] = useState();
  const [selectedAssisted, setSelectedAssisted] = useState();

  const handleClick = (ev, item) => {
    ev.preventDefault();
    setIsModal(!isModal);
    setSelectedAssisted(item);
  };
  useEffect(() => {
    (async () => await getMyUser().then((res) => setUser(res)))();
  }, []);

  const columns = [
{
  name: 'Nome',
  selector: row => row.fullname,
},
{
  name: 'Atividades',
  selector: row => row.activity,
},
{
  name: 'Data de Nascimento',
  selector: row => row.birth_date,
},
{
  name: 'Cor da Pele',
  selector: row => row.skin_color,
},
{
  name: 'Renda Familiar',
  selector: row => row.family_income,
},
{
  name: 'Escolaridade',
  selector: row => row.scholarity,
},
];

  const data = assistedList.map((assisted, index) => {
      return {
        id: index,
        fullname: assisted?.fullname,
        birth_date: assisted?.birth_date.split('-').reverse().join('/'),
        skin_color: skinColorsList.find((item) => item.value === assisted?.skin_color)?.name,
        family_income: assisted?.family_group?.family_income,
        activity: assisted.activities.map(activity => activity?.name).join(', '),
        scholarity: scholarityList.find((item) => item.value === assisted?.scholarity)?.name,
        fullitem: assisted
      }
    }
  )

    return (
      <>
    <DataTable
    responsive
    striped
    pointerOnHover
    dense
    columns={columns}
    data={data}
    onRowClicked={(row, event) => {handleClick(event, row.fullitem);}}
    />

    {isModal && <ModalGrey data={selectedAssisted} setIsModalContainer={setIsModal}/>}
    </>
    );

};

export default SearchWatched;
