import styled from "styled-components";

export const StyledTitle = styled.h4`
  color: ${({ hasError }) => (hasError ? "#B71C1C" : "#000")};
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: ${({ isSubtitle }) => (isSubtitle ? "400" : "600")};
  line-height: normal;
`;
