import styled from "styled-components";

export const Content = styled.form`
  position: relative;
  margin: 0 auto;
  padding-top: 2.961rem;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 377px;
  height: calc(100vh - 5rem);

  @media (min-width: 1024px) {
    position: relative;
    padding-top: 5rem;
  }
`;
export const BoxHr = styled.div`
  padding-top: 2rem;
  @media (min-width: 768px) {
    display: none;
  }
`;

// BOX

export const BoxTitle = styled.div`
  width: 17rem;
  @media (min-width: 768px) {
    width: 11rem;
  }
`;

export const BoxContainer = styled.div`
  @media (min-width: 768px) {
    width: 25rem;
  }
`;

export const Container = styled.div`
  @media (min-width: 768px) {
    padding-top: 1.5rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BoxMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const BoxDesktop = styled.div`
  display: none;
  @media (min-width: 780px) {
    display: flex;
    flex-direction: column;
  }
`;

export const BoxForm = styled.div`
  padding-top: 2rem;
  @media (min-width: 768px) {
    padding-top: 5rem;
  }
`;

export const BoxBtn = styled.div`
 
  @media (min-width: 768px) {
    padding-top: 2rem;
  }

  @media(max-width: 770px) {
    margin-top: 20px;
  }
`;
