import styled from "styled-components";

import { colors } from "../../../../styles/Theme";
import { BackgTransitionMob } from "../../../../assets";

// export const Container = styled.div`
//   width: 100vw;
//   height: 100vh;
// `;

// export const Main = styled.main`
//   width: 100%;
//   height: calc(100vh - 5rem);
//   display: flex;
// `;

export const ContainerTransition = styled.section`
  position: relative;
  width: 100vw;
  height: initial;

  @media (min-width: 1024px) {
    width: 36%;
    height: 100%;
    border-right: 1px solid ${colors.gray};
  }

  @media (min-width: 800px) {
    position: initial;
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100vw;
  height: initial;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 800px) {
    width: 100%;
    height: 90%;
  }
  @media (max-height: 800px) {
    height: 40vh;
  }
`;

export const ContentForm = styled.section`
  margin: 0 auto;
  width: 70%;

  @media (min-width: 1024px) {
    margin: 0;
    width: 100%;
  }
`;

export const IllustrationText = styled.p`
  margin: 0 auto;
  padding-bottom: 1rem;
  top: 70px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  width: 205px;
  right: 20px;

  @media (min-width: 1024px) {
    max-width: 250px;
    flex: 1;

    margin: 0;
    margin-bottom: 20px;

    font-size: 16px;
  }

  @media (min-width: 1100px) {
    font-size: 18px;
  }
`;

export const IllustrationContainer = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  @media (min-width: 1440px) {
    /* top: -24rem; */
  }
`;

export const ImgIllustration = styled.img`
  display: ${(props) => (props.mobile ? "flex" : "none")};
  position: relative;
  top: -0.5rem;
  padding-left: 43%;
  width: 100%;
  height: 13rem;
  object-fit: cover;
  border-end-end-radius: 50%;
  border-end-start-radius: 50%;
  background: ${colors.white};
  z-index: 2;

  @media (max-width: 350px) {
    /* padding-left: 30%; */
  }

  @media (min-width: 1024px) {
    width: 250px;
    height: 200px;

    position: relative;
    top: 10px;
    left: 1.5rem;

    display: ${(props) => (props.descktop ? "flex" : "none")};

    border-end-end-radius: 0;
    border-end-start-radius: 0;

    object-fit: cover;
    padding-left: 0;

    z-index: -1;
  }
`;

export const ImgVi = styled.img`
  position: relative;
  top: -4px;
  padding-left: 50%;
  /* width:100vw; */
  /* height: 15rem;
  background: ${colors.white};

  object-fit: cover;

  /* @media (max-width: 465px) {
    padding-left: 35%;
  }

  @media (max-width: 400px) {
    padding-left: 9%;
  }*/

  @media (min-width: 1024px) {
    position: none;
    top: 0;
    padding-left: 0;
  }
`;

export const BoxTransitionTasks = styled.div`
  margin: 0 auto;
  text-align: center;
  width: fit-content;

  @media (min-width: 1024px) {
    flex: 1;
    width: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: auto;
    margin-top: 20px;
    text-align: start;
  }
`;

export const ContentFooter = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${colors.gray04};
  /* background-image: url(${BackgTransitionMob}); */
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 1024px) {
    width: 100%;
    height: 60%;
    margin: auto;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    border-start-start-radius: 40%;
    border-start-end-radius: 40%;
  }

  @media (min-width: 804px) {
    margin: 0;
  }
`;

export const Footer = styled.footer`
  padding: 4.625rem 0 2rem 0;

  @media (min-width: 1024px) {
    padding: 0px;
    & > button:nth-child(2) {
      right: 15px;
    }
  }
`;
