import React from "react";
import { useNavigate } from "react-router-dom";

// componentes
import Title from "../ProvisionalTitle";
import Layout from "../LayoutImage";
import Button from "../Buttons/button";

const RenderTitle = ({ title }) => <Title successScreen>{title}</Title>;

const SuccessScreen = ({ title, margin, wrap }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <Layout directionContent="flex-start">
      <RenderTitle title={title} wrap />
      <Button
        margin={margin}
        marginLeft="0"
        text="Conecte-se"
        display="flex"
        width="8.6rem"
        onClick={handleClick}
      />
    </Layout>
  );
};

export default SuccessScreen;
