import styled, { css } from "styled-components";
import { colors } from "../../../styles/Theme";

export const Container = styled.form`
  padding-bottom: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  margin-bottom: 5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 56%;
    margin: 0 auto;
    padding-bottom: 0;
  }
`;

export const BoxTitle = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    width: 100%;
    padding-top: 2.813rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormAdd = styled.div`
  width: 100%;
  background: #f3f7ff;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    text-align: left;
  }
`;

export const LineBtn = styled.hr`
  color: 1px solid ${colors.black02};
  display: absolute;
  width: 90%;
  height: 0.1rem;
  margin-top: 1rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Box = styled.div`
  margin-top: 1rem;
  padding-bottom: ${(props) => props.paddingBottom && "1rem"};
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (min-width: 1024px) {
    width: auto;
  }
`;

export const Label = styled.label`
  margin-top: ${(props) => (props.margin ? "0.438rem" : ".1rem")};
  font-weight: 400;
  padding-right: 1.813rem;
`;

export const LabelCheckbox = styled.label`
  padding-top: ${(props) => props.paddingTop && ".563rem"};
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 400;
`;

export const InputText = styled.input`
  background: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 5px;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: ${(props) => props.isError && `1px solid ${colors.error}`};
`;

export const InputTextCriterio = styled.input`
  border: 1px solid #bebebe;
  border-radius: 5px;
  width: 8rem;
  height: auto;

  padding: 0.5rem;
  font-size: 1.01rem;
  letter-spacing: 0.05em;
  color: #000000;
  margin-top: 0.1rem;
`;

export const InputDate = styled.input`
  padding-left: 0.5rem;
  padding-right: 10px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  width: 13.5rem;
  height: 1.563rem;
  margin-left: 0.5rem;
  font-size: 16px;
  font-family: monospace;
  background-color: transparent;
  text-transform: uppercase;
  border: ${(props) => props.isError && `1px solid ${colors.error}`};
  background: ${(props) => props.colorful && `${colors.darkGray}`};
  border: ${(props) => props.colorful && `1px solid ${colors.gray03}`};
  color: ${(props) => props.colorful && `${colors.gray}`};
`;

export const CustomCheckbox = styled.div`
  background-color: ${(props) => (props.checked ? "#97C160" : "#E5E5E5")};
`;

export const InputCheckboxt = styled.input`
  :before {
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    background-color: red;
  }

  ${(props) =>
    props.isError &&
    css`
      :before {
        content: "";
        color: transparent !important;
        display: block;
        width: 1.15rem;
        height: 1.15rem;
        border-radius: 3px;
        border: 1px solid ${colors.error};
      }
    `}
`;

export const LabelCheckboxt = styled.label`
  :before {
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    background-color: red;
  }
`;

//custoumcheckbox

export const InputCheckbox = styled.input`
  all: unset;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  margin-right: 0.5rem;
  background-color: #ffff;
  &:checked {
    background-color: #ff8f00;
    width: 0.8rem;
    height: 0.8rem;
  }

  ${(props) =>
    props.isError &&
    css`
      :before {
        content: "";
        color: transparent !important;
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 3px;
        border: 1px solid ${colors.error};
      }
    `}
`;

export const Select = styled.select`
  background: #5484ed;
  border-radius: 5px;
  width: 18.75rem;
  height: 2.5rem;
  border: none;
  color: #f5f5f5;
  font-weight: 700;
`;

export const Option = styled.option`
  background: white;
  color: #000000;
`;

export const ButtonAdd = styled.button`
  margin-top: 1rem;
  background: #5484ed;
  border-radius: 50px;
  width: 8.319rem;
  height: 1.688rem;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  @media (min-width: 1024px) {
  }
  &:hover {
    font-weight: 800;
  }
`;

export const Bold = styled.span`
  font-weight: 700;
  padding-bottom: 0.5rem;
`;

export const FontSize = styled.span`
  font-size: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1022px) {
  }
`;

export const ContentActivityInRegistration = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FormRemove = styled.div`
  margin-bottom: 2.563rem;
  width: 100%;
  background: #ffecd3;
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  max-width: ${(props) => props.small && "48%"};

  @media (max-width: 1022px) {
    flex-direction: column;
  }

  label {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
  }
`;

export const ContentFormRemove = styled.div`
  width: 90%;

  @media (min-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const ParagRemoveText = styled.p`
  width: 20rem;
  height: 2.2rem;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #707070;
  padding: 1rem;

  @media (min-width: 1024px) {
    width: 13rem;
    height: 1.2rem;
  }
`;

export const InputDateRemove = styled.div`
  margin-left: 0.5rem;
  padding-left: 0.3rem;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  width: 10rem;
  height: 1.563rem;
  color: #707070;
`;

export const ImageRemoveData = styled.img`
  padding-left: 0.5rem;
  width: 1.5rem;
  filter: brightness(0) saturate(100%) invert(55%) sepia(0%) saturate(6025%)
    hue-rotate(39deg) brightness(86%) contrast(90%);
  cursor: pointer;
`;

export const HoverEdit = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 40%;

  @media (min-width: 1024px) {
    min-width: 15%;
  }

  &:hover {
    input {
      background: #ffffff;
      border: 1px solid #ff8f00;
    }

    img {
      filter: brightness(0) saturate(100%) invert(76%) sepia(46%)
        saturate(6725%) hue-rotate(360deg) brightness(102%) contrast(105%);
    }
  }
`;

export const InputCheckboxRemove = styled.input`
  margin-right: 0.5rem;

  input:checked {
    color: red;
  }
`;

export const HoverRemove = styled.div`
  margin-top: ${(props) => (props.marginTop ? ".375rem" : ".438rem")};
  min-height: 1.563rem;
  height: auto;
  display: flex;
  align-items: left;
  &:hover {
    p {
      border: 1px solid #ff8f00;
    }

    img {
      filter: brightness(0) saturate(100%) invert(76%) sepia(46%)
        saturate(6725%) hue-rotate(360deg) brightness(102%) contrast(105%);
    }
  }
`;

export const ParagRemoveCriterion = styled.p`
  padding: 0.8rem;
  width: 9.328rem;
  min-height: 2rem;
  height: auto;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #000000;
  overflow-wrap: anywhere;

  @media (min-width: 1024px) {
    width: 8em;
    min-height: 1rem;
    font-size: 0.9rem;
    padding: 0 0.7rem;
  }
`;

export const ParagRemoveCriterion1 = styled.p`
  width: 9.328rem;
  height: 2rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 0.8rem;
  border: ${(props) => props.isError && `1px solid ${colors.error}`};

  @media (min-width: 1024px) {
    width: 8em;
    height: 1rem;
    font-size: 0.9rem;
    padding: 0.7rem;
  }
`;

export const InputRemoveCriterion = styled.input`
  width: 9.328rem;
  height: 2rem;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #000000;
  padding: 0.8rem;
  border: ${(props) => props.isError && `1px solid ${colors.error}`};

  @media (min-width: 1024px) {
    width: 8em;
    height: 1rem;
    font-size: 0.9rem;
    padding: 0.7rem;
  }
`;

export const ParagraphOng = styled.p`
  text-align: left;
  font-size: 0.688rem;
  color: #000000;

  @media (min-width: 1024px) {
    width: 22rem;
  }
`;

export const ParagRemoveOng = styled.p`
  width: 13.249rem;
  min-height: 2.2rem;
  background: #e7e7e7;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #707070;
  padding: 0.5rem;

  @media (min-width: 1024px) {
    width: 15rem;
    min-height: 0.5rem;
  }
`;

export const BoxButtons = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border-top: solid 1px #ff8f00;

  @media (min-width: 1024px) {
    border-top: solid 1px #000000;
  }

  button {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      font-weight: 800;
    }
  }
`;

export const ButtonRemove = styled.button`
  width: 6.688rem;
  height: 1.688rem;
  background: #ff8f0020;
  border: 1px solid #ff8f00;
  color: #ff8f00;
  border-radius: 50px;
  margin-top: 1rem;
`;

export const ButtonSave = styled.button`
  width: 6.688rem;
  height: 1.688rem;
  background: #ff8f00;
  border: 1px solid #ff8f00;
  color: #ffffff;
  border-radius: 50px;
  margin-top: 1rem;
`;

export const BoxBtn = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 5rem;

  @media (min-width: 1024px) {
    margin-bottom: 1rem;
    padding-bottom: 0;
  }
`;
export const Line = styled.hr`
  color: 1px solid ${colors.black02};
  display: absolute;
  width: 50rem;
  height: 0.1rem;
  margin-right: 0.5rem;
`;

export const Criterios = styled.div`
  display: flex;
  align-items: left;
  justify-content: flex-end;
  min-width: 40%;

  &:hover {
    input {
      border: 1px solid #ff8f00;
    }
  }
`;

export const ImgBox = styled.div`
  justify-content: row;
  min-width: 40%;
`;

export const LineForm = styled.hr`
  color: 1px solid ${colors.black02};
  display: absolute;
  justify-content: start;
`;

export const NovoCriterioBox = styled.div`
  margin-top: 1.25rem;
`;

export const BoxBtnAdd = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @media (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const P = styled.p`
  margin-top: -1rem;
  padding-bottom: 2.188rem;
  font-size: 0.8rem;

  @media (min-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const Package = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding-bottom: 1rem;
  border-bottom: solid 1px #5484ed;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 90%;
    margin-top: 1rem;
    border-bottom: solid 1px #000000;
  }
`;

export const TextError = styled.p`
  margin-top: -1rem;
  font-size: 0.75rem;
  color: ${colors.error};
  font-weight: 400;

  @media (min-width: 1024px) {
    font-size: 0.875rem;
  }
`;
