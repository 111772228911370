import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Container = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? "37px" : "31px")};
  z-index: 1;
  padding: 0 0.625rem 0.625rem 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: ${(props) => (props.width ? props.width : "6.8rem")};
  width: 100%;
  background: ${colors.white};
  border-radius: 4px 4px 4px 4px;
  border-bottom: solid 1px ${colors.gray05};
  border-left: solid 1px ${colors.gray05};
  border-right: solid 1px ${colors.gray05};

  @media (max-width: 500px) {
    width: ${(props) => props.mobWidth};
  }
  
  @media (min-width: 1024px) {
    position: initial;
  }

`;

export const Label = styled.label`
  position: relative;
  padding-top: 0.625rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "Open Sans";
  font-style: normal;
  color: ${(props) => (props.checked ? colors.blue01 : colors.black)};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px 4px 4px 4px;
  background: ${colors.white};
  cursor: pointer;

  :hover {
    color: ${colors.blue01};
  }
`;

export const Fill = styled.div`
  position: absolute;
  top: 13px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: ${(props) => (props.checked ? colors.blue01 : colors.gray05)};
`;

export const Input = styled.input`
  margin-right: 0.685rem;
  width: 0.6rem;
  height: 0.6rem;
  background: ${(props) => (props.checked ? colors.blue01 : colors.gray05)};
  border-radius: 50%;
`;

export const Line = styled.span`
  display: ${(props) => props.last && "none"};
  margin-top: 0.625rem;
  height: 1px;
  width: 16.375rem;
  background: ${colors.gray05};

  :firsti-child {
    display: none;
  }
`;
