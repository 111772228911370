import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import { api } from "../../../../modules/api";

//Components
import Layout from "../layout";
import Title from "../../../../components/Title";

//Styles
import * as S from "./styles";

// Logo
import {
  IconPaperGreen,
  IconDoc,
  IconDocWhite,
  IconPaperGreenCheck,
  IconUpload,
  IconRemove,
} from "../../../../assets";

import { changePermission } from "../../../../utils/permissions";
import Button from "../../../../components/Buttons/button";
import BtnBackWhite from "../../../../components/Buttons/backGrey";

const Subtitle = ({ subtitle, sendingDocuments }) => (
  <>
    <S.Subtitle width={sendingDocuments}>{subtitle}</S.Subtitle>
  </>
);

const RenderSubtitle = () => (
  <>
    <Subtitle
      width={true}
      // homeScreen
      subtitle="O maior número de documentos enviados possibilitará 
      que a  Favela Inc faça uma filtragem mais qualificada de oportunidade 
      de acordo com o seu perfil de atuação."
    />
  </>
);

const SendDocument = () => {
  const navigate = useNavigate();
  const [ongId, setOngId] = useState("");
  const [user, setUser] = useState("");
  const [isSelected, setIsSelected] = useState({
    type: undefined,
    isOpen: false,
  });
  const [upload, setUpload] = useState([]);
  const [isError, setIsError] = useState(undefined);
  changePermission(3);

  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = async () => {
    if (localStorage.getItem("idToken")) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("idToken")}`,
        },
      };
      const response = await api.get("/user/me/", config);
      setOngId(response?.data[0]?.ong_id?.id);
      setUser(response?.data[0]);
    }
  };

  const [file, setFile] = useState([
    {
      title: "Documento oficial com foto *",
      type: "documentoOficial",
      documentType: "cpf",
      file: undefined,
      required: true,
    },
    {
      title: `Alvará de funcionamento ${
        localStorage.getItem("@Vision/document") === "CPF" ? "" : "*"
      }`,
      type: "alvaraFuncionamento",
      documentType: "cpf",
      file: undefined,
      required:
        localStorage.getItem("@Vision/document") === "CPF" ? false : true,
    },
    {
      title: "Registro do CNPJ * ",
      type: "regitroCnpj",
      documentType: "cnpj",
      file: undefined,
      required: true,
    },
    {
      title: "Estatuto Social",
      type: "estatutoSocial",
      documentType: "cpf",
      file: undefined,
      required: false,
    },
    {
      title: "Registro no CNAS – Conselho Nacional de Assistência Social",
      type: "registroCNAS",
      documentType: "cpf",
      file: undefined,
      required: false,
    },
    {
      title: "Certificado de Utilidade Pública Federal",
      type: "certificadoFederal",
      documentType: "cpf",
      file: undefined,
      required: false,
    },
    {
      title: "Prêmios e selos",
      type: "premioSelos",
      documentType: "cpf",
      file: undefined,
      required: false,
    },
    {
      title: "Demonstrativos financeiros",
      type: "demostrativoFinanceiro",
      documentType: "cpf",
      file: undefined,
      required: false,
    },
  ]);
  const [isLoading, setIsLoading] = useState({});

  const isDescktop = window.innerWidth > 768;

  //cria uma função para validar o arquivo, e retornar o nome dele
  const validateFile = (file) => {
    const fileName = file.name;

    const fileExtension = fileName.split(".").pop().toLowerCase();
    const validExtensions = ["pdf", "docx", "png", "jpg", "jpeg"];
    if (validExtensions.includes(fileExtension)) {
      return fileName + " - " + getFileSize(file);
    } else {
      alert("Formato inválido");
    }
  };
  // converte o tamanho do arquivo em bytes para megabytes
  const getFileSize = (file) => {
    const fileSize = file.size;
    if (fileSize > 1024 * 1024) {
      return (fileSize / 1024 / 1024).toFixed(2) + "MB";
    } else if (fileSize > 1024) {
      return (fileSize / 1024).toFixed(2) + "KB";
    }
  };

  const cancelUpload = (itemType) => {
    setTimeout(() => {
      const newUpload = upload.filter((item) => item.type !== itemType);
      setUpload(newUpload);
      // setStopUpload(true);
      progressBar(itemType, true);
      setIsLoading({ ...isLoading, [itemType]: false });
      progress = 0;
    }, 100);
  };

  const [currentProgress, setCurrentProgress] = useState(0);

  let progress = 0;
  const progressBar = (itemType, stop = false) => {
    setIsLoading({ ...isLoading, [itemType]: true });
    setTimeout(() => {
      setIsLoading({ ...isLoading, [itemType]: false });
    }, 1000);
  };

  const handleSelectedDocument = (item) => {
    const verify = isSelected.isOpen;

    setIsSelected({
      type: item,
      isOpen: !verify,
    });
  };

  //função para deixar o campo obrigatório

  const handleSubmit = (e) => {
    e.preventDefault();
    const idToken = localStorage.getItem("idToken");
    const storeDocuments = localStorage.getItem("@Vision/document");

    let requiredFieldCPF = !upload.filter(
      (file) => file.type === "documentoOficial"
    ).length;
    let carequiredFieldCNPJ =
      !!upload.filter((file) => file.type === "documentoOficial").length &&
      !!upload.filter((file) => file.type === "alvaraFuncionamento").length &&
      !!upload.filter((file) => file.type === "regitroCnpj").length;

    if (
      (storeDocuments === "CPF" && requiredFieldCPF) ||
      (storeDocuments === "CNPJ" && !carequiredFieldCNPJ)
    ) {
      setIsError(true);
    } else {
      setIsError(false);
      const headers = {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      upload.forEach((i) => {
        let formData = new FormData(); //formdata object

        formData.append("name", i.type);
        formData.append("file", i.file);
        formData.append("ong_id", ongId);

        api
          .post("/documents/", formData, headers)
          .then((response) => {
            changePermission(4);
          })
          .catch((err) => {
            console.log("err", err);
          });
      });

      api
        .patch(`/ong/${user?.ong_id?.id}/`, { current_form: "4" }, headers)
        .then((response) => {
          localStorage.removeItem("formStep");
          localStorage.setItem("FormOngfinished", true);
        })
        .catch((error) => {
          console.log(error);
        });

      return navigate("/tela-de-sucesso");
    }
  };

  const renderProgressbar = (itemType) => {
    return (
      <S.BoxProgressBar currentProgress={currentProgress}>
        <div id="Progress_Status">
          <div id="myprogressBar"></div>
        </div>
        <S.ButtonRemove type="button">
          <S.ImgIconRemove
            src={IconRemove}
            alt={IconRemove}
            onClick={() => cancelUpload(itemType)}
          />
          Cancelar
        </S.ButtonRemove>
      </S.BoxProgressBar>
    );
  };

  const renderUpload = (item) => {
    return (
      <S.ContainerSelect>
        <S.BoxSelect>
          <Dropzone
            onDrop={(file) => {
              progressBar(item.type);
              file = file[0];
              const fileName = validateFile(file);
              if (!fileName) {
                return null;
              }
              const fileSize = file.size;
              if (fileSize > 10485760) {
                alert("O arquivo excede o limite de 10MB");
                return;
              }
              const obj = [
                ...upload,
                {
                  file: file,
                  fileName: fileName,
                  fileSize: getFileSize(file),
                  type: item["type"],
                },
              ];
              setIsSelected({
                type: undefined,
                isOpen: false,
              });
              setUpload(obj);
              setIsError(false);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input
                    required={item.required}
                    {...getInputProps()}
                    name={item.type}
                    onChange={(e) => {
                      if (
                        upload.filter((file) => file.type === item.type)
                          .length === 0
                      ) {
                        progressBar(item.type);
                      }
                      const file = e.target.files[0];
                      const fileName = validateFile(file);
                      if (!fileName) {
                        return null;
                      }
                      const fileSize = file.size;
                      if (fileSize > 10485760) {
                        alert("O arquivo excede o limite de 10MB");
                        return;
                      }
                      const obj = [
                        ...upload,
                        {
                          file: file,
                          fileName: fileName,
                          fileSize: getFileSize(file),
                          type: item["type"],
                        },
                      ];
                      setIsSelected({
                        type: undefined,
                        isOpen: false,
                      });
                      setUpload(obj);
                    }}
                  />

                  <S.BoxUploadSelect>
                    <S.BoxIconUpload>
                      <S.ImgUpload src={IconUpload} alt="IconUpload" />
                    </S.BoxIconUpload>
                    <S.BoxContentSelect>
                      <div>
                        {isDescktop ? (
                          <S.ParagraphSelect>
                            Selecione um arquivo ou arraste e solte aqui
                          </S.ParagraphSelect>
                        ) : (
                          <S.ParagraphSelect>
                            Selecione um arquivo
                          </S.ParagraphSelect>
                        )}
                        <S.InfoExtensions>
                          JPG, PNG ou PDF, o tamanho do arquivo deve ser
                          inferior a 10 MB
                        </S.InfoExtensions>
                      </div>
                      <div>
                        <S.Label>Selecione o arquivo</S.Label>
                      </div>
                    </S.BoxContentSelect>
                  </S.BoxUploadSelect>
                </div>
              </section>
            )}
          </Dropzone>
        </S.BoxSelect>
        <S.HrTwo />
      </S.ContainerSelect>
    );
  };

  return (
    <Layout descktop currentStep="4">
      <S.Container onSubmit={handleSubmit}>
        <Title
          form
          subtitle="Lista de documentos oficiais"
          icon={IconPaperGreen}
          paddingLeftDesk="2.438rem"
        >
          Envio <div>de documentos</div>
        </Title>
        <RenderSubtitle />

        {isDescktop ? null : (
          <S.Description>
            Abaixo, selecione o tipo de documento para anexar o arquivo
            correspondente
          </S.Description>
        )}

        {file
          .filter((item) =>
            localStorage.getItem("@Vision/document") === "CPF" &&
            item.documentType === "cpf"
              ? item
              : localStorage.getItem("@Vision/document") === "CNPJ"
              ? item
              : null
          )
          .map((item, index) => (
            <S.BoxDocuments key={index}>
              <S.InfoDocuments
                isHover={
                  upload.filter((file) => file.type === item.type).length !== 0
                }
                onClick={() => handleSelectedDocument(item.type)}
              >
                <S.ImgDoc
                  src={
                    isLoading[item.type]
                      ? IconDoc
                      : upload.filter((file) => file.type === item.type)
                          .length !== 0
                      ? IconPaperGreenCheck
                      : IconDocWhite
                  }
                  img
                  alt={IconDoc}
                />
                <S.Paragraph
                  loading={isLoading[item.type]}
                  color={
                    upload.filter((file) => file.type === item.type).length !==
                    0
                  }
                >
                  {item.title}
                  {isLoading[item.type] && renderProgressbar(item.type)}
                </S.Paragraph>
                <S.Size>
                  {
                    upload.filter((file) => file.type === item.type)[0]
                      ?.fileSize
                  }
                </S.Size>
              </S.InfoDocuments>

              {isSelected.type === item.type &&
                isSelected.isOpen &&
                upload.filter((file) => file.type === item.type).length === 0 &&
                renderUpload(item)}
            </S.BoxDocuments>
          ))}

        <S.ContentFooter>
          {isError && (
            <S.TextError>
              Por favor, selecione os campos obrigatórios
            </S.TextError>
          )}
          {/* <Footer noBack text="Finalizar" /> */}
          {isDescktop && (
            <S.BoxFooter>
              <Button
                text="Voltar"
                color="#CADBFF"
                fontColor="#5484ED"
                onClick={() =>
                  navigate("/cadastro/estrutura-fisica-e-funcional")
                }
              />
              <Button text="Finalizar" />
            </S.BoxFooter>
          )}
          {!isDescktop && (
            <>
              <Button text="Finalizar" />
              <BtnBackWhite />
            </>
          )}
        </S.ContentFooter>
      </S.Container>
    </Layout>
  );
};

export default SendDocument;
