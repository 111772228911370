import styled from "styled-components/macro";
import { colors } from "../../../styles/Theme";

export const Content = styled.form`
  margin: 0 auto;
  padding-top: 2.961rem;
  display: flex;
  flex-direction: column;
  max-width: 510px;
  height: calc(100vh - 5rem);
  

  @media (min-width: 1024px) {
  }
`;

export const ContentTitle = styled.div`
  padding-bottom: 1.563rem;
  

  @media (min-width: 1024px) {
    padding-bottom: 3.125rem;
  }

  > div {
    display: flex;
  }
`;

export const BoxBtn = styled.div`
  margin: 0.5rem auto 1rem;
  

  @media (min-width: 1024px) {
    width: 19rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }
`;

export const ContentLink = styled.div`
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    line-height: 1;
  }

  @media (min-width: 1024px) {
    padding-bottom: 0;
  }
`;

export const Paragraph = styled.p`
  font-size: 0.75rem;
  font-weight: 300;
  font-family: "Open Sans";
  line-height: 1rem;
  color: ${colors.gray};
  text-align: center;

  @media (min-width: 1024px) {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const ParagraphLink = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${colors.blue};
  cursor: pointer;
  text-decoration-line: ${(props) => props.underline && "underline"};
  text-decoration: none;

  a {
    color: ${colors.blue};
  }

  @media (min-width: 1024px) {
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
  } ;
`;

export const BoxMain = styled.div`
  @media (min-width: 1024px) {
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    width: 22rem;
  }
`;

export const Container = styled.div`
  @media (min-width: 965px) {
    margin-top: -10px;
  }
`;
