import React from "react";
import * as S from "./styles";

//Component
import AddItem from "../../AddItem";
import InputRadio from "../radio";

const InputRadioAddItem = ({
  name,
  title,
  value,
  handleDeleteItem,
  list,
  hasIcon,
  marginLeft,
  isError,
  handleNewItemChange,
  onChange,
  inputValue,
  addNewItem,
}) => {
  return (
    <S.Container marginLeft={marginLeft}>
      <S.Title>{title}</S.Title>
      <InputRadio name={name} value={value} type="radio" onChange={onChange} />
      {value && (
        <AddItem
          name={name}
          value={inputValue}
          handleDeleteItem={handleDeleteItem}
          list={list}
          hasIcon={hasIcon}
          isError={isError}
          handleNewItemChange={handleNewItemChange}
          handleAddItem={addNewItem}
        />
      )}

      {isError && <h1>campo obrigatórios</h1>}
    </S.Container>
  );
};

export default InputRadioAddItem;
