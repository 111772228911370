import React from "react";
import * as S from "./styles";

import Input from "./input";

const InputRadio = ({
  border,
  small,
  name,
  title,
  value,
  padding,
  width,
  titleWidth,
  noSecondLabel,
  column,
  noMarginRight,
  isError,
  onChange,
  noChecked = false,
  firstLabel = "Sim",
  secondLabel = "Não",
  firstValue = true,
  secondValue = false,
}) => {
  const RenderInputRadio = ({
    id,
    label,
    name,
    value,
    checked,
    noMarginRight,
    paddingLeft,
    widthMob,
    error,
    ...rest
  }) => (
    <Input
      small={small}
      id={id}
      name={name}
      label={label}
      value={value}
      checked={checked}
      width={width}
      noMarginRight={noMarginRight}
      paddingLeft={paddingLeft}
      widthMob={widthMob}
      isError={error}
      {...rest}
    />
  );

  return (
    <S.Container>
      {!!title && (
        <S.Title border={border} width={titleWidth}>
          {" "}
          {title}{" "}
        </S.Title>
      )}
      <S.ContentInput padding={padding} column={column}>
        <RenderInputRadio
          id={firstValue}
          name={name}
          label={firstLabel}
          value={firstValue}
          defaultChecked={value === firstValue ? "on" : null}
          noMarginRight={noSecondLabel}
          error={isError}
          onChange={onChange}
          {...(noChecked ? {} : { checked: value === firstValue })}
        />
        {!noSecondLabel && (
          <RenderInputRadio
            id={secondValue}
            name={name}
            label={secondLabel}
            value={secondValue}
            defaultChecked={value === secondValue ? "on" : null}
            noMarginRight={noMarginRight}
            error={isError}
            onChange={onChange}
            {...(noChecked ? {} : { checked: value === secondValue })}
          />
        )}
      </S.ContentInput>
    </S.Container>
  );
};

export default InputRadio;
