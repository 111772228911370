const toSnakeCase = (str) => {
  if (str.toLowerCase() === str) return str;

  let output = "";

  for (let i = 0; i < str.length; i++) {
    const letter = str[i];

    if (letter.toUpperCase() === letter) {
      output += "_" + letter.toLowerCase();

      continue;
    }

    output += letter;
  }

  return output;
};

function isObject(obj) {
  return (
    obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"
  );
}

const transformObjKeys = (obj) => {
  if (isObject(obj)) {
    const output = {};

    Object.keys(obj).forEach((key) => {
      output[toSnakeCase(key)] = transformObjKeys(obj[key]);
    });

    return output;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => transformObjKeys(item));
  }

  return obj;
};

export default transformObjKeys;
