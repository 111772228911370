import styled from "styled-components";
import { colors } from "../../../styles/Theme";

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${colors.black};
`;
export const Input = styled.input`
  background: ${colors.darkGray};
  border: none;
  border-radius: 5px;
  padding-left: 0.75rem;
  height: 2.5rem;
  margin-top: 0.3rem;
  &::placeholder {
    margin-left: 100px;
  }

  @media (min-width: 965px) {
    width: 20rem;
    height: 2rem;
  }
`;
export const RememberLabel = styled.div`
  padding-right: 0.75rem;
  margin-bottom: 3rem;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  font-weight: bold;
  width: 100%;
  margin-top: 8px;
  @media (min-width: 965px) {
    width: 20rem;
  }
`;

export const LabelCheckbox = styled.label`
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${colors.black};
  font-family: "Open Sans";
  align-items: center;
`;

export const RememberInput = styled.input`
  margin-right: 4px;
  background: ${colors.darkGray};
  border: none;
  border-radius: 10px;
`;

export const Text = styled.p`
  border-radius: 10px;
`;

export const ButtonReset = styled.div`
  background: transparent;
  color: #0080ff;
  font-size: 0.75rem;
  text-align: end;
  cursor: pointer;

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;
