import React from "react";
import * as S from "../../pages/Dashboard/formSectionsList/officialContactsAndData/styles";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

const MaskedInput = ({
  value,
  onChange,
  name,
  mask,
  label,
  subLabel,
  paddingBottom,
  sPaddingBottom,
  paddingRight,
  order,
  flex,
  place,
  widthLabel,
  marginLabel,
  width,
  height,
  icon,
  noRequired,
  onMask,
  type,
  error,
  ResponseWidth,
  marginTop,
}) => {
  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: onlyNumbers(event.target.value),
      },
    });
  }

  return (
    <>
      <S.ContentLabel
        width={width}
        sPaddingBottom={sPaddingBottom}
        paddingBottom={paddingBottom}
        paddingRight={paddingRight}
        order={order}
        flex={flex}
        widthLabel={widthLabel}
        marginLabel={marginLabel}
        ResponseWidth={ResponseWidth}
        marginTop={marginTop}
      >
        <S.ContentInfo subLabel={subLabel}>{label}</S.ContentInfo>
        <S.Input
          name={name}
          height={height}
          width={width}
          placeholder={place}
          mask={mask}
          type={type || "text"}
          value={value}
          onMask={onMask}
          onChange={handleChange}
          icon={icon}
          error={error}
          isError={error}
          required={noRequired ? false : true}
          ResponseWidth={ResponseWidth}
        />
      </S.ContentLabel>
    </>
  );
};

export default MaskedInput;
