import styled from "styled-components";
import { colors } from "../../styles/Theme";

export const Subtitle = styled.h2`
  padding-bottom: ${(props) =>
    props.paddingBottomMob && props.paddingBottomMob};
  max-width: 100%;
  width: ${(props) => (props.widthMob ? props.widthMob : "19rem")};
  font-family: ${(props) => props.font && props.font};
  font-size: 1rem;
  font-weight: ${(props) => (props.bold ? props.bold : "600")};
  color: ${(props) => (props.color ? props.color : colors.black)};
  line-height: 1.375rem;
  padding-top: ${(props) => props.paddingTopMob && props.paddingTopMob};
  @media (min-width: 1024px) {
    font-size: ${(props) => (props.homeScreen ? "1.875rem" : "1.125rem")};
    line-height: ${(props) => (props.homeScreen ? "2rem" : "1.532rem")};
    width: ${(props) => (props.homeScreen ? "42rem" : "25rem")};
    font-weight: ${(props) => (props.homeScreen ? "600" : "400")};
    width: ${(props) => (props.widthDesk ? props.widthDesk : "22rem")};
    font-weight: ${(props) => (props.weight ? props.weight : "600")};
    padding-bottom: ${(props) => props.paddingBottom && props.paddingBottom};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "0")};
  }

  @media (min-width: 1024px) and (max-width: 1760px) {
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : "0")};
  }
`;
