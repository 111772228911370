//1
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//Component
import Header from '../../../components/Header'
import Transition from './transition'

import * as S from './styles'
import { useState } from 'react'

const FormSectionsList = ({
  children,
  descktop,
  setInTransition,
  isTransition,
  currentStep
}) => {
  const [largura, setlargura] = useState(window.innerWidth)
  const { pathname } = useLocation()
  useEffect(() => {
    const handleResize = () => {
      setlargura(window.innerWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [largura])
 
  return (
    <S.Container >
      {pathname === '/configuracoes' ? (
        ''
      ) : (
        <Header currentStep={currentStep} />
      )}
      <S.Main descktop={descktop}>
        {pathname === '/configuracoes' ? (
          ''
        ) : largura < 1024 ? (
          pathname === '/transicao' ? (
            <Transition descktop={descktop} setInTransition={setInTransition} />
          ) : (
            ''
          )
        ) : (
          <Transition descktop={descktop} setInTransition={setInTransition} />
        )}

        <S.ContentForm screen={largura} inTransition={isTransition} path={pathname}>
          {children}
        </S.ContentForm>
      </S.Main>
    </S.Container>
  )
}

export default FormSectionsList
