import React from "react";
import { GiPadlock, GiPadlockOpen } from "react-icons/gi";
import * as S from "./styles";

//Components
import Info from "../../components/Info";

const Input = ({
  backg,
  name,
  label,
  subLabel,
  place,
  value,
  type,
  onChange,
  paddingBottom,
  sPaddingBottom,
  paddingRight,
  width,
  height,
  maxLength,
  error,
  icon,
  order,
  flex,
  noRequired,
  isIconInfo,
  widthLabel,
  marginLabel,
  mobWidth,
  marginLabelMob,
  contentWidth,
  maxWidth,
  ResponseWidth,
  marginTop,
  widthInput,
  ...rest
}) => {
  const [currType, setCurrType] = React.useState(type);

  const renderModalInfo = () => (
    <Info isOval low>
      Pedir quando o assistido for menor de 16 anos. Se ele não for, selecione a
      opção “Não se aplica”.
    </Info>
  );
  return (
    <>
      <S.ContentLabel
        width={width}
        contentWidth={contentWidth}
        sPaddingBottom={sPaddingBottom}
        paddingBottom={paddingBottom}
        paddingRight={paddingRight}
        order={order}
        flex={flex}
        widthLabel={widthLabel}
        marginLabel={marginLabel}
        marginLabelMob={marginLabelMob}
        error={error}
        ResponseWidth={ResponseWidth}
        marginTop={marginTop}
      >
        {label && (
          <S.ContentInfo subLabel={subLabel}>
            {label}
            {isIconInfo && renderModalInfo()}
          </S.ContentInfo>
        )}

        <S.ContentInput>
          <S.Input
            backg={backg}
            height={height}
            name={name}
            type={currType || "text"}
            placeholder={place || "Digite..."}
            value={value}
            onChange={onChange}
            isError={error}
            maxlength={maxLength}
            required={noRequired ? false : true}
            icon={icon}
            autoComplete={icon && "off"}
            mobWidth={mobWidth}
            maxWidth={maxWidth}
            widthInput={widthInput}
            {...rest}
          />
          {error && <S.TextError>Campo obrigatório</S.TextError>}

          {!!error && (
            <S.TextError>
              {error.lenght || !!error
                ? error
                : "Dados incorretos - tente novamente"}
            </S.TextError>
          )}
          {type === "password" && (
            <button
              type="button"
              onClick={() =>
                setCurrType(currType === "text" ? "password" : "text")
              }
            >
              {currType === "password" ? <GiPadlock /> : <GiPadlockOpen />}
            </button>
          )}
        </S.ContentInput>
      </S.ContentLabel>
    </>
  );
};
export default Input;
