import styled from 'styled-components';

import { colors } from '../../styles/Theme';

export const Container = styled.section`
  position: relative;
  padding-bottom: 1.125rem;
  width: 60%;
  background-image: url(${props => props.bckg});
  background-repeat: no-repeat;
  background-position: 0% 40%;
  background-size: cover;

  @media ( min-width: 1024px ) {
    width: 55%;
  }
  @media ( max-width: 768px ) {
    display: none;
  }

  @media ( max-width: 1440px ) {
    width: 50%;
    height: 100%;
  background-position: 0% 25%;
  }
`;

export const Figure = styled.figure`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  right: ${props => props.next && '29px'};
  left: ${props => !props.next && '24px'};
  transform: ${props => props.next && 'rotate(180deg)'};
`;

export const IconImg = styled.img`
  opacity: .5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

export const ImageSlider = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ContentBall = styled.div`
  position: absolute;
  bottom: 1.875rem;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
`;

export const Ball = styled.div`
  margin-left: 0.75rem;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.currentSlider ? colors.orange : colors.darkGreen};
  cursor: pointer;

  :hover {
    background-color: ${colors.orange};
  }
`;