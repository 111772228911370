

import React, { useState } from "react";
import * as S from "./styles";

import Modal from './modal';

import { Vector } from "../../assets";

const Info = ({ children, isOval, low, isIconCloseModal }) => {
  const [isClicked, setClicked] = useState(true);

  const handleClicked = () => {
    setClicked(!isClicked);
  };

  return (
    <S.Container isOval={isOval}>
      <S.Button onClick={handleClicked}>
        <img src={Vector} alt='ImageInfo' />
      </S.Button>
      {isClicked && (
        <Modal
          isOval={isOval}
          low={low}
          isIconCloseModal={isIconCloseModal}
          onClick={handleClicked}
        >
          {children}
        </Modal>
      )}
    </S.Container>
  );
};

export default Info;
