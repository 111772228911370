import React, { useState, useEffect } from "react";

// Components
import Btn from "../../Buttons/button";

//Styles
import * as S from "./styles";

const ModalAlert = ({
  icon,
  title,
  subtitle,
  buttonText,
  handleCloseModal,
}) => {
  return (
    <S.Container>
      <S.Modal>
        <S.CloseButton onClick={handleCloseModal} />

        <S.InnerModal>
          <S.TextContainer>
            <S.Icon src={icon} />
            <S.Title>{title || "Opa, algo deu errado"}</S.Title>
            <S.Subtitle>{subtitle || "Opa, algo deu errado"}</S.Subtitle>
          </S.TextContainer>

          <S.ButtonContainer>
            <Btn
              text={buttonText || "Tentar novamente"}
              color={"#0080FF"}
              borderRadius={"5px"}
              marginBottom={"0"}
              marginLeft={"0"}
              onClick={handleCloseModal}
              width={"95%"}
            />
          </S.ButtonContainer>
        </S.InnerModal>
      </S.Modal>
    </S.Container>
  );
};

export default ModalAlert;
