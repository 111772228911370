import React from "react";

// Styles
import * as S from "./styles";

export default function FooterText({ text, link, href, underline }) {
  return (
    <S.Container>
      <S.Paragraph>{text}</S.Paragraph>
      <S.ParagraphLink underline={underline} href={href}>{link}</S.ParagraphLink>
    </S.Container>
  );
}
