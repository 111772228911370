import styled from 'styled-components';

export const BoxConclusion = styled.div`
  width: 100%;
  max-width: ${props => props.small && '48%'};
  min-width: 500px;
  height: 100%;
  display: ${props => props.displayNone ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  animation: ${props => props.displayFlex && `appearContentHelp 1s alternate`};

  @keyframes appearContentHelp {
    from { opacity: 0; }
    to   { opacity: 1;}
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 16.063rem;
  background: #93DC3810;
  border: 1px solid #93DC38;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media(min-width: 1024px) {

  }
`;

export const ActivityName = styled.p`
  padding: 0 .875rem;
  min-width: 10.196rem;
  max-width: 40rem;
  min-height: 1.583rem;
  text-align: center;
  background: #E7E7E7;
  border: 1px solid #C4C4C4;
  border-radius: 3px;
  display: flex;
  align-items: center;
`;

export const TextSave = styled.h2`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.563rem;
  text-align: center;
  color: #68B804;

`;

export const DescriptionSave = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.563rem;
  text-align: center;
  color: #707070;
  max-width: 90%;
  @media (min-width: 1024px) {
    width: 32em;
  }
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const Count = styled.p`
  border-top: 1px solid #68B804;
  font-size: 1.125rem;
  font-weight: 400;
  width: 90%;
  text-align: center;
  color: #707070;
  padding-top:1rem;
`;