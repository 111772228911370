import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// providers
import { useApi } from '../../providers/Api';
import { useLocation } from 'react-router-dom';
//Components
import Header from '../../components/Header';
import SEO from '../../components/HeaderHome';
import CardViHome from '../../components/CardVi';
import FooterHome from '../../components/Footer/FooterHome';

//Styles
import * as S from './styles';

// Logo
import {
  TeamworkBtn,
  HumanEmployeesBtn,
  VectorBtn,
  ProcessingBtn,
} from '../../assets';

const SideBarSettings = ({ homeScreen, children, seo }) => {
  const navigate = useNavigate();
  const { getMyUser } = useApi();
  const [user, setUser] = useState('');
  const [showActivityManagement, setShowActivityManagement] = useState(true);
  const isDesktop = useMemo(
    () => window.innerWidth < 1440 && window.innerHeight < 800,
    []
  );
  useEffect(() => {
    const handleGetUser = async () => {
      const response = await getMyUser();
      const role = response.role.toLowerCase();
      role === 'auxiliar' && setShowActivityManagement(false);
      setUser(response);
    };
    handleGetUser();
  }, []);

  const handleRoute = (route) => {
    navigate(route);
  };

  const isDisabled = useMemo(() => {
    return user.role === 'Auxiliar' || user.role === 'Analista';
  }, [user]);

  const { pathname } = useLocation();
  return (
    <S.Container>
      <Header />
      <SEO seo={seo} />
      <S.Content>
        <S.ContentButtons2 homeScreen={homeScreen}>
          <S.Button
            disabled={isDisabled}
            onClick={() => handleRoute('/cadastro-de-novas-atividades')}
          >
            <S.ImageButton src={TeamworkBtn} alt="" />
            <S.ButtonTxt>
              Cadastro de
              <br />
              novas atividades
            </S.ButtonTxt>
          </S.Button>
          <S.Button
            disabled={isDisabled}
            onClick={() => handleRoute('/cadastro-novos-assistidos')}
          >
            <S.ImageButton src={HumanEmployeesBtn} alt="" />
            <S.ButtonTxt>
              Cadastro de
              <br />
              novos assistidos
            </S.ButtonTxt>
          </S.Button>
          {showActivityManagement && (
            <S.Button onClick={() => handleRoute('/listar-assistidos')}>
              <S.ImageButton src={VectorBtn} alt="" />
              <S.ButtonTxt>
                Gestão de assistidos
                <br />e atividades
              </S.ButtonTxt>
            </S.Button>
          )}

          <S.Button
            disabled={isDisabled}
            onClick={() => handleRoute('/configuracoes')}
          >
            <S.ImageButton src={ProcessingBtn} alt="" />
            <S.ButtonTxt>Configurações</S.ButtonTxt>
          </S.Button>
          <div>{isDesktop ? null : <CardViHome />}</div>
        </S.ContentButtons2>
        <S.LineVertical />
        {children}
      </S.Content>
      <FooterHome screen="home" />
    </S.Container>
  );
};

export default SideBarSettings;
